import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 999,
    color: "#ffffff",
  },
}));

const ModalLoading = (props) => {
  const classes = useStyles();
  const application = useSelector((state) => ({
    ...state.application,
  }));
  return (
    <Backdrop className={classes.backdrop} open={application.modalLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default ModalLoading;
