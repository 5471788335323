import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Topbar from "./Topbar/Topbar";
import Loading from "../components/Loading";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: "#F8F8F8",
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: 10,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: theme.palette.primary.main,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    // width: theme.spacing(7) + 1,
    width: "5% !important",
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8) + 1,
    },
  },
  toolbar: {
    display: "flex",
    "& img": {
      margin: "0 auto",
    },
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  body: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  content: {
    flexGrow: 1,
    width: "100%",
    marginTop: "80px",
  },
  navItems: {
    flexGrow: 1,
    display: "flex",
    padding: "52px 18px 0",
    "& li": {
      width: "auto",
      padding: 0,
    },
    "& li:nth-child(2)": {
      paddingLeft: 0,
    },
  },
  item: {
    padding: "0 10px",
    textTransform: "none",
    marginTop: 7,
    height: 56,
    fontSize: 16,
    borderBottom: "2px solid transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  active: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    borderBottom: "2px solid #182C52",
    borderRadius: 0,
    "& $icon": {
      color: theme.palette.primary.contrastText,
    },
  },
}));

export default function MiniDrawer(props) {
  const classes = useStyles();
  // const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { children } = props;
  // const dispatch = useDispatch();
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

 
 
 
  return (
    <div className={classes.root}>
      <Loading />
      <CssBaseline />
      <Topbar onSidebarOpen={handleDrawerOpen} />
      {/* <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}>
        <div className={classes.toolbar}>
          <img alt="Logo" src="/images/logos/logo-sm.svg" width={'30px'} />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <SidebarNav />
      </Drawer> */}
      <main className={classes.content}>
        {/* <div className={classes.toolbar} /> */}
        {/* <List component="ul" className={classes.navItems}>
          {pages.map(page => (
            <ListItem
              disableGutters
              key={page.title}
              onClick={e => {
                // restForm(page);
                setSelectedMenu(page.title);
              }}>
              <Button
                activeClassName={classes.active}
                className={classes.item}
                component={CustomRouterLink}
                to={page.href}>
                {page.title}
              </Button>
            </ListItem>
          ))}
        </List> */}
        {children}
        {/* <Footer /> */}
      </main>
    </div>
  );
}
