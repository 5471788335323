import { makeStyles } from '@material-ui/core'


export default makeStyles((theme) => ({
    createBtnProductGroup: {
        color: "white",
        backgroundColor: "#182C52",
        "&:hover": {
            //backgroundColor: "#FE5000",
           backgroundColor: "#182C52",
        },
       
    },
}));