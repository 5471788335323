import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Dialog, IconButton, Typography, Box } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  dialogWidth: {
    "& div.MuiDialog-paper": {
      width: 1920,
    },
  },
  dialogMediumWidth: {
    "& div.MuiDialog-paper": {
      width: 800,
    },
  },
  dialogSmallWidth: {
    "& div.MuiDialog-paper": {
      width: 500,
    },
  },
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: "16px 24px 0",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    borderBottom: "1px solid #182C52",
    width: "max-content",
    borderRadius: 0,
    lineHeight: 2,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({}))(MuiDialogContent);

export default function DialogBox(props) {
  const classes = useStyles();
  const { handleClose, header, children, open, width, contentStyle, height } = props;

  return (
    <Box component="div">
      <Dialog
        className={width === 'medium' ? classes.dialogMediumWidth : width ? classes.dialogWidth : classes.dialogSmallWidth}
        maxWidth="xl"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        height={height}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {header}
        </DialogTitle>
        <DialogContent style={contentStyle ? contentStyle : {}}>{children}</DialogContent>
      </Dialog>
    </Box>
  );
}
