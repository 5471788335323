import React, { useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import PieChart from "../../../Common/Charts/PieChart";
import { toCurrencyFormat } from "../../../Common/Utils/ClientUtil";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import DialogBox from "views/Common/Modals/Dailog";
import palette from "../../../../theme/palette";
import useStyles from './Style'




const DashboardContent = (props) => {
  const classes = useStyles();
  const [expandDialogOpen, setExpandDialogOpen] = useState(false);
  const [id, setId] = useState(null);
  const { campaignDetail = [] } = props;


  //dialog open function

  const handleExpandDialogOpen = (id) => {
    setId(id);
    setExpandDialogOpen(true);
  };

  //dilog close function

  const handleExpandDialogClose = () => {
    setExpandDialogOpen(false);
  };
  return (
    <>
      <DialogBox
        open={expandDialogOpen}
        handleClose={handleExpandDialogClose}
        header={
          <Typography className={classes.dashbrdCampType}>
            {id?.campagian_group_name}
          </Typography>
        }
        children={
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ padding: "25px 0px 25px 0px" }}>
                <PieChart

                  test={"tData"}
                  toRound={true}
                  legendOffset={40}
                  data={id?.data?.map((rdata) => rdata?.camp_cnt)}
                  testAvg={id?.data?.reduce(
                    (n, { camp_cnt }) => n + camp_cnt,
                    0
                  )}
                  labels={id?.data?.map((rdata) => rdata?.x)}
                  colors={[
                    "#F9AD9F",
                    "#CE6EF7",
                    "#9FE9F9",
                    "#089191",
                    "#CEA3FF",
                  ]}
                  sparkline={false}
                  height={180}
                />
              </div>
            </Grid>
          </Grid>
        }
      ></DialogBox>
      {campaignDetail.length > 0 ? (
        campaignDetail.map((item, i) => {

          return (
            <Box className={classes.contentInner} key={i}>
              <Typography className={classes.dashbrdCampType}>
                <Box
                  component="div"
                  container
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "9px",
                    cursor: "pointer",
                  }}
                >
                  <Typography component="span">{item.campagian_group_name}</Typography>
                  <ZoomOutMapIcon
                    fontSize="small"
                    style={{ color: palette.primary.main, position: "absolute", right: 0 }}
                    onClick={(e) => {
                      handleExpandDialogOpen(item);
                    }}
                  />
                </Box>
              </Typography>
              <PieChart
                key={i}
                test={"tData"}
                toRound={true}
                data={item?.data?.map((rdata) => rdata?.camp_cnt)}
                testAvg={item?.data?.reduce(
                  (n, { camp_cnt }) => n + camp_cnt,
                  0
                )}
                labels={item?.data?.map((rdata) => rdata?.x)}
                colors={[
                  "#F9AD9F",
                  "#CE6EF7",
                  "#9FE9F9",
                  "#089191",
                  "#CEA3FF",
                ]}
                sparkline={true}
                height={120}
              />

              <Typography variant="h4">
                <Typography component="span" style={{ fontSize: "23px", marginRight: "3px" }}>
                  {toCurrencyFormat(
                    item?.data?.reduce((n, { cust_cnt }) => n + cust_cnt, 0)
                  )}
                </Typography>
                <PeopleAltOutlinedIcon
                  fontSize={"small"}
                  className={classes.usersIcon}
                />
              </Typography>

            </Box>
          );
        })
      ) : (
        <p style={{ margin: "0 auto" }}>No data found</p>
      )}
    </>
  );
};

export default DashboardContent;
