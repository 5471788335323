import React, { useEffect, useState } from "react";

import {
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { APIS } from "../../config";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextFieldElement from "views/Common/Filters/TextFieldElement";
import DialogBox from "views/Common/Modals/Dailog";
import secureAxios from "../../config/api-config";
import AddGroup from "./AddGroup";
import MultilineTextField from "views/Common/Filters/MultilineTextField";
import { useDispatch } from "react-redux";
import MessageDialog from "views/Common/MessageDialog/MessageDialog";
import SingleSelectDropdown from "views/Common/Filters/SingleSelectDropdown";
import useStyles from './style'


const CreateGroupForm = ({ onAdd, initialProducts }) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false);
  const [products, setProducts] = useState(JSON.parse(JSON.stringify(initialProducts)));
  const [division, setDivision] = useState({});
  const [progress, setProgress] = useState(false);
  const [message, setMessage] = useState("");
  const [opens, setOpens] = useState(false);
  const [type, setType] = useState("");
  const [GroupCategory] = useState(["Happy Harvest", "Crofton", "Happy Farms", "Little Salad Bar", "Simply Nature", "Kellogg's", "Fit & Active"]);
  const validSchema = Yup.object().shape({
    NAME: Yup.string().required("Required").matches(/^[a-zA-Z0-9_'|.?,()@ [\]]+$/, "Some special char are not allowed"),
    DESCRIPTION: Yup.string().matches(/^[a-zA-Z0-9_'|.?,()@ [\]]+$/, "Some special char are not allowed"),
    CATEGORY: Yup.string()
  });

  useEffect(() => {
    setProducts(JSON.parse(JSON.stringify(initialProducts)));
  }, [initialProducts])


  const productgroupSave = (values, { resetForm }) => {
    setProgress(true);
    dispatch({
      type: "LOADING",
      payload: true
    })
    const products = [];
    for (let key in division) {
      division[key].classes.forEach((c) => {
        products.push({
          division_nbr: c.divCode,
          dept_nbr: c.deptCode,
          class_nbr: c.code
        });
      })
    }
    secureAxios
      .post(APIS.MASTER.PRODUCT_GROUP_SAVE, {
        product_grp_name: values.NAME,
        prd_grp_desc: values.DESCRIPTION,
        prd_grp_catg: values.CATEGORY,
        products
      })
      .then((res) => {
        dispatch({
          type: "LOADING",
          payload: false
        })
        setOpens(true);
        setType("success");
        setMessage("Created new group successfully");
        setProgress(false);
        onAdd();
        resetForm();
        setDivision({});
        setProducts(JSON.parse(JSON.stringify(initialProducts)));
      }).catch((err) => {
        setOpens(true);
        setType("error");
        setMessage(err.response.data.errors.map(e => e.msg).join());
        setProgress(false);
        dispatch({
          type: "LOADING",
          payload: false
        })

      });
  }

  const handleProductModal = (div, prod) => {
    setDivision(JSON.parse(JSON.stringify(div)));
    setProducts(JSON.parse(JSON.stringify(prod)));
    setOpen(false);
  };

  const getProductsCount = () => {
    let count = 0;
    Object.keys(division).forEach(
      (key) => (count += division[key].classes.length)
    );
    return count;
  };
  //close the popup
  const handleClose = () => {
    setOpens(false);
  };
  return (
    <>
      <MessageDialog
        type={type}
        open={opens}
        message={message}
        close={handleClose}
      />
      <Formik
        initialValues={{
          NAME: "",
          DESCRIPTION: "",
          CATEGORY: "",
          PRODUCTS: [],
        }}
        validationSchema={validSchema}
        onSubmit={(values, params) => {
          productgroupSave(values, params);
        }}
      >
        {({
          isValid,
          dirty,
          resetForm,
          handleChange,
          values
        }) => (
          <Form autoComplete="off" noValidate style={{ padding: '10px 10px', textAlign: 'left' }} >
            <Typography className={classes.sectionHeadingLarge}>Create New Group</Typography>
            <Grid container spacing={3}>
              {/* <Grid item xs={12}>
                <Typography className={classes.sectionHeading}>
                  Enter Group Details
                </Typography>
              </Grid> */}

              <Grid item xs={6}>
                <TextFieldElement
                  name={"NAME"}
                  label="Group Name"
                  width={"w100"}
                />
              </Grid>
              <Grid item xs={6}>
                {/* <TextFieldElement
                  name={"CATEGORY"}
                  label="Group Category"
                  width={"w100"}
                /> */}
                <SingleSelectDropdown
                  width={"w100"}
                  value={values.CATEGORY}
                  name={"CATEGORY"}
                  handleMethod={handleChange}
                  label={"Group Category"}
                  options={GroupCategory} noneRequired={true} />
              </Grid>
              <Grid item xs={12}>
                <MultilineTextField
                  name={"DESCRIPTION"}
                  label="Group Description"
                  width={"w100"}
                />
              </Grid>
              <Grid item xs={10} >
                <Button
                  variant="outlined"
                  disabled={!!(Object.keys(initialProducts).length === 0)}
                  onClick={() => setOpen(true)}
                  className={classes.audSelectBtn}
                >
                  Select Product List
                </Button>

                {Object.keys(division).length ? (
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.successMsg}
                  >
                    &nbsp; {`${getProductsCount()} Product List(s) Selected`}
                  </Typography>
                ) : (
                  <Typography variant="body2" component="em">
                    &nbsp; {"No Product List Selected"}
                  </Typography>
                )}
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} className={"textRight"}>
                  <Button
                    className={classes.cancelBtn}
                    type="button"
                    variant="contained"
                    onClick={() => {
                      resetForm();
                      setDivision({});
                      setProducts(JSON.parse(JSON.stringify(initialProducts)));
                    }}
                  >
                    Reset
                  </Button>

                  <Button
                    className={classes.primaryBtn}
                    type="Submit"
                    variant="contained"
                    color="primary"
                    disabled={!(dirty && isValid) || Object.keys(division).length === 0 || progress}
                  >
                    Save
                  </Button>


                </Grid>
              </Grid>
            </Grid>

            <DialogBox
              open={open}
              handleClose={() => setOpen(false)}
              width={"medium"}
              header={<div><span>Add Product</span> <span style={{ color: '#888', marginLeft: '20px', fontSize: '12px' }}><strong>Hierarchy:</strong> Division &gt; Department &gt; Class</span></div>}
              contentStyle={{ marginBottom: '70px' }}
              children={
                <AddGroup
                  handleProductModal={handleProductModal}
                  products={products}
                  division={division}
                />
              }
            ></DialogBox>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateGroupForm;
