import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid, Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  info: {
    padding: "0 20px 20px",
    textAlign: "center",
    "& h3": {
      lineHeight: 10,
    },
  },
  paper: {
    position: "relative",
    padding: "10px",
    borderRadius: "0px",
  },
}));

const DisplayContainer = ({ title, content, contentRight, paperElevation }) => {
  const classes = useStyles();

  return (
    <Box component="div" container className={classes.info}>
      <Paper
        className={classes.paper}
        elevation={paperElevation ? paperElevation : 1}
      >
        <>
          <Grid item md={12}>
            <Grid
              container
              style={{ display: title ? "flex" : "none", height: "60px" }}
            >
              <Grid item md={3}>
                <Typography
                  variant="subtitle1"
                  align="left"
                  style={{ color: "#464A53" }}
                >
                  {title}
                </Typography>
              </Grid>
              <Grid item md={9}>
                {contentRight}
              </Grid>
            </Grid>
          </Grid>

          {content}
        </>
      </Paper>
    </Box>
  );
};

export default DisplayContainer;
