import React from "react";
import {
  Box,
  Typography,
  Switch,
  FormControlLabel,
} from "@material-ui/core";

const ToggleButtonCom = (props) => {
  const { outlierChecked, setOutlierChecked } = props;
  const toggleOutlierChecked = () => {
    setOutlierChecked((prev) => !prev);
  };

  return (
    <Box
      component="div"
      style={{ textAlign: "right" }}
    >
      <Typography
        component="h4"
        variant="h4"
        style={{
          fontSize: 14,
          color: "#333333",
        }}
      >
        Outlier Removal
      </Typography>
      <Typography component="h4" variant="h4">
        <Typography component="p" style={{ display: "inline-block" }}>
          {"OFF"}
        </Typography>
        <FormControlLabel
          style={{ margin: "0 15px", transform: "inherit" }}
          value="outlier"
          control={
            <Switch
              size="small"
              checked={outlierChecked}
              onChange={toggleOutlierChecked}
              color="primary"
            />
          }
          label=""
          labelPlacement="start"
        />
        <Typography component="p" style={{ display: "inline-block" }}>
          {"ON"}
        </Typography>
      </Typography>
    </Box>
  );
};
export default ToggleButtonCom;