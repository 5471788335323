import React, { useEffect, useState } from "react";
import ReactApexCharts from "react-apexcharts";

import { toCurrencyFormat, numberWithCommas } from "../Utils/ClientUtil";
import { toMetricFormat } from "../Utils/ClientUtil";



const BarGroup = (props) => {

  const [chartInit, setChartInit] = useState();
  // const colors = palette.chartColors.trends;

  const { xAxisTitle, yAxisTitle, data, xAxis, kpiName } = props;

  useEffect(() => {
    setChartInit({
      series: data ? data : [],
      options: {
        colors: ["#8F2EFF", "#F7C76E"],
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            top: -20,
            bottom: 0,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "50%",
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return kpiName ? toMetricFormat(val, kpiName) : val;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        xaxis: {
          show: true,
          categories: xAxis ? xAxis : [],
          axisTicks: {
            show: false,
          },
          title: {
            text: xAxisTitle ? xAxisTitle : "",
            style: {
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 100,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        yaxis: {
          show: true,
          title: {
            text: yAxisTitle ? yAxisTitle : "",
            style: {
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 100,
              cssClass: "apexcharts-yaxis-title",
            },
          },
          axisBorder: {
            show: true,
          },
          labels: {
            formatter: function (value) {
              return toCurrencyFormat(value);
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (y) {
              return numberWithCommas(y.toFixed(2));
            },
          },
        },
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
        },
      },
    });
  }, [data,kpiName,xAxis,xAxisTitle,yAxisTitle]);

  return (
    <React.Fragment>
      {chartInit && (
        <>
          <ReactApexCharts
            options={chartInit?.options}
            series={chartInit?.series}
            type="bar"
            height={290}
          />
        </>
      )}
    </React.Fragment>
  );
};
export default BarGroup;
