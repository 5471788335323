import React, { useState } from "react";
import { Box, Grid, Typography, Divider } from "@material-ui/core";
import MeasureCarousel from "./MeasureCarousel";
import InfoComp from "components/InfoComp";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { toMetricFormat } from "../Common/Utils/ClientUtil";
import DialogBox from "../Common/Modals/Dailog";
import ExpandDialog from "./ExpandDialog";
import useStyles from './style'

const PurchaseBehavior = (props) => {
  const classes = useStyles();
  //define props
  const { purchaseData,  testControlChecked } = props;

  //define state
  const [expandDialogOpen, setExpandDialogOpen] = useState(false);
  const [expandDialogData, setExpandDialogData] = useState({
    val: {},
    view: "",
  });

  //function for dialog open
  // const handleExpandDialogOpen = (val, view) => {
  //   setExpandDialogOpen(true);
  //   setExpandDialogData({
  //     ...expandDialogData,
  //     val: val,
  //     view: view,
  //   });
  // };
  const handleExpandDialogClose = () => {
    setExpandDialogOpen(false);
  };
  return (
    <>
      {Object?.keys(purchaseData)?.length && purchaseData.kpiRows.length ? (
        purchaseData?.kpiRows?.map((item, index) => (

          <Grid
            container
            spacing={3}
            className={classes.accordBlock}
            key={index}
          >
            <Grid item xs={4}>
              <Box component="div" className={classes.sideBlock}>
                <Typography component="h4" variant="subtitle1">
                  {item.kpiLabel}
                  {/* {` at ${selectedBrandLevel} level`} */}
                  <Typography component="span" variant="subtitle1">
                    <InfoComp
                      content={<InfoOutlinedIcon fontSize={"small"} />}
                      data={item.kpiDefn}
                    />
                  </Typography>

                </Typography>
              </Box>
              {/* LHS card  */}
              {testControlChecked && (
                <div className={classes.card}>
                  <div>
                    <div className={classes.innerCard}>
                      <div className={classes.cardText}>
                        {item?.kpiName === "responder_rate" ? "Response Rate Abs Lift" : "Lift %"}
                      </div>
                      <div className={classes.cardText}>
                        {item?.kpiName === "responder_rate" ? "Campaign Purchasers Abs Lift" : "Absolute Lift"}
                      </div>
                    </div>
                    <div className={classes.innerCard}>
                      <div className={classes.liftText}
                        style={{
                          color:
                            item?.stat_sig === null
                              ? "#E01F29" :
                              item?.stat_sig === undefined
                                ? "#E01F29" :
                                item?.stat_sig < 0.05
                                  ? "#24A148"
                                  : item?.stat_sig > 0.2
                                    ? "#E01F29"
                                    : "#ff8f00",

                        }}>
                        {item?.kpiName === "responder_rate" ?
                          (item?.absLift === null ? "NA" : toMetricFormat((item?.absLift), item?.kpiName))
                          : (item?.lift === null ? "NA" : item?.lift?.toFixed(2) + "%")}

                      </div>
                      <div className={classes.absLiftText}>

                        {item?.kpiName === "responder_rate" ?
                          (item?.lift === null ? "NA" : item?.lift?.toFixed(0))
                          : (item?.absLift === null ? "NA" : toMetricFormat((item?.absLift), item?.kpiName))
                        }
                      </div>
                    </div>
                  </div>
                  <Divider variant="middle" className={classes.dividerPurchase} />
                  <div>
                    <div className={classes.innerCard}>
                      <div className={classes.cardText}>Test</div>
                      <div className={classes.cardText}>Control</div>
                    </div>
                    <div className={classes.innerCard}>
                      <div className={classes.absLiftText}>
                        {item?.testAverage === null ? "NA" : toMetricFormat(item?.testAverage, item?.kpiName)}
                      </div>
                      <div className={classes.absLiftText}>
                        {item?.controlPost === null ? "NA" : toMetricFormat(item?.controlPost, item?.kpiName)}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* unchecked */}

              {!testControlChecked && (
                <div className={classes.card}>
                  <div>
                    <div className={classes.innerCard}>
                      <div className={classes.cardText}>
                        {item?.kpiName === "responder_rate" ? "Response Rate Abs Lift" : "Lift %"}
                      </div>
                      <div className={classes.cardText}>
                        {item?.kpiName === "responder_rate" ? "Campaign Purchasers Abs Lift" : "Absolute Lift"}
                      </div>
                    </div>
                    <div className={classes.innerCard}>
                      <div className={classes.liftText}
                        style={{
                          color:
                            item?.stat_sig === null
                              ? "#E01F29" :
                              item?.stat_sig === undefined
                                ? "#E01F29" :
                                item?.stat_sig < 0.05
                                  ? "#24A148"
                                  : item?.stat_sig > 0.2
                                    ? "#E01F29"
                                    : "#ff8f00",

                        }}
                      >
                        {/* {item?.preAbsolutelift === null ? "NA" : item.preAbsolutelift?.toFixed(2)}% */}
                        {item?.kpiName === "responder_rate" ?
                          (item?.preAbsolutelift === null ? "NA" : item?.preAbsolutelift)
                          : (item?.prelift === null ? "NA" :item?.prelift)
                        }
                      </div>
                      <div className={classes.absLiftText}>
                        {item?.kpiName === "responder_rate" ?
                          (item?.prelift === null ? "NA" : item?.prelift?.toFixed(0))
                          : (item?.preAbsolutelift === null ? "NA" : toMetricFormat((item?.preAbsolutelift), item?.kpiName))
                        }
                      </div>
                    </div>
                  </div>
                  <Divider variant="middle" className={classes.dividerPurchase} />
                  <div>
                    <div className={classes.innerCard}>
                      <div className={classes.cardText}>Test(Pre/Post)</div>

                      <div className={classes.cardText}>Control(Pre/Post)</div>
                    </div>

                    <div className={classes.innerCard}>
                      <div className={classes.absLiftText}>
                        {item?.benchmark === null ? "NA" : toMetricFormat(item?.benchmark, item?.kpiName)}/
                        {item?.testAverage === null ? "NA" : toMetricFormat(item?.testAverage, item.kpiName)}
                      </div>
                      <div className={classes.absLiftText}>
                        {item?.controlPre === null ? "NA" : toMetricFormat(item?.controlPre, item?.kpiName)}/
                        {item?.controlPost === null ? "NA" : toMetricFormat(item?.controlPost, item?.kpiName)}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={8}>
              <MeasureCarousel
                data={item?.segmentArr}
                view={"purchaseBehavior"}
                type={item?.kpiType}
                kpiName={item?.kpiName}
                kpiLabel={item?.kpiLabel}
                testControlChecked={testControlChecked}
              />
            </Grid>
          </Grid>
        ))
      ) : (
        <Typography component="h3" variant="h3" className={classes.info}>
          {"No records to display"}
        </Typography>
      )}
      <DialogBox
        width={1920}
        open={expandDialogOpen}
        handleClose={handleExpandDialogClose}
        header={`${expandDialogData?.val?.kpiLabel} at AEO Inc. level`}
        children={
          <ExpandDialog
            data={expandDialogData}
            view={"purchaseBehavior"}
            testControlChecked={testControlChecked}
          />
        }
      ></DialogBox>
    </>
  );
};

export default PurchaseBehavior;
