import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Radio,
  RadioGroup,
  Typography,
  FormControl,
  FormControlLabel
} from '@material-ui/core';
import { ErrorMessage } from 'formik';

const useStyles = makeStyles(theme => ({
  formLabel: {
    margin: '5px 0 10px',
    color: '#182C52',
    fontWeight: 500
  },
  errorText: {
    color: 'red',
    fontSize: 12
  }
}));

const RadioGroupElement = props => {
  const classes = useStyles();
  const { name, label, handleMethod, options, defaultvalues, disabled } = props;
  return (
    <>
      <FormControl component="fieldset">
        {label ? <Typography className={classes.formLabel}>{label}</Typography> : ""}
        <RadioGroup
          row
          name={name}
          onChange={handleMethod}
          defaultValue={defaultvalues}>
          {options.map(option => (
            <FormControlLabel
              disabled={disabled}
              key={option}
              value={option}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <Typography className={classes.errorText}>
        <ErrorMessage name={name} />
      </Typography>
    </>
  );
};

export default RadioGroupElement;
