import React, { useEffect, useState } from "react";
import DialogBox from "views/Common/Modals/Dailog";
import { APIS } from "../../config";
import CustomMaterialTable from "../Common/CustomMaterialTable";
import secureAxios from "../../config/api-config";
import {
  Box,
  Typography,
  TableHead,
  TableCell,
  TableRow,
  CircularProgress,
} from "@material-ui/core";

import useStyles from './style'

const ViewGroup = ({ data, onClose, productsList }) => {
  const classes = useStyles();
  const [products, setProducts] = useState(null);
  const [load, setLoad] = useState(false)
  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchDetails = () => {
    setLoad(true)
    secureAxios
      .get(APIS.MASTER.PRODUCT_GROUPS + `/${data.product_grp_id}`)
      .then((res) => {
        setLoad(false)
        const finalProducts = [];

        if (res.status === 200) {
          if (res.data.products) {
            for (let p of res.data.products) {
              if (productsList[p.division_nbr]) {
                productsList[p.division_nbr].checked = true;
                if (productsList[p.division_nbr]) {
                  productsList[p.division_nbr].departments[
                    p.dept_nbr
                  ].checked = true;
                  if (
                    productsList[p.division_nbr].departments[p.dept_nbr].class[
                    p.class_nbr
                    ]
                  ) {
                    finalProducts.push({
                      ...p,
                      division: productsList[p.division_nbr].name,
                      department:
                        productsList[p.division_nbr].departments[p.dept_nbr]
                          .name,
                      class:
                        productsList[p.division_nbr].departments[p.dept_nbr]
                          .class[p.class_nbr].name,
                    });
                    productsList[p.division_nbr].departments[p.dept_nbr].class[
                      p.class_nbr
                    ].checked = true;
                  }
                }
              }
            }
          }
        }
        // console.log('finalProducts', finalProducts);
        setProducts(finalProducts);
      }).catch((err) => {
        setLoad(false)
        console.log(err);
      });
  };
  return (
    <DialogBox
      open={true}
      handleClose={onClose}
      width={"medium"}
      height={350}
      header={data.product_grp_name}
      children={
        <Box
          component="div">
          <Typography variant="body2" className="mt10">
            Category: {data.prd_grp_catg ? data.prd_grp_catg : "NA"}
          </Typography>
          <Typography variant="body2" className="mb20">
            Description: {data.prd_grp_desc ? data.prd_grp_desc : "NA"}
          </Typography>
          {
            load ?
              <Box
                component="div" style={{
                  display: "flex", justifyContent: "center", alignItems: "center",
                  margin: "20px 0"
                }}>
                <CircularProgress size={32} />
              </Box> :
              <>
                {products && <CustomMaterialTable
                  title=""
                  columns={[
                    {
                      render: (rowData) => { return rowData.division }
                    },
                    {
                      render: (rowData) => { return rowData.department }
                    },
                    {
                      render: (rowData) => { return rowData.class },
                    },
                  ]}
                  data={products}
                  options={{
                    sorting: true,
                    search: false,
                    toolbar: false,
                    paging: true,
                    pageSize: 10,
                    filtering: false,
                    maxBodyHeight: 300,
                    position: 'sticky',
                  }}
                  components={{
                    Container: props => <Box
                      component="div" className="tblScroll">{props.children}</Box>,
                    Header: () => {
                      return (
                        <TableHead
                          className={classes.tableRightBorder}
                        >
                          <TableRow>
                            <TableCell >Division</TableCell>
                            <TableCell >Department</TableCell>
                            <TableCell >Class</TableCell>
                          </TableRow>
                        </TableHead>
                      );
                    },
                  }}
                ></CustomMaterialTable>}
              </>

          }

        </Box>
      }
    ></DialogBox>
  );
};

export default ViewGroup;
