import { makeStyles } from "@material-ui/core";
import palette from "theme/palette";

export default makeStyles((theme) => ({
    root: {
      padding: "0 16px",
      marginTop: 45,
    },
    pageHeading: {
      fontSize: 22,
      color: "#333333",
      marginBottom: 10,
    },
    audListItem: {
      display: "flex",
      flexDirection: "column",
      borderBottom: "1px solid #E6E7E8",
      padding: 10,
      "& p": {
        fontWeight: 500,
        margin: "10px 0",
        "& span": {
          color: palette.text.active,
        },
        "& svg + span": {
          verticalAlign: "top",
          padding: "0 3px",
          color: "#404041",
          fontWeight: 300,
        },
      },
    },
    cardHeading: {
      fontWeight: 400,
      fontSize: 16,
      letterSpacing: "-0.02em",
      color: "#404041",
      paddingBottom: 20,
    },
    cardSubHeading: {
      fontWeight: 400,
      fontSize: 16,
      letterSpacing: "-0.02em",
      color: "#404041",
      padding: "10px 0 0",
    },
    sectionHeading: {
      fontWeight: 400,
      fontSize: 14,
      color: "#404041",
      padding: "20px 0",
    },
    accordGreen: {
      background: "#EAF6ED",
      borderRadius: 2,
      borderLeft: "3px solid #24A148",
      marginBottom: 15,
      "& p": {
        fontSize: 12,
        color: "#404041",
        "& svg": {
          color: "#24A148",
          verticalAlign: "middle",
          marginRight: 5,
        },
      },
    },
    accordRed: {
      background: "#FBE8E9",
      borderRadius: 2,
      borderLeft: "3px solid #E01F29",
      marginBottom: 15,
      "& p": {
        fontSize: 12,
        color: "#404041",
        "& svg": {
          color: "#E01F29",
          verticalAlign: "middle",
          marginRight: 5,
        },
      },
    },
    dualAccord: {
      color: "#E01F29 !important",
    },
    rightText: {
      float: "right",
      color: "#333333",
      cursor: "pointer",
      "& svg": {
        verticalAlign: "bottom",
        color: "#333333",
      },
    },
    description: {
      background: "#FCFCFC",
      border: "1px solid #E6E7E8",
      padding: 10,
      fontSize: 12,
    },
    heading: {
      fontSize: 14,
      color: "#929497",
      marginBottom: 5,
    },
    value: {
      fontSize: 14,
      color: "#333333",
      fontWeight: 500,
    },
    groupBtn: {
      background: "#FBA504",
      color: "#ffffff",
      borderRadius: 0,
      textTransform: "capitalize",
      padding: "2px 8px",
      textAlign: "left",
      "&:hover": {
        backgroundColor: "#FBA504",
      },
    },
    campIcon: {
      padding: "3px 5px",
      background: "#E5EEFF",
      borderRadius: 2,
      fontWeight: 500,
      fontSize: 12,
      color: "#182C52",
      marginRight: 10,
      "& svg": {
        verticalAlign: "middle",
        fontSize: 12,
        marginRight: 3,
      },
    },
    durationArea: {
      color: palette.primary.main,
      fontWeight: 500,
      fontSize: 14,
      "& span": {
        color: palette.text.active,
      },
      "& svg": {
        fontSize: 18,
        marginRight: 5,
        verticalAlign: "text-top",
      },
    },
    detailBlock: {
      width: "95%",
      margin: "0 auto",
    },
    primaryBtn: {
      borderRadius: 0,
      textTransform: "capitalize",
      height: "36px",
    },
    cancelBtn: {
      "&:hover": {
        backgroundColor: "#e7e7e7",
      },
      margin: "0 16px 0",
      borderRadius: "0",
      textTransform: "capitalize",
    },
    duration: {
      padding: "0 20px",
      color: "#2d2d2d",
    },
  }));


//campaign view style