import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Paper, Tab, Tabs, AppBar } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { updateNestedTabNavigation } from "../../../redux/measure/measureLandingRedux";

const useStyles = makeStyles((theme) => ({
  tabArea: {
    margin: theme.spacing(0, 0),
    "& button": {
      textTransform: "capitalize",
    },
  },
  tab: {
    "& button": {
      padding: 0,
      margin: "15px 30px 0 0",
      "& span.MuiTab-wrapper": {
        padding: "10px 0",
        color: "#182C52",
        borderBottom: 0,
        lineHeight: 1,
      },
    },

    "& button.Mui-selected": {
      "& span.MuiTab-wrapper": {
        color: "#ffffff",
        background: "#182C52",
      },
    },

    "& span.MuiTabs-indicator": {
      display: "none",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const NestedTabSection = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const appReduxState = useSelector((state) => ({
    ...state.measureLandingDetail,
  }));
  const { nestedTabNavigation } = appReduxState;
  const { tabItems, variant, data,  outlierChecked,campaignId,testControlChecked} = props;
  const handleChange = (event, newValue) => {
    dispatch(updateNestedTabNavigation(newValue));
  };

  return (
    <AppBar position="static" className={classes.tabArea} elevation={0}>
      <Paper square elevation={0}>
        <Tabs
          className={classes.tab}
          value={nestedTabNavigation}
          onChange={handleChange}
          variant={variant}
          indicatorColor="primary"
          textColor="primary"
        >
          {tabItems.map((item) => (
            <Tab
              key={item.id}
              label={<p>{item.title} </p>}
              {...a11yProps(item.id)}
            />
          ))}
        </Tabs>
        <Suspense fallback={"Loading..."}>
          <TabPanel value={nestedTabNavigation} index={0}>
            <props.Tab1
              data={data}
              campaignId={campaignId}
              
              outlierChecked={outlierChecked}
              testControlChecked={testControlChecked}
            />
          </TabPanel>
         
              <TabPanel value={nestedTabNavigation} index={1}>
                <props.Tab2
                  data={data}
                  campaignId={campaignId}
                 
                  outlierChecked={outlierChecked}
                  testControlChecked={testControlChecked}
                />
              </TabPanel>
             

        </Suspense>
      </Paper>
    </AppBar>
  );
};

export default NestedTabSection;
