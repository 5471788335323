import { combineReducers } from "redux";
import application from "./redux/reducers/application";
import campDetailReducer from "./redux/campDetail/campaignDetailRedux";
import dashBoardReducer from "./redux/dashboard/dashBoardRedux";
import measureLandingRedux from "./redux/measure/measureLandingRedux";
import drilldownDetailReducer from "./redux/measure/DrilldownRedux";
import groupViewRedux from "redux/measure/groupViewRedux";

const reducer = combineReducers({
  application,
  campDetail: campDetailReducer,
  dashBoardDetail: dashBoardReducer,
  measureLandingDetail: measureLandingRedux,
  drilldownDetail: drilldownDetailReducer,
  groupView: groupViewRedux
});

export default reducer;
