import IconButton from "@material-ui/core/IconButton";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import MaterialTable from "material-table";
import PropTypes from "prop-types";
import React from "react";
import Icons from "./Utils/Icons";
import palette from "../../theme/palette"

const useStyles1 = makeStyles((theme) => ({
  root: {
    height: 10,
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const CustomMaterialTable = (props) => {

  const { pageSize } = props;
  const rowsPerPageOptions = [5, 10, 25, 50, 100];

  return (
    <MaterialTable
      {...props}
      options={{
        // position: 'sticky',
        debounceInterval: 1000,
        isLoading: true,
        padding: "dense",
        emptyRowsWhenPaging: false,
        // maxBodyHeight: 380,
        headerStyle: {
          fontWeight: "bold",
          position: "inherit",
          textAlign: "left",
          border: '1px solid',
          borderColor: palette.tableColors.border,
          backgroundColor: palette.tableColors.background,
          ...props.options.headerStyle,
        },
        cellStyle: {
          textAlign: "left",
          border: '1px solid',
          borderColor: palette.tableColors.border,
        },
        pageSize,
        pageSizeOptions: rowsPerPageOptions,
        draggable: false,
        ...props.options,
      }}
      icons={Icons}
    />
  );
};

export default CustomMaterialTable;
