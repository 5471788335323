import { applyMiddleware, createStore } from "redux";
import rootReducer from "./reducer";
import { configureStore } from "@reduxjs/toolkit";
import thunk from 'redux-thunk';

// const store = createStore(rootReducer);
const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk]
});

export default store;
