import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Box } from "@material-ui/core";
import LineChart from "../Common/Charts/LineChart";
import retriveMetricFromStr from "../Common/Utils/ClientUtil";


const useStyles = makeStyles((theme) => ({
  chartArea: {
    padding: "20px 0",

    "& p": {
      color: "#333333",
      fontSize: 14,
      textTransform: "capitalize",
    },
  },
}));

const MeasureTrends = (props) => {
  const classes = useStyles();
  const { selectedKPI, graphData, testControlGraphData,startDate } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={6}>
        <Box className={classes.chartArea}>
          <Typography>
            {`Test Vs Extrapolated Control Comparison for ${retriveMetricFromStr(
            selectedKPI?.name
           
          )}`}</Typography>
          <Typography>Start date ({startDate})</Typography>
          <LineChart
            title={selectedKPI?.name}
            xAxis={testControlGraphData?.xAxisExtra}
            series={[
              { name: "Test", data: testControlGraphData?.testDataExtra },
              { name: "Extrapolated Control", data: testControlGraphData?.controlDataExtra },
            ]}
            showAnnotations={true}
            chartColors={["#0BC1C1", "#F4B43E"]}
            type={selectedKPI?.type}
            label={selectedKPI?.label}
            startDate={startDate}
            dateDayDuring={testControlGraphData?.dateDayDuring}
            dateDayPre={testControlGraphData?.dateDayPre}
            campStart={testControlGraphData?.campStart}
            campPre={testControlGraphData?.campPre}
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <Box className={classes.chartArea}>
          <Typography>
            {`%Lift in ${retriveMetricFromStr(selectedKPI?.name)}`}
          </Typography>

          <LineChart
            title={"Lift %"}
            xAxis={graphData?.xAxis}
            series={[{ name: "Lift", data: graphData?.liftData }]}
            showAnnotations={false}
            chartColors={["#FB04E2"]}
            type={selectedKPI?.type}
            label={selectedKPI?.label}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default MeasureTrends;
