import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { ErrorMessage } from "formik";
import Flatpickr from "react-flatpickr";


const useStyles = makeStyles((theme) => ({
  dateRangeLeft: {
    border: "1px solid #ccc",
    borderRadius: 4,
    minWidth: "230px",
    width: "100%",
    height: 53,
    margin: 0,
    textAlign: "left",
    outline: 0,
    fontSize: 14,
    color: "#8D8D8D",
    padding: 14,

    // margin: "0 0 0 10px",
  },
  errorText: {
    color: "red",
    fontSize: 12,
  },
  formLabel: {
    fontSize: 12,
    display: "contents",
    // margin: "5px 0 10px",
  },
}));

const DateRange = (props) => {
  const classes = useStyles();
  require("flatpickr/dist/themes/material_blue.css");
  const { name, label, handleMethod, placeHolder, value,disable,maxDate } = props;

  return (
    <>
      {label ? (
        <Typography className={classes.formLabel}>{label}</Typography>
      ) : (
        ""
      )}

      <Flatpickr
        name={name}
        value={value}
        options={{
          maxDate: maxDate,
          mode: "range",
          showMonths: 2,
          altInputClass: classes.dateRangeLeft,
          altInput: true,
          altFormat: "M d Y",
          disable: disable ? disable : [],
          locale: {
            firstDayOfWeek: 1,
          },
          dateFormat: "Y-m-d",
          onChange: function(val) {
            if (val.length === 2) {
              handleMethod(name, val);
            }
          },
        }}
        placeholder={placeHolder}
      />
      <Typography className={classes.errorText}>
        <ErrorMessage name={name} />
      </Typography>
    </>
  );
};

export default DateRange;
