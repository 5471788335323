export const USFormatNumber = (number) => {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });
  return formatter.format(number);
};

export const numberWithCommas = (number) => {
  return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const toCurrencyFormat = (labelValue) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(0) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(0) + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
        ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(0) + "K"
        : Math.abs(Number(labelValue)).toFixed(2);
};

export const toCamelcaseStr = (str) => {
  // Convert first charector to uppercase
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
};
export const roundToTwoDecimalPlaces = (number, decimalPlaces) => {
  if (number === null) {
    return null
  }
  else if (Number?.isInteger(number)) {
    return number; // Convert integer to string
  } else {
    if (typeof decimalPlaces !== 'number' || isNaN(decimalPlaces)) {
      decimalPlaces = 2; // Default to two decimal places if decimalPlaces is invalid
    }
    const multiplier = 10 ** decimalPlaces;
    const truncatedNumber = Math?.floor(number * multiplier) / multiplier;
    return truncatedNumber?.toFixed(decimalPlaces);
  }

}
export const toFloatValue = (num) => {
  // Convert float number to 2 decimal points number
  if (num && num % 1 !== 0) {
    return num?.toFixed(2);
  } else {
    return num;
  }
};

export const checkDecimal = (num) => {
  // checking floating number, if number is float then fixed to 2 decimal else number is integer then number will be separated by comma
  if (num % 1 !== 0) {
    return parseFloat(parseFloat(num)?.toFixed(2)).toLocaleString("en", {
      minimumFractionDigits: 2,
    });
  } else {
    return parseInt(num).toLocaleString();
  }
};

export const toMetricFormat = (value, key) => {
  // All metric unit formating with value
  const metric = key?.toLowerCase();
  if (metric === "responder_rate") {
    return `${value?.toFixed(2)}%`;
  } else if (metric === "orders") {
    return `${numberWithCommas(value?.toFixed(0))}`;
  } else if (metric === "avg_order_value") {
    return `$${value?.toFixed(2)}`;
  } else if (metric === "sales" || metric === "margin_amt") {
    return `$${numberWithCommas(value?.toFixed(0))}`;
  } else {
    return value;
  }
};

const retriveMetricFromStr = (str) => {
  // str = str.toLowerCase();
  if (str.includes("_")) {
    const txtArr = str?.replace(/_/g, " ");
    return txtArr;
  } else {
    return str;
  }
};
export default retriveMetricFromStr;

export const testTypeValidation = {
  assortment: true,
  pricing: true,
  remodel: true,
  "front end": false,
  promotion: true,
  "store event": true,
  labor: false,
  schedule: false,
};

export const getMetricUnit = (key) => {
  // All metric unit formating
  const metric = key.toLowerCase();
  if (metric === "sales") {
    return `$`;
  } else if (metric === "open_rate") {
    return `%`;
  } else {
    return "";
  }
};
export const onlyDeptMapping = {
  "no of customers": true,
  "new customers": true,
  "repeat customers": true,
  "reactivated customers": true,
  "market share": true,
};
export const onlyStoreMapping = {
  "cleanliness score": true,
  "fast score": true,
  "friendly score": true,
  "product availability": true,
  "price perception": true,
};

export const lineChartMapping = {
  "no of customers": true,
  "new customers": true,
  "repeat customers": true,
  "reactivated customers": true,
};

export const noRoundKPI = {
  "market share": true,
  "fast score": true,
  "cleanliness score": true,
  "friendly score": true,
  "price perception": true,
  "product availability": true,
};
export const noRoundKPIList = [
  "market share",
  "fast score",
  "cleanliness score",
  "friendly score",
  "price perception",
  "product availability",
];

export function getCurrentDate(separator = '-') {
  let newDate = new Date()
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let hours = newDate.getHours()
  let minutes = newDate.getMinutes()
  let seconds = newDate.getSeconds()
  return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}${separator}${hours}${separator}${minutes}${separator}${seconds}${separator}`
}