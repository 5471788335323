import {
  LOADING,
  MODALLOADING,
  DATE,
  ENABLEDEEPDIVE,
  TABNAVIGATION,
  NESTEDTABNAV,
  LANDINGFILTERS,
  STATUS,
  DATERANGE,
  NAME,
  BRANDS,
  FULFILMENT,
  CAMPCHANNEL,
  TYPE,
  SELECTEDDEEPDIVE,
  KPISUMFILTERS,
  KPISUMKPI,
  KPISUMBRAND,
  KPISUMCHANNEL,
  KPISUMREGION,
  MERCHFILTERS,
  MERCHKPI,
  MERCHBRAND,
  MERCHCHANNEL,
  MERCHREGION,
  MERCHMERCHHIERARCHY,
  ENGAGEFILTERS,
  ENGAGEKPI,
  ENGAGEBRAND,
  ENGAGECHANNEL,
  ENGAGEREGION,
  ENGAGEDEVICE,
  ENGAGETOUCHBASE,
  DIGITALFILTERS,
  DIGITALKPI,
  DIGITALBRAND,
  DIGITALCHANNEL,
  DIGITALREGION,
  DIGITALDEVICE,
  DIGITALSESSION,
  DIGITALAPPWEB,
} from "../actions/types";

const initialState = {
  loading: false,
  modalLoading: false,
  date: new Date(),
  enableDeepdive: false,
  tabNavigation: 0,
  nestedTabNav: 0,
  landingFilters: {
    status: "",
    startDate: "",
    endDate: "",
    name: "",
    brands: [],
    fulfillmentChannel: [],
    channel: [],
    testType: [],
    dataLevel: [],
  },
  selectedDeepdive: {},
  kpiSumFilters: {
    selectedKpi: [],
    brands: [],
    channel: [],
    region: [],
    fulfillmentChannel: [],
  },
  merchHierarchyFilters: {
    selectedKpi: [],
    brands: [],
    channel: [],
    region: [],
    merchHierarchy: [],
    fulfillmentChannel: [],
  },
  engageFilters: {
    selectedKpi: [],
    brands: [],
    channel: [],
    region: [],
    device: [],
    touchBase: [],
  },
  digitalFilters: {
    selectedKpi: [],
    brands: [],
    channel: [],
    region: [],
    device: [],
    sessionChannel: [],
    appWeb: [],
  },
};
const application = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, loading: action.payload };
    case MODALLOADING:
      return { ...state, modalLoading: action.payload };
    case DATE:
      return { ...state, date: action.payload };
    case ENABLEDEEPDIVE:
      return { ...state, enableDeepdive: action.payload };
    case TABNAVIGATION:
      return { ...state, tabNavigation: action.payload };
    case NESTEDTABNAV:
      return { ...state, nestedTabNav: action.payload };
    case LANDINGFILTERS:
      return {
        ...state,
        landingFilters: action.payload,
      };
    case STATUS:
      return {
        ...state,
        landingFilters: {
          ...state.landingFilters,
          status: action.payload,
        },
      };
    case DATERANGE:
      return {
        ...state,
        landingFilters: {
          ...state.landingFilters,
          startDate: action.payload[0],
          endDate: action.payload[1],
        },
      };
    case NAME:
      return {
        ...state,
        landingFilters: {
          ...state.landingFilters,
          name: action.payload,
        },
      };
    case BRANDS:
      return {
        ...state,
        landingFilters: {
          ...state.landingFilters,
          brands: action.payload,
        },
      };
    case FULFILMENT:
      return {
        ...state,
        landingFilters: {
          ...state.landingFilters,
          fulfillmentChannel: action.payload,
        },
      };
    case CAMPCHANNEL:
      return {
        ...state,
        landingFilters: {
          ...state.landingFilters,
          channel: action.payload,
        },
      };
    case TYPE:
      return {
        ...state,
        landingFilters: {
          ...state.landingFilters,
          testType: action.payload,
        },
      };
    case SELECTEDDEEPDIVE:
      return { ...state, selectedDeepdive: action.payload };
    case KPISUMFILTERS:
      return { ...state, kpiSumFilters: action.payload };
    case KPISUMKPI:
      return {
        ...state,
        kpiSumFilters: {
          ...state.kpiSumFilters,
          selectedKpi: action.payload,
        },
      };
    case KPISUMBRAND:
      return {
        ...state,
        kpiSumFilters: {
          ...state.kpiSumFilters,
          brands: action.payload,
        },
      };
    case KPISUMCHANNEL:
      return {
        ...state,
        kpiSumFilters: {
          ...state.kpiSumFilters,
          channel: action.payload,
        },
      };
    case KPISUMREGION:
      return {
        ...state,
        kpiSumFilters: {
          ...state.kpiSumFilters,
          region: action.payload,
        },
      };
    case MERCHFILTERS:
      return { ...state, merchHierarchyFilters: action.payload };
    case MERCHKPI:
      return {
        ...state,
        merchHierarchyFilters: {
          ...state.merchHierarchyFilters,
          selectedKpi: action.payload,
        },
      };
    case MERCHBRAND:
      return {
        ...state,
        merchHierarchyFilters: {
          ...state.merchHierarchyFilters,
          brands: action.payload,
        },
      };
    case MERCHCHANNEL:
      return {
        ...state,
        merchHierarchyFilters: {
          ...state.merchHierarchyFilters,
          channel: action.payload,
        },
      };
    case MERCHREGION:
      return {
        ...state,
        merchHierarchyFilters: {
          ...state.merchHierarchyFilters,
          region: action.payload,
        },
      };
    case MERCHMERCHHIERARCHY:
      return {
        ...state,
        merchHierarchyFilters: {
          ...state.merchHierarchyFilters,
          merchHierarchy: action.payload,
        },
      };
    case ENGAGEFILTERS:
      return { ...state, engageFilters: action.payload };
    case ENGAGEKPI:
      return {
        ...state,
        engageFilters: {
          ...state.engageFilters,
          selectedKpi: action.payload,
        },
      };
    case ENGAGEBRAND:
      return {
        ...state,
        engageFilters: {
          ...state.engageFilters,
          brands: action.payload,
        },
      };
    case ENGAGECHANNEL:
      return {
        ...state,
        engageFilters: {
          ...state.engageFilters,
          channel: action.payload,
        },
      };
    case ENGAGEREGION:
      return {
        ...state,
        engageFilters: {
          ...state.engageFilters,
          region: action.payload,
        },
      };
    case ENGAGEDEVICE:
      return {
        ...state,
        engageFilters: {
          ...state.engageFilters,
          device: action.payload,
        },
      };
    case ENGAGETOUCHBASE:
      return {
        ...state,
        engageFilters: {
          ...state.engageFilters,
          touchBase: action.payload,
        },
      };
    case DIGITALFILTERS:
      return { ...state, digitalFilters: action.payload };
    case DIGITALKPI:
      return {
        ...state,
        digitalFilters: {
          ...state.digitalFilters,
          selectedKpi: action.payload,
        },
      };
    case DIGITALBRAND:
      return {
        ...state,
        digitalFilters: {
          ...state.digitalFilters,
          brands: action.payload,
        },
      };
    case DIGITALCHANNEL:
      return {
        ...state,
        digitalFilters: {
          ...state.digitalFilters,
          channel: action.payload,
        },
      };
    case DIGITALREGION:
      return {
        ...state,
        digitalFilters: {
          ...state.digitalFilters,
          region: action.payload,
        },
      };
    case DIGITALDEVICE:
      return {
        ...state,
        digitalFilters: {
          ...state.digitalFilters,
          device: action.payload,
        },
      };
    case DIGITALSESSION:
      return {
        ...state,
        digitalFilters: {
          ...state.digitalFilters,
          sessionChannel: action.payload,
        },
      };
    case DIGITALAPPWEB:
      return {
        ...state,
        digitalFilters: {
          ...state.digitalFilters,
          appWeb: action.payload,
        },
      };
    default:
      return state;
  }
};

export default application;
