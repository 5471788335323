import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import store from "./store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import AuthProvider from "./config/azure-services/AuthProvider";
if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept();
}
ReactDOM.render(
  <Provider store={store}>
    <AuthProvider>
    <App />
    </AuthProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
