import React from "react";
import Card from "@material-ui/core/Card";
import {Divider } from "@material-ui/core";
import "./Style.css";

const CommandCard = (props) => {
  const styleCSS = {
    height: props.height,
    backgroundColor: props.backgroundColor,
    color: props.color,
    width: props.width,
    borderRadius: props.borderRadius,
    margin: props.margin,
  };

  const styleHr = {
    display: props.display,
  };

  return (
    <Card elevation={1} style={styleCSS}>
      <div className="slider_card_flex">
        <div className="slider_card_text">{props.text}{props.paragraph}</div>
        {props.button}
      </div>
      <Divider style={styleHr} />
      {props.children}
    </Card>
  );
};
CommandCard.defaultProps = {
  backgroundColor: "white",
  color: "black",
  margin: "0.5rem 0.5rem",
};
export default CommandCard;
