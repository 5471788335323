import React, { useEffect, useState } from "react";
import CustomMaterialTable from "../Common/CustomMaterialTable";
import MultiSelectDropdown from "../Common/Filters/MultiSelectDropdown";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import CustomMultiSelectDropdown from "../Common/Filters/CustomMultiSelectDropdown";
import {
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Grid,
  Button,
  Box,
} from "@material-ui/core/";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import ExportCsv from "../Common/Utils/ExportCsv";
import secureAxios from "../../config/api-config";
import MeasureTrends from "./MeasureTrends";
import ModalLoading from "../../components/ModalLoading";
import _ from "lodash";
import {
  numberWithCommas,
  roundToTwoDecimalPlaces,
} from "../Common/Utils/ClientUtil";
import { TablePagination } from "@material-ui/core";
import { APIS } from "../../config";
import { useParams } from "react-router-dom";
import palette from "theme/palette";
import useStyles from "./style";



function PatchedPagination(props) {
  const {
    ActionsComponent,
    onChangePage,
    onChangeRowsPerPage,
    ...tablePaginationProps
  } = props;

  return (
    <TablePagination
      {...tablePaginationProps}
      // @ts-expect-error onChangePage was renamed to onPageChange
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      ActionsComponent={(subprops) => {
        const { onPageChange, ...actionsComponentProps } = subprops;
        return (
          // @ts-expect-error ActionsComponent is provided by material-table
          <ActionsComponent
            {...actionsComponentProps}
            onChangePage={onPageChange}
          />
        );
      }}
    />
  );
}

export default function KpiSummary(props) {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const {  outlierChecked, testControlChecked, data } = props;

  // console.log({ props });
  
  const appReduxState = useSelector((state) => ({
    ...state.drilldownDetail,
  }));
  const { kpiSumFilters } = appReduxState;

  const [selectedTblKpi, setSelectedTblKpi] = useState({
    id: "",
    name: "",
    type: "",
    label: "",
  });
  const [kpiDropdown, setKpiDropdown] = useState([]);
  const [groupDropDdown, setGroupDropdown] = useState([]);
  const [collectionDropDdown, setCollectionDropdown] = useState([]);
  const [flightDropDdown, setFlightDropdown] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [testControlGraphData, setTestControlGraphGraphData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [selectedKPIs, setSelectedKPIs] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState([]);
  const [selectedFlight, setSelectedFlight] = useState([]);
  const [msg, setMsg] = useState("Loading...");
  let successfulApiCalls = 0;
  let apiCallCount = 0

  //function check api reponse 
  const handleApiSuccess = () => {
    successfulApiCalls++;
    // Check if both API calls have been successfully completed
    if (successfulApiCalls === apiCallCount) {
      // Close the loader
      dispatch({
        type: "MODALLOADING",
        payload: false,
      });
    }
  };


  //table
  const columns = [
    {
      field: "Metrics",
      colspan: 1,
      render: (rowData) => {
        return rowData.kpiLabel;
      },
    },
        {
          field: "COLLECTION",
          colspan: 1,
          render: (rowData) => {
            return rowData.collectionName;
          },
        },
        {
          field: "FLIGHT",
          colspan: 1,
          render: (rowData) => {
            return rowData.flightId;
          },
        },
     
    {
      field: "Products",

      colspan: 1,
      render: (rowData) => {
        return rowData.productGroup;
      },
    },
    {
      field: "POST_TEST",
      render: (rowData) => {
        return (
          rowData["POST_TEST"],
          rowData.postTest === 0 ? rowData.postTest : rowData.postTest ? roundToTwoDecimalPlaces(rowData.postTest) : "NA"
          // rowData.postTest ? numberWithCommas(rowData.postTest?.toFixed(2)) : ""
        );
      },
    },
    {
      field: "POST_CONTROL",
      render: (rowData) => {
        return (
          rowData["POST_CONTROL"],
          // rowData.postControl
          //   ? numberWithCommas(rowData?.postControl?.toFixed(2))
          //   : ""
          rowData.postControl === 0 ? rowData.postControl : rowData.postControl ? roundToTwoDecimalPlaces(rowData.postControl) : "NA"
        );
      },
    },
    {
      field: "Lift %",
      render: (rowData) => {
        return rowData["Lift %"], rowData?.lift ? rowData.lift?.toFixed(2) : "0";
      },
    },
    {
      field: "ABS_LIFT",
      render: (rowData) => {
        return (
          rowData["ABS_LIFT"],
          rowData?.absLift ?
            (rowData?.kpi === "campaign_purchasers"
              ? numberWithCommas(rowData?.absLift?.toFixed(0)) : numberWithCommas(rowData?.absLift?.toFixed(2)))
            : "0"
        );
      },
    },
    {
      field: "P Value",
      render: (rowData) => (
        <Typography
          className={classes.statsigArea}
          style={
            {
              backgroundColor:
                rowData.confidenceLevel ===
                  "Statistically Significant"
                  ? palette.semanticBGColors.secondaryGreen
                  : rowData.confidenceLevel ===
                    "Statistically Insignificant" || rowData.confidenceLevel === null
                    ? palette.semanticBGColors.secondaryRed
                    : palette.semanticBGColors.secondaryYellow,
              color:
                rowData.confidenceLevel ===
                  "Statistically Significant"
                  ? palette.semanticBGColors.primaryGreen
                  : rowData.confidenceLevel ===
                    "Statistically Insignificant" || rowData.confidenceLevel === null
                    ? palette.semanticBGColors.primaryRed
                    : palette.semanticBGColors.primaryYellow,
              border: "1px solid",
              borderColor:
                rowData.confidenceLevel ===
                  "Statistically Significant"
                  ? palette.semanticBGColors.primaryGreen
                  : rowData.confidenceLevel ===
                    "Statistically Insignificant" || rowData.confidenceLevel === null
                    ? palette.semanticBGColors.primaryRed
                    : palette.semanticBGColors.primaryYellow,
            }
          }
        >
          {rowData?.confidenceLevel === "Statistically Significant"
            ? "Significant"
            : rowData?.confidenceLevel === "Statistically Insignificant" || rowData.confidenceLevel === null
              ? "Insignificant"
              : rowData?.confidenceLevel}
        </Typography>
      ),
    },
  ];

  //prepost off
  const toggleColumns = [
    {
      field: "Metrics",
      colspan: 1,
      render: (rowData) => {
        return rowData.kpiLabel;
      },
    },
        {
          field: "COLLECTION",
          colspan: 1,
          render: (rowData) => {
            return rowData.collectionName;
          },
        },
        {
          field: "FLIGHT",
          colspan: 1,
          render: (rowData) => {
            return rowData.flightId;
          },
        },
     
    {
      field: "Products",
     
      colspan: 1,
      render: (rowData) => {
        return rowData?.productGroup;
      },
    },
    {
      field: "PRE_TEST",
      render: (rowData) => {
        return (
          rowData["PRE_TEST"],
          // rowData.pctTest ===0 ? rowData.pctTest : rowData.pctTest ? roundToTwoDecimalPlaces(rowData.pctTest):"NA"
          rowData.preTest === 0 ? rowData.preTest : rowData.preTest ? numberWithCommas(rowData.preTest.toFixed(2)) : "NA"
        );
      },
    },
    {
      field: "POST_TEST",
      width:"5%",
      render: (rowData) => {
        return (
          rowData["POST_TEST"],
          rowData?.postTest === 0 ? rowData?.postTest : rowData?.postTest ? numberWithCommas(rowData?.postTest?.toFixed(2)) : "NA"
          // rowData.postTest ? numberWithCommas(rowData.postTest.toFixed(2)) : ""
        );
      },
    },
    {
      field: "TEST_PER_CHANGE",
  
      render: (rowData) => {
        return (
          rowData["TEST_PER_CHANGE"],
          rowData?.pctTest === null
            ? "NA"
            : roundToTwoDecimalPlaces(rowData?.pctTest)

          // rowData.pctTest ? numberWithCommas(rowData.pctTest) : ""
        );
      },
    },
    {
      field: "PRE_CONTROL",
      
      render: (rowData) => {
        return (
          rowData["PRE_CONTROL"],
          // rowData.preControl
          //   ? numberWithCommas(rowData. rowData?.postTest ===0 ? rowData?.postTest :rowData?.postTest? numberWithCommas(rowData?.postTest?.toFixed(2)) : "NA".toFixed(2))
          //   : ""
          rowData?.preControl === 0 ? rowData?.preControl : rowData?.preControl ? numberWithCommas(rowData?.preControl?.toFixed(2)) : "NA"
        );
      },
    },

    {
      field: "POST_CONTROL",
     
      render: (rowData) => {
        return (
          rowData["POST_CONTROL"],
          // rowData.postControl
          //   ? numberWithCommas(rowData.postControl.toFixed(2))
          //   : ""
          rowData?.postControl === 0 ? rowData?.postControl : rowData?.postControl ? numberWithCommas(rowData?.postControl?.toFixed(2)) : "NA"
        );
      },
    },
    {
      field: "CONTROL_PER_CHANGE",
   
      render: (rowData) => {
        return (
          rowData["CONTROL_PER_CHANGE"],
          // rowData.pctControl
          //   ? numberWithCommas(rowData.pctControl.toFixed(2))
          //   : ""
          rowData?.pctControl === null
            ? "NA"
            : roundToTwoDecimalPlaces(rowData?.pctControl)
        );
      },
    },

    {
      field: "Lift %",
     
      render: (rowData) => {
        // return rowData["Lift %"], rowData.lift ? rowData.lift.toFixed(2) : "";
        return rowData["Lift %"], rowData?.preLiftPct ? rowData?.preLiftPct?.toFixed(2) : "0"
      },
    },
    {
      field: "ABS_LIFT",
   
      render: (rowData) => {

        return (
          rowData["ABS_LIFT"],
          rowData?.preAbsLift ?
            (rowData?.kpi === "campaign_purchasers"
              ? numberWithCommas(rowData?.absLift?.toFixed(0)) : numberWithCommas(rowData?.preAbsLift?.toFixed(2)))
            : "0"
        );
      },
    },
    {
      field: "P Value",
      render: (rowData) => (
        <Typography
          className={classes.statsigArea}
          style={
            {
              backgroundColor:
                rowData.diff_n_diff_confidence_level ===
                  "Statistically Significant"
                  ? palette.semanticBGColors.secondaryGreen
                  : rowData.diff_n_diff_confidence_level ===
                    "Statistically Insignificant" || rowData.diff_n_diff_confidence_level === null
                    ? palette.semanticBGColors.secondaryRed
                    : palette.semanticBGColors.secondaryYellow,
              color:
                rowData.diff_n_diff_confidence_level ===
                  "Statistically Significant"
                  ? palette.semanticBGColors.primaryGreen
                  : rowData.diff_n_diff_confidence_level ===
                    "Statistically Insignificant" || rowData.diff_n_diff_confidence_level === null
                    ? palette.semanticBGColors.primaryRed
                    : palette.semanticBGColors.primaryYellow,
              border: "1px solid",
              borderColor:
                rowData.diff_n_diff_confidence_level ===
                  "Statistically Significant"
                  ? palette.semanticBGColors.primaryGreen
                  : rowData.diff_n_diff_confidence_level ===
                    "Statistically Insignificant" || rowData.diff_n_diff_confidence_level === null
                    ? palette.semanticBGColors.primaryRed
                    : palette.semanticBGColors.primaryYellow,
            }
          }
        >
          {rowData.diff_n_diff_confidence_level === "Statistically Significant"
            ? "Significant"
            : rowData.diff_n_diff_confidence_level === "Statistically Insignificant" || rowData.diff_n_diff_confidence_level === null
              ? "Insignificant"
              : rowData.diff_n_diff_confidence_level}
        </Typography>
      ),
    },
  ];

  const getKpioptions = (val, status) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.KIPS, {
        outlierValue: outlierChecked ? true : false,
        filters: val,
        kpiGroup: "Transaction",
        tabName: "KpiSummary",
        campaign_id: id,
       
      })
      .then((res) => {
        if (res.status === 200) {
          handleApiSuccess();
          let resData = res.data.responseData;
          setKpiDropdown(resData);
          if (status) {
            //here to set 5 seletced kpis
            const allData = [
              "Net Sales",
              "Response Rate",
              "Transactions",
              "ADS",
              "Net Margin",
            ];
            const selectedKPIs = resData.filter((item) =>
              allData.includes(item.kpiLabel)
            );
            setSelectedKPIs(selectedKPIs);
          } else {
            let newSelected = val.selectedKpi.filter((item) =>
              resData.includes(item)
            );
            setSelectedKPIs(newSelected);
          }
        }
      });
  };
  const getGroupoptions = (val, status) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.GROUP, {
        outlierValue: outlierChecked ? true : false,
        filters: val,
        kpiGroup: "Transaction",
        tabName: "KpiSummary",
        campaign_id: id,
       
      })
      .then((res) => {
        if (res.status === 200) {
          handleApiSuccess();
          let resData = res.data.responseData;
          setGroupDropdown(resData);
          if (status) {
            let all = resData.filter((item) => {
              return item === "Total";
            });
            setSelectedGroup(all);
          } else {
            let newSelected = val.productGrp.filter((item) =>
              resData.includes(item)
            );
            setSelectedGroup(newSelected);
          }
        }
      });
  };
  const getCollectionoptions = (val, status) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.COLLECTION, {
        outlierValue: outlierChecked ? true : false,
        filters: val,
        kpiGroup: "Transaction",
        tabName: "KpiSummary",
        campaign_id: id,
      
      })
      .then((res) => {
        if (res.status === 200) {
          handleApiSuccess();
          let resData = res.data.responseData;
          setCollectionDropdown(resData);
          if (status) {
            setSelectedCollection([]);
          } else {
            let newSelected = val.collection.filter((item) =>
              resData.includes(item)
            );
            setSelectedCollection(newSelected);
          }
        }
      });
  };
  const getFlightoptions = (val, status) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.FLIGHT_ID, {
        outlierValue: outlierChecked ? true : false,
        filters: val,
        kpiGroup: "Transaction",
        tabName: "KpiSummary",
        campaign_id: id,
       
      })
      .then((res) => {
        if (res.status === 200) {
          handleApiSuccess();
          let resData = res.data.responseData;
          setFlightDropdown(resData);
          if (status) {
            let all = resData.filter((item) => {
              return item === "ALL";
            });
            setSelectedFlight(all);
          } else {
            let newSelected = val.flight.filter((item) =>
              resData.includes(item)
            );
            setSelectedFlight(newSelected);
          }
        }
      });
  };

  const fetchTblData = (viewStatus, val) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.KPI_SUMMARY, {
        outlierValue: outlierChecked ? true : false,
        filters: val,
        campaign_id: id,
        kpiGroup: "Transaction"
      })
      .then(function (response) {
        if (response.status === 200) {
          handleApiSuccess();
          if (!response.data.responseData.length) {
            setMsg("No records to display");
          }
          setTblData(response.data.responseData);

          if (
            response?.data?.responseData?.length > 0
          ) {
            if (viewStatus === "firstLoad") {
              setSelectedTblKpi({
                ...selectedTblKpi,
                id: response.data.responseData[0]["kpiRowId"],
                name: response.data.responseData[0]["kpi"],
                type: response.data.responseData[0]["kpiType"],
                label: response.data.responseData[0]["kpiLabel"],
              });
            }

            fetchGraphData(response.data.responseData[0]["kpiRowId"]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchGraphData = (val) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.KPI_SUMMARY_GRAPH_DATA, {
       
        campaign_id: id,
        kpiGroup: "Transaction",
        selectedKpiRowId: val,
       
        outlierValue: outlierChecked ? true : false,
      })
      .then(function (response) {
        if (response.status === 200) {
          handleApiSuccess();
          setGraphData(response?.data?.graphData);
          setTestControlGraphGraphData(response?.data?.graphDataExtra)

        }
      });
  };

  useEffect(() => {
    getKpioptions(kpiSumFilters, "firstLoad");
    getGroupoptions(kpiSumFilters, "firstLoad");
    getCollectionoptions(kpiSumFilters, "firstLoad");
    getFlightoptions(kpiSumFilters, "firstLoad");
    fetchTblData("firstLoad", {
      selectedKpi: [
        "sales",
        "responder_rate",
        "orders",
        "avg_order_value",
        "margin_amt",
      ],
      productGrp: [],
      collection: [],
      flight: ["ALL"],
    });
  }, [outlierChecked]);

  //function for file download in excel
  const newArr = []

  tblData.forEach((x, i) => {
    const kpiSummaryData = {};
    if (testControlChecked) {
      // Condition when testControlChecked is true
      kpiSummaryData['Sno'] = `${i + 1}`;
      kpiSummaryData['Metrics'] = `${x.kpiLabel}`;
      kpiSummaryData['Collection'] = `${x.collectionName}`;
      kpiSummaryData['Flight'] = `${x.flightId}`;
      kpiSummaryData['Products'] = `${x.productGroup}`;
      kpiSummaryData['Test'] = `${x.test_during_value?.toFixed(2)}`;
      kpiSummaryData['Control'] = `${x.control_during_value?.toFixed(2)}`;
      kpiSummaryData['lift%'] = `${x.lift?.toFixed(2)}`;
      kpiSummaryData['Abs'] = `${x.kpi === "campaign_purchasers"? x.absLift?.toFixed(0):x.absLift?.toFixed(2)}`;
      kpiSummaryData['P-Value(Analysis peroid)'] = `${x.significance ===null?"null": x.significance}`;
      kpiSummaryData['Stat Sig (p-value(Analysis peroid))'] = `${x.confidenceLevel}`;
    } else {
      // Condition when testControlChecked is falsy
      kpiSummaryData['Sno'] = `${i + 1}`;
      kpiSummaryData['Metrics'] = `${x.kpiLabel}`;
      kpiSummaryData['Collection'] = `${x.collectionName}`;
      kpiSummaryData['Flight'] = `${x.flightId}`;
      kpiSummaryData['Products'] = `${x.productGroup}`;
      kpiSummaryData['Test(Pre-Avg)'] = `${x.test_pre_value?.toFixed(2)}`;
      kpiSummaryData['Test(Post-Avg)'] = `${x.test_during_value?.toFixed(2)}`;
      kpiSummaryData['Test(%Change)'] = `${x.pctTest?.toFixed(2)}`;
      kpiSummaryData['Control(Pre-Avg)'] = `${x.control_pre_value?.toFixed(2)}`;
      kpiSummaryData['Control(Post-Avg)'] = `${x.control_during_value?.toFixed(2)}`;
      kpiSummaryData['Control(%Change)'] = `${x.pctControl?.toFixed(2)}`;
      kpiSummaryData['Lift %'] = `${x.preLiftPct?.toFixed(2)}`;
      kpiSummaryData['Abs'] = `${x.kpi === "campaign_purchasers" ?x.absLift?.toFixed(0) :x.preAbsLift?.toFixed(2)}`;
      kpiSummaryData['P-Value(Pre-post)'] = `${x.diff_n_diff_significance===null?"null":x.diff_n_diff_significance?.toFixed(4)}`;
      kpiSummaryData['Stat Sig (p-value(Pre-post))'] = `${x.diff_n_diff_confidence_level}`;
    }

    newArr.push(kpiSummaryData);
  });



  return (
    <>

      <ModalLoading />
      <Box component="div" className={classes.filterAreaDropDown}>
        <Formik
          initialValues={{
            KPI: selectedKPIs,
            GROUP: selectedGroup,
            COLLECTION: selectedCollection,
            FLIGHT: selectedFlight,
          }}
          enableReinitialize={true}
          onSubmit={(values) => {
            dispatch({
              type: "MODALLOADING",
              payload: true,
            });
            fetchTblData("firstLoad", {
              selectedKpi: _.map(selectedKPIs, "kpiName"),
              productGrp: selectedGroup,
              collection: selectedCollection,
              flight: selectedFlight,
            });
          }}
        >
          {({
            values,

            setFieldValue,
            resetForm,
          }) => (
            <Form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <CustomMultiSelectDropdown
                    width={"w100"}
                    label={"Metrics"}
                    name={"KPI"}
                    value={values.KPI}
                    handleMethod={(value) => {
                      setFieldValue("KPI", value);
                      setSelectedKPIs(value);
                    }}
                    options={kpiDropdown}
                  />
                </Grid>

                
                  <Grid item xs={12} sm={6} md={3}>
                    <MultiSelectDropdown
                      width={"w100"}
                      label={"Collection"}
                      name={"COLLECTION"}
                      value={values.COLLECTION}
                      handleMethod={(value) => {
                        setFieldValue("COLLECTION", value);
                        setSelectedCollection(value);
                        getFlightoptions({
                          selectedKpi: _.map(selectedKPIs, "kpiName"),
                          productGrp: selectedGroup,
                          collection: value,
                          flight: selectedFlight,
                        });
                      }}
                      options={collectionDropDdown}
                    />
                  </Grid>
               
                  <Grid item xs={12} sm={6} md={2}>
                    <MultiSelectDropdown
                      width={"w100"}
                      label={"Audience group"}
                      name={"FLIGHT"}
                      value={values.FLIGHT}
                      handleMethod={(value) => {
                        setFieldValue("FLIGHT", value);
                        setSelectedFlight(value);
                      }}
                      options={flightDropDdown}
                    />
                  </Grid>
               
                <Grid item xs={12} sm={6} md={2}>
                  <MultiSelectDropdown
                    width={"w100"}
                    label={"Products"}
                    name={"GROUP"}
                    value={values.GROUP}
                    handleMethod={(value) => {
                      setFieldValue("GROUP", value);
                      setSelectedGroup(value);
                    }}
                    options={groupDropDdown}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={1}>
                  <Button className={classes.buttonFilter} type="submit">
                    Submit
                  </Button>
                </Grid>

                <Grid item xs={12} sm={6} md={1}>
                  <div className={classes.innerContainer}>
                    <RotateLeftIcon
                      color="primary"
                      className={classes.resetBtn}
                      onClick={() => {
                        dispatch({
                          type: "MODALLOADING",
                          payload: true,
                        });
                        resetForm();
                        getKpioptions(kpiSumFilters, "firstLoad");
                        getGroupoptions(kpiSumFilters, "firstLoad");
                        getCollectionoptions(kpiSumFilters, "firstLoad");
                        getFlightoptions(kpiSumFilters, "firstLoad");

                        fetchTblData("firstLoad", {
                          selectedKpi: [
                            "sales",
                            "responder_rate",
                            "orders",
                            "avg_order_value",
                            "margin_amt",
                          ],
                          productGrp: [],
                          collection: [],
                          flight: ["ALL"],

                          // channel: [],
                          region: ["ALL"],
                        });
                      }}
                    />
                    <ExportCsv
                      tableData={newArr}
                      fileName={testControlChecked ? `kpiSummary_Analysis_peroid_${id}` : `kpiSummary_pre_post_${id}`}
                      style={{
                        verticalAlign: "top",
                        margin: "10px 8px 10px 0",
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
      {tblData?.length ? (
        <>
         
            <>
              {" "}
              {graphData?.hasOwnProperty("testData") && testControlGraphData?.hasOwnProperty("testDataExtra") ? (
                <MeasureTrends
                  selectedKPI={selectedTblKpi}
                  graphData={graphData}
                  startDate={data?.startDate?.value}
                  testControlGraphData={testControlGraphData}
                ></MeasureTrends>
              ) : (
                <div style={{ textAlign: "center", margin: "50px" }}>
                  {graphData === "no data" ? "" : "...Loading Data for Graphs"}
                </div>
              )}
            </>
         
          <CustomMaterialTable
            title=""
            columns={!testControlChecked ? toggleColumns : columns}
            data={tblData}
            onRowClick={(event, rowData) => {
             
                dispatch({
                  type: "MODALLOADING",
                  payload: true,
                });
                setSelectedTblKpi({
                  ...selectedTblKpi,
                  id: rowData.kpiRowId,
                  name: rowData.kpi,
                  type: rowData.kpiType,
                  label: rowData.kpiLabel,
                });
                fetchGraphData(rowData.kpiRowId);
              
            }}
            options={{
              sorting: true,
              search: false,
              toolbar: false,
              paging: true,
              pageSize: 5,
              
              rowStyle: (rowData) => {
                return {
                  color:
                    selectedTblKpi.id === rowData.kpiRowId
                      ? palette.tableColors.bodyText
                      : "inherit",
                  cursor: "pointer",
                  backgroundColor:
                    selectedTblKpi.id === rowData.kpiRowId
                      ? palette.tableColors.selectedBackground
                      : "inherit",
                     
                };
              },
            }}
            components={{
              Container: (props) => (
                <Box component="div" className="tblScroll">
                  {props.children}
                </Box>
              ),
              Pagination: PatchedPagination,
              Header: () => {
                return (
                  <>
                    {!testControlChecked ? (
                      <TableHead className={classes.tableRightBorder}>
                        <TableRow>
                          <TableCell  rowSpan={2}>Metrics</TableCell>
                          <TableCell rowSpan={2}>Collection</TableCell>
                          <TableCell rowSpan={2}>Flight</TableCell>
                          <TableCell rowSpan={2}>Products</TableCell>
                          <TableCell colSpan={3}>
                            {"Test"}
                          </TableCell>
                          <TableCell colSpan={3}>
                            {"Control"}
                          </TableCell>
                          <TableCell colSpan={3}>
                            {"lift"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Pre Avg</TableCell>
                          <TableCell>Post Avg</TableCell>
                          <TableCell>% Change</TableCell>
                          <TableCell>Pre Avg</TableCell>
                          <TableCell>Post Avg</TableCell>
                          <TableCell>% Change</TableCell>
                          <TableCell>Lift%</TableCell>
                          <TableCell>Abs</TableCell>
                          <TableCell>Stat Sig (p-value)</TableCell>
                        </TableRow>
                      </TableHead>
                    ) : (
                      <TableHead className={classes.tableRightBorder}>
                        <TableRow>
                          <TableCell  rowSpan={2}>Metrics</TableCell>
                          <TableCell rowSpan={2}>Collection</TableCell>
                          <TableCell rowSpan={2}>Flight</TableCell>
                          <TableCell rowSpan={2}>Products</TableCell>
                          <TableCell rowSpan={2}>{"Test"}</TableCell>
                          <TableCell rowSpan={2}>{"Control"}</TableCell>
                          <TableCell colSpan={3}>
                            {"Lift"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Lift%</TableCell>
                          <TableCell>Abs</TableCell>
                          <TableCell>Stat Sig (p-value)</TableCell>
                        </TableRow>
                      </TableHead>
                    )}
                  </>
                );
              },
            }}
          ></CustomMaterialTable>
        </>
      ) : (
        <Grid item xs={12}>
          <Typography
            component="h3"
            variant="subtitle2"
            className={classes.info}
          >
            {msg}
          </Typography>
        </Grid>
      )}
    </>
  );
}
