import React, {  useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";
import "./assets/scss/index.scss";
import Routes from "./routes";
import { PATHS } from "./config";
import { useHistory } from "react-router-dom";
const browserHistory = createBrowserHistory();

const App = () => {
  const history = useHistory();
  useEffect(() => {
    if (localStorage?.getItem("accessToken")?.length) {
      if (history) {
        history.push(PATHS.DESIGN_CAMPAIGN);
      }
    }
  });
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter history={browserHistory}>
        <Routes />
      </BrowserRouter>
    </ThemeProvider>
  );
};
export default App;
