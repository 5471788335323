import { makeStyles } from '@material-ui/core'
import palette from 'theme/palette';

export default makeStyles((theme) => ({
    root: {
        padding: "10px 30px",
        float: "left",
        width: "100%",
    },
    tabBackground: {
        float: "left",
        width: "100%",
        background: palette.background.paper,
        "& header": {
            borderRadius: "6px 6px 0 0"
        }
    },
    sideBlock: {
        padding: "15px 15px 30px",
        margin: "20px 10px 10px",
        textAlign: 'center',
        boxShadow: "2.73577px 3.6477px 11.855px rgb(0 0 0 / 8%)",
        background: palette.primary.main,
        borderRadius: 6,
        "& h2": {
            color: palette.white,
            padding: 15
        },
        "& h6": {
            color: palette.white,
        }
    },
    block: {
        paddingBottom: 15,
        margin: "20px 10px 10px",
        textAlign: 'center',
        boxShadow: "2.73577px 3.6477px 11.855px rgb(0 0 0 / 8%)",
        "& h5": {
            padding: 8,
            background: palette.primary.main,
            color: palette.white,
            borderRadius: "6px 6px 0 0"
        },
        "& h4": { padding: "15px 10px 15px", },
        "& h6": {}
    },
    accordion: {
        margin: "0 10px 20px !important",
        borderLeft: "3px solid",
        borderColor: palette.primary.main,
        background: "rgb(1 53 152 / 2%)",
        borderRadius: "6px",
    },
    progress: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        margin: "0 auto",
        paddingTop: "15px"
    },
    tableCustom: {
        position: "sticky",
        top: -1,
        backgroundColor: palette.tableColors.background,
        zIndex: 1,
        "& th": {
            border: "1px solid",
            borderColor: palette.tableColors.border,
            fontSize: 14,
            padding: "6px 16px",
            textAlign: "left",
            color: palette.tableColors.text,
            fontWeight: 400
        },
    },
    tableContainer: {
        "& tr": {
            "& td:last-child": {
                "& div": {
                    justifyContent: "center"
                }
            }
        },
        "& td": {
            borderRight: "1px solid",
            borderLeft: "1px solid",
            borderColor: palette.tableColors.border + "!important",
        }
    },
    filterContainer: {
        padding: "20px 15px 0",
        background: palette.background.paper,
        boxShadow: "2.73577px 3.6477px 11.855px rgb(0 0 0 / 8%)",
        borderRadius: 6,
        marginBottom: 20,
        float: "left",
        width: "100%"
    },
    filterAreaDropDown: {
        margin: "0 0 20px 0",
        "& $input": {
            padding: 10,
        },
        "& $label": {
            transform: "translate(14px, 10px) scale(1)",
        },
        "& $div.MuiSelect-outlined.MuiSelect-outlined": {
            padding: 10,
        },
        "& button": {
            marginTop: 3
        },
        "& $input + $input": {
            height: "37px !important",
        },
    },
    nodata:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    },
    innerContainer:{
        padding:"8px",
       
        
    },
    informationText:{

        padding:"8px 4px",
        fontWeight:500,
        color: "#FFFFFF",
        fontSize:"12px",
        letterSpacing:"0.8px",
        fontFamily:'"Poppins", "sans-serif"'
    },
    formControl: {
        margin: theme.spacing(1),
        width: "60%"
      },
      indeterminateColor: {
        color: "#182C52"
      },
      selectAllText: {
        fontWeight: 500
      },
      selectedAll: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
      },
      groupViewfiledownload:{
        display:"flex",
        gap:"11px",
        textAlign:"center",
        padding:"2px"
      }
}));