import {
  Box,
  Grid,
  Button,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomMaterialTable from "../../../Common/CustomMaterialTable";
import secureAxios from "../../../../config/api-config";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { APIS } from "../../../../config";
import { Form, Formik } from "formik";
import MessageDialog from "views/Common/MessageDialog/MessageDialog";
import { useDispatch } from "react-redux";
import DialogBox from "views/Common/Modals/Dailog";
import FlatDatePicker from "views/Common/Filters/FlatDatePicker";
import TextFieldElement from "views/Common/Filters/TextFieldElement";
import Moment from "moment";
import palette from "../../../../theme/palette";
import * as Yup from "yup";
import useStyles from "./Style";

export default function ViewCampaignDetail() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const dashBoardData = useSelector((state) => state?.dashBoardDetail);
  // console.log(dashBoardData, "dashBoardData");

  //state
  const [load, setLoad] = useState(false);
  const [id, setId] = useState(null);
  const [tblData, setTblData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [deletedId, setDeleteId] = React.useState("");
  const [message, setMessage] = useState("");
  const [types, setTypes] = useState("");
  const [open2, setOpen2] = useState(false);
  const [load2, setLoad2] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editRowdata, setEditRowdata] = useState({});
  const [campSearchData, setCampSearchData] = useState([]);

  //validation
  const validSchema = Yup.object().shape({
    NAME: Yup.string()
      .required("Required")
      .matches(
        /^[a-zA-Z0-9_'|.?,()@ [\]]+$/,
        "Some special char are not allowed"
      ),
    // ENDDATE: Yup.array().min(1, "Required")
  });
  //tables columns
  const columns = [
    {
      title: "ID",
      field: "ID",

      render: (rowData) => {
        return rowData.ID;
      },
      filtering: true,
    },
    {
      title: "Name",
      field: "name",

      render: (rowData) => {
        return rowData.Name;
      },
      customFilterAndSearch: (term, rowData) =>
        rowData.Name.toLowerCase().indexOf(term.toLowerCase()) > -1,
    },

    {
      title: "Group",
      field: "group",
      render: (rowData) => {
        return rowData.grpName;
      },
      customFilterAndSearch: (term, rowData) =>
        rowData.grpName.toLowerCase().indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Type",
      field: "type",
      render: (rowData) => {
        return rowData.Type;
      },
      customFilterAndSearch: (term, rowData) =>
        rowData.Type.toLowerCase().indexOf(term.toLowerCase()) > -1,
    },
    // {
    //   title: "Type Of campaign",
    //   field: "type of campaign",
    //   render: (rowData) => {
    //     return rowData?.type_of_campaign === null
    //       ? "NA"
    //       : rowData?.type_of_campaign;
    //   },
    //   customFilterAndSearch: (term, rowData) =>
    //     rowData?.type_of_campaign?.toLowerCase()?.indexOf(term?.toLowerCase()) >
    //     -1,
    // },
    {
      title: "Circulation",
      field: "Circulation",
      render: (rowData) => {
        return rowData.Profile_Count === null ? "NA" : rowData.Profile_Count;
      },
      filtering: false,
    },

    {
      title: "Created Date",
      field: "Created date",
      render: (rowData) => (
        // format(new Date(Camp_startDate), "yyyy-MM-dd");
        <Typography style={{ whiteSpace: "nowrap" }} variant="tableText">
          {Moment(rowData?.createdDate?.value).format("D MMM YYYY")}
        </Typography>
      ),
      filtering: false,
    },
    {
      title: "Start Date",
      field: "startDate",
      render: (rowData) => (
        <Typography style={{ whiteSpace: "nowrap" }} variant="tableText">
          {rowData.Start_Date}
        </Typography>
      ),
      filtering: false,
    },
    {
      title: "End_Date",
      field: "End_Date",
      render: (rowData) => (
        <Typography style={{ whiteSpace: "nowrap" }} variant="tableText">
          {rowData.End_Date}
        </Typography>
      ),
      filtering: false,
    },
    {
      title: "Duration",
      field: "duration",
      render: (rowData) => {
        return rowData.Duration;
      },
      filtering: false,
    },
    {
      title: "Created by",
      field: "created by",
      render: (rowData) => {
        return rowData.campaign_owner;
      },
      customFilterAndSearch: (term, rowData) =>
        rowData.campaign_owner?.toLowerCase().indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Job status",
      field: "Job Status",
      render: (rowData) => (
        <Typography
          style={{
            backgroundColor: colorCheck(rowData)[1],
            color: colorCheck(rowData)[0],
            border: "1px solid",
            borderColor: colorCheck(rowData)[0],
          }}
          className={classes.dag_text}
        >
          {StatusCheck(rowData)}
        </Typography>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.dagStatus.toLowerCase().indexOf(term.toLowerCase()) > -1,
    },
  ];

  // function to color
  function colorCheck(rowData) {
    if (rowData.dagStatus === "success" && rowData?.Status === "ACTIVE") {
      return [
        palette.semanticBGColors.primaryGrey,
        palette.semanticBGColors.secondaryGrey,
      ];
    } else if (
      rowData.dagStatus === "success" &&
      rowData.Status === "SCHEDULED"
    ) {
      return [
        palette.semanticBGColors.primaryGrey,
        palette.semanticBGColors.secondaryGrey,
      ];
    } else if (
      rowData.dagStatus === "success" &&
      rowData.Status === "UPCOMING"
    ) {
      return [
        palette.semanticBGColors.primaryRed,
        palette.semanticBGColors.secondaryRed,
      ];
    } else if (rowData.dagStatus === "success") {
      return [
        palette.semanticBGColors.primaryGreen,
        palette.semanticBGColors.secondaryGreen,
      ];
    } else if (rowData.dagStatus === "running") {
      return [
        palette.semanticBGColors.primaryYellow,
        palette.semanticBGColors.secondaryYellow,
      ];
    } else if (rowData.dagStatus === "queued") {
      return [
        palette.semanticBGColors.primaryBlue,
        palette.semanticBGColors.secondaryBlue,
      ];
    } else if (rowData.dagStatus === "failed") {
      return [
        palette.semanticBGColors.primaryRed,
        palette.semanticBGColors.secondaryRed,
      ];
    } else {
      return [
        palette.semanticBGColors.primaryOrange,
        palette.semanticBGColors.secondaryOrange,
      ];
    }
  }

  //function to check status
  function StatusCheck(rowData) {
    if (rowData.dagStatus === "success" && rowData?.Status === "ACTIVE") {
      return "Scheduled";
    } else if (
      rowData.dagStatus === "success" &&
      rowData?.Status === "SCHEDULED"
    ) {
      return "Failed";
    } else if (
      rowData.dagStatus === "success" &&
      rowData?.Status === "UPCOMING"
    ) {
      return "Scheduled";
    } else {
      return rowData.dagStatus;
    }
  }

  //api call list of campaign
  const searchCampdata = () => {
    secureAxios
      .get(APIS.DESIGN_CAMPAIGN.SEARCH_CAMPLIST)
      .then((res) => {
        setCampSearchData(res.data.campName);
      })
      .catch((err) => {
        console.log(err, "Search data");
      });
  };
  //Function for api call table data
  const tableData = () => {
    setLoad(true);
    secureAxios
      .post(APIS.DESIGN_CAMPAIGN.DETAILS, {
        dateRange: dashBoardData?.dateRange,
        DashboardBrands: dashBoardData?.brands,
      })
      .then((res) => {
        setLoad(false);
        setTblData(res.data.queryResult);
      });
  };
  //api for campaign delete
  const deleteCampaignhandle = (campaignId) => {
    dispatch({
      type: "LOADING",
      payload: true,
    });
    secureAxios
      .put(`${APIS.DESIGN_CAMPAIGN.DELETECAMPAIGN}/${campaignId}`)
      .then((res) => {
        dispatch({
          type: "LOADING",
          payload: false,
        });
        setOpen2(true);
        setMessage("Deleted successfully");
        setTypes("success");
        tableData();
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      searchCampdata();
    }
    return () => {
      isMounted = false;
    };
  }, []);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      tableData();
    }

    return () => {
      isMounted = false;
    };
  }, [dashBoardData]);

  //redirect for measure page
  const redirect = (rowData) => {
    setId(id);
    history.push(`/campaignDetails/${rowData.ID}`);
  };

  // function for close delete popup
  function handleClose() {
    setOpen(false);
    setDeleteId("");
  }

  const deleteHandler = () => {
    deleteCampaignhandle(deletedId);
    setOpen(false);
    setDeleteId("");
  };

  const confirmHandler = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  //function for closepopup message
  const handleClosePopupMessage = () => {
    setOpen2(false);
  };

  // EDIT Campaign END DATE ------- START -------------------
  const editHandler = (data) => {
    // console.log(data.Name);
    const rowData = {
      ...data,
      Newend_Date: data.End_Date,
      campName: data.Name,
    };
    setEditRowdata(rowData);
    setEditOpen(true);
  };
  // console.log({ editRowdata });
  const handleUpdateDate = (name) => {
    // console.log(editRowdata, "--");
    setLoad2(true);

    secureAxios
      .put(APIS.DESIGN_CAMPAIGN.UPDATE_DETAILS, {
        Camp_Id: editRowdata.ID,
        Camp_startDate: editRowdata.Start_Date,
        Camp_EndDate: editRowdata.Newend_Date,
        campaign_name: name,
      })
      .then((res) => {
        setEditOpen(false);
        setOpen2(true);
        setMessage("Campaign updated successfully");
        setTypes("success");
        tableData();
        setLoad2(false);
      });
    // }
    // else {
    //   setEditOpen(false);
    //   tableData();
    // }
  };
  const handleEditModal = () => {
    setEditOpen(false);
  };

  // EDIT Campaign END DATE ------- CLOSE -------------------

  return (
    <Box component="div" container p={1}>
      <MessageDialog
        open={open}
        buttonType={false}
        close={handleClose}
        handleButton={deleteHandler}
        type={"error"}
        message={"Are you sure you want to delete ?"}
      />
      {/* popu message for success */}
      <MessageDialog
        type={types}
        open={open2}
        message={message}
        close={handleClosePopupMessage}
      />
      {/* Edit Campaign POPUP ----- START ----------- */}
      {editRowdata && (
        <DialogBox
          open={editOpen}
          handleClose={handleEditModal}
          width={"medium"}
          header={"Edit Campaign Details:"}
          children={
            <Grid item xs={12}>
              <Formik
                initialValues={{
                  ENDDATE: editRowdata.End_Date,
                  NAME: editRowdata.Name,
                }}
                enableReinitialize={true}
                validationSchema={validSchema}
                onSubmit={(values) => {}}
              >
                {({ values, setFieldValue, isValid }) => (
                  <Form noValidate autoComplete="off">
                    <Box component="div" className={classes.detailBlock}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={8} md={6}>
                          <Typography className={classes.heading}>
                            {" "}
                            {"Group: "}{" "}
                          </Typography>
                          <Typography component="h5" className={classes.value}>
                            {" "}
                            {editRowdata.grpName}{" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                          <Typography className={classes.heading}>
                            {" "}
                            {"Start Date: "}{" "}
                          </Typography>
                          <Typography component="h5" className={classes.value}>
                            {/* {console.log(editRowdata.Start_Date)} */}
                            {editRowdata.Start_Date}{" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <TextFieldElement
                            name={"NAME"}
                            label="Campaign Name"
                            width={"w100"}
                          />
                          {editRowdata?.Name?.toLowerCase() ===
                          values?.NAME?.toLowerCase() ? (
                            ""
                          ) : campSearchData?.includes(
                              values?.NAME?.toLowerCase()
                            ) ? (
                            <Typography
                              style={{ color: "#ff0026", fontsize: 13 }}
                            >
                              {" Campaign Name exists!"}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                          <FlatDatePicker
                            name={"ENDDATE"}
                            label="Enter End Date"
                            mode="single"
                            value={new Date(editRowdata.Newend_Date)}
                            minDate={new Date(editRowdata?.Start_Date)}
                            handleMethod={(name, value) => {
                              setFieldValue("ENDDATE", value[0]);
                              setEditRowdata({
                                ...editRowdata,
                                Newend_Date: value[0],
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        direction="row"
                        xs={12}
                        justifyContent="flex-end"
                        container
                      >
                        <Button
                          variant="contained"
                          className={classes.createBtn}
                          onClick={() => handleUpdateDate(values.NAME)}
                          disabled={
                            !values.NAME.replace(/\s/g, "").length || !isValid
                          }
                        >
                          {load2 ? (
                            <CircularProgress
                              size={22}
                              style={{ color: "white" }}
                            />
                          ) : (
                            "Update campaign"
                          )}
                        </Button>
                      </Grid>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Grid>
          }
        ></DialogBox>
      )}
      {/* Edit Campaign POPUP ----- CLOSE ----------- */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          {load ? (
            <CircularProgress />
          ) : (
            <React.Fragment>
              <CustomMaterialTable
                title=""
                columns={columns}
                data={tblData}
                // data={dagData}
                options={{
                  actionsColumnIndex: -1,
                  sorting: true,
                  search: false,
                  toolbar: false,
                  paging: true,
                  pageSize: 5,
                  filtering: "filter",
                  maxBodyHeight: 380,
                  position: "sticky",
                }}
                actions={[
                  (rowData) => ({
                    icon: "visibility",
                    hidden:
                      rowData?.dagStatus !== "success" ||
                      rowData?.Status === "ACTIVE",
                    tooltip: "View Details",
                    onClick: (event, rowData) => {
                      if (rowData?.dagStatus === "success") {
                        redirect(rowData);
                      } else {
                        return;
                      }
                    },
                  }),
                  {
                    icon: "edit",
                    tooltip: "Edit Campaign",
                    onClick: (e, rowData) => {
                      editHandler(rowData);
                    },
                    // hidden: "edit",
                  },
                  {
                    icon: "delete",
                    tooltip: "Delete Campaign",
                    onClick: (e, rowData) => {
                      confirmHandler(rowData.ID);
                    },
                    // hidden: "delete",
                    // onClick: (event, rowData) => DeleteCampaignhandle(rowData?.ID)
                  },
                ]}
                components={{
                  Container: (props) => (
                    <Box component="div" className="tblScroll">
                      {props.children}
                    </Box>
                  ),
                  Header: () => {
                    return (
                      <TableHead className={classes.tableRightBorder}>
                        <TableRow>
                          <TableCell rowSpan={2}>Campaign Id</TableCell>
                          <TableCell rowSpan={2}>Campaign Name</TableCell>
                          <TableCell rowSpan={2}>Group</TableCell>
                          <TableCell rowSpan={2}>Journey Type</TableCell>
                          {/* <TableCell rowSpan={2}>Type Of Campaign</TableCell>  */}
                          <TableCell rowSpan={2}>Circulation</TableCell>
                          <TableCell rowSpan={2}>Created Date</TableCell>
                          <TableCell rowSpan={2}>Start Date</TableCell>
                          <TableCell rowSpan={2}>End Date</TableCell>
                          <TableCell rowSpan={2}>Duration (Days)</TableCell>
                          <TableCell rowSpan={2}>Created By</TableCell>
                          <TableCell rowSpan={2}>Job Status</TableCell>
                          <TableCell rowSpan={2}>Action</TableCell>
                        </TableRow>
                      </TableHead>
                    );
                  },
                }}
              ></CustomMaterialTable>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
