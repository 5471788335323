import React from "react";
import {
  Box,
  Paper,
  CircularProgress,
  Grid,
  Typography,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from "@material-ui/core";
import useStyles from "./Style";
import BasicStackedBar from "views/Common/Charts/BasicStackedBarChart";
import _ from "lodash";

const CustomerBase = (props) => {
  const classes = useStyles();
  //props
  const { trendData, load3, load2, percentage } = props
  const { graphData, sums } = props?.graphData
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={6}>
        {
          load2 ? <Box component={"div"} className={classes.progress}>
            <CircularProgress />
          </Box> :
            <>

              {graphData?.length ?
                <>
                  <Box component={"div"} className={classes.recentage}>
                    <Box component={"div"} className={classes.perTextHeading}>Annual customer base = Starting Base + Re-Engaged + Acquisitions -Lapsed</Box>
                  </Box>

                  <Box component={"div"} className={classes.graphTextArea}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography>YOY % change is &nbsp;
                          <Typography component="span"> {percentage} %</Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}><Typography component="span">{`${sums?.CUST_CT_TY_SUM?.toFixed(2)}  M`}</Typography></Grid>
                      <Grid item xs={6} sm={6} md={6}><Typography component="span">{`${sums?.CUST_CT_LY_SUM?.toFixed(2)}  M`}</Typography></Grid>
                    </Grid>
                  </Box>
                  <BasicStackedBar
                    xAxisLabel={""}
                    data={[
                      {
                        "name": "BASE START PERIOD",
                        "data": _.map(_.filter(
                          graphData, { 'name': "BASE START PERIOD" }
                        ), "data")[0]
                      },
                      {
                        "name": "RE-ENGAGED",
                        "data": _.map(_.filter(
                          graphData, { 'name': "RE-ENGAGED" }
                        ), "data")[0]
                      },
                      {
                        "name": "NEW TO COMPANY",
                        "data": _.map(_.filter(
                          graphData, { 'name': "NEW TO COMPANY" }
                        ), "data")[0]
                      },

                      {
                        "name": "INACTIVE",
                        "data": _.map(_.filter(
                          graphData, { 'name': "INACTIVE" }
                        ), "data")[0]
                      },

                    ]}
                    categories={["TY", "LY"]}
                    label={""}
                    horizontal={false}
                    total={false}
                    colors={["#393939", "#215678", "#59a17d", "#af0f2e"]}
                  />
                </>
                : <Box component={"div"} className={classes.noData}>Opps !No data found</Box>}
            </>
        }
      </Grid >

      {/* //table data */}
      < Grid item xs={12} sm={6} md={6} >

        {
          load3 ? <Box component={"div"} className={classes.progress} >
            <CircularProgress />
          </Box > :
            <>
              {
                trendData?.length > 0 ?
                  <>
                    <Typography className={classes.tableText}>Percentage change in the customer base YOY </Typography>
                    <TableContainer className={classes.container} component={Paper} >
                      <Table className={classes.tableContainer} size="small" aria-label="a dense table">
                        <TableHead className={classes.tableRightBorder}>
                          <TableCell>Channel</TableCell>
                          {
                            trendData[0]?.Trend_month?.map((x) => (
                              <TableCell align="right">{x}</TableCell>
                            ))
                          }
                        </TableHead>
                        <TableBody>
                          {trendData?.map((row) => (
                            <TableRow key={row?.Channel}>
                              <TableCell component="th" scope="row">
                                {row?.Channel}
                              </TableCell>
                              {
                                row?.data?.map((x) => (
                                  <>
                                    <TableCell align="right">{(x?.percentage_difference?.toFixed(1))}%</TableCell>
                                  </>
                                ))
                              }

                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                  : <Box component={"div"} className={classes.noData}>Opps !No data found</Box>}
            </>
        }
      </Grid >
    </Grid >
  );
};

export default CustomerBase;
