import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
import {
  roundToTwoDecimalPlaces,
  toMetricFormat,
  numberWithCommas
} from "../../Common/Utils/ClientUtil";
import CustomMaterialTable from "../../Common/CustomMaterialTable";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import BarChartNegative from "../../Common/Charts/BarChartNegative";
import { useSelector } from "react-redux";
import secureAxios from "config/api-config";
import { useHistory } from "react-router-dom";
import useStyles from "./Style";
import _ from "lodash";
import { order, kpiOrder } from "./index";
import ExportCsv from "views/Common/Utils/ExportCsv";

const GroupViewDetail = (props) => {
  const history = useHistory();
  const filterData = useSelector((state) => state.groupView);
  const { brand, startEndDate, purchaseItemBrand } = filterData;
  const { campType, outlierChecked, selectedCampaignIds } = props;
  const classes = useStyles();

  //define state
  const [kpiList, setKpiList] = useState([]);
  const [kpiDetails, setKpiDetails] = useState([]);
  const [taotalCampMax, setTotalCampMax] = useState("")
  const [load, setLoad] = useState(false);
  const [load2, setLoad2] = useState(false);
  const [expanded, setExpanded] = useState(false);

  //function for sort card
  const sortedData = _.sortBy(kpiList, function (obj) {
    return _.indexOf(order, obj.kpi);
  });

  const sortedDataKpiOrder = {};
  kpiOrder.forEach(key => {
    if (kpiDetails.hasOwnProperty(key)) {
      sortedDataKpiOrder[key] = kpiDetails[key];
    }
  });

  //table data
  const columns = [
    {
      title: "Name",
      field: "name",
      render: (rowData) => {
        return rowData?.dataPointName;
      },
    },
    {
      title: "Lift",
      field: "lift",
      render: (rowData) => {
        return rowData?.lift + "%";
      },
    },
    {
      title: "Abs lift",
      field: "abs_lift",
      render: (rowData) => {
        return rowData?.kpiName === "responder_rate"
          ? numberWithCommas(parseFloat(rowData?.abs_lift)?.toFixed(0))
          : toMetricFormat(parseFloat(rowData?.abs_lift), rowData?.kpiName);
      },
    },
  ];
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //redirect to measure page
  const redirect = (rowData) => {
    history.push(`/campaignDetails/${rowData.campaignNameId}`);
  };



  //api call for getAllGroupViewData

  const getAllGroupViewData = () => {
    setLoad(true);
    secureAxios
      .post(`groupView/getAllGroupViewData`, {
        brand: purchaseItemBrand === "TOT" ? "ALL" : purchaseItemBrand,
        group: campType,
        startDate: startEndDate[0],
        endDate: startEndDate[1],
        outlierValue: outlierChecked,
        campgn_brand: brand,
        campaignId: selectedCampaignIds?.length > 0 ? selectedCampaignIds : null
      })
      .then((res) => {
        setKpiList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally((err) => {
        setLoad(false);
      });
  }

  const getKpiDetails = () => {
    setLoad2(true)
    secureAxios
      .post("groupView/getKPIDetails", {
        brand: purchaseItemBrand === "TOT" ? "ALL" : purchaseItemBrand,
        groupName: campType,
        startDate: startEndDate[0],
        endDate: startEndDate[1],
        outlierValue: outlierChecked,
        campgn_brand: brand,
        campaignId: selectedCampaignIds?.length > 0 ? selectedCampaignIds : null
      })
      .then((res) => {
        setLoad2(false);
        setKpiDetails(res?.data?.groupedData);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally((err) => {
        setLoad2(false);
      });
  }

  //api call for getKpiDetails
  useEffect(() => {
    let isSub = true;
    if (isSub) {
      getAllGroupViewData()
      getKpiDetails()
    }
    return () => {
      isSub = false;
    };
  }, [campType, outlierChecked, brand, purchaseItemBrand, startEndDate, selectedCampaignIds]);


  //api call for total campaign data
  useEffect(() => {
    let isSub = true;
    if (isSub) {
      secureAxios
        .post("groupView/totalCampaign", {
          brand: purchaseItemBrand === "TOT" ? "ALL" : purchaseItemBrand,
          groupName: campType,
          startDate: startEndDate[0],
          endDate: startEndDate[1],
          outlierValue: outlierChecked,
          campgn_brand: brand
        })
        .then((res) => {
          setTotalCampMax(res?.data?.maxTotalCamp);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally((err) => {

        });
    }
    return () => {
      isSub = false;
    };
  }, [campType, outlierChecked, brand, purchaseItemBrand, startEndDate]);

  //file download
  const fileDownload = (item, index) => {
    const newArr = [];
    // Loop through items and process each one
    item.forEach((itemData, dataIndex) => {
      // console.log({itemData});
      const kpiSummaryData = {};
      kpiSummaryData['Sno'] = `${dataIndex + 1}`;
      kpiSummaryData['Campaign Id'] = `${itemData.campaignNameId}`;
      kpiSummaryData['Lift'] = `${itemData.lift}`;
      kpiSummaryData['AbsLift'] = `${itemData?.kpiName ==="responder_rate" ? parseFloat(itemData?.abs_lift)?.toFixed(0): parseFloat(itemData?.abs_lift)?.toFixed(0)}`;
      kpiSummaryData['P-Value'] = `${itemData.stat_sig}`;
      newArr.push(kpiSummaryData);
    });
  
    return newArr;
  };
  
  
  

  return (
    <Grid container spacing={2}>
      {load ? (
        <Box component="div" className={classes.progress}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid item xs={12} sm={4} md={2}>
            <Box component="div" className={classes.sideBlock}>
              <Typography component="h2" variant="h2">
                {taotalCampMax}
              </Typography>
              <Typography variant="h6">Campaigns</Typography>
            </Box>
          </Grid>

          {kpiList?.length > 0 ? (
            <>
              {sortedData?.map((item, index) => (

                <Grid item xs={12} sm={4} md={2} key={index}>
                  <Box component="div" className={classes.block}>
                    <Typography component="h5" variant="subtitle2">

                      {item?.kpiLabel}
                    </Typography>
                    <Typography variant="h4">
                      {roundToTwoDecimalPlaces(item?.lift)}% Lift
                    </Typography>
                    <Typography variant="h6">
                      {item?.kpiLabel === "Response Rate" ? item?.absLift?.toFixed() : (toMetricFormat(item?.absLift, item?.kpi))} Abs Lift
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </>
          ) : (
            <div className={classes.nodata}>Opps ! No data Found</div>
          )}
        </>
      )}
      {/* Accordion */}
      <Grid item xs={12} md={12}>
        {load2 ? (
          <Box component="div" className={classes.progress}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {Object?.keys(kpiDetails).length > 0 ? (
              <>
                {_.entries(sortedDataKpiOrder)?.map(
                  (item, index) => (
                    
                    <Accordion
                      className={classes.accordion}
                      key={index}
                      expanded={expanded === index}
                      onChange={handleAccordionChange(index)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography
                          component="h5"
                          variant="subtitle2"
                          color="primary"
                        >
                          {item[0]}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>

                        <Box component="div" className="sectionScroll mb20">
                          <BarChartNegative
                            data={item[1]}
                            kpiName={item[1][0]?.kpiName}
                            xAxisTitle={"Lift (%)"}
                            yAxisTitle={"Campaigns"}
                            categories={_.map(
                              item[1],
                              "dataPointName"
                            )
                            }
                            testControlChecked={true}
                            groupView={true}
                          />
                        </Box>
                            {/* File download */}
                        <div className={classes.groupViewfiledownload}>
                          <Typography component="h4">Click here to download file</Typography>
                          <ExportCsv 
                          fileName={`Group_view_data_${item[0]}`}
                          tableData={fileDownload(item[1], handleAccordionChange)}
                          />
                        </div>
                         {/* Table data */}
                        <CustomMaterialTable
                          title=""
                          columns={columns}
                          data={item[1]}
                          options={{
                            actionsColumnIndex: -1,
                            sorting: true,
                            search: false,
                            toolbar: false,
                            paging: true,
                            pageSize: 5,
                          }}
                          actions={[
                            (rowData) => ({
                              icon: "visibility",
                              tooltip: "View Details",
                              onClick: (event, rowData) => {
                                redirect(rowData);
                              },
                            }),
                          ]}
                          components={{
                            Container: (props) => (
                              <Box
                                component="div"
                                className={classes.tableContainer}
                              >
                                {props.children}
                              </Box>
                            ),
                            Header: () => {
                              return (
                                <TableHead className={classes.tableCustom}>
                                  <TableRow>
                                    <TableCell rowSpan={2}>
                                      Campaign Name
                                    </TableCell>
                                    <TableCell rowSpan={2}>Lift</TableCell>
                                    <TableCell rowSpan={2}>Abs Lift</TableCell>
                                    <TableCell rowSpan={2}>Action</TableCell>
                                  </TableRow>
                                </TableHead>
                              );
                            },
                          }}
                        ></CustomMaterialTable>
                      </AccordionDetails>
                    </Accordion>
                  )
                )}
              </>
            ) : (
              <div className={classes.nodata}>Oops ! No data found</div>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};
export default GroupViewDetail;