import React from "react";
import { Redirect} from "react-router-dom";


function hasJWT() {
  let flag = false;
  //check user has JWT token
  flag =
    localStorage.getItem("accessToken") && localStorage.getItem("accessToken")
      ? true
      : false;


  return flag;
}

const PrivateRoute = (props) => {
  // console.log(props);
  return (
    <>{hasJWT() ? props.content : <Redirect to={{ pathname: "/login" }} />}</>
  );
};

export default PrivateRoute;
