import React, { useEffect, useState } from "react";

import CustomMaterialTable from "../Common/CustomMaterialTable";
import MultiSelectDropdown from "../Common/Filters/MultiSelectDropdown";
import CustomMultiSelectDropdown from "../Common/Filters/CustomMultiSelectDropdown";
import {
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Grid,
  Button,
  Box,
} from "@material-ui/core/";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import ExportCsv from "../Common/Utils/ExportCsv";
import secureAxios from "../../config/api-config";
import _ from "lodash";
import { numberWithCommas } from "../Common/Utils/ClientUtil";
import { APIS } from "../../config";
import AutocompleteDropdown from "../Common/Filters/AutocompleteDropdown";
import ModalLoading from "components/ModalLoading";
import palette from "../../theme/palette";
import { useParams } from "react-router";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import useStyles from './style'



export default function MerchHierarchy(props) {
  const classes = useStyles();
  const { id } = useParams()
  const dispatch = useDispatch();
  const {
    outlierChecked,
    testControlChecked,
  } = props;
  const appReduxState = useSelector((state) => ({
    ...state.drilldownDetail,
  }));
  const { merchHierarchyFilters } = appReduxState;
  const [alignment, setAlignment] = useState("Division");
  const [kpiDropdown, setKpiDropdown] = useState([]);
 
  const [,setRegionDropdown] = useState([]);
  const [fulfilmentDropdown, setFulfilmentDropdown] = useState([]);
  const [groupDropDdown, setGroupDropdown] = useState([]);
  const [collectionDropDdown, setCollectionDropdown] = useState([]);
  const [flightDropDdown, setFlightDropdown] = useState([]);
  const [divDropDdown, setDivDropdown] = useState([]);
  const [deptDropDdown, setDeptDropdown] = useState([]);
  const [classDropDdown, setClassDropdown] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [, setMsg] = useState("Loading...");
  const [selectedKPIs, setSelectedKPIs] = useState([]);
  // const [selectedBrand, setSelectedBrand] = useState([]);
 
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedFulfilment, setSelectedFulfilment] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState([]);
  const [selectedFlight, setSelectedFlight] = useState([]);
  const [selectedDiv, setSelectedDiv] = useState([]);
  const [selectedDept, setSelectedDept] = useState([]);
  const [selectedClass, setSelectedClass] = useState([]);

  let successfulApiCalls = 0;
  let apiCallCount = 0

  //function check api reponse 
  const handleApiSuccess = () => {
    successfulApiCalls++;
    // Check if both API calls have been successfully completed
    if (successfulApiCalls === apiCallCount) {
      // Close the loader
      dispatch({
        type: "MODALLOADING",
        payload: false,
      });
    }
  };


  useEffect(() => {
    setSelectedKPIs([]);
  }, []);

  //table columns
  const columns = [
    {
      field: "KPI",

      colspan: 4,
      render: (rowData) => { return rowData.kpiLabel },
    },
    {
      field: "COLLECTION",
      colspan: 4,
      render: (rowData) => { return rowData.collection_name },
    },
    {
      field: "FLIGHT",
      colspan: 4,
      render: (rowData) => { return rowData.flight_id },
    },

    {
      field: "CHANNEL",
      colspan: 4,
      render: (rowData) => { return rowData.fullfilmentchannel }
    },
    {
      field: "Products",
      colspan: 4,
      render: (rowData) => { return rowData.productGroup },
    },
    {
      field: "DIVISION",
      colspan: 4,
      render: (rowData) => { return rowData.division },
    },
    {
      field: "DEPARTMENT",
      colspan: 4,
      render: (rowData) => { return rowData.dept },
    },
    {
      field: "CLASS",
      colspan: 4,
      render: (rowData) => { return rowData.class },
    },
    {
      field: "POST_TEST",
      render: (rowData) => {
        return (
          rowData["POST_TEST"],
          rowData.postTest !== null ? numberWithCommas(rowData.postTest?.toFixed(2)) : "0"
        );
      },
    },
    {
      field: "POST_CONTROL",

      render: (rowData) => {
        return (
          rowData["POST_CONTROL"],
          rowData?.postControl !== null ? numberWithCommas(rowData?.postControl?.toFixed(2)) : "0"
        );
      },
    },
    {
      field: "Lift %",

      // render: (rowData) => {
      //   return (rowData["Lift %"], rowData?.lift ? rowData?.lift?.toFixed(2) : "0");
      // },
      render: (rowData) => {
        return (
          rowData["Lift %"], rowData?.lift ?
          rowData?.lift?.toFixed(2)
            : "0"
        );
      },
    },
    {
      field: "ABS_LIFT",
      render: (rowData) => {
        return (
          rowData["ABS_LIFT"], rowData?.absLift ?
            (rowData?.kpi === "campaign_purchasers"
              ? numberWithCommas(rowData?.absLift?.toFixed(0)) : numberWithCommas(rowData?.absLift?.toFixed(2)))
            : "0"
        );
      },
    },
    {
      field: "P Value",

      render: (rowData) => (
        <Typography
          className={classes.statsigArea}
          style={
            {
              backgroundColor: rowData.confidenceLevel === "Statistically Significant" ? palette.semanticBGColors.secondaryGreen
                : rowData.confidenceLevel === "Statistically Insignificant" || rowData.confidenceLevel === null ? palette.semanticBGColors.secondaryRed
                  : palette.semanticBGColors.secondaryYellow,
              color: rowData.confidenceLevel === "Statistically Significant" ? palette.semanticBGColors.primaryGreen
                : rowData.confidenceLevel === "Statistically Insignificant" || rowData.confidenceLevel === null ? palette.semanticBGColors.primaryRed
                  : palette.semanticBGColors.primaryYellow,
              border: "1px solid",
              borderColor: rowData.confidenceLevel === "Statistically Significant" ? palette.semanticBGColors.primaryGreen
                : rowData.confidenceLevel === "Statistically Insignificant" || rowData.confidenceLevel === null ? palette.semanticBGColors.primaryRed
                  : palette.semanticBGColors.primaryYellow,
            }
          }
        >
          {rowData?.confidenceLevel === "Statistically Significant" ? "Significant"
            : rowData?.confidenceLevel === "Statistically Insignificant" || rowData.confidenceLevel === null ? "Insignificant"
              : rowData?.confidenceLevel
          }
        </Typography>
      ),
    },
  ];
  const toggleColumns = [
    {
      field: "KPI",
      colspan: 4,
      render: (rowData) => { return rowData?.kpiLabel },
    },

    {
      field: "COLLECTION",
      colspan: 4,
      render: (rowData) => { return rowData?.collection_name },
    },
    {
      field: "FLIGHT",
      colspan: 4,
      render: (rowData) => { return rowData.flight_id },
    },
    {
      field: "CHANNEL",
      colspan: 4,
      render: (rowData) => { return rowData.fullfilmentchannel }
    },
    {
      field: "Products",
      colspan: 1,
      render: (rowData) => { return rowData?.productGroup }
    },
    {
      field: "DIVISION",
      colspan: 4,
      render: (rowData) => { return rowData.division },
    },
    {
      field: "DEPARTMENT",
      colspan: 4,
      render: (rowData) => { return rowData.dept },
    },
    {
      field: "CLASS",
      colspan: 4,
      render: (rowData) => { return rowData.class },
    },
    {
      field: "PRE_TEST",
      render: (rowData) => {
        return (
          rowData["PRE_TEST"],
          rowData?.preTest !== null ? numberWithCommas(rowData?.preTest?.toFixed(2)) : "0"
        );
      },
    },
    {
      field: "POST_TEST",
      render: (rowData) => {
        return (
          rowData["POST_TEST"],
          rowData?.postTest !== null ? numberWithCommas(rowData?.postTest.toFixed(2)) : "0"
        );
      },
    },
    {
      field: "TEST_PER_CHANGE",
      render: (rowData) => {
        return (
          rowData["TEST_PER_CHANGE"],
          rowData.pctTest ? numberWithCommas(rowData?.pctTest?.toFixed(2)) : "0"
        );
      },
    },
    {
      field: "PRE_CONTROL",
      render: (rowData) => {
        return (
          rowData["PRE_CONTROL"],
          rowData.preControl
            ? numberWithCommas(rowData.preControl.toFixed(2))
            : "0"
        );
      },
    },

    {
      field: "POST_CONTROL",
      render: (rowData) => {
        return (
          rowData["POST_CONTROL"],
          rowData.postControl !== null
            ? numberWithCommas(rowData.postControl.toFixed(2))
            : ""
        );
      },
    },
    {
      field: "CONTROL_PER_CHANGE",
      render: (rowData) => {
        return (
          rowData["CONTROL_PER_CHANGE"],
          rowData?.pctControl
            ? numberWithCommas(rowData?.pctControl?.toFixed(2))
            : "0"
        );
      },
    },

    {
      field: "Lift %",
      render: (rowData) => {
        return rowData["Lift %"],
          rowData?.preLiftPct ? rowData?.preLiftPct?.toFixed(2) : "0"
      },
    },
    {
      field: "ABS_LIFT",
      render: (rowData) => {

        return (
          rowData["ABS_LIFT"],
          rowData?.preAbsLift ?
            (rowData?.kpi === "campaign_purchasers"
              ? numberWithCommas(rowData?.absLift?.toFixed(0)) : numberWithCommas(rowData?.preAbsLift?.toFixed(2)))
            : "0"
        );
      },
    },

    {
      field: "P Value",

      render: (rowData) => (
        <Typography
          className={classes.statsigArea}
          style={
            {
              backgroundColor: rowData.diff_n_diff_confidence_level === "Statistically Significant" ? palette.semanticBGColors.secondaryGreen
                : rowData.diff_n_diff_confidence_level === "Statistically Insignificant" || rowData.diff_n_diff_confidence_level === null ? palette.semanticBGColors.secondaryRed
                  : palette.semanticBGColors.secondaryYellow,
              color: rowData.diff_n_diff_confidence_level === "Statistically Significant" ? palette.semanticBGColors.primaryGreen
                : rowData.diff_n_diff_confidence_level === "Statistically Insignificant" || rowData.diff_n_diff_confidence_level === null ? palette.semanticBGColors.primaryRed
                  : palette.semanticBGColors.primaryYellow,
              border: "1px solid",
              borderColor: rowData.diff_n_diff_confidence_level === "Statistically Significant" ? palette.semanticBGColors.primaryGreen
                : rowData.diff_n_diff_confidence_level === "Statistically Insignificant" || rowData.diff_n_diff_confidence_level === null ? palette.semanticBGColors.primaryRed
                  : palette.semanticBGColors.primaryYellow,
            }
          }
        >
          {rowData?.diff_n_diff_confidence_level === "Statistically Significant" ? "Significant"
            : rowData?.diff_n_diff_confidence_level === "Statistically Insignificant" || rowData.diff_n_diff_confidence_level === null ? "Insignificant"
              : rowData?.diff_n_diff_confidence_level
          }
        </Typography>
      ),
    },

  ];
  const getKpioptions = (val, status) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.KIPS, {
        outlierValue: outlierChecked ? true : false,
        filters: val,
        kpiGroup: "Transaction",
        tabName: "MerchHierarchy",
        aggregationLevel: alignment,
        campaign_id: id,
        
      })
      .then((res) => {
        if (res.status === 200) {
          let resData = res.data.responseData;
          setKpiDropdown(resData);
          if (status) {
            //here to set 5 seletced kpis
            const allData = [
              "Net Sales",
              "Response Rate",
              "Transactions",
              "ADS",
              "Net Margin",
            ];
            const selectedKPIs = resData.filter((item) =>
              allData.includes(item.kpiLabel)
            );
            setSelectedKPIs(selectedKPIs);
          } else {
            let newSelected = resData.filter((array) =>
              val.selectedKpi.some((filter) => filter === array.kpiName)
            );
            setSelectedKPIs(newSelected);
          }
        }
      }).catch((err) => { })
      .finally((err) => {
        handleApiSuccess();
      });
  };
  
  
  const getRegionoptions = (val, status) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.REGIONS, {
        outlierValue: outlierChecked ? true : false,
        filters: val,
        kpiGroup: "Transaction",
        tabName: "MerchHierarchy",
        aggregationLevel: alignment,
        campaign_id: id,
       
      })
      .then((res) => {
        if (res.status === 200) {
          let resData = res.data.responseData;
          setRegionDropdown(resData);
          if (status) {
            let all = resData.filter((item) => {
              return item === "ALL";
            });
            setSelectedRegion(all);
          } else {
            let newSelected = val.region.filter((item) =>
              resData.includes(item)
            );
            setSelectedRegion(newSelected);
          }
        }
      }).catch((err) => { })
      .finally((err) => {
        handleApiSuccess();
      });
  };
  const getFulfilmentoptions = (val, status) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.FULLFILLMENT_CHANNEL, {
        outlierValue: outlierChecked ? true : false,
        filters: val,
        campaign_id: id,
        kpiGroup: "Transaction",
        tabName: "MerchHierarchy",
       
      })
      .then((res) => {
        if (res.status === 200) {
          let resData = res.data.responseData;
          setFulfilmentDropdown(resData);
          if (status) {
            let all = resData.filter((item) => {
              return item === "ALL";
            });
            setSelectedFulfilment(all);
          } else {
            let newSelected = val.fulfillmentChannel.filter((item) =>
              resData.includes(item)
            );
            setSelectedFulfilment(newSelected);
          }

        }
      }).catch((err) => { })
      .finally((err) => {
        handleApiSuccess();
      });
  };
  //Apicall for flight
  const getFlightoptions = (val, status) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.FLIGHT_ID, {
        outlierValue: outlierChecked ? true : false,
        filters: val,
        kpiGroup: "Transaction",
        tabName: "MerchHierarchy",
        aggregationLevel: alignment,
        campaign_id: id,
       
      })
      .then((res) => {
        if (res.status === 200) {
          let resData = res?.data?.responseData;
          setFlightDropdown(resData);
          if (status) {
            let all = resData.filter((item) => {
              return item === "ALL";
            });
            setSelectedFlight(all);
          } else {
            let newSelected = val?.flight?.filter((item) =>
              resData.includes(item)
            );
            setSelectedFlight(newSelected);
          }

        }
      })
      .catch((err) => { })
      .finally((err) => {
        handleApiSuccess();
      });
  };
  //Apicall for get collection data
  const getCollectionoptions = (val, status) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.COLLECTION, {
        outlierValue: outlierChecked ? true : false,
        filters: val,
        kpiGroup: "Transaction",
        tabName: "MerchHierarchy",
        aggregationLevel: alignment,
        campaign_id: id,
       
      })
      .then((res) => {
        if (res.status === 200) {
          let resData = res?.data?.responseData;
          setCollectionDropdown(resData);
          if (status) {
            let all = resData.filter((item) => {
              return item === "ALL";
            });
            setSelectedCollection(all);
          } else {
            let newSelected = val?.collection?.filter((item) =>
              resData.includes(item)
            );
            setSelectedCollection(newSelected);
          }

        }
      })
      .catch((err) => { })
      .finally((err) => {
        handleApiSuccess();
      });
  };
  //Apicall for group
  const getGroupoptions = (val, status) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.GROUP, {
        outlierValue: outlierChecked ? true : false,
        filters: val,
        kpiGroup: "Transaction",
        tabName: "MerchHierarchy",
        aggregationLevel: alignment,
        campaign_id: id,
       
      })
      .then((res) => {
        if (res.status === 200) {
          let resData = res?.data?.responseData;
          setGroupDropdown(resData);
          if (status) {
            let all = resData.filter((item) => {
              return item === "Total";
            });
            setSelectedGroup(all);
          } else {
            let newSelected = val?.productGrp?.filter((item) =>
              resData.includes(item)
            );
            setSelectedGroup(newSelected);
          }

        }
      })
      .catch((err) => { })
      .finally((err) => {
        handleApiSuccess();
      });
  };
  //Apicall for Division
  const getDivisionoptions = (filters, status) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.DIVISION, {
        outlierValue: outlierChecked ? true : false,
        filters: filters,
        kpiGroup: "Transaction",
        tabName: "MerchHierarchy",
        aggregationLevel: "Division",
        campaign_id: id,
       
      })
      .then((res) => {
        if (res.status === 200) {
          let resData = res.data.responseData;
          setDivDropdown(resData);
          if (status) {
            let all = resData.filter((item) => {
              return item === "ALL";
            });
            setSelectedDiv(all);
          } else {
            let newSelected = filters.division.filter((item) =>
              resData.includes(item)
            );
            setSelectedDiv(newSelected);
          }
        }
      }).catch((err) => { })
      .finally((err) => {
        handleApiSuccess();
      });
  };
  //Apicall for Department
  const getDeptoptions = (filters, status) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.DEPARTMENT, {
        outlierValue: outlierChecked ? true : false,
        filters: filters,
        kpiGroup: "Transaction",
        tabName: "MerchHierarchy",
        aggregationLevel: "Department",
        campaign_id: id,
       
      })
      .then((res) => {
        if (res.status === 200) {
          let resData = res.data.responseData;
          setDeptDropdown(resData);
          if (status) {
            let all = resData.filter((item) => {
              return item === "ALL";
            });
            setSelectedDept(all);
          } else {
            console.log("ddd");
            let newSelected = filters.department.filter((item) =>
              resData.includes(item)
            );
            setSelectedDept(newSelected);
          }
        }
      }).catch((err) => { })
      .finally((err) => {
        handleApiSuccess();
      });
  };
  //Apicall for Class
  const getClassoptions = (filters, status) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.CLASS, {
        outlierValue: outlierChecked ? true : false,
        filters: filters,
        kpiGroup: "Transaction",
        tabName: "MerchHierarchy",
        aggregationLevel: "Class",
        campaign_id: id,
       
      })
      .then((res) => {
        if (res.status === 200) {
          let resData = res.data.responseData;
          setClassDropdown(resData);
          if (status) {
            let all = resData.filter((item) => {
              return item === "ALL";
            });
            setSelectedClass(all);
          } else {
            let newSelected = filters.class.filter((item) =>
              resData.includes(item)
            );
            setSelectedClass(newSelected);
          }
        }
      }).catch((err) => { })
      .finally((err) => {
        handleApiSuccess();
      });
  };
  const fetchTblData = (val, aggLevel) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.MERCH_SUMMARY, {
        outlierValue: outlierChecked ? true : false,
        filters: val,
        kpiGroup: "Transaction",
        tabName: "MerchHierarchy",
        aggregationLevel: aggLevel,
        campaign_id: id,
        
      })
      .then(function (response) {
        if (response.status === 200) {

          if (!response?.data?.responseData?.length) {
            setMsg("No records to display");
          }
          setTblData(response?.data?.responseData);

        }
      })
      .catch((err) => { })
      .finally((err) => {
        handleApiSuccess();
      });
  };

  useEffect(() => {
    getKpioptions(merchHierarchyFilters, "firstLoad");
    getGroupoptions(merchHierarchyFilters, "firstLoad");
    getCollectionoptions(merchHierarchyFilters, "firstLoad");
    getFlightoptions(merchHierarchyFilters, "firstLoad");
    // getBrandoptions(merchHierarchyFilters, "firstLoad");
    getFulfilmentoptions(merchHierarchyFilters, "firstLoad");
    getRegionoptions(merchHierarchyFilters, "firstLoad");
    getDivisionoptions(merchHierarchyFilters, "firstLoad");
    getDeptoptions(merchHierarchyFilters, "firstLoad");
    getClassoptions(merchHierarchyFilters, "firstLoad");
    fetchTblData(
      {
        selectedKpi: [
          "sales",
          "responder_rate",
          "orders",
          "avg_order_value",
          "margin_amt",
        ],
        // brands: ["ALL"],
        // channel: [],
        region: ["ALL"],
        merchHierarchy: [],
        fulfillmentChannel: ["ALL"],
        collection: ["ALL"],
        flight: ["ALL"],
        productGrp: [],
        division: ["ALL"],
        department: ["ALL"],
        class: ["ALL"],
      },
      alignment
    );

  }, [outlierChecked]);

  //function for csv download file
  const newArr = []
  tblData.forEach((x, i) => {
    const kpiSummaryData = {};
    if (testControlChecked) {
      // Condition when testControlChecked is true
      kpiSummaryData['Sno'] = `${i + 1}`;
      kpiSummaryData['Metrics'] = `${x.kpiLabel}`;
      kpiSummaryData['Collection'] = `${x.collection_name}`;
      kpiSummaryData['Flight'] = `${x.flight_id}`;
      kpiSummaryData['Fulfilment Channel'] = `${x.fullfilmentchannel}`;
      kpiSummaryData['Products'] = `${x.productGroup}`;
      kpiSummaryData['Division'] = `${x.division}`;
      kpiSummaryData['Department'] = `${x.dept}`;
      kpiSummaryData['Class'] = `${x.class}`;
      kpiSummaryData['Test'] = `${x.test_during_value?.toFixed(2)}`;
      kpiSummaryData['Control'] = `${x.control_during_value?.toFixed(2)}`;
      kpiSummaryData['lift%'] = `${x.lift?.toFixed(2)}`;
      kpiSummaryData['Abs'] = `${x.kpi === "campaign_purchasers" ? x.absLift?.toFixed(0) : x.absLift?.toFixed(2)}`;
      kpiSummaryData['P-Value(Analysis peroid)'] = `${x.significance ===null?"null": x.significance}`;
      kpiSummaryData['Stat Sig (p-value(Analysis peroid))'] = `${x.confidenceLevel}`;
    } else {
      // Condition when testControlChecked is false
      kpiSummaryData['Sno'] = `${i + 1}`;
      kpiSummaryData['Metrics'] = `${x.kpiLabel}`;
      kpiSummaryData['Collection'] = `${x.collection_name}`;
      kpiSummaryData['Flight'] = `${x.flight_id}`;
      kpiSummaryData['Fulfilment Channel'] = `${x.fullfilmentchannel}`;
      kpiSummaryData['Products'] = `${x.productGroup}`;
      kpiSummaryData['Division'] = `${x.division}`;
      kpiSummaryData['Department'] = `${x.dept}`;
      kpiSummaryData['Class'] = `${x.class}`;
      kpiSummaryData['Test(Pre-Avg)'] = `${x.test_pre_value?.toFixed(2)}`;
      kpiSummaryData['Test(Post-Avg)'] = `${x.test_during_value?.toFixed(2)}`;
      kpiSummaryData['Test(%Change)'] = `${x.pctTest?.toFixed(2)}`;
      kpiSummaryData['Control(Pre-Avg)'] = `${x.control_pre_value?.toFixed(2)}`;
      kpiSummaryData['Control(Post-Avg)'] = `${x.control_during_value?.toFixed(2)}`;
      kpiSummaryData['Control(%Change)'] = `${x.pctControl?.toFixed(2)}`;
      kpiSummaryData['Lift %'] = `${x.preLiftPct?.toFixed(2)}`;
      kpiSummaryData['Abs'] = `${x.kpi === "campaign_purchasers" ? x.absLift?.toFixed(0) : x.preAbsLift?.toFixed(2)}`;
      kpiSummaryData['P-Value(Pre-post)'] = `${x.diff_n_diff_significance ===null ? "null":x.diff_n_diff_significance?.toFixed(4)}`;
      kpiSummaryData['Stat Sig (p-value(Pre-post))'] = `${x.diff_n_diff_confidence_level}`;
    }
    newArr.push(kpiSummaryData);
  });

  return (
    <>

      <ModalLoading />
      <Box component="div" className={classes.filterAreaDropDown}>
        <Formik
          initialValues={{
            KPI: selectedKPIs,
            GROUP: selectedGroup,
            COLLECTION: selectedCollection,
            FLIGHT: selectedFlight,
            // BRAND: selectedBrand,
           
            REGION: selectedRegion,
            FULFILMENT: selectedFulfilment,
            DIVISION: selectedDiv,
            DEPARTMENT: selectedDept,
            CLASS: selectedClass,
          }}
          enableReinitialize={true}
          onSubmit={(values) => {
            fetchTblData(
              {
                selectedKpi: _.map(selectedKPIs, "kpiName"),
                // brands: selectedBrand,
                // channel: selectedChannel,
                region: selectedRegion,
                fulfillmentChannel: selectedFulfilment,
                collection: selectedCollection,
                flight: selectedFlight,
                productGrp: selectedGroup,
                division: selectedDiv,
                department: selectedDept,
                class: selectedClass,
              },
              alignment
            );
          }}
        >
          {({

            values,
            handleChange,
            setFieldValue,
            resetForm,
          }) => (
            <Form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={2}>
                  <CustomMultiSelectDropdown
                    width={"w100"}
                    label={"Metrics"}
                    name={"KPI"}
                    value={values.KPI}
                    handleMethod={(value) => {
                      setFieldValue("KPI", value);
                      setSelectedKPIs(value);
                    }}
                    options={kpiDropdown}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                  <MultiSelectDropdown
                    width={"w100"}
                    label={"Collection"}
                    name={"COLLECTION"}
                    value={values.COLLECTION}
                    handleMethod={(value) => {
                      setFieldValue("COLLECTION", value);
                      setSelectedCollection(value);
                      getFlightoptions({
                        selectedKpi: _.map(selectedKPIs, "kpiName"),
                        // brands: selectedBrand,
                        
                        region: selectedRegion,
                        fulfillmentChannel: selectedFulfilment,
                        collection: value,
                        flight: selectedFlight,
                        productGrp: selectedGroup,
                        division: selectedDiv,
                        department: selectedDept,
                        class: selectedClass,
                      });
                    }}
                    options={collectionDropDdown}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <MultiSelectDropdown
                    width={"w100"}
                    label={"Audience Group"}
                    name={"FLIGHT"}
                    value={values.FLIGHT}
                    handleMethod={(value) => {
                      setFieldValue("FLIGHT", value);
                      setSelectedFlight(value);
                    }}
                    options={flightDropDdown}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <MultiSelectDropdown
                    width={"w100"}
                    label={"Fulfilment"}
                    name={"FULFILMENT"}
                    value={values.FULFILMENT}
                    handleMethod={(value) => {
                      setFieldValue("FULFILMENT", value);
                      setSelectedFulfilment(value);
                    }}
                    options={fulfilmentDropdown}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <MultiSelectDropdown
                    width={"w100"}
                    label={"Products"}
                    name={"GROUP"}
                    value={values.GROUP}
                    handleMethod={(value) => {
                      setFieldValue("GROUP", value);
                      setSelectedGroup(value);
                    }}
                    options={groupDropDdown}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="autoCompleteArea">
                  <AutocompleteDropdown
                    width={"w100"}
                    label={"Division"}
                    name={"DIVISION"}
                    value={values.DIVISION}
                    handleMethod={(value) => {
                      setFieldValue("DIVISION", value);
                      setSelectedDiv(value);
                      getDeptoptions({
                        selectedKpi: _.map(selectedKPIs, "kpiName"),
                        // brands: selectedBrand,
                    
                        region: selectedRegion,
                        fulfillmentChannel: selectedFulfilment,
                        collection: selectedCollection,
                        flight: selectedFlight,
                        productGrp: selectedGroup,
                        division: value,
                        department: selectedDept,
                        class: selectedClass,
                      });
                      getClassoptions({
                        selectedKpi: _.map(selectedKPIs, "kpiName"),
                        // brands: selectedBrand,
                      
                        region: selectedRegion,
                        fulfillmentChannel: selectedFulfilment,
                        collection: selectedCollection,
                        flight: selectedFlight,
                        productGrp: selectedGroup,
                        division: value,
                        department: selectedDept,
                        class: selectedClass,
                      });
                    }}
                    options={divDropDdown}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} className="autoCompleteArea">
                  <AutocompleteDropdown
                    width={"w100"}
                    label={"Department"}
                    name={"DEPARTMENT"}
                    value={values.DEPARTMENT}
                    handleMethod={(value) => {
                      setFieldValue("DEPARTMENT", value);
                      setSelectedDept(value);
                      getClassoptions({
                        selectedKpi: _.map(selectedKPIs, "kpiName"),
                        // brands: selectedBrand,
                       
                        region: selectedRegion,
                        fulfillmentChannel: selectedFulfilment,
                        collection: selectedCollection,
                        flight: selectedFlight,
                        productGrp: selectedGroup,
                        division: selectedDiv,
                        department: value,
                        class: selectedClass,
                      });
                    }}
                    options={deptDropDdown}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} className="autoCompleteArea">
                  <AutocompleteDropdown
                    label="Class"
                    name={"CLASS"}
                    options={classDropDdown}
                    width={"w100"}
                    value={values.CLASS}
                    handleMethod={(value) => {
                      setFieldValue("CLASS", value);
                      setSelectedClass(value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={1}>
                  <Button className={classes.buttonFilter} type="submit">
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={1}>
                  <div className={classes.innerContainer}>
                    <RotateLeftIcon
                      color="primary"
                      className={classes.resetBtn}
                      onClick={() => {
                        dispatch({
                          type: "MODALLOADING",
                          payload: true,
                        });
                        setAlignment("Division");
                        resetForm();
                        getKpioptions(merchHierarchyFilters, "firstLoad");
                        // getBrandoptions(merchHierarchyFilters, "firstLoad");
                        // getChanneloptions(merchHierarchyFilters, "firstLoad");
                        getRegionoptions(merchHierarchyFilters, "firstLoad");
                        fetchTblData(
                          {
                            selectedKpi: [
                              "sales",
                              "responder_rate",
                              "orders",
                              "avg_order_value",
                              "margin_amt",
                            ],
                            // brands: ["ALL"],
                            // channel: [],
                            region: ["ALL"],
                            merchHierarchy: [],
                            fulfillmentChannel: ["ALL"],
                            collection: ["ALL"],
                            flight: ["ALL"],
                            productGrp: [],
                            division: ["ALL"],
                            department: ["ALL"],
                            class: ["ALL"],
                          },
                          alignment
                        );
                        getGroupoptions(merchHierarchyFilters, "firstLoad");
                        getCollectionoptions(merchHierarchyFilters, "firstLoad");
                        getFlightoptions(merchHierarchyFilters, "firstLoad");
                        getFulfilmentoptions(merchHierarchyFilters, "firstLoad");
                        getRegionoptions(merchHierarchyFilters, "firstLoad");
                        getDivisionoptions(merchHierarchyFilters, "firstLoad");
                        getDeptoptions(merchHierarchyFilters, "firstLoad");
                        getClassoptions(merchHierarchyFilters, "firstLoad");
                      }}
                    />

                    <ExportCsv
                      tableData={newArr}
                      fileName={testControlChecked ? `merchHierarchy_Analysis_peroid_${id}` : `merchHierarchy_pre_post_${id}`}
                      style={{
                        verticalAlign: "top",
                        margin: "10px 8px 8px 0",
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>

      <CustomMaterialTable
        title=""
        columns={!testControlChecked ? toggleColumns : columns}
        data={tblData}
        options={{
          sorting: true,
          search: false,
          toolbar: false,
          paging: true,
          pageSize: 5,
          // maxBodyHeight: "250px",
          // fixedColumns: { left: 4 },
        }}
        components={{
          Container: props => <Box
            component="div" className="tblScroll">{props.children}</Box>,
          Header: () => {
            return (
              <>
                {!testControlChecked ? (
                  <TableHead
                    className={classes.tableRightBorder}
                  >
                    <TableRow>
                      <TableCell
                        rowSpan={2}
                      >
                        Metrics
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                      >
                        Collection
                      </TableCell>

                      <TableCell
                        rowSpan={2}
                      >
                        Flight
                      </TableCell>

                      <TableCell
                        rowSpan={2}
                      >
                         Fulfilment Channel
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                      >
                        Products
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                      >
                        Division
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                      >
                        Department
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                      >
                        Class
                      </TableCell>
                      <TableCell
                        colSpan={3}
                      >
                        {"Test"}
                      </TableCell>
                      <TableCell
                        colSpan={3}
                      >
                        {"Control"}
                      </TableCell>
                      <TableCell
                        colSpan={3}
                      >
                        {"lift"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Pre Avg
                      </TableCell>
                      <TableCell>
                        Post Avg
                      </TableCell>
                      <TableCell>
                        % Change
                      </TableCell>
                      <TableCell>
                        Pre Avg
                      </TableCell>
                      <TableCell>
                        Post Avg
                      </TableCell>
                      <TableCell>
                        % Change
                      </TableCell>
                      <TableCell>
                        Lift%
                      </TableCell>
                      <TableCell>
                        Abs
                      </TableCell>
                      <TableCell>
                        Stat Sig (p-value)
                      </TableCell>

                    </TableRow>
                  </TableHead>
                ) : (
                  <TableHead
                    className={classes.tableRightBorder}
                  >
                    <TableRow>
                      <TableCell
                        rowSpan={2}
                      >
                        Metrics
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                      >
                        Collection
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                      >
                        Flight
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                      >
                        Fulfilment Channel
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                      >
                        Products
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                      >
                        Division
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                      >
                        Department
                      </TableCell>
                      <TableCell

                        rowSpan={2}
                      >
                        Class
                      </TableCell>


                      <TableCell

                        rowSpan={2}


                      >
                        {"Test"}
                      </TableCell>
                      <TableCell

                        rowSpan={2}


                      >
                        {"Control"}
                      </TableCell>
                      <TableCell

                        colSpan={3}


                      >
                        {"Lift"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                      >
                        Lift%
                      </TableCell>
                      <TableCell


                      >
                        Abs
                      </TableCell>
                      <TableCell


                      >
                        Stat Sig (p-value)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                )}
              </>
            );
          },
        }}
      ></CustomMaterialTable>
    </>
  );
}
