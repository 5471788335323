import React, { useState } from "react";
import { Typography, Box, Grid } from "@material-ui/core";
import DialogBox from "../Common/Modals/Dailog";
import ProgressBar from "../Common/Charts/ProgressBar";
import retriveMetricFromStr from "../Common/Utils/ClientUtil";
import { toMetricFormat } from "../Common/Utils/ClientUtil";
import _ from "lodash";
import BarChartNegative from "views/Common/Charts/BarChartNegative";
import BarChartNegativeResponseRate from "views/Common/Charts/BarChartNegativeResponseRate";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import CustomExpandView from "./CustomExpandView";
import MultipleCarousle from "views/Common/MultipleCarousle/MultipleCarousle.";
import CustomExpandViewResponseRate from "./CustomExpandViewResponseRate";
import useStyles from './MeasureCarousleStyle'

const MeasureCarousel = (props) => {
  const classes = useStyles();
  const { testControlChecked, data, view, kpiName, kpiLabel } = props;
  // console.log(view, "view");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const handleCarouselDialogClose = () => {
    setDialogOpen(false);
    // setDialogData("");
  };
  const handleDialogOpen = (val) => {
    setDialogOpen(true);
    setDialogData(val);
  };

  let order = [
    "Region",
    "Key Products",
    "Flight Details",
    "Purchase Item Brand",
    "Division",
    "Dept",
    "Collections",
    "Fulfillment Channel",
  ];
  let segmentData = [];
  let rejectFinalDataset = _.reject(data, ["segmentName", "Flight Details"]);
  let finalDataset = _.reject(rejectFinalDataset, ["segmentName", "Region"]);
  let sortedData = _.sortBy(finalDataset, function (obj) {
    return _.indexOf(order, obj.segmentName);
  });
  if (view === "purchaseBehavior") {
    segmentData = sortedData;
  } else {
    segmentData = data;
  }

  return (
    <>
      {segmentData?.length > 0 ? (
        <MultipleCarousle>
          {segmentData
            ? segmentData?.map((item, index) => {
              return (
                <Box component="div" className={classes.block} key={index}>
                  <Box component="div" className={classes.innerBlock}>
                    <Typography component="h4" variant="subtitle1">
                      {`${retriveMetricFromStr(item?.segmentName)}`}
                      <br />
                      <Typography component="h6">
                        {item?.topDataPoint}
                      </Typography>
                    </Typography>

                    <Typography
                      component="span"
                      variant="subtitle1"
                      className={classes.expandIcon}
                    >
                      <ZoomOutMapIcon
                        onClick={(e) => {
                          handleDialogOpen(item);
                        }}
                      />
                    </Typography>
                    {/* conditon for testcontrolcheck and purchasebehaviour card */}
                    {testControlChecked && view === "purchaseBehavior" && (
                      <div className={classes.testControl}>
                        <div className={classes.carousle_container_card}>
                          <div className={classes.liftText}>
                            {kpiName === "responder_rate" ? "Response Rate Abs Lift" : "Lift %"}
                          </div>
                          <div
                            style={{
                              color:
                                item?.stat_sig === null
                                  ? "#E01F29" :
                                  item?.stat_sig === undefined
                                    ? "#E01F29" :
                                    item?.stat_sig < 0.05
                                      ? "#24A148"
                                      : item?.stat_sig > 0.2
                                        ? "#E01F29"
                                        : "#ff8f00",

                            }}
                            className={classes.cardTextLift}>
                            {kpiName === "responder_rate" ? (item.absLift === null ? "NA" : toMetricFormat(item?.absLift, kpiName))
                              : (item?.lift === null ? "NA" : item?.lift)}
                          </div>
                        </div>
                        <div className={classes.carousle_container_card}>
                          <div className={classes.liftText}>
                            {kpiName === "responder_rate" ? "Campaign Purchasers Abs Lift" : "Absolute Lift"}
                          </div>
                          <div className={classes.cardText}>
                            {kpiName === "responder_rate" ? (item?.lift === null ? "NA" : item?.lift?.toFixed(0))
                              : (item.absLift === null ? "NA" : toMetricFormat(item?.absLift, kpiName))}
                          </div>
                        </div>
                        <div className={classes.carousle_container_card}>
                          <div className={classes.liftText}>Test</div>
                          <div className={classes.cardText}>
                            {" "}
                            {item?.testDuringValue === null
                              ? "NA"
                              : toMetricFormat(
                                item?.testDuringValue,
                                kpiName
                              )}
                          </div>
                        </div>
                        <div className={classes.carousle_container_card}>
                          <div className={classes.liftText}>Control</div>
                          <div className={classes.cardText}>
                            {" "}
                            {item?.controlPost === null
                              ? "NA"
                              : toMetricFormat(item?.controlPost, kpiName)}
                          </div>
                        </div>
                      </div>
                    )}

                    {!testControlChecked && view === "purchaseBehavior" && (
                      <div className={classes.testControl}>
                        <div className={classes.carousle_container_card}>
                          <div className={classes.liftText}>
                            {kpiName === "responder_rate" ? "Response Rate Abs Lift" : "Lift %"}
                          </div>
                          <div
                            style={{
                              color:
                                item?.stat_sig === null
                                  ? "#E01F29" :
                                  item?.stat_sig === undefined
                                    ? "#E01F29" :
                                    item?.stat_sig < 0.05
                                      ? "#24A148"
                                      : item?.stat_sig > 0.2
                                        ? "#E01F29"
                                        : "#ff8f00",
                            }}
                            className={classes.cardText}
                          >
                            {kpiName === "responder_rate" ? (item.preAbsolutelift === null ? "NA" : toMetricFormat(item?.preAbsolutelift, kpiName))
                              : (item?.prelift === null ? "NA" : item?.prelift)}
                          </div>
                        </div>
                        <div className={classes.carousle_container_card}>
                          <div className={classes.liftText}>
                            {kpiName === "responder_rate" ? "Campaign Purchasers Abs Lift" : "Absolute Lift"}

                          </div>
                          <div className={classes.cardText}>
                            {kpiName === "responder_rate" ? (item?.prelift === null ? "NA" : item?.prelift?.toFixed(0))
                              : (item.preAbsolutelift === null ? "NA" : toMetricFormat(item?.preAbsolutelift, kpiName))}
                          </div>
                        </div>
                        <div className={classes.carousle_container_card}>
                          <div className={classes.liftText}>
                            Test(Pre/Post)
                          </div>
                          <div className={classes.cardText}>
                            {item?.benchmark === null
                              ? "NA"
                              : toMetricFormat(item?.benchmark, kpiName)}
                            /
                            {item?.testDuringValue === null
                              ? "NA"
                              : toMetricFormat(
                                item?.testDuringValue,
                                kpiName
                              )}
                          </div>
                        </div>
                        <div className={classes.carousle_container_card}>
                          <div className={classes.liftText}>
                            Control(Pre/Post)
                          </div>
                          <div className={classes.cardText}>
                            {item?.controlPre === null
                              ? "NA"
                              : toMetricFormat(item?.controlPre, kpiName)}
                            /
                            {item?.controlPost === null
                              ? "NA"
                              : toMetricFormat(item?.controlPost, kpiName)}
                          </div>
                        </div>
                      </div>
                    )}

                    {/* condition for campaignEngagement  */}
                    {testControlChecked &&
                      view !== "purchaseBehavior" &&
                      view === "campaignEngagement" && (
                        <div className={classes.testControl}>
                          <div className={classes.carousle_container_card}>
                            <div className={classes.liftText}>Benchmark</div>
                            <div className={classes.cardText}>
                              {item?.benchmark === null ? "NA" : item?.benchmark?.toFixed(2)}
                            </div>
                          </div>
                          <div className={classes.carousle_container_card}>
                            <div className={classes.liftText}>Post</div>
                            <div className={classes.cardText}>
                              {item?.testDuringValue === null ? "NA" : item?.testDuringValue}
                            </div>
                          </div>
                        </div>
                      )}
                    {/* condition for digital acivity */}

                    {testControlChecked &&
                      view !== "purchaseBehavior" &&
                      view === "digitalActivity" && (
                        <div className={classes.testControl}>
                          <div className={classes.carousle_container_card}>
                            <div className={classes.liftText}>
                              Benchmark
                            </div>
                            <div className={classes.cardText}>
                              {item?.benchmark === null ? "NA" : item?.benchmark}
                            </div>
                          </div>
                          <div className={classes.carousle_container_card}>
                            <div className={classes.liftText}>Test Post</div>
                            <div className={classes.cardText}>
                              {item?.testDuringValue === null ? "NA" : item?.testDuringValue}
                            </div>
                          </div>
                          <div className={classes.carousle_container_card}>
                            <div className={classes.liftText}>Control Post</div>
                            <div className={classes.cardText}>
                              {item?.controlDuringValue === null ? "NA" : item?.controlDuringValue}
                            </div>
                          </div>
                        </div>
                      )}

                    {/* condtion for post control data */}
                    {!testControlChecked && view !== "purchaseBehavior" && view === "campaignEngagement" && (
                      <div className={classes.testControl}>
                        <div className={classes.carousle_container_card}>
                          <div className={classes.liftText}>Benchmark</div>
                          <div className={classes.cardText}>
                            {item?.benchmark === null ? "NA" : item?.benchmark?.toFixed(2)}
                          </div>
                        </div>
                        <div className={classes.carousle_container_card}>
                          <div className={classes.liftText}>Post</div>
                          <div className={classes.cardText}>
                            {item?.testDuringValue === null ? "NA" : item?.testDuringValue}
                          </div>
                        </div>
                      </div>
                    )}
                    {/* toggle off and view === digital */}
                    {!testControlChecked &&
                      view !== "purchaseBehavior" &&
                      view === "digitalActivity" && (
                        <div className={classes.testControl}>
                          <div className={classes.carousle_container_card}>
                            <div className={classes.liftText}>
                              Benchmark
                            </div>
                            <div className={classes.cardText}>
                              {item?.benchmark === null ? "NA" : item?.benchmark}
                            </div>
                          </div>
                          <div className={classes.carousle_container_card}>
                            <div className={classes.liftText}>Test Post</div>
                            <div className={classes.cardText}>
                              {item?.testDuringValue === null ? "NA" : item?.testDuringValue}
                            </div>
                          </div>
                          <div className={classes.carousle_container_card}>
                            <div className={classes.liftText}>Control Post</div>
                            <div className={classes.cardText}>
                              {item?.controlDuringValue === null ? "NA" : item?.controlDuringValue}
                            </div>
                          </div>
                        </div>
                      )}
                  </Box>
                </Box>
              );
            })
            : ""}
        </MultipleCarousle>
      ) : (
        <Typography component="h3" variant="h3" className={classes.info}>
          {"No records to display"}
        </Typography>
      )}
      <DialogBox
        open={dialogOpen}
        width={view === "purchaseBehavior" ? 1920 : "medium"}
        handleClose={handleCarouselDialogClose}
        header={
          dialogData.segmentName
            ? `${retriveMetricFromStr(dialogData.segmentName)} - ${kpiLabel}`
            : "NA"
        }
        children={
          <>
            {view === "purchaseBehavior" ? (
              <Grid container spacing={3}>
                {(kpiName === "responder_rate" &&
                  dialogData.segmentName === "Division") ||
                  (kpiName === "responder_rate" &&
                    dialogData.segmentName === "Dept") ? (
                  <CustomExpandViewResponseRate
                    dialogData={dialogData}
                    kpiName={kpiName}
                    testControlChecked={testControlChecked}
                  />
                ) : (kpiName === "responder_rate" &&
                  dialogData.segmentName !== "Division") ||
                  (kpiName === "responder_rate" &&
                    dialogData.segmentName !== "Dept") ? (
                  <>
                    <Grid item xs={6}>
                      <BarChartNegativeResponseRate
                        data={dialogData.segmentDatapts}
                        kpiName={kpiName}
                        xAxisTitle={"Response Rate Absolute Lift"}
                        yAxisTitle={dialogData.segmentName}
                        categories={_.map(
                          dialogData.segmentDatapts,
                          "dataPointName"
                        )}
                        testControlChecked={testControlChecked}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <BarChartNegativeResponseRate
                        data={dialogData.segmentDatapts}
                        kpiName={kpiName}
                        xAxisTitle={"Campaign Purchasers Absolute Lift"}
                        yAxisTitle={dialogData.segmentName}
                        categories={_.map(
                          dialogData.segmentDatapts,
                          "dataPointName"
                        )}
                        testControlChecked={testControlChecked}
                      />
                    </Grid>
                  </>
                ) : dialogData.segmentName === "Division" ||
                  dialogData.segmentName === "Dept" ? (
                  <CustomExpandView dialogData={dialogData} kpiName={kpiName} testControlChecked={testControlChecked} />
                ) : (
                  <>
                    <Grid item xs={6}>
                      <BarChartNegative
                        data={dialogData.segmentDatapts}
                        kpiName={kpiName}
                        xAxisTitle={"Lift (%)"}
                        yAxisTitle={dialogData.segmentName}
                        categories={_.map(
                          dialogData.segmentDatapts,
                          "dataPointName"
                        )}
                        testControlChecked={testControlChecked}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <BarChartNegative
                        data={dialogData.segmentDatapts}
                        kpiName={kpiName}
                        xAxisTitle={"Absolute Lift"}
                        yAxisTitle={dialogData.segmentName}
                        categories={_.map(
                          dialogData.segmentDatapts,
                          "dataPointName"
                        )}
                        testControlChecked={testControlChecked}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            ) : (
              <ProgressBar data={dialogData} viewStatus={view} />
            )}
          </>
        }
      ></DialogBox>
    </>
  );
};

export default MeasureCarousel;
