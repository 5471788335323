import { makeStyles } from '@material-ui/core'
import palette from 'theme/palette';

export default makeStyles((theme) => ({
    tableRightBorder: {
        position: "sticky",
        top: -1,
        backgroundColor: palette.tableColors.background,
        zIndex: 1,
        "& th": {
            border: "1px solid",
            borderColor: palette.tableColors.border,
            fontSize: 14,
            padding: "6px 16px",
            textAlign: "left",
            color: palette.tableColors.text,
            fontWeight: 400
        }
    },
    createBtn: {
        background: palette.primary.main,
        color: "white",
        borderRadius: 0,
        textTransform: "capitalize",
        top: "0",
        marginTop: "35px",
    },
    cursorDisabled: {
        cursor: "not-allowed",
    },
    endDateares: {
        display: "flex !important",
        width: "auto !important",
        height: "40px !important",
        fontSize: "14px !important",
        background: "#fff !important",
        color: "#c4c4c4 !important",
        borderRadius: "4px !important",
    },
    heading: {
        fontSize: 14,
        color: "#929497",
        marginBottom: 4,
    },
    value: {
        fontSize: 14,
        color: "#333333",
        fontWeight: 500,
    },
    dag_text: {
        padding: "0 10px",
        borderRadius: "4px",
        textAlign: "center",
        color: "white",
        textTransform: "capitalize",
    },
}));