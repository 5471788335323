import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dateRange: "",
  brands: "",
  reset:false
};

export const dashBoardDetail = createSlice({
  name: "dashBoardDetail",
  initialState,
  reducers: {
    fetchDate: (state, action) => {
      let data = action.payload;
      state.dateRange = data;
    },
    getBrands: (state, action) => {
      let data = action.payload == 'ALL'? "": action.payload;
      
      state.brands = data;
    },
    resetDashboard:(state, action) => {
      // let data =action.payload? initialState:state;
      const data={
        brands:action.payload,
        dateRange:"",
      }
      state.brands =action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { fetchDate, getBrands,resetDashboard} = dashBoardDetail.actions;

export default dashBoardDetail.reducer;
