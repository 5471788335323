import React from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { Card, CircularProgress, Grid } from "@material-ui/core";
import { Form, Formik, ErrorMessage } from "formik";
import useStyles from "./Style";
import Customeracquisition from "./Customeracquisition";
import CustomerBase from "./CustomerBase";
import { useState } from "react";
import { useEffect } from "react";
import secureAxios from "config/api-config";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Typography,
  ListItemText
} from "@material-ui/core";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const Insight = () => {
  const classes = useStyles();
  //define state
  const [value, setValue] = useState(0);
  const [date, setDate] = useState([]);
  const [data, setData] = useState([]);
  const [trendData, setTrendData] = useState([])
  const [graphData, setGraphData] = useState([]);
  const [load6, setLoad6] = useState(false)
  const [load4, setLoad4] = useState(false)
  const [load5, setLoad5] = useState(false)
  const [load3, setLoad3] = useState(false)
  const [load2, setLoad2] = useState(false)
  const [load1, setLoad1] = useState(false)
  const [selectedBrandLevel, setSelectedBrandLevel] = useState("TOTAL COMPANY");
  const [selectedDate, setSelectedDate] = useState({});
  const [brandValue, setBrandValue] = useState([]);
  const [comments, setComments] = useState([])
  const [percentage, setPercentage] = useState("")


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //api call for table data
  function getTableData() {
    setLoad1(true)
    secureAxios
      .post("kpiInsights/acquisition", {
        year: selectedDate.fiscalyear_id,
        month: selectedDate.fiscalyearperiod_id,
        brand: selectedBrandLevel,
      })
      .then((res) => {
        setLoad1(false)
        setData(res.data.queryResult);
      });
  }

  //api call for brand 
  function healthBrand() {
    setLoad4(true)
    secureAxios
      .get("kpiInsights/healthBrand")
      .then((res) => {
        setLoad4(false)
        setBrandValue(res?.data?.brandData);
      })
      .catch((err) => {
        setLoad4(false)
        console.log(err);
      });
  }

  //api call for date
  function getDate() {
    setLoad5(true)
    secureAxios
      .get("kpiInsights/getDate")
      .then((res) => {
        setLoad5(false)
        setDate(res?.data?.data);
        setSelectedDate(res?.data?.data[0])
      })
      .catch((err) => {
        setLoad5(false)
        console.log(err);
      });
  }

  //api call for graph
  function getGraphData() {
    setLoad2(true)
    secureAxios
      .post("kpiInsights/getAllCustomerBase", {
        // year: selectedDate.fiscalyear_id,
        // month: selectedDate.fiscalyearperiod_id + 1, //pass +1 month
        brand: selectedBrandLevel,
      })
      .then((res) => {

        setGraphData(res?.data)
        setPercentage(res.data?.percentageChange)
        setLoad2(false)
      });
  }

  //api call for tredn 
  //
  function getTrendData() {
    setLoad3(true)
    secureAxios
      .post("kpiInsights/baseTrend", {
        // year: selectedDate.fiscalyear_id,
        // month: selectedDate.fiscalyearperiod_id, //pass +1 month
        brand: selectedBrandLevel,
      })
      .then((res) => {
        setLoad3(false)
        setTrendData(res?.data?.finalResult)
      }).catch((err) => {
        setLoad3(false)
      });
  }

  //api call for commenst
  function getComments() {
    setLoad6(true)
    secureAxios
      .post("kpiInsights/comments", {
        // year: selectedDate.fiscalyear_id,
        // month: selectedDate.fiscalyearperiod_id, //pass +1 month
        brand: selectedBrandLevel,
      })
      .then((res) => {
        setLoad6(false)
        setComments(res?.data?.queryResult)
      }).catch((err) => {
        setLoad6(false)
      });
  }
  //call useEffect
  useEffect(() => {
    let isSub = true
    if (isSub) {
      healthBrand();
      getDate();
    }
    return () => {
      isSub = false
    }

  }, []);

  useEffect(() => {
    let isSub = true
    if (isSub) {
      if (Object?.keys(selectedDate).length > 0) {
        getTableData();
        getTrendData()
        getGraphData();
        getComments()
      }

    }
    return () => {
      isSub = false
    }
  }, [selectedBrandLevel, selectedDate]);



  return (
    <>
      <Box component="div" className={classes.cardContainer}>
        <Card className={classes.cardArea}>
          <Typography
            variant="subtitle1"
            align="left"
            className={classes.kpiText}
          >
            {"Customer Health Dashboard"}
          </Typography>
          {load4 || load5 ? <div className={classes.progress}>
            <CircularProgress />
          </div> :
            <>        <Box component="div" className={classes.filterContainer}>
              <Formik
                initialValues={{
                  BRAND: selectedBrandLevel,
                  DATE: selectedDate,
                }}
                enableReinitialize={true}
                onSubmit={(values) => { }}
              >
                {({ values, setFieldValue }) => (
                  <Form
                    noValidate
                    autoComplete="off"
                    className={classes.filterAreaDropDown}
                  >
                    <Grid container spacing={3}>
                      {/* Brand dropdown*/}
                      <Grid item xs={6} md={3}>
                        <FormControl variant="outlined" className={classes.formControl}>
                          <InputLabel>
                            {"Customer Base"}
                          </InputLabel>
                          <Select
                            name={"BRAND"}
                            value={values.BRAND}
                            label={"Customer Base"}
                            onChange={(e) => {
                              setFieldValue("BRAND", e.target.value);
                              setSelectedBrandLevel(e.target.value);
                            }}
                          >
                            {brandValue?.length >0 ? brandValue?.map((item, index) => (
                              <MenuItem key={index} value={item?.BRAND}>
                                {item?.LABEL}
                              </MenuItem>
                            ))
                            :null}
                          </Select>
                        </FormControl>

                      </Grid>
                      {/* date filter */}
                      <Grid item xs={6} md={3} className={classes.disableSelect}>
                        <FormControl variant="outlined" className={classes.formControl} disabled>
                          <InputLabel>
                            {"Month"}
                          </InputLabel>
                          <Select
                            name={"DATE"}
                            value={values.DATE}
                            label={"Month"}
                            onChange={(e) => {
                              setFieldValue("DATE", e.target.value);
                              setSelectedDate(e.target.value);
                            }}
                          >
                            {date?.length > 0
                              ? date?.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                  {item?.fiscalperiod_desc}
                                </MenuItem>
                              ))
                              : <ListItemText classes={{ primary: classes.selectAllText }}
                                primary={"No Data Found"}
                              />
                            }
                          </Select>
                          <Typography className={classes.errorText}>
                            <ErrorMessage name={values.DATE} />
                          </Typography>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Box>
              <Box component="div" className={classes.root}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab
                    className={classes.tabLevel}
                    label="Customer Base"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className={classes.tabLevel}
                    label="Customer Acquisition"
                    {...a11yProps(1)}
                  />
                </Tabs>
                {Object?.keys(date)?.length > 0 && brandValue?.length > 0 && (
                  <>
                    <TabPanel value={value} index={0}>
                      {
                        load2 ? <div className={classes.progress}>
                          <CircularProgress />
                        </div> :

                          <CustomerBase
                            selectedDate={selectedDate}
                            graphData={graphData}
                            trendData={trendData}
                            load3={load3}
                            load2={load2}
                            percentage={percentage}
                            selectedBrandLevel={selectedBrandLevel}
                          />

                      }
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Customeracquisition
                        data={data}
                        load1={load1}
                        load6={load6}
                        comments={comments}
                        selectedDate={selectedDate}
                        selectedBrandLevel={selectedBrandLevel}
                      />
                    </TabPanel>
                  </>
                )}
              </Box>
            </>

          }
        </Card>
      </Box>
    </>
  );
};

export default Insight;
