import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  info: {
    margin: '2px',
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: '500px',
  },
  arrow: {
    '&:before': {
      border: '1px solid #E6E8ED',
    },
    color: theme.palette.common.white,
  },
}))(Tooltip);
export default function InfoComp(props) {
  const classes = useStyles();

  return (
    <>
      <LightTooltip
        title={props.data}
        placement="right-start"
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        arrow
        className={classes.info}
      >
        {props.content}
      </LightTooltip>
    </>
  );
}
