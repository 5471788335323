import React from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from './Style'


const DashboardCount = (props) => {
  const classes = useStyles();
  const { countData = [] } = props;
  return (
    <>
      <Box component="div" className={classes.totalCount}>
        <Typography variant="h4" alignCenter>
          {countData[0]?.total_campaigns}
        </Typography>
        <Typography variant="h5" alignCenter>
          {"Total Campaign"}
        </Typography>
      </Box>
     
    </>
  );
};

export default DashboardCount;
