import React, { forwardRef, useState } from "react";
import { NavLink as RouterLink, useHistory } from "react-router-dom";

import {
  AppBar,
  Hidden,
  IconButton,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { loadCSS } from "fg-loadcss";
import logo from "../../assets/TLogo.png";
import { useDispatch, useSelector } from "react-redux";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import {
  clearCollectionList,
  clearProductGroupList,
  clearCampDetails,
} from "../../redux/campDetail/campaignDetailRedux";
import { useEffect } from "react";
import config from "../../config/azure-configs/azureConfig";
import secureAxios from "config/api-config";
import { UserAgentApplication } from "msal";
import useStyles from "./Style";

const Topbar = (props) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [selectedMenu, setSelectedMenu] = useState("");
  const [data, setData] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const userAgentApplication = new UserAgentApplication({
    auth: {
      clientId: config.appId,
      redirectUri: config.redirectUri,
      postLogoutRedirectUri: config.postLogoutRedirectUri,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  });

  const userName = localStorage.getItem("userName");

  const logout = () => {
    userAgentApplication.logout();
    localStorage.clear();
    dispatch(clearCampDetails());
    dispatch(clearCollectionList([]));
    dispatch(clearProductGroupList([]));
  };

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("apiToken"));
    setName(userDetails?.fullName);
  }, []);

  //api call for sales data
  useEffect(() => {
    secureAxios
      .get("/designCampaign/dashboard/salesDetails")
      .then((res) => {
        setData(res?.data?.salesDtl[0]?.last_modified_date);
        // console.log("=>>",res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // const userName = name

  // const logout = () => {
  //   // userAgentApplication.logout();
  //   localStorage.clear();
  //   history.push("/");
  //   dispatch(clearCampDetails());
  //   dispatch(clearCollectionList([]));
  //   dispatch(clearProductGroupList([]));
  // };

  React.useEffect(() => {
    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
      <RouterLink {...props} />
    </div>
  ));

  const pages = [
    {
      title: "Test Design",
      href: "/design-campaign",
    },
    {
      title: "Test Results",
      href: "/measure-campaign",
    },
    {
      title: "Aggregate Results",
      href: "/group-view",
    },
  ];
  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className={classes.topBar}>
        <Toolbar>
          <List component="ul" className={classes.navItems}>
            <ListItem disableGutters style={{ width: "40%" }} alt="Logo">
              <img
                src={logo}
                alt={"TALP CUSTOMER"}
                style={{ height: "60px", margin: "0 0 0 0" }}
                onClick={(e) => {
                  history.push("/design-campaign");
                }}
              />
              <ListItemText primary={<Typography>TALP Customer</Typography>} />
            </ListItem>
            {pages.map((page) => (
              <ListItem
                disableGutters
                key={page.title}
                onClick={(e) => {
                  // restForm(page);
                  setSelectedMenu(page.title);
                }}
              >
                <Button
                  activeClassName={
                    history.location.pathname.split("/")[1] !== page.href
                      ? classes.active
                      : ""
                  }
                  className={classes.item}
                  component={CustomRouterLink}
                  to={page.href}
                >
                  {page.title}
                </Button>
              </ListItem>
            ))}
          </List>

          <Hidden mdDown>
            &nbsp; &nbsp; &nbsp; &nbsp;
            {/* <Typography variant={"subtitle2"} className={classes.text}>
              
              Last modified sales data <span className={classes.textData}>({data})</span>
            </Typography> */}
            <Typography variant={"subtitle2"} className={classes.text}>
              {/* <IconButton
                className={"fas fa-user-circle userIcon"}
                size={"small"}
              />
              &nbsp; */}
              {userName}
            </Typography>
            &nbsp; &nbsp; &nbsp;
            <PowerSettingsNewIcon
              style={{ color: "#929497", cursor: "pointer" }}
              size={"small"}
              onClick={logout}
            />
          </Hidden>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Topbar;
