import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import secureAxios from "config/api-config";
import { APIS } from "config";
import CampaignViewDetails from "./CampaignViewDetails";
import useStyles from "./style";
import SingleSelectDropdown from "../../Common/Filters/SingleSelectDropdown";
import DeepdiveTabs from "views/MeasureDeepdive/DeepdiveTabs";
import DrillDown from "views/MeasureDeepdive/DrillDown";
import CampaignHighlights from "views/MeasureDeepdive/CampaignHighlights";
import ToggleButtonCom from "../ToggleButton/ToggleButtonCom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ReactBreadcrumbs from "views/Common/Breadcrumb/Breadcrumb";
// import FullLoader from "views/Common/FullLoader/FullLoader";


const CampaignViewHome = (props) => {
  const history = useHistory()
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch()

  const appReduxState = useSelector((state) => ({
    ...state.measureLandingDetail,
  }));
  /**
   * deepdiveTabNavigation ===0 campaignHighlights page
   */

  const { deepdiveTabNavigation } = appReduxState;
  const [testControlChecked, setTestControlChecked] = useState(false);
  const [data, setData] = useState([]);
  const [flightData, setFlightData] = useState([]);
  const [customersData, setCustomersData] = useState([]);
  const [purchaseBehavior, setPurchaseBehavior] = useState([]);
  const [campaignEngagement, setCampaignEngagement] = useState([]);
  const [digitalActivity, setDigitalActivity] = useState([]);
  const [customerInsights, setCustomerInsights] = useState([]);
  const [selectedBrandLevel, setSelectedBrandLevel] = useState("TOT");
  const [collectionLevel, setCollectionLevel] = useState([]);
  const [selectedCollectionLevel, setSelectedCollectionLevel] = useState("ALL");
  const [outlierChecked, setOutlierChecked] = useState(true);
  const [brandValue, setBrandValue] = useState([]);
  const [campType, setCampType] = useState(null)
  const [present, setPresent] = useState(false)
  // console.log({present});
  const [load, setLoad] = useState(false);
  const [load2, setLoad2] = useState(false);
 
  // const [hideToggle] = useState(["Adhoc-Fixed", "Simple Multitouch ongoing", "Simple Multitouch one time send", "Complex multitouch ongoing send", "Complex multitouch one time send", "One touch ongoing","Others"]);
  //for tabs
  const tabItems = [
    {
      id: 1,
      title: "Campaign Highlights",
    },
    {
      id: 2,
      title: "Campaign Drill Down",
    },
  ];
  //toogle check
  const toggleOutlierCheckedPost = () => {
    setTestControlChecked((prev) => !prev);
  };

//api call for fetch type of campaign
  useEffect(() => {
    secureAxios.post("measureCampaign/deepdive/typeOfCampaign", {
      params: {
        campaign_id: id,
      },
    }).then((res) => {
      setCampType(res?.data?.queryResult?.type_of_campaign)
      setPresent(true)
    })
  }, [])
  //api call based on campaignid
  const detaislById = async () => {
    setLoad2(true);
    try {
      const response = await secureAxios.post(
        APIS.MEASURE_CAMPAIGN.CAMPAIGN_LIST_ID,
        {
          params: {
            campaign_id: id,
            outlierValue: outlierChecked,
          },
        }
      );
      setLoad2(false);
      setData(response?.data?.responseData?.[0]);
      setCustomersData(response.data?.testControlData?.[0]);
      setFlightData(response?.data?.flightData);
    } catch (err) {
      setLoad2(false);
    }
  };
  //get data for brand
  const brandValueData = async () => {
    setLoad(true);
    try {
      const res = await secureAxios.post("measureCampaign/deepdive/brand", {
        campaign_id: id,
        outlierValue: outlierChecked,
      });
      setBrandValue(res?.data?.brandData);
      setLoad(true);
    } catch (err) {
      console.log(err);
    }
  };

  //Get all the collection name

  const CollectionNameData = async () => {
    try {
      const res = await secureAxios.post(
        "measureCampaign/deepdive/collection_name",
        {
          campaign_id: id,
          outlierValue: outlierChecked,
        }
      );
      setCollectionLevel(res?.data?.collectionNameData);
    } catch (err) { }
  };



  //api call for accrodain block
  const fetchNames = async () => {
   
    dispatch({
      type: "LOADING",
      payload: true,
    });
    await Promise.all([
      secureAxios.get(APIS.MEASURE_CAMPAIGN.DEEPDIVE.PURCHASE_BEHAVIOUR, {
        params: {
          outlierValue: outlierChecked,
          testValue: testControlChecked,
          campaign_id: id,
          brandName: selectedBrandLevel === "TOT" ? "ALL" : selectedBrandLevel,
          collection_name: selectedCollectionLevel,
        },
      }),
      secureAxios.get(APIS.MEASURE_CAMPAIGN.DEEPDIVE.DIGITAL_ACTIVITY, {
        params: {
          outlierValue: outlierChecked,
          campaign_id: id,
          collection_name: selectedCollectionLevel,
        },
      }),
      secureAxios.get(APIS.MEASURE_CAMPAIGN.DEEPDIVE.CAMPAIGN_ENGAGEMENT, {
        params: {
          outlierValue: outlierChecked,
          campaign_id: id,
          collection_name: selectedCollectionLevel,

        },
      }),
      secureAxios.get(APIS.MEASURE_CAMPAIGN.DEEPDIVE.CUSTOMER_INSIGHT, {
        params: {
          outlierValue: outlierChecked,
          campaign_id: id,
          collection_name: selectedCollectionLevel,
        },
      }),

    ])
      .then((response) => {
        // console.log(response, "engamnet");
        setPurchaseBehavior(response[0]?.data?.responseData);
        setDigitalActivity(response[1]?.data?.responseData);
        setCampaignEngagement(response[2]?.data?.responseData);
        setCustomerInsights(response[3]?.data);

      })
      .catch((err) => {
        console.log(err);
      })
      .finally((err) => {
       
        dispatch({
          type: "LOADING",
          payload: false,
        });
      });
  };

  useEffect(() => {
    if (deepdiveTabNavigation === 0) {
      brandValueData();
      CollectionNameData();
    }
  }, [outlierChecked, deepdiveTabNavigation]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (present === true) {
        if (deepdiveTabNavigation === 0) {
          fetchNames();
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, [
    outlierChecked,
    selectedBrandLevel,
    selectedCollectionLevel,
    deepdiveTabNavigation,
    testControlChecked,
    present
  ]);

  useEffect(() => {
    let sub = true
    if (sub) {
      detaislById()
    }
    return () => {
      sub = false
    }
  }, [outlierChecked])



  //redirect to next page
  const redirect = () => {
    history.push("/measure-campaign")
  }

  return (
    <Box component="div" className={classes.root}>
      <ReactBreadcrumbs />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={classes.backButtonContainer}>
            <ArrowBackIcon color="primary" className={classes.backIcon} onClick={redirect} />
            {/* toggle for outlier detecation */}
            <ToggleButtonCom
              outlierChecked={outlierChecked}
              setOutlierChecked={setOutlierChecked}
            />
          </div>
          {/* CampaignViewDetails */}
          <CampaignViewDetails
            customersData={customersData}
            flightData={flightData}
            id={id}
            data={data}
            load2={load2}
          />
          {/*toogle test control and dropdown brand and collection */}
          <Box component="div">
            <Formik
              initialValues={{
                BRAND: selectedBrandLevel,
                COLLECTION: selectedCollectionLevel,
              }}
              enableReinitialize={true}
              onSubmit={(values) => { }}
            >
              {({ values, setFieldValue }) => (
                <Form noValidate autoComplete="off">
                  <Box component="div" className={classes.filterArea}>
                    <Grid container spacing={3}>
                      {/* here we check if  
                      data?.type_of_campaign === "Adhoc-Fixed"
                      then only prepost toggle visiable */}

                      {
                        campType === "Adhoc-Fixed" || campType === "One touch ongoing" || campType === "Others" ?
                        <Grid item xs={4} md={4} className={"pt0"}>
                          <Typography
                            component="h4"
                            variant="h4"
                            style={{
                              fontSize: 12,
                              color: "#929497",
                            }}
                          >
                            Test and Control
                          </Typography>
                          <Typography
                            component="h4"
                            variant="h4"
                            className={classes.prePostToggle}
                          >
                            <Typography
                              component="p"
                              style={{ display: "inline-block" }}
                            >
                              {"Pre/Post"}
                            </Typography>
                            <FormControlLabel
                              style={{ margin: "0 15px" }}
                              value="outlier"
                              control={
                                <Switch
                                  size="small"
                                  checked={testControlChecked}
                                  onChange={toggleOutlierCheckedPost}
                                  style={{ color: "#182C52" }}
                                />
                              }
                              label=""
                              labelPlacement="start"
                            />
                            <Typography
                              component="p"
                              style={{ display: "inline-block" }}
                            >
                              {"Analysis Period"}
                            </Typography>
                          </Typography>
                        </Grid>

                      :null}

                      {
                        <>
                          {deepdiveTabNavigation === 0 && (
                            <>

                              <Grid item xs={4} md={3}>
                                <SingleSelectDropdown
                                  width={"w100"}
                                  label={"Brand Level"}
                                  name={"BRAND"}
                                  value={values.BRAND}
                                  noneRequired={false}
                                  allRequired={false}
                                  handleMethod={(e) => {
                                    if (e.target.value) {
                                      setSelectedBrandLevel(e.target.value);
                                      setFieldValue("BRAND", e.target.value);
                                    } else {
                                      setSelectedBrandLevel("");
                                      setFieldValue("BRAND", "");
                                    }
                                  }}
                                  options={brandValue}
                                />
                              </Grid>
                              <Grid item xs={4} md={3}>
                                <SingleSelectDropdown
                                  width={"w100"}
                                  label={"Collection Level"}
                                  name={"COLLECTION"}
                                  value={values.COLLECTION}
                                  noneRequired={false}
                                  allRequired={false}
                                  handleMethod={(e) => {
                                    if (e.target.value) {
                                      setSelectedCollectionLevel(
                                        e.target.value
                                      );
                                      setFieldValue(
                                        "COLLECTION",
                                        e.target.value
                                      );
                                    } else {
                                      setSelectedCollectionLevel("");
                                      setFieldValue("COLLECTION", "");
                                    }
                                  }}
                                  options={collectionLevel}
                                />
                              </Grid>
                            </>
                          )}
                        </>
                      }
                    </Grid>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
          {/* tabs items */}
          <Box component="div">

            <DeepdiveTabs
              tabItems={tabItems}
              data={data}
              outlierChecked={outlierChecked}
              purchaseBehavior={purchaseBehavior}
              campaignEngagement={campaignEngagement}
              digitalActivity={digitalActivity}
              customerInsights={customerInsights}
              testControlChecked={testControlChecked}
              selectedBrandLevel={selectedBrandLevel}
              brandValue={brandValue}
              Tab1={CampaignHighlights}
              load={load}
              Tab2={DrillDown}
            />

          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CampaignViewHome;
