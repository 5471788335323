import { colors } from "@material-ui/core";

const white = "#FFFFFF";
const black = "#000000";

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: "#24272A",
    main: "#182C52",
    light: colors.indigo[100],
  },
  secondary: {
    contrastText: white,
    dark: colors.blueGrey[900],
    main: "#182C52",
    light: colors.blueGrey[300],
  },
  graph: {
    contrastText: white,
    primary: "#FBA504",
    secondary: "#8E44AD",
    teal: "rgba(0, 150, 136, 0.7)",
    lime: "rgba(205, 220, 57, 0.7)",
    red: "#ffd0d2",
    green: "#ceffd2"
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    secondary: "#929497",
    primary: "#929497",
    disable: "#6EA0F7",
    link: colors.blue[600],
    active: "#182C52",
    white: "#FFFFFF",
    dark: "#404041",
  },
  background: {
    default: "#FFFFFF",
    paper: white,
    grey: "#c0c0c0",
    blue: "#182C52"
  },
  chartColors: {
    trends: ["#FBA504", "#8E44AD", "#00D2D3"],
  },
  semanticBGColors: {
    primaryRed: "#E01F29",
    primaryYellow: "#F0BF0F",
    primaryGreen: "#24A148",
    primaryBlue: "#0159FE",
    primaryGrey: "#404041",
    primaryOrange: "#182C52",
    secondaryRed: "#FBE8E9",
    secondaryYellow: "#FDF8E6",
    secondaryGreen: "#EAF6ED",
    secondaryBlue: "#E5EEFF",
    secondaryGrey: "#F6F7F7",
    secondaryOrange: "#FFDCCC"
  },
  tableColors: {
    border: "#d4d4d4",
    //background: "#182C52",
    background: "#182C52",
    text: "#ffffff",
    bodyText: "#404041",
    selectedBackground: "rgba(0, 0, 0, 0.04)"
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
};
