import React, { useEffect, useState } from "react";
import ReactApexCharts from "react-apexcharts";
import { toMetricFormat } from "../Utils/ClientUtil";
import _ from "lodash";
import palette from "theme/palette";

const BarChartVerticalNegative = (props) => {
    const [chartInit, setChartInit] = useState();
    const { xAxisTitle, colors, data, yAxisTitle, tooltipData, categories, height, title, preData } = props;

    useEffect(() => {
        setChartInit({
            series: [{
                name: yAxisTitle,
                data: _.map(data, "data")

            }],
            options: {
                chart: {
                    type: 'bar'
                },
                tooltip: {
                    custom: function ({ seriesIndex, dataPointIndex, w }) {
                        const selectedData = w?.globals?.labels[dataPointIndex]
                        const finalDataSet = data?.find(item => item?.name === selectedData)
                        const testPostData = tooltipData?.filter(item => item?.name === selectedData && item?.test_control_flag === "test" && item?.campgn_prd_type === "Post")
                        const controlPostData = tooltipData?.filter(item => item?.name === selectedData && item?.test_control_flag === "control" && item?.campgn_prd_type === "Post")
                        const testPreData = tooltipData?.filter(item => item?.name === selectedData && item?.test_control_flag === "test" && item?.campgn_prd_type === "Pre")
                        const controlPreData = tooltipData?.filter(item => item?.name === selectedData && item?.test_control_flag === "control" && item?.campgn_prd_type === "Pre")

                        return preData ? '<ul style="padding: 10px; list-style: none">' +
                            '<li style="font-size: 12px">' + finalDataSet?.name + '</li>' +
                            '<li style="font-size: 12px">Test Pre : ' + testPreData[0]?.cust_pct + '%' + '</li>' +
                            '<li style="font-size: 12px">Control Pre: ' + controlPreData[0]?.cust_pct + '%' + '</li>' +
                            '<li style="font-size: 12px">Test Post: ' + testPostData[0]?.cust_pct + '%' + '</li>' +
                            '<li style="font-size: 12px">Control Post: ' + controlPostData[0]?.cust_pct + '%' + '</li>' +
                            // '<li style="font-size: 12px">% Change : ' + finalDataSet?.data + '%' + '</li>' +
                            '</ul>'
                            : '<ul style="padding: 10px; list-style: none">' +
                            '<li style="font-size: 12px">' + finalDataSet?.name + '</li>' +
                            '<li style="font-size: 12px">Test : ' + testPostData[0]?.cust_pct + '%' + '</li>' +
                            '<li style="font-size: 12px">Control : ' + controlPostData[0]?.cust_pct + '%' + '</li>' +
                            '</ul>';
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        barHeight: '100%',
                        barWidth: "100%",
                    }
                },
                colors: colors ? colors : [function (opt) {
                    const selectedData = opt?.w?.globals?.labels[opt?.dataPointIndex]
                    const finalDataSet = data?.find(item => item?.name === selectedData)
                    if (finalDataSet?.data > 0) {
                        return palette.graph.green
                    } else {
                        return palette.graph.red
                    }
                }],
                stroke: {
                    width: [0, 4],
                    curve: 'smooth'
                },
                title: {
                    text: title
                },
                grid: {
                    show: true,
                    position: 'back',
                    borderColor: '#eeeeee',
                    strokeDashArray: 0,
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    yaxis: {
                        lines: {
                            show: true
                        }
                    },
                },
                dataLabels: {
                    enabled: true,
                    // offsetX: 45,
                    formatter: function (val) {
                        return val?.toFixed(2) + "%"
                    },
                    style: {
                        colors: [palette.text.dark]
                    }
                },
                yaxis: {
                    title: {
                        text: yAxisTitle,
                    },
                    labels: {
                        formatter: function (val) {

                            return val?.toFixed(2)

                        },
                    }
                },
                xaxis: {
                    tickAmount: 4,
                    title: {
                        text: xAxisTitle,
                    },
                    categories: categories,

                }
            },
        });
    }, [categories]);

    return (
        <>
            {chartInit && (
                <>
                    <ReactApexCharts
                        options={chartInit?.options}
                        series={chartInit?.series}
                        type="bar"
                        height={data?.length <= 10 ? 300 : height}
                    />
                </>
            )}
        </>
    );
};
export default BarChartVerticalNegative;