export const order = [
  "responder_rate",
  "orders",
  "avg_order_value",
  "sales",
  "margin_amt",
];

export const kpiOrder = ["Response Rate", "Transactions", "ADS", "Net Sales", "Net Margin"]
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};
