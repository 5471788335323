import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Style.css";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

const MultipleCarousle = (props) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      // breakpoint: { max: 1400, min: 1024 },
      items: 2,
      // slidesToSlide: 4
      partialVisibilityGutter: 40,
    },
    largeDesktop: {
      breakpoint: { max: 3000, min: 1400 },
      items: 3,
      // slidesToSlide: 4
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 80,
    },
  };

  const arrowStyle = {
    background: "transparent",
    border: 0,
    color: "#fff",
    fontSize: "80px",
    ouline: "none",
  };
  const CustomRight = ({ onClick }) => (
    <button className="arrow right" onClick={onClick} style={arrowStyle}>
      <ChevronRightIcon />
    </button>
  );
  const CustomLeft = ({ onClick }) => (
    <button className="arrow left" onClick={onClick} style={arrowStyle}>
      <ChevronLeftIcon />
    </button>
  );

  return (
    <div>
      <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={true}
        autoPlaySpeed={3000}
        customRightArrow={<CustomRight />}
        customLeftArrow={<CustomLeft />}
        itemClass="carousel-item-padding-40-px"
        containerClass="carousel-container"
        // itemClass="carousel-item"
        // centerMode={true}

        partialVisible={true}
      >
        {props.children}
      </Carousel>
    </div>
  );
};

export default MultipleCarousle;