import { Grid, Box, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import useStyles from "./Style";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
//add redux
import { useDispatch, useSelector } from "react-redux";
import {
  addSelectedProduct,
} from "../../../redux/campDetail/campaignDetailRedux";


const ProductGroup = ({ sdata, handleAudModal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectPro = useSelector((state) => [
    ...state.campDetail.selectProductList,
  ]);
  // console.log({ selectPro });
  const [checkData, setcheckData] = useState([]);
  // const [sdata, setSdata] = useState([]);
  const [show, setShow] = useState(false);
  const [check, setCheck] = useState(
    selectPro ? selectPro.map((x) => x.product_grp_id) : null
  );
  // console.log({ check });
  // console.log(checkData,"checkData");

  //Function for show toggle
  const toggle = (i) => {
    if (show === i) {
      return setShow(null);
    }
    setShow(i);
  };

  //function for checkboxHandle

  const checkHandle = (e, grpData) => {
    e.preventDefault();
    console.log(e);
    if (e.target.checked) {
      setcheckData([
        ...checkData,
        {
          product_grp_id: grpData.product_grp_id,
          product_grp_name: grpData.product_grp_name,
        },
      ]);
      setCheck([...check, grpData.product_grp_id]);
    } else {
      setCheck(check.filter((x) => x !== grpData.product_grp_id));
      setcheckData(
        checkData.filter(
          (people) => people.product_grp_id !== grpData.product_grp_id
        )
      );
    }
  };

  //save data in redux
  const productSubmit = async () => {
    dispatch(addSelectedProduct(checkData));
    handleAudModal()
    
  };

  useEffect(() => {
    setcheckData(selectPro);
  }, []);

  //delete function chip

  const handleDelete = (grpData, id) => {
    console.log(grpData, "lolo");
    // dispatch(deleteSelectedProduct(id));
    
    setCheck(check.filter((x) => x !== id));
    setcheckData(
      checkData.filter((x) => x.product_grp_id !== grpData.product_grp_id)
    );
  };
  
  
  return (
    <>
      
      <Grid container spacing={3} className={classes.productGroupContainer}>
        
        <Grid item md={6} xs={12}>
          {/* <div>
            <button onClick={openPopup} type="button">click</button>
          </div> */}
          {checkData.map((chipdata, index) => (
            <Chip
              label={
                chipdata.prd_grp_catg
                  ? chipdata.prd_grp_catg
                  : chipdata.product_grp_name
              }
              key={index}
              onDelete={() => handleDelete(chipdata, chipdata.product_grp_id)}
              style={{ margin: "2px 1px", padding: "2px 2px" }}
            />
          ))}
        </Grid>
        <Grid item md={6} xs={12} className={"borderLeft"} key={check.join()}>
          <Box component="div">
            {sdata
              .filter((x) => x.type === "group")
              .map((x, i) => {
                return (
                  <div key={i}>
                    <div className={classes.innerContainer}>
                      {show === i ? (
                        <ArrowForwardIosIcon
                          style={{
                            transform: "rotate(90deg)",
                            fontSize: "15px",
                          }}
                          onClick={() => toggle(i)}
                        />
                      ) : (
                        <ArrowForwardIosIcon
                          onClick={() => toggle(i)}
                          style={{ fontSize: "15px" }}
                        />
                      )}
                      <Typography component="span">{x.name}</Typography>
                    </div>
                    {show === i ? (
                      <div>
                        {x.children.map((grpData, index) => {
                          // console.log(check.includes(grpData.product_grp_id),"data");
                          return (
                            <div key={index} style={{ paddingLeft: "30px" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    className={classes.checkbox}
                                    onChange={(e) => checkHandle(e, grpData)}
                                    // value={checkData}
                                    checked={check.includes(
                                      grpData.product_grp_id
                                    )}
                                  />
                                }
                                id={grpData.id}
                                label={
                                  <Typography>
                                    {grpData.product_grp_name}
                                  </Typography>
                                }
                              />
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            {sdata
              .filter((x) => x.type === "product")
              .map((x, index) => {
                return (
                  <div key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={classes.checkbox}
                          onChange={(e) => checkHandle(e, x)}
                          value={checkData}
                          checked={check.includes(x.product_grp_id)}
                        />
                      }
                      id={x.product_grp_id}
                      label={x.product_grp_name}
                    />
                  </div>
                );
              })}
          </Box>
        </Grid>
      </Grid>
      <div className={classes.buttonContainer}>
        <Button
          onClick={productSubmit}
          variant="contained"
          className={classes.createBtn}
          style={{position:'absolute', bottom: '20px', right: '35px' }}
          // disabled={checkData.length === 0}
        >
          Save and Next
        </Button>
      </div>
    </>
  );
};

export default ProductGroup;


