import { makeStyles } from '@material-ui/core'
import palette from 'theme/palette';

export default makeStyles((theme) => ({
  checkbox: {
    "&.MuiCheckbox-root": {
      color: palette.primary.main,
    },
    "&.MuiCheckbox-colorSecondary": {
      "&.Mui-checked": {
        color: palette.primary.main,
      },
    },
  },
  primaryBtn: {
    marginTop: 8,
    borderRadius: 0,
    textTransform: "capitalize",
  },
  //index js style
  tableRightBorder: {
    position: "sticky",
    top: -1,
    backgroundColor: palette.tableColors.background,
    "& th": {
      border: "1px solid",
      borderColor: palette.tableColors.border,
      fontSize: 14,
      padding: "6px 16px",
      textAlign: "left",
      color: palette.tableColors.text,
      fontWeight: 400,
    },
  },
  action_group: {
    "& div": {
      padding: "0 8px",
      borderRadius: 4,
      display: "inline-block",
      marginLeft: 4,
      cursor: "pointer",
    },
  },
  action_view: {
    border: "1px solid",
    borderColor: palette.semanticBGColors.primaryBlue,
    backgroundColor: palette.semanticBGColors.secondaryBlue,
    color: palette.semanticBGColors.primaryBlue,
  },
  action_edit: {
    border: "1px solid",
    borderColor: palette.semanticBGColors.primaryGreen,
    backgroundColor: palette.semanticBGColors.secondaryGreen,
    color: palette.semanticBGColors.primaryGreen,
  },
  //create form group style
  sectionHeading: {
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: "-0.02em",
    color: "#404041",
    textAlign: 'left'
  },
  sectionHeadingLarge: {
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: "-0.02em",
    color: "#404041",
    // marginTop: '-10px',
    marginBottom: '8px',
    textAlign: 'left'
  },
 
  cancelBtn: {
    "&:hover": {
      backgroundColor: "#e7e7e7",
    },
    margin: "8px 16px 0",
    borderRadius: "0",
    textTransform: "capitalize",
  },
  audSelectBtn: {
    color: "#333333",
    border: "1px solid #333333",
    borderRadius: "6px",
    textTransform: "capitalize",
    padding: "0 10px",
    margin: "0px 0 9px 0px",
  },
  successMsg: {
    color: "#24A148 !important",
    fontWeight: 500,
    display: "inline-block",
  },
}));
