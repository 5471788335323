import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Box } from "@material-ui/core";
import LineChart from "../Common/Charts/LineChart";
import retriveMetricFromStr from "../Common/Utils/ClientUtil";


const useStyles = makeStyles((theme) => ({
  chartArea: {
    padding: "20px 0",

    "& p": {
      color: "#333333",
      fontSize: 14,
      textTransform: "capitalize",
    },
  },
}));

const MeasureLineTrend = (props) => {
  const classes = useStyles();
  const { selectedKPI, graphData, view } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12}>
        <Box className={classes.chartArea}>
          <Typography>{`${retriveMetricFromStr(
            selectedKPI.name
          )} Trend`}</Typography>
          <LineChart
            title={selectedKPI.name}
            xAxis={graphData.xAxis}
            series={
              view === "CampaignEngagement" ?
                [{ name: "Test", data: graphData.kpiData }]
                : [{ name: "Test", data: graphData.testData },
                { name: "Control", data: graphData.controlData }]}
            showAnnotations={false}
            chartColors={["#0BC1C1", "#F4B43E"]}
            type={selectedKPI.type}
            label={selectedKPI.label}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default MeasureLineTrend;
