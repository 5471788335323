import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    purchaseItemBrand: "TOT",
    brand: "TOT",
    startEndDate: []
};

export const groupView = createSlice({
    name: "groupView",
    initialState,
    reducers: {
        updatePIBrand: (state, action) => {
            let data = action.payload;
            state.purchaseItemBrand = data
        },
        updateBrand: (state, action) => {
            let data = action.payload;
            state.brand = data
        },
        updateDate: (state, action) => {
            let data = action.payload;
            state.startEndDate = data
        },
    },
});

export const { updatePIBrand, updateBrand, updateDate } = groupView.actions;

export default groupView.reducer;
