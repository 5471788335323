import React from "react";
import { Button, Card, Grid, Typography } from "@material-ui/core";
import NORESULT from '../../assets/no-result.svg'
import { useHistory } from "react-router-dom/cjs/react-router-dom";
const NoWrapper = (props) => {
    const { heading, subHeading, showBtn, btnPath, btnText } = props;
    const history = useHistory();
    return (
        <Card style={{ width: '100%', padding: '20px 0px 20px', }}>
            <Grid container style={{ textAlign: 'center' }}>
                <Grid item xs={12}>
                    <div style={{ textAlign: 'center' }}>
                        {(heading) && (<Typography variant="h3">{heading} </Typography>)}
                        
                        {(subHeading) && (<Typography variant="h6">{subHeading}</Typography>)}
                    </div>
                </Grid>
                <Grid item xs={12} style={{ margin: '20px 0'}}>
                    <img src={NORESULT} alt="" width={300} height={300} />
                </Grid>
                {(showBtn) && (<Grid item xs={12}>
                    <Button variant="contained" onClick={() => history.push(`${(btnPath) ?? '/design-campaign'}`)}>{`${(btnText) ?? 'Go Back'}`}</Button>
                </Grid>)}
            </Grid>
        </Card>
    )
}

export default NoWrapper;