import { makeStyles } from '@material-ui/core'


export default makeStyles((theme) => ({
    block: {
      boxSizing: "border-box",
      background: "#ffffff",
      "& h4": {
        color: "#ffffff !important",
        marginBottom: 10,
        height: "70px",
        padding: "5px 10px",
        background: "#182C52",
        fontSize: 14,
        "& h6": {
          color: "#ffffff",
          fontSize: 12,
          lineHeight: 1.2,
        },
      },
      "& h5": { color: "#333333", minHeight: 55 },
      marginTop: 35,
    },
    innerBlock: {
      margin: "10px !important",
      minHeight: "150px",
      overflow: "hidden",
      position: "relative",
      padding: 0,
      boxShadow: "2.73577px 3.6477px 11.855px rgb(0 0 0 / 8%)",
      borderRadius: 6,
    },
    value: { color: "#24A148" },
    highlight: { color: "#8E44AD" },
    chartValues: {
      marginTop: 10,
      fontSize: "11px",
    },
    valBM: { color: "##182C52", marginRight: 15 },
  
    info: {
      padding: "40px 0 !important",
      textAlign: "center",
      "& h3": {
        lineHeight: 10,
      },
    },
    hints: {
      position: "absolute",
      top: 7,
      right: 15,
    },
    hint: {
      float: "left",
      width: "auto",
      marginLeft: "15px",
    },
    circle: {
      width: "12px",
      height: "12px",
      borderRadius: "12px",
      float: "left",
      position: "relative",
    },
    circleHalf: {
      width: "6px",
      height: "12px",
      borderTopLeftRadius: "12px",
      borderBottomLeftRadius: "12px",
      float: "left",
      position: "absolute",
      left: 0,
      top: 0,
      background: "#e01f29",
    },
    text: {
      width: "auto",
      float: "left",
      marginLeft: "5px",
      color: "#777",
      lineHeight: "12px",
      fontSize: "11px",
    },
    testControl: { display: "table", width: "100%", padding: "0 10px 20px" },
    testControlLabel: {
      width: "60px",
      float: "left",
      paddingTop: "29px",
      color: "#000",
      fontWeight: "500",
    },
    testConrolBar: { width: "calc(100% - 60px)", float: "left" },
    carousle_container_card: {
      display: "flex",
      justifyContent: "space-between",
      padding: "8px 0px",
    },
    liftText: {
      fontSize: "16px",
      fontWeight: 400,
      color: "#333333",
      letterSpacing: "-0.02em",
    },
    cardTextLift: {
      fontSize: "14px",
      fontWeight: 500,
      color: "#24A148",
      letterSpacing: "-0.02em",
    },
    cardText: {
      fontSize: "14px",
      fontWeight: 500,
      color: "#333333",
      letterSpacing: "-0.02em",
    },
    expandIcon: {
      "& svg": {
        position: "absolute",
        top: 5,
        right: 5,
        color: "#ffffff",
        cursor: "pointer",
      },
    },
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  }));