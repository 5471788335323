import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Divider
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import MeasureCarousel from "./MeasureCarousel";
import CustomerInsights from "./CustomerInsights";
import InfoComp from "components/InfoComp";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import PurchaseBehavior from "./PurchaseBehavior";
import DialogBox from "../Common/Modals/Dailog";
import ExpandDialog from "./ExpandDialog";
import useStyles from "./style";
import { format } from "date-fns";

const CampaignHighlights = (props) => {
  const classes = useStyles();

  //define props
  const {
    purchaseBehavior,
    campaignEngagement,
    digitalActivity,
    customerInsights,
    testControlChecked,
    selectedBrandLevel,

  } = props;
  // console.log("props value C",props)
  //define state

  const [expandDialogOpen, setExpandDialogOpen] = useState(false);
  const [expandDialogData] = useState({
    val: {},
    view: "",
  });

  const handleExpandDialogClose = () => {
    setExpandDialogOpen(false);
  };

  function getNextTuesday(date = new Date()) {
    const dateCopy = new Date(date.getTime());

    const nextTue = new Date(
      dateCopy.setDate(
        dateCopy.getDate() + ((7 - dateCopy.getDay() + 2) % 7 || 7),
      ),
    );

    return format(nextTue, "M/dd/yyyy");
  }

  return (
    <>
      <Card className={classes.cardItem} elevation={2}>
        <CardContent>

          <Typography className={classes.sectionHeading}>
            Campaign Engagement
          </Typography>
          <Accordion
            className={
              campaignEngagement?.overallStatus === "Green"
                ? classes.accordGreen
                : campaignEngagement?.overallStatus === "Red"
                  ? classes.accordRed
                  : classes.accordGrey
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                {campaignEngagement.overallStatus === "Green" ?
                  <TrendingUpIcon />
                  : campaignEngagement.overallStatus === "Red" ?
                    <TrendingDownIcon />
                    : ""
                }
                {campaignEngagement.topDescription}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {Object?.keys(campaignEngagement).length &&
                campaignEngagement.kpiRows.length ? (
                campaignEngagement.kpiRows.map((item, index) => (
                  <Grid
                    container
                    spacing={3}
                    className={classes.accordBlock}
                    key={index}
                  >
                    <Grid item xs={4}>
                      <Box component="div" className={classes.sideBlock}>
                        <Typography component="h4" variant="subtitle1">
                          {item?.kpiLabel}
                          <Typography component="span" variant="subtitle1">
                            <InfoComp
                              content={<InfoOutlinedIcon fontSize={"small"} />}
                              data={item?.kpiDefn}
                            />
                          </Typography>

                        </Typography>
                      </Box>
                      {testControlChecked && (
                        <div className={classes.card}>
                          <div>
                            <div className={classes.innerCard}>
                              {/* <div className={classes.cardText}>Lift %</div> */}
                              <div className={classes.cardText}>
                                % Change
                              </div>
                            </div>
                            <div className={classes.innerCard}>

                              <div className={classes.absLiftText}>
                                {item.pctChange}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className={classes.innerCard}>
                              <div className={classes.cardText}>Benchmark</div>
                              <div className={classes.cardText}>Test Avg</div>
                            </div>
                            <div className={classes.innerCard}>
                              <div className={classes.liftText}>
                                {item?.benchmark !== null
                                  ? item?.benchmark?.toFixed(2)
                                  : "NA"}
                              </div>
                              <div className={classes.absLiftText}>
                                {item?.testAverage}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* unchecked */}

                      {!testControlChecked && (
                        <div className={classes.card}>
                          <div>
                            <div className={classes.innerCard}>
                              {/* <div className={classes.cardText}>Lift %</div> */}
                              <div className={classes.cardText}>
                                % Change
                              </div>
                            </div>
                            <div className={classes.innerCard}>
                              {/* <div className={classes.liftText}>
                                {item.lift}%
                              </div> */}
                              <div className={classes.absLiftText}>
                                {item.pctChange}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className={classes.innerCard}>
                              <div className={classes.cardText}>
                                Benchmark
                              </div>

                              <div className={classes.cardText}>Test Avg</div>
                            </div>

                            <div className={classes.innerCard}>
                              <div className={classes.absLiftText}>
                                {item?.benchmark !== null ? item?.benchmark?.toFixed(2) : "NA"}
                              </div>
                              <div className={classes.absLiftText}>
                                {item?.testAverage}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={8}>
                      <MeasureCarousel
                        data={item?.segmentArr}
                        view={"campaignEngagement"}
                        type={item?.kpiType}
                        kpiLabel={item?.kpiLabel}
                        kpiName={item?.kpiName}
                        testControlChecked={testControlChecked}
                        selectedBrandLevel={selectedBrandLevel}
                      />
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Typography
                  component="h3"
                  variant="h3"
                  className={classes.info}
                >
                  {"No records to display"}
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
          {/* Digital activity  */}
          <Typography className={classes.sectionHeading}>
            Digital Activity
          </Typography>
          <Accordion
            className={
              digitalActivity?.overallStatus === "Green"
                ? classes.accordGreen
                : digitalActivity?.overallStatus === "Red"
                  ? classes.accordRed
                  : classes.accordGrey
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                {digitalActivity.overallStatus === "Green" ?
                  <TrendingUpIcon />
                  : digitalActivity.overallStatus === "Red" ?
                    <TrendingDownIcon />
                    : ""
                }
                {digitalActivity?.topDescription}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {Object?.keys(digitalActivity).length &&
                digitalActivity?.kpiRows?.length ? (
                digitalActivity?.kpiRows?.map((item, index) => (
                  <Grid
                    container
                    spacing={3}
                    className={classes.accordBlock}
                    key={index}
                  >
                    <Grid item xs={4}>
                      <Box component="div" className={classes.sideBlock}>
                        <Typography component="h4" variant="subtitle1">
                          {item?.kpiLabel}
                          <Typography component="span" variant="subtitle1">
                            <InfoComp
                              content={<InfoOutlinedIcon fontSize={"small"} />}
                              data={item?.kpiDefn}
                            />
                          </Typography>

                        </Typography>
                      </Box>
                      {testControlChecked && (
                        <div className={classes.card}>
                          <div>
                            <div className={classes.innerCard}>
                              <div className={classes.cardText}>Benchmark</div>
                              <div className={classes.cardText}>% Change</div>
                            </div>
                            <div className={classes.innerCard}>
                              <div className={classes.liftText}>
                                {item?.benchmark !== null
                                  ? item?.benchmark
                                  : "NA"}
                              </div>
                              <div className={classes.absLiftText}>
                                {item?.pctChange === null ? "NA" : item?.pctChange}
                              </div>
                            </div>
                          </div>
                          <Divider variant="middle" className={classes.divider} />
                          <div>
                            <div className={classes.innerCard}>
                              <div className={classes.cardText}>Control Avg</div>
                              <div className={classes.cardText}>Test Avg</div>
                            </div>
                            <div className={classes.innerCard}>
                              <div className={classes.liftText}>
                                {item?.controlDuringValue === null || item?.controlDuringValue === undefined ? "NA" : item?.controlDuringValue}
                              </div>
                              <div className={classes.absLiftText}>
                                {item?.testAverage === null ? "NA" : item?.testAverage}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {!testControlChecked && (
                        <div className={classes.card}>
                          <div>
                            <div className={classes.innerCard}>
                              <div className={classes.cardText}>Benchmark</div>
                              <div className={classes.cardText}>% Change</div>
                            </div>
                            <div className={classes.innerCard}>
                              <div className={classes.liftText}>
                                {item?.benchmark !== null
                                  ? item?.benchmark
                                  : "NA"}
                              </div>
                              <div className={classes.absLiftText}>
                                {item?.pctChange === null ? "NA" : item?.pctChange}
                              </div>
                            </div>
                          </div>
                          <Divider variant="middle" className={classes.divider} />
                          <div>
                            <div className={classes.innerCard}>
                              <div className={classes.cardText}>Control Avg</div>

                              <div className={classes.cardText}>Test Avg</div>
                            </div>

                            <div className={classes.innerCard}>
                              <div className={classes.absLiftText}>
                                {item?.controlDuringValue === null || item?.controlDuringValue === undefined ? "NA" : item?.controlDuringValue}
                              </div>
                              <div className={classes.absLiftText}>
                                {item?.testAverage === null ? "NA" : item?.testAverage}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={8}>
                      <MeasureCarousel
                        data={item?.segmentArr}
                        view={"digitalActivity"}
                        type={item?.kpiType}
                        kpiLabel={item?.kpiLabel}
                        kpiName={item?.kpiName}
                        testControlChecked={testControlChecked}
                      />
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Typography
                  component="h3"
                  variant="h3"
                  className={classes.info}
                >
                  {"No records to display"}
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
          <Typography className={classes.sectionHeading}>
            Purchase Behavior
          </Typography>
          <Accordion
            className={
              purchaseBehavior?.overallStatus === "Green"
                ? classes.accordGreen
                : purchaseBehavior?.overallStatus === "Red"
                  ? classes.accordRed
                  : classes.accordGrey
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                {purchaseBehavior.overallStatus === "Green" ?
                  <TrendingUpIcon />
                  : purchaseBehavior.overallStatus === "Red" ?
                    <TrendingDownIcon />
                    : ""
                }
                {purchaseBehavior?.topDescription}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PurchaseBehavior
                purchaseData={purchaseBehavior}
                testControlChecked={testControlChecked}
                selectedBrandLevel={selectedBrandLevel}
              />
            </AccordionDetails>
          </Accordion>
          {/* Customer Insights  */}
          <Typography className={classes.sectionHeading}>
            Customer Insights
          </Typography>
          <Accordion
            className={
              customerInsights?.overallStatus === "Green"
                ? classes.accordGreen
                : customerInsights?.overallStatus === "Red"
                  ? classes.accordRed
                  : classes.accordGrey
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {testControlChecked && customerInsights?.finalResult?.cltvResult?.cltvPostData?.resultsCommentPostCltv?.length > 0 &&
                <Typography className={classes.trendIcon}>
                  <TrendingUpIcon />
                  {customerInsights?.finalResult?.cltvResult?.cltvPostData?.resultsCommentPostCltv?.[0]?.commentPost?.includes("increase") ? customerInsights?.finalResult?.cltvResult?.cltvPostData?.resultsCommentPostCltv?.[0]?.commentPost : ""}
                  {customerInsights?.finalResult?.cltvResult?.cltvPostData?.resultsCommentPostCltv?.[1]?.commentPost?.includes("increase") ? customerInsights?.finalResult?.cltvResult?.cltvPostData?.resultsCommentPostCltv?.[1]?.commentPost : ""} {" | "}
                  {customerInsights?.finalResult?.liecycleResult?.lifeCyclePostData?.resultsCommentPostLifecycle?.[0]?.commentPostLifecycle?.includes("increase") ? customerInsights?.finalResult?.liecycleResult?.lifeCyclePostData?.resultsCommentPostLifecycle?.[0]?.commentPostLifecycle : ""}
                  {customerInsights?.finalResult?.liecycleResult?.lifeCyclePostData?.resultsCommentPostLifecycle?.[1]?.commentPostLifecycle?.includes("increase") ? customerInsights?.finalResult?.liecycleResult?.lifeCyclePostData?.resultsCommentPostLifecycle?.[1]?.commentPostLifecycle : ""}

                  <br />
                  <TrendingDownIcon />
                  {customerInsights?.finalResult?.cltvResult?.cltvPostData?.resultsCommentPostCltv?.[0]?.commentPost?.includes("decrease") ? customerInsights?.finalResult?.cltvResult?.cltvPostData?.resultsCommentPostCltv?.[0]?.commentPost : ""}
                  {customerInsights?.finalResult?.cltvResult?.cltvPostData?.resultsCommentPostCltv?.[1]?.commentPost?.includes("decrease") ? customerInsights?.finalResult?.cltvResult?.cltvPostData?.resultsCommentPostCltv?.[1]?.commentPost : ""} {" | "}
                  {customerInsights?.finalResult?.liecycleResult?.lifeCyclePostData?.resultsCommentPostLifecycle?.[0]?.commentPostLifecycle?.includes("decrease") ? customerInsights?.finalResult?.liecycleResult?.lifeCyclePostData?.resultsCommentPostLifecycle?.[0]?.commentPostLifecycle : ""}
                  {customerInsights?.finalResult?.liecycleResult?.lifeCyclePostData?.resultsCommentPostLifecycle?.[1]?.commentPostLifecycle?.includes("decrease") ? customerInsights?.finalResult?.liecycleResult?.lifeCyclePostData?.resultsCommentPostLifecycle?.[1]?.commentPostLifecycle : ""}
                </Typography>
              }
              {!testControlChecked && customerInsights?.finalResult?.cltvResult?.cltvPostData?.resultsCommentPostCltv?.length > 0 &&
                <Typography className={classes.trendIcon}>
                  <TrendingUpIcon />
                  {customerInsights?.finalResult?.cltvResult?.cltvPredata?.resultsCommentPreCltv?.[0]?.commentPre?.includes("increase") ? customerInsights?.finalResult?.cltvResult?.cltvPredata?.resultsCommentPreCltv?.[0]?.commentPre : ""}
                  {customerInsights?.finalResult?.cltvResult?.cltvPredata?.resultsCommentPreCltv?.[1]?.commentPre?.includes("increase") ? customerInsights?.finalResult?.cltvResult?.cltvPredata?.resultsCommentPreCltv?.[1]?.commentPre : ""} {" | "}
                  {customerInsights?.finalResult?.liecycleResult?.lifeCyclePredata?.resultsCommentPreLifecycle?.[0]?.commentPreLifecycle?.includes("increase") ? customerInsights?.finalResult?.liecycleResult?.lifeCyclePredata?.resultsCommentPreLifecycle?.[0]?.commentPreLifecycle : ""}
                  {customerInsights?.finalResult?.liecycleResult?.lifeCyclePredata?.resultsCommentPreLifecycle?.[1]?.commentPreLifecycle?.includes("increase") ? customerInsights?.finalResult?.liecycleResult?.lifeCyclePredata?.resultsCommentPreLifecycle?.[1]?.commentPreLifecycle : ""}
                  <br />
                  <TrendingDownIcon />
                  {customerInsights?.finalResult?.cltvResult?.cltvPredata?.resultsCommentPreCltv?.[0]?.commentPre?.includes("decrease") ? customerInsights?.finalResult?.cltvResult?.cltvPredata?.resultsCommentPreCltv?.[0]?.commentPre : ""}
                  {customerInsights?.finalResult?.cltvResult?.cltvPredata?.resultsCommentPreCltv?.[1]?.commentPre?.includes("decrease") ? customerInsights?.finalResult?.cltvResult?.cltvPredata?.resultsCommentPreCltv?.[1]?.commentPre : ""} {" | "}
                  {customerInsights?.finalResult?.liecycleResult?.lifeCyclePredata?.resultsCommentPreLifecycle?.[0]?.commentPreLifecycle?.includes("decrease") ? customerInsights?.finalResult?.liecycleResult?.lifeCyclePredata?.resultsCommentPreLifecycle?.[0]?.commentPreLifecycle : ""}
                  {customerInsights?.finalResult?.liecycleResult?.lifeCyclePredata?.resultsCommentPreLifecycle?.[1]?.commentPreLifecycle?.includes("decrease") ? customerInsights?.finalResult?.liecycleResult?.lifeCyclePredata?.resultsCommentPreLifecycle?.[1]?.commentPreLifecycle : ""}
                </Typography>
              }
              {customerInsights?.finalResult?.cltvResult?.cltvPostData?.resultsCommentPostCltv?.length === 0 && <Typography>{"Expand to view Details"}</Typography>}
            </AccordionSummary>
            <AccordionDetails>
              {customerInsights?.finalResult?.cltvResult?.cltvPostData?.resultsCommentPostCltv?.length > 0 ? (
                <CustomerInsights
                  data={customerInsights}
                  testControlChecked={testControlChecked}
                />
              ) : (
                <Typography
                  component="h3"
                  variant="body1"
                  className={classes.info}
                >
                  {` The Customer Insights section will be updated by next Tuesday (${getNextTuesday(new Date())}) after CLTV tables are loaded with the weekly data.`}
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>

        </CardContent>
      </Card>

      <DialogBox
        width={1920}
        open={expandDialogOpen}
        handleClose={handleExpandDialogClose}
        header={expandDialogData.val.kpiLabel}
        children={
          <ExpandDialog
            data={expandDialogData}
            testControlChecked={testControlChecked}
          />
        }
      ></DialogBox>
    </>
  );
};

export default CampaignHighlights;
