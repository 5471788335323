import React from "react";
import {
  Box,
  Grid,
  TableHead,
  TableCell,
  TableRow,
  Card,
  Typography,
  CircularProgress,
  List, ListItem
} from "@material-ui/core";
import useStyles from "./Style";
import CustomMaterialTable from "views/Common/CustomMaterialTable";
const Customeracquisition = (props) => {
  const { data, load1, comments, load6 } = props;
  const classes = useStyles();
 

  //table columns
  const columns = [
    {
      title: "Channel",
      field: "Channel",
      render: (rowData) => { return rowData?.CHANNEL }
    },
    {
      title: "This Year",
      field: "This year",
      render: (rowData) => { return rowData?.TY_COUNT }
    },
    {
      title: "Pct change",
      field: "Pct change",
      render: (rowData) => { return rowData?.YOY_PCT_CHANGE }
    },
    {
      title: "LY_COUNT",
      field: "LY_COUNT",
      render: (rowData) => { return rowData?.LY_COUNT },
    },
    {
      title: "RETENSION_RATE",
      field: "RETENSION_RATE",
      render: (rowData) => { return rowData?.RETENSION_RATE }

    },
  ];
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={8}>
          {
            load1 ? <Box component={"div"} className={classes.progress}>
              <CircularProgress />
            </Box> :
              <>
                {
                  data?.length > 0 ?
                    <CustomMaterialTable
                      title=""
                      data={data}
                      columns={columns}
                      options={{
                        actionsColumnIndex: -1,
                        sorting: true,
                        search: false,
                        toolbar: false,
                        paging: false,
                        pageSize: 5,
                      }}
                      components={{
                        Container: (props) => (
                          <Box component="div" className="tblScroll">
                            {props?.children}
                          </Box>
                        ),
                        Header: () => {
                          return (
                            <TableHead className={classes.tableRightBorder}>
                              <TableRow>
                                <TableCell rowSpan={2}>Channel</TableCell>
                                <TableCell colSpan={2}>This Year</TableCell>
                                <TableCell colSpan={2}>Last Year</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell rowSpan={2}>Customers</TableCell>
                                <TableCell rowSpan={2}>YOY %</TableCell>
                                <TableCell rowSpan={2}>Customers</TableCell>
                                <TableCell rowSpan={2}>Retention Rate %</TableCell>
                              </TableRow>
                            </TableHead>
                          );
                        },
                      }}
                    >

                    </CustomMaterialTable>
                    : <Box component={"div"} className={classes.noData}>Opps !No data found</Box>
                }
              </>
          }
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          {
            load6 ? <Box component={"div"} className={classes.progress}>
              <CircularProgress />
            </Box>
              :
              <>
                {
                  comments?.length > 0 ?
                    <>
                      <Card elevation={1} className={classes.cardContainerInsights}>
                        <Typography className={classes.kpiTextHeader}>Acquisition by Channel</Typography>
                        <List className={classes.listArea}>
                          {comments?.map((x, index) => (
                            <ListItem
                              disableGutters
                              key={index}>
                              <Typography variant="body2" component="p">
                                {` ${x?.COMMENTS}`}
                              </Typography>
                            </ListItem>
                          ))
                          }
                        </List>
                      </Card>
                    </>
                    : <Box component={"div"} className={classes.noData}>Opps !No data found</Box>}
              </>
          }
        </Grid>
      </Grid>
    </>
  );
};

export default Customeracquisition;
