import React, { Suspense, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Tab,
  Tabs,
  AppBar,
  Box,
  Grid,
  Typography,
  Card,
} from "@material-ui/core";
import GroupViewDetail from "./GroupViewDetail";
import secureAxios from "config/api-config";
import useStyles from "./Style";


import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
// import Select, { components } from "react-select";
import SingleSelectDropdown from "../../Common/Filters/SingleSelectDropdown";
import ToggleButtonCom from "../ToggleButton/ToggleButtonCom";
import FlatDatePicker from "../../Common/Filters/FlatDatePicker";
import { updatePIBrand, updateBrand, updateDate } from "../../../redux/measure/groupViewRedux";
import MultiSelect from "views/Common/Filters/MultiSelect";
const { format } = require("date-fns");

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



const GroupViewLanding = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filterData = useSelector((state) => state.groupView);
  const { startEndDate, brand, purchaseItemBrand } = filterData;
  const [campTypeList, setCampTypeList] = useState([]);
  const [tabNav] = useState(0);
  const [activeIndex, setActiveIndex] = useState("LIFECYCLE");
  const [campType, setCampType] = useState("LIFECYCLE");
  const [selectedPurchaseItemBrand, setSelectedPurchaseItemBrand] = useState("TOT");
  const [selectedBrandLevel, setSelectedBrandLevel] = useState("TOT");
  const [outlierChecked, setOutlierChecked] = useState(true);
  const [purchaseItembrandValue, setPurchaseItemBrandValue] = useState([]);
  const [brandValue, setBrandValue] = useState([]);
  const [campaignName, setCampaignName] = useState([])
  const [options, setOptions] = useState([])
  const [date, setDate] = useState([]);
  // console.log(date.length,"date");
  const [selectedCampaignIds, setSelectedCampaignIds] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
 
  //handle change
  const handleChange = (selectedOptions) => {
    // Extract campaign IDs from selected options
    const selectedIds = selectedOptions?.map((option) => option.value);
    // Update the state with the selected campaign IDs
    setSelectedCampaignIds(selectedIds);
  }

  //index change
  const singleCat = (catId) => {
    setCampType(catId);
    setActiveIndex(catId);
    setSelectedCampaignIds([])
    setFormattedData([])
  };

  //brand API
  const brandValueData = async () => {
    try {
      const res = await secureAxios.get("groupView/getBrandData");
      setPurchaseItemBrandValue(res?.data?.responseData);
      setBrandValue(res?.data?.responseData);
    } catch (err) {
      console.log(err);
    }
  };



  // API call for campaign name
  const getCampaignName = async () => {
    try {
      const res = await secureAxios.post("groupView/campaignName", {
        brand: purchaseItemBrand === "TOT" ? "ALL" : purchaseItemBrand,
        group: campType,
        startDate: startEndDate[0],
        endDate: startEndDate[1],
        outlierValue: outlierChecked,
        campgn_brand: brand,
        // campaignId: selectedCampaignIds?.length > 0 ? selectedCampaignIds : null
      });
      setCampaignName(res?.data?.queryResult);
      setOptions(res?.data?.queryResult)
    } catch (err) {
      console.log(err);
    }
  };

  //date API
  const fetchDate = async () => {
    try {
      const res = await secureAxios.get("/groupView/date");
      setDate(res?.data);
      dispatch(updateDate([res?.data?.minDate, res?.data?.maxDate]));
    } catch (err) { }
  };

  //API call
  useEffect(() => {
    let isSub = true;
    if (isSub) {
      secureAxios
        .get("groupView/getCampaignType")
        .then((res) => {
          setCampTypeList(res?.data?.queryResult);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => {
      isSub = false;
    };
  }, []);

  useEffect(() => {
    let isSub = true
    if(isSub){
      fetchDate();
      brandValueData();
    }
    return()=>{
      isSub = false
    }
  }, []);

  useEffect(() => {
   let isSub = true
   if(isSub){
    if(date.length !==0){
      getCampaignName()
    }
   }
   return()=>{
    isSub = false
   }
    
  }, [activeIndex, campType, brand, purchaseItemBrand, outlierChecked, startEndDate])
  
  
  // Format the options and set in the state
  useEffect(() => {
    const formattedData = options?.map((campaign) => ({
      label: campaign.campaign_name,
      value: campaign.campgn_id,
    }));
    setFormattedData(formattedData);
  }, [options]); 
  return (
    <Box component="div" className={classes.root}>
      <Box component="div" className={classes.filterContainer}>
        <Formik
          initialValues={{
            PURCHASEITEMBRAND: selectedPurchaseItemBrand,
            BRAND: selectedBrandLevel,
            DATERANGE: [date?.minDate, date?.maxDate],
          }}
          enableReinitialize={true}
          onSubmit={(values) => { }}
        >
          {({ values, setFieldValue }) => (
            <Form
              noValidate
              autoComplete="off"
              className={classes.filterAreaDropDown}
            >
              <Grid container spacing={3}>
                <Grid item xs={6} md={3}>
                  <SingleSelectDropdown
                    width={"w100"}
                    label={"Brand Level Impact"}
                    name={"PURCHASEITEMBRAND"}
                    value={values.PURCHASEITEMBRAND}
                    noneRequired={false}
                    allRequired={false}
                    handleMethod={(e) => {
                      if (e.target.value) {
                        setSelectedPurchaseItemBrand(e.target.value);
                        setFieldValue("PURCHASEITEMBRAND", e.target.value);
                        dispatch(updatePIBrand(e.target.value));
                      } else {
                        // setSelectedPurchaseItemBrand("");
                        // setFieldValue("PURCHASEITEMBRAND", "");
                        dispatch(updatePIBrand(e.target.value));
                        setSelectedPurchaseItemBrand("");
                        setFieldValue("PURCHASEITEMBRAND", "");
                      }
                    }}
                    options={purchaseItembrandValue}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <SingleSelectDropdown
                    width={"w100"}
                    label={"Campaign Brand"}
                    name={"BRAND"}
                    value={values.BRAND}
                    noneRequired={false}
                    allRequired={false}
                    handleMethod={(e) => {
                      if (e.target.value) {
                        setSelectedBrandLevel(e.target.value);
                        setFieldValue("BRAND", e.target.value);
                        dispatch(updateBrand(e.target.value));
                      } else {
                        setSelectedBrandLevel("");
                        setFieldValue("BRAND", "");
                      }
                    }}
                    options={brandValue}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <FlatDatePicker
                    name={"DATERANGE"}
                    label="Select Period"
                    value={values?.DATERANGE}
                    mode={"range"}
                    handleMethod={(name, value) => {
                      if (value.length === 2) {
                        setDate({
                          maxDate: format(value[1], "yyyy-MM-dd"),
                          minDate: format(value[0], "yyyy-MM-dd"),
                        });
                        setFieldValue("DATERANGE", value);
                        dispatch(
                          updateDate([
                            format(value[0], "yyyy-MM-dd"),
                            format(value[1], "yyyy-MM-dd"),
                          ])
                        );
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={3} className="pt0">
                  <ToggleButtonCom
                    outlierChecked={outlierChecked}
                    setOutlierChecked={setOutlierChecked}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
      <Box component="div" className={classes.tabBackground}>
        <AppBar elevation={1} position="static" color="white">
          <Tabs
            centered
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
            className={classes.tabs}
            value={activeIndex}
            onChange={(event, newValue) => {
              setActiveIndex(newValue);
            }}
          >
            {campTypeList?.map((item, index) => (
              <Tab
                wrapped
                onClick={() => singleCat(item?.campaign_grp_name)}
                key={item}
                label={item?.campaign_grp_name}
                value={item?.campaign_grp_name}
              />
            ))}
          </Tabs>
        </AppBar>

        <Box
          component="div" className={classes.innerContainer}>
          <Card elevation={1} style={{ backgroundColor: "#182C52" }} >
            <Typography className={classes.informationText}>
              Filtered results below are the cumulative impact inclusive of Directional/Significant Metrics
            </Typography>
          </Card>
        </Box>
        {/* campaign name multiselect dropdown */}
        <Box
          component="div" className={classes.innerContainer}>
         
          <MultiSelect
            items={formattedData}
            // getOptionDisabled={getOptionDisabled}
            label="Select campaign name"
            placeholder="Type campaign name"
            disableClearable={true}
            selectAllLabel="Select all"
            onChange={handleChange}
            setFormattedData={setFormattedData}
            activeIndex={activeIndex}
            
          />
        </Box>
        <Suspense fallback={"Loading..."}>
          {campTypeList?.map((item, index) => (
            <TabPanel value={tabNav} index={index} key={index}>
              {Object?.keys(date)?.length && startEndDate?.length &&

                <GroupViewDetail
                  campType={campType}
                  outlierChecked={outlierChecked}
                  campaignName={campaignName}
                  selectedCampaignIds={selectedCampaignIds}
                />
              }
            </TabPanel>
          ))}
        </Suspense>
      </Box>
    </Box>
  );
};
export default GroupViewLanding;