import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  tabNavigation: 0,
  deepdiveTabNavigation: 0,
  drilldownTabNavigation: 0,
  nestedTabNavigation: 0,
  enableDeepdive: false,
  selectedDeepdive: {},
  groupViewDate:[],
  landingFilters: {
    startPeriod: "",
    endPeriod: "",
    status: "",
    name: "",
    brands: "",
    fulfillmentChannel: [],
    group: [],
    testType: [],
    dataLevel: [],
  },
};

export const measureLandingDetails = createSlice({
  name: "measureLandingDetail",
  initialState,
  reducers: {
    updateLoadingStatus: (state, action) => {
      state.loading = action.payload;
    },
    updateDatalevel: (state, action) => {
      state.landingFilters.dataLevel = action.payload;
    },
    updateDeepdiveStatus: (state, action) => {
      state.enableDeepdive = action.payload;
    },
    updateSelectedDeepdive: (state, action) => {
      state.selectedDeepdive = action.payload;
    },
    updateTabNavigation: (state, action) => {
      state.tabNavigation = action.payload;
    },
    updateNestedTabNavigation: (state, action) => {
      state.nestedTabNavigation = action.payload;
    },
    updateDeepdiveTabNavigation: (state, action) => {
      state.deepdiveTabNavigation = action.payload;
    },
    updateDrilldownTabNavigation: (state, action) => {
      state.drilldownTabNavigation = action.payload;
    },
    updateGroupViewDate: (state, action) => {
      state.groupViewDate = action.payload;
    },
  },
});

export const {
  updateLoadingStatus,
  updateDatalevel,
  updateDeepdiveStatus,
  updateSelectedDeepdive,
  updateTabNavigation,
  updateNestedTabNavigation,
  updateDeepdiveTabNavigation,
  updateDrilldownTabNavigation,
  updateGroupViewDate,
} = measureLandingDetails.actions;

export default measureLandingDetails.reducer;
