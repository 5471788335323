import React, { useEffect, useState } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { APIS } from "../../config";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextFieldElement from "views/Common/Filters/TextFieldElement";
import DialogBox from "views/Common/Modals/Dailog";
import secureAxios from "../../config/api-config";
import AddGroup from "./AddGroup";
import MultilineTextField from "views/Common/Filters/MultilineTextField";
import { useDispatch } from "react-redux";
import MessageDialog from "views/Common/MessageDialog/MessageDialog";
import SingleSelectDropdown from "views/Common/Filters/SingleSelectDropdown";
import useStyles from './style'

let categoryVal = ["AE", "AERIE"];
const EditGroupForm = ({ onAdd, data, cancelEdit, initialProducts }) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false);
  const [products, setProducts] = useState(
    JSON.parse(JSON.stringify(initialProducts))
  );
  const [division, setDivision] = useState({});
  const [progress, setProgress] = useState(false);
  const [message, setMessage] = useState("");
  const [opens, setOpens] = useState(false);
  const [type, setType] = useState("");
  // const [categoryVal,setCategoryVal] =(["AE","AERIE"]);
  const validSchema = Yup.object().shape({
    NAME: Yup.string().required("Required").matches(/^[a-zA-Z0-9_'|.?,()@ [\]]+$/, "Some special char are not allowed"),
    DESCRIPTION: Yup.string().matches(/^[a-zA-Z0-9_'|.?,()@ [\]]+$/, "Some special char are not allowed"),
  });

  useEffect(() => {
    calculateDivision();
  }, [initialProducts]);


  const productgroupSave = (values, { resetForm }) => {
    dispatch({
      type: "LOADING",
      payload: true
    })
    setProgress(true);
    const products = [];
    for (let key in division) {
      division[key].classes.forEach((c) => {
        products.push({
          division_nbr: c.divCode,
          dept_nbr: c.deptCode,
          class_nbr: c.code,
        });
      });
    }

    secureAxios
      .put(`${APIS.MASTER.PRODUCT_GROUP_SAVE}/${data.group.product_grp_id}`, {
        product_grp_name: values.NAME,
        prd_grp_desc: values.DESCRIPTION,
        prd_grp_catg: values.CATEGORY,
        products,
      })
      .then((res) => {
        setProgress(false);
        onAdd();
        resetForm();
        setDivision({});
        setProducts(JSON.parse(JSON.stringify(initialProducts)));
        cancelEdit();
        dispatch({
          type: "LOADING",
          payload: false
        })


      }).catch((err) => {
        setOpens(true);
        setType("error");
        setMessage("Update Error");
        dispatch({
          type: "LOADING",
          payload: false
        })
      });
  };

  const handleProductModal = (div, prod) => {
    setDivision(JSON.parse(JSON.stringify(div)));
    setProducts(JSON.parse(JSON.stringify(prod)));
    setOpen(false);
  };

  const getProductsCount = () => {
    let count = 0;
    Object.keys(division).forEach(
      (key) => (count += division[key].classes.length)
    );
    return count;
  };

  const calculateDivision = () => {
    const productsList = products;
    if (data) {
      for (let p of data.products) {
        if (productsList[p.division_nbr]) {
          productsList[p.division_nbr].checked = true;
          if (productsList[p.division_nbr]) {
            productsList[p.division_nbr].departments[p.dept_nbr].checked = true;
            if (
              productsList[p.division_nbr].departments[p.dept_nbr].class[
              p.class_nbr
              ]
            ) {
              productsList[p.division_nbr].departments[p.dept_nbr].class[
                p.class_nbr
              ].checked = true;
            }
          }
        }
      }
    }

    const divs = {};
    for (let divkey in productsList) {
      const divData = productsList[divkey];
      for (let depkey in divData.departments) {
        const depData = divData.departments[depkey];
        for (let classkey in depData.class) {
          const classData = depData.class[classkey];
          if (classData.checked) {
            if (!divs[divkey]) {
              divs[divkey] = {
                division: divData.name,
                classes: [],
              };
            }
            divs[divkey].classes.push({
              ...classData,
              divCode: divData.code,
              deptCode: depData.code,
            });
          }
        }
      }
    }
    setDivision(divs);
    setProducts(productsList);
  };
  //close the popup
  const handleClose = () => {
    setOpens(false);
  };
  return (
    <>
      <MessageDialog
        type={type}
        open={opens}
        message={message}
        close={handleClose}
      />
      <Formik
        initialValues={{
          NAME: data.group.product_grp_name,
          DESCRIPTION: data.group.prd_grp_desc,
          CATEGORY: data?.group?.prd_grp_catg,
        }}
        validationSchema={validSchema}
        onSubmit={(values, params) => {
          productgroupSave(values, params);
        }}
      >
        {({ isValid, dirty, resetForm, setFieldValue, values, handleChange }) => (
          <Form
            autoComplete="off"
            noValidate
            style={{ padding: "25px 30px", textAlign: "left" }}
          >
            <Typography className={classes.sectionHeadingLarge}>
              Edit Group
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography className={classes.sectionHeading}>
                  Enter Group Details
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <TextFieldElement
                  name={"NAME"}
                  label="Group Name"
                  width={"w100"}
                />
              </Grid>
              <Grid item xs={6}>
                {/* <TextFieldElement
                  name={"CATEGORY"}
                  label="Group Category"
                  width={"w100"}
                /> */}
                <SingleSelectDropdown
                  width={"w100"}
                  value={values?.CATEGORY}
                  label={"Group Category"}
                  name={"CATEGORY"}
                  handleMethod={handleChange}
                  options={categoryVal}
                  noneRequired={true}
                />
              </Grid>
              <Grid item xs={12}>
                <MultilineTextField
                  name={"DESCRIPTION"}
                  label="Group Description"
                  width={"w100"}
                />
              </Grid>
              <Grid item xs={10} className={"mt20"}>
                <Button
                  variant="outlined"
                  disabled={!!(Object.keys(products).length === 0)}
                  onClick={() => setOpen(true)}
                  className={classes.audSelectBtn}
                >
                  Select Product List
                </Button>

                {Object.keys(division).length ? (
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.successMsg}
                  >
                    &nbsp; {`${getProductsCount()} Product List(s) Selected`}
                  </Typography>
                ) : (
                  <Typography variant="body2" component="em">
                    &nbsp; {"No Product List Selected"}
                  </Typography>
                )}
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} className={"textRight"}>
                  <Button
                    className={classes.cancelBtn}
                    type="button"
                    variant="contained"
                    onClick={cancelEdit}
                  >
                    Cancel
                  </Button>

                  <Button
                    className={classes.primaryBtn}
                    type="Submit"
                    variant="contained"
                    color="primary"
                  //disabled={
                  //!isValid || Object.keys(division).length === 0 || progress
                  //}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <DialogBox
              open={open}
              handleClose={() => setOpen(false)}
              width={"medium"}
              header={
                <div>
                  <span>Add Product</span>{" "}
                  <span
                    style={{
                      color: "#888",
                      marginLeft: "20px",
                      fontSize: "12px",
                    }}
                  >
                    <strong>Hierarchy:</strong> Division &gt; Department &gt;
                    Class
                  </span>
                </div>
              }
              contentStyle={{ marginBottom: "70px" }}
              children={
                <AddGroup
                  handleProductModal={handleProductModal}
                  products={products}
                  division={division}
                />
              }
            ></DialogBox>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditGroupForm;
