import axios from "axios";
import store from "../store";

export const RemoveAuth = () => {
  localStorage.clear();
  const url = window.location.host;
  window.location.replace(`http://${url}/login`);
};

let backendURL;
if (process.env.NODE_ENV === "development") {
  backendURL = process.env.REACT_APP_API;
} else {
  backendURL = "https://talpa-customer-backend-as.azurewebsites.net";
}
let secureAxios = axios.create({
  baseURL: backendURL,
});



secureAxios.interceptors.request.use(
  function(config) {
    // spinning start to show
    if (
      config.hasOwnProperty('showLoading') &&
      config.showLoading &&
      !store.getState().application.loading
    ) {
      axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
      axios.defaults.headers['Access-Control-Allow-Origin'] = '*';
      // store.dispatch(setLoading(true));
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

secureAxios.interceptors.response.use(
  function(response) {
    if (
      response.config.hasOwnProperty("showLoading") &&
      response.config.showLoading &&
      store.getState().application.loading
    ) {
      store.dispatch({ type: "LOADING", payload: false });
    }
    return response;
  },
  function(error) {
    // if (error?.response?.status === 403 || error?.response?.status === 401) {
    //   RemoveAuth();
    // }
    return Promise.reject(error);
  }
);

export default secureAxios;
