import React, { Suspense } from "react";
import { Redirect, Switch, Route, Router } from "react-router-dom";
import { RouteWithLayout } from "./components";
import { Main as MainLayout } from "./layouts";
import Login from "./views/Login/Login";
import Auth from "./views/Auth/Auth";
import DesignCampaign from "./views/DesignCampaign/DesignCampaign";
import ProductGroup from "./views/ProductGroup";
import PrivateRoute from "./PrivateRoute";
import { PATHS } from './config';
import history from "./History";
import CreateGroup from "views/ProductGroup/createGroup";
import ScrollTop from "ScrollTop";
import GroupViewHome from "./views/MeasureCampaign/GroupView/GroupViewLanding"
import CampaignViewHome from "views/MeasureCampaign/CampaignViewDetails/CampaignViewHome";
import Forbidden403 from './views/Forbidden403'
const MeasureCampaign = React.lazy(() =>
  import("./views/MeasureCampaign/MeasureCampaign​")
);
const routes = (props) => {
  return (
    <Router history={history}>
      <ScrollTop />
      <Switch>
        <Redirect exact from="/" to={PATHS.LOGIN} />
        <Route path={PATHS.LOGIN} component={Login} />
        <Route path={PATHS.AUTH} component={Auth} />
       
        <RouteWithLayout
          component={Forbidden403}
          exact
          layout={MainLayout}
          path={`/not-allowed`}
        />
        <Suspense fallback={<div>Loading</div>}>
          
          <PrivateRoute
            content={
              <RouteWithLayout
                component={MeasureCampaign}
                exact
                layout={MainLayout}
                path={PATHS.MEASURE_CAMPAIGN}
              />
            }
          />
          <PrivateRoute
            content={
              <RouteWithLayout
                component={DesignCampaign}
                exact
                layout={MainLayout}
                path={PATHS.REVIEW_CAMPAIGN}
              />
            }
          />
          <PrivateRoute
            content={
              <RouteWithLayout
                component={DesignCampaign}
                exact
                layout={MainLayout}
                path={PATHS.CREATE_CAMPAIGN}
              />
            }
          />

          <PrivateRoute
            content={
              <RouteWithLayout
                component={DesignCampaign}
                exact
                layout={MainLayout}
                path={PATHS.DESIGN_CAMPAIGN}
              />
            }
          />
          <PrivateRoute
            content={
              <RouteWithLayout
                component={ProductGroup}
                exact
                layout={MainLayout}
                path={PATHS.PRODUCT_GROUP}
              />
            }
          />
          <PrivateRoute
            content={
              <RouteWithLayout
                component={CreateGroup}
                exact
                layout={MainLayout}
                path={PATHS.PRODUCT_GROUP_CREATE}
              />
            }
          />
          <PrivateRoute
            content={
              <RouteWithLayout
                component={CampaignViewHome}
                exact
                layout={MainLayout}
                path="/campaignDetails/:id"
              />
            }
          />
          <PrivateRoute
            content={
              <RouteWithLayout
                component={GroupViewHome}
                exact
                layout={MainLayout}
                path="/group-view"
              />
            }
          />
        </Suspense>
      </Switch>
    </Router>
  );
};

export default routes;
