import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import palette from "../theme/palette";

const useStyles = makeStyles((theme) => ({
  action_delete: {
    border: "1px solid",
    borderColor: palette.semanticBGColors.primaryRed,
    backgroundColor: palette.semanticBGColors.secondaryRed,
    color: palette.semanticBGColors.primaryRed,
  }
}));
const ConfirmComponent = ({
  title,
  onConfirm,
  onCancel,
  confirmText,
}) => {
  return (<Dialog
    open={true}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle style={{ minWidth: '300px' }}>
      {title}
    </DialogTitle>
    <DialogActions>
      <Button onClick={onCancel}>Cancel</Button>
      <Button onClick={onConfirm} autoFocus style={{ color: '#FF0000' }}>
        {confirmText}
      </Button>
    </DialogActions>
  </Dialog>)
}
const ConfirmDelete = ({
  onDelete
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  return (<>
    {open && <ConfirmComponent title={'Are you sure to delete?'} onCancel={() => setOpen(false)} onConfirm={() => {
      onDelete();
      setOpen(false);
    }} confirmText={'Delete'} />}
    <Box component={"div"} onClick={() => setOpen(true)} className={classes.action_delete}>{"Delete"}</Box>
  </>)
}
export default ConfirmDelete;