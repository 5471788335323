import React, { useEffect, useState } from "react";
import ReactApexCharts from "react-apexcharts";

const PieChart = (props) => {
  const [chartInit, setChartInit] = useState();
  const {
    data,
    height,
    colors,
    testAvg,
    labels,
    sparkline,
    test,
    legendOffset,
    toRound,
  } = props;

  useEffect(() => {
    setChartInit({
      series: data,
      options: {
        chart: {
          // stacked: true,
          // toolbar: {
          //   show: true,
          // },
          sparkline: {
            enabled: sparkline,
          },
        },
        dataLabels: {
          enabled: false,
        },
        labels: labels,
        plotOptions: {
          pie: {
            donut: {
              size: "85%",
              labels: {
                show: true,
                name: {
                  offsetY: -5,
                  color: "#333333",
                  fontSize: "12px",
                },
                value: {
                  color: "#24A148",
                  offsetY: 0,
                  fontSize: "14px",
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: test === "tData" ? "Total" : "Total Average",
                  color: "#333333",
                  fontSize: "12px",
                  formatter: () => {
                    const val = toRound
                      ? Math.round(testAvg?.toFixed(2))
                      : testAvg;
                    return val;
                  },
                },
              },
            },
          },
        },
        stroke: {
          width: 0,
        },

        colors: colors ? colors : [],
        legend: {
          position: "right",
          offsetY: legendOffset ? legendOffset : 80,
        },
        tooltip: {
          x: {
            enabled: false,
            show: false,
          },
          y: {
            formatter: (value) => {
              const val = toRound
                ? Math.round(value?.toFixed(2))
                : value?.toFixed(2);
              return val;
            },
          },
        },
      },
    });
  }, [colors,labels,data,legendOffset,sparkline,test,testAvg,toRound]);

  return (
    <React.Fragment>
      {chartInit && (
        <>
          <ReactApexCharts
            options={chartInit.options}
            series={chartInit.series}
            type={"donut"}
            height={height ? height : 120}
          />
        </>
      )}
    </React.Fragment>
  );
};
export default PieChart;
