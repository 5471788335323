import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  audienceList: [],
  collectionList: [],
  selectProductList: [],
  addProductGroupData: [],
  campDetails: {
    NAME: "",
    GROUP: "",
    TYPE: "",
    DESCRIPTION: "",
    BLACKOUT: [],
    DATE: '',
    ENDDATE: '',
    DURATION: '',
    DURATIONTYPE: 'days',
    PREPERIOD: '',
    BRANDS: "",
    CHANNELS: [],
    CAMPAIGNTYPE: "",
    PRIMARYMETRIC: "",
    CREATEDON: new Date(),
    CREATEDBY: "Admin",
    CAMPAIGN: "",
    SEGMENTTYPE: "",

  },
  editDetailID: null,
  audSearchData: []
};

export const campaignDetail = createSlice({
  name: "campDetail",
  initialState,
  reducers: {
    campDescription: (state, action) => {
      let data = action.payload;
      state.campDetails = data;
    },
    addAudienceList: (state, action) => {
      let data = action.payload;
      // state.audienceList = data;
      state.audienceList = [...state.audienceList, data];
    },
    deleteAudienceList: (state, action) => {
      let data = action.payload;
      state.audienceList = state.audienceList.filter(
        (option) => option.test_name !== data
      );
    },
    updateAudienceList: (state, action) => {
      let data = action.payload;
      state.audienceList[data.objIndex].control_id = data.newVal.id;
      state.audienceList[data.objIndex].control_name = data.newVal.label;
      state.audienceList[data.objIndex].cust_cout = data.newVal.cout;
    },
    clearAudienceList: (state, action) => {
      let data = action.payload;
      state.audienceList = data;
    },
    addCollectionList: (state, action) => {
      let data = action.payload;
      state.collectionList = [...state.collectionList, data];
    },
    deleteCollectionList: (state, action) => {
      let data = action.payload;
      let dataList = state.collectionList.filter(function (item) {
        return item.collectionName !== data;
      });
      state.collectionList = dataList;
    },
    fillCollectionList: (state, action) => {
      let data = action.payload;
      state.audienceList = data
    },
    updateCollectionList: (state, action) => {
      let data = action.payload;
      state.collectionList = data
    },
    editCampDetail: (state, action) => {
      let data = action.payload;
      state.editDetailID = data;
    },
    addSelectedProduct: (state, action) => {
      let data = action.payload;
      state.selectProductList = data;
    },
    deleteSelectedProduct: (state, action) => {
      let data = action.payload;
      let dataList = state.selectProductList.filter(function (item) {
        return item.product_grp_id !== data;
      });
      state.selectProductList = dataList;
    },
    clearCampDetails: (state, action) => {
      let data = {
        NAME: "",
        GROUP: "",
        TYPE: "",
        DESCRIPTION: "",
        BLACKOUT: [],
        DATE: '',
        ENDDATE: '',
        DURATION: '',
        DURATIONTYPE: 'days',
        PREPERIOD: [],
        BRANDS: "",
        CHANNELS: [],
        CAMPAIGNTYPE: "",
        PRIMARYMETRIC: "",
        CREATEDON: new Date(),
        CREATEDBY: "Admin",
        CAMPAIGN: "",

      };
      state.campDetails = data;
    },
    clearCollectionList: (state, action) => {
      let data = action.payload;
      state.collectionList = data;
    },
    clearProductGroupList: (state, action) => {
      let data = action.payload;
      state.selectProductList = data;
    },
    addAudSearchData: (state, action) => {
      let data = action.payload;
      state.audSearchData = data;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  campDescription,
  addAudienceList,
  deleteAudienceList,
  updateAudienceList,
  clearAudienceList,
  addCollectionList,
  deleteCollectionList,
  fillCollectionList,
  updateCollectionList,
  editCampDetail,
  addSelectedProduct,
  deleteSelectedProduct,
  clearCampDetails,
  clearCollectionList,
  clearProductGroupList,
  addAudSearchData
} = campaignDetail.actions;

export default campaignDetail.reducer;
