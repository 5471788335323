

import React, { useState, useEffect } from "react";
import {
  Button,
  CssBaseline,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import tredenceLogo from "../../assets/Tredence-Logo.svg";
import banner from "../../assets/Front.svg";
import { UserAgentApplication } from "msal";
import config from "../../config/azure-configs/azureConfig";
import { getUserDetails } from "config/azure-services/GraphService";
import useStyles from './Style'
import { useDispatch } from "react-redux";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://tredence.com/">
        Tredence
      </Link>
      {new Date().getFullYear()}
    </Typography>
  );
}



export default function SignInSide(props) {
  const classes = useStyles();
  const [formType, setFormType] = useState("login");
  const history = useHistory();
 const dispatch = useDispatch()
  const userAgentApplication = new UserAgentApplication({
    auth: {
      clientId: config.appId,
      redirectUri: config.redirectUri,
      postLogoutRedirectUri: config.postLogoutRedirectUri,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  });

  const [loginState, setLoginState] = useState({
    isAuthenticated: false,
    user: {},
    error: null,
  });

  const login = async () => {
    try {
      await userAgentApplication.loginRedirect({
        scopes: config.scopes,
        prompt: "select_account",
      });
      //this will redirect to login page
      //await userAgentApplication.loginRedirect();
      await getUserProfile();
    } catch (err) {
      let error = {};

      if (typeof err === "string") {
        let errParts = err.split("|");
        error =
          errParts.length > 1
            ? { message: errParts[1], debug: errParts[0] }
            : { message: err };
      } else {
        error = {
          message: err.message,
          debug: JSON.stringify(err),
        };
      }
      setLoginState({
        isAuthenticated: false,
        user: {},
        error: error,
      });
    }
  };

  const getUserProfile = async () => {
    try {
      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token

      let accessToken = await userAgentApplication.acquireTokenSilent({
        scopes: config.scopes,
      });

      if (accessToken) {
        // Get the user's profile from Graph
        let user = await getUserDetails(accessToken);
        console.log(user);
        localStorage.setItem("userName", user.displayName);
        localStorage.setItem("accessToken", accessToken.accessToken);
       
        setLoginState({
          isAuthenticated: true,
          user: {
            displayName: user.displayName,
            email: user.mail || user.userPrincipalName,
            givenName: user.givenName,
            surname: user.surname,
          },
          error: null,
        });
         history.push("/design-campaign");
      }
    } catch (err) {
      let error = {};
      if (typeof err === "string") {
        let errParts = err.split("|");
        error =
          errParts.length > 1
            ? { message: errParts[1], debug: errParts[0] }
            : { message: err };
      } else {
        error = {
          message: err.message,
          debug: JSON.stringify(err),
        };
      }
      setLoginState({
        isAuthenticated: false,
        user: {},
        error: error,
      });
    }
  };

  useEffect(() => {
    dispatch({
      type: "LOADING",
      payload: true,
    });
    if (localStorage?.getItem("accessToken")?.length) {
      if (history) {
        history.push("/design-campaign");
      }
      dispatch({
        type: "LOADING",
        payload: false,
      });
    }
  });

  const LoginForm = (
    <div className={classes.loginPaper}>
      <img src={tredenceLogo} alt={"Tredence logo"} />

      <Button
       type="submit"
        fullWidth
        variant="contained"
        
        className={classes.submit}
        onClick={login}
      >
        Sign In
      </Button>
      <Box mt={5}>
        <Copyright />
      </Box>
    </div>
  );

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image}>
        <img src={banner} alt={"banner"} />
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        {formType === "login" && LoginForm}
      </Grid>
    </Grid>
  );
}
