import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Paper, Tab, Tabs, AppBar } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { updateDeepdiveTabNavigation } from "../../redux/measure/measureLandingRedux";
const useStyles = makeStyles((theme) => ({
  tabArea: {
    margin: "5px 0 0",
    "& button": {
      textTransform: "capitalize",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DeepdiveTabs = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const appReduxState = useSelector((state) => ({
    ...state.measureLandingDetail,
  }));
  const { deepdiveTabNavigation } = appReduxState;
  const {
    tabItems,
    variant,
    data,
    outlierChecked,
    purchaseBehavior,
    campaignEngagement,
    digitalActivity,
    customerInsights,
    testControlChecked,
    selectedBrandLevel,
    brandValue,
    load
  } = props;
  const handleChange = (event, newValue) => {
    dispatch(updateDeepdiveTabNavigation(newValue));
  };
  return (
    <AppBar position="static" className={classes.tabArea} elevation={0}>
      <Paper square elevation={0}>
        <Tabs
          value={deepdiveTabNavigation}
          onChange={handleChange}
          variant={variant}
          indicatorColor="primary"
          textColor="primary"
        >
          {tabItems.map((item) => (
            <Tab
              key={item.id}
              label={<p>{item.title} </p>}
              {...a11yProps(item.id)}
            />
          ))}
        </Tabs>
        <Suspense fallback={"Loading..."}>
          <TabPanel value={deepdiveTabNavigation} index={0}>
            <props.Tab1
              data={data}
              testControlChecked={testControlChecked}
              selectedBrandLevel={selectedBrandLevel}
              purchaseBehavior={purchaseBehavior}
              campaignEngagement={campaignEngagement}
              digitalActivity={digitalActivity}
              customerInsights={customerInsights}
              brandValue={brandValue}
              load={load}
            />
          </TabPanel>
          <TabPanel value={deepdiveTabNavigation} index={1}>
            <props.Tab2
              data={data}
              outlierChecked={outlierChecked}
              testControlChecked={testControlChecked}
              selectedBrandLevel={selectedBrandLevel}
              load={load}
            />
          </TabPanel>
        </Suspense>
      </Paper>
    </AppBar>
  );
};

export default DeepdiveTabs;
