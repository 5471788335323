import { makeStyles } from '@material-ui/core'


export default makeStyles((theme) => ({
  //dashboard content

  root: {
    padding: theme.spacing(3),
  },
  paper: {
    borderRadius: "10px !important",
  },
  GridOuter: {
    display: "flex",
    width: "1305",
    background: "#FFFFFF",
    height: "190px",
    padding: "2%",
  },
  dashboardCountOuter: {
    Width: "20%",
    height: "20%",

    background: "#FFFFFF",
    borderRadius: "0 px",
    // alignSelf: "stretch",
    padding: "10px",
    marginTop: "-1%",
    borderRight: "1px solid grey",
    "& h4": {
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "45px",
      fontSize: "30px",
      color: "#333333",
    },
    "& h5": {
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "21px",
      fontSize: "14px",
      color: "#929497",
      textAlign: "center",
    },
  },
  dashBoardContentouter: {
    display: "flex",
    flexWrp: "wrap",
    justifyContent: "space-around",
   
  },
  contentWrapper: {
    /* Auto layout */
    paddingBottom: "5px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "160px",
    height: "142px",
    borderRight: "1px solid #D8D8D8",
    /* Neutral Colors/BG Color White */
    background: "#FFFFFF",
    borderRadius: "0px",
    /* Inside auto layout */
    flex: "none",
    alignSelf: "stretch",
  },
  contentInner: {
    display: "flex",
    flexDirection: "column",
    minWidth: "160px",
    // height: "160px",
    alignItems: "center",
    position: "relative",
    "& h4": {
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "45px",
      fontSize: "30px",
      color: "#333333",
    },
  },
  dashbrdCampType: {
    "& span":
    {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      color: "#333333",
    }
  },
  leftContainer: {
    width: "260px !important",
  },
  infoArea: {
    textAlign: "center",
    marginBottom: 25,
    "& svg": {
      color: "#182C52",

      verticalAlign: "sub",

      marginRight: 10,
    },
  },
  usersIcon: {
    color: "#9f9e9e",
    verticalAlign: "middle",
  },
  //dashboard count style
  totalCount: {
    background: "rgb(102 156 255 / 10%)",
    borderBottom: "2px solid #679BFE",
    padding: "20%",
    // marginBottom: 7,
    height: "100%",
    "& h4": {
      color: "#679BFE",
      fontWeight: "500",
      lineHeight: "40px",
      fontSize: "30px",
    },
    "& h5": {
      fontWeight: "400",
      lineHeight: "21px",
      fontSize: "14px",
      color: "#333333",
    },
  },
  activeCount: {
    background: "rgb(88 218 127 / 10%)",
    borderBottom: "2px solid #58DA7E",
    padding: 15,
    marginTop: 7,
    "& h4": {
      color: "#58DA7E",
      fontWeight: "500",
      lineHeight: "40px",
      fontSize: "30px",
    },
    "& h5": {
      fontWeight: "400",
      lineHeight: "21px",
      fontSize: "14px",
      color: "#333333",
    },
  },
//   C:\Users\shikhara-ops\talp-frontend\src\views\DesignCampaign\DesignHome\Dashboard\index.js
dashboardCountOuterMain: {
    borderRight: "1px solid #D8D8D8",
    margin: "20px 0",
    minHeight: "200px",
  },
  rightContainer: {
    display: "flex",
    overflowX: "auto",
    alignItems: "center",
    "&::-webkit-scrollbar": {
      height: 12,
      width: 12,
      border: "1px solid #d5d5d5"
    },
    "&::-webkit-scrollbar-track": {
      background: "#f2f2f2",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#c8c8c8",
      borderRadius: "20px",
      border: "3px solid #f2f2f2"
    },

  },
  //viewtimestyle
  bottom: {
    color: "#C6C7C8",
  },
  top: {
    color: (props) => `${props.color || "#1a90ff"}`,
    position: "absolute",
    left: 1,
  },
  circle: {
    strokeLinecap: "round",
  },
  text: {
    fontSize: "0.9rem",
    fontWeight: 500,
  },
  filterArea: {
    "& $input": {
      padding: 8,
    },
    "& $label": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& $div.MuiSelect-outlined.MuiSelect-outlined": {
      padding: 8,
    },
  },

  timelineChart: {
    display: "flex",
    marginBottom: 20
  },
  viewTimelineText: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: "12px",
    textDecoration: "none",
    textAlign: "center",
    display: "inline-block",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  dateRangeArea: {
    display: "flex",
    width: "350px",
  },
  dateRangeLabel: {
    width: "80%",
    marginTop: "5%",
    fontWeight: "300",
    color: "black",
  },
  Reset: {
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
    color: theme.palette.primary.main,
    fontWeight: 500,
    height: "52px",
    cursor: "pointer",
  },
  timelineContainer: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    padding: "12px"
  }
}));