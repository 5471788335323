import React, { useEffect, useState } from "react";
import { Grid, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ViewCampaignDetail from "./ViewCampaign/ViewCampaignDetail";
import Dashboard from "./Dashboard/index.js";
import DisplayContainer from "../../Common/Utils/DisplayContainer";
import ViewTimeline from "./Dashboard/ViewTimeline";
import { useHistory } from "react-router-dom";
import secureAxios from "../../../config/api-config";
import { useDispatch } from "react-redux";
import { fetchDate } from "../../../redux/dashboard/dashBoardRedux";
import { PATHS, APIS } from "../../../config";
import Insight from "./Insight/Insight";
import useStyles from "./Style";

const DashBoardHome = () => {
  const classes = useStyles();
  const history = useHistory();
  const [dashboardDate, setDashboardDate] = useState("");
  const [reset, setReset] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      // get the data from the api
      await secureAxios.get(APIS.DESIGN_CAMPAIGN.DATE_RANGE).then((res) => {
        if (res.status === 200) {
          // console.log(res.data);
          setDashboardDate(res.data);

          dispatch(fetchDate(res.data));
        }
      });
    };

    fetchData().catch((err) => console.log(err));
  }, [reset]);

  const resetDate = (val) => {
    setReset((prev) => !prev);
  };

  return (
    <>
      <DisplayContainer
        title={"Dashboard"}
        content={<Dashboard dashboardDate={dashboardDate} />}
        contentRight={
          <ViewTimeline
            datePeriod={dashboardDate?.length ? dashboardDate : ""}
            resetDate={resetDate}
          />
        }
      />

      <Insight />

      <DisplayContainer
        title={"View Campaign Details"}
        content={<ViewCampaignDetail />}
        contentRight={
          <Grid
            direction="row"
            xs={12}
            justifyContent="flex-end"
            style={{ gap: "2px" }}
            container
          >
            <Button
              variant="contained"
              color="secondary"
              className={classes.createBtnProductGroup}
              onClick={(e) => {
                history.push(PATHS.PRODUCT_GROUP);
              }}
            >
              <AddIcon />
              Product Groups
            </Button>

            <Button
              variant="contained"
              color="secondary"
              className={classes.createBtnProductGroup}
              onClick={(e) => {
                history.push(PATHS.CREATE_CAMPAIGN);
              }}
            >
              <AddIcon />
              Create Campaign
            </Button>
          </Grid>
        }
      />
    </>
  );
};

export default DashBoardHome;
