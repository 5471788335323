import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DrillDownTabs from "./DrillDownTabs";
import { useDispatch } from "react-redux";
import Transactional from "./Transactional";
import CampaignEngagement from "./CampaignEngagement";
import DigitalActivity from "./DigitalActivity";
import { Card, CardContent } from "@material-ui/core";
import { updateDrilldownTabNavigation } from "../../redux/measure/measureLandingRedux";
import { useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({}));

export default function DrillDown(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation()
  const [campaignData] = React.useState(location?.state?.campaignData?.ID)
  const { data,  outlierChecked,testControlChecked } = props;
  // console.log(location,"page");

  // console.log("props value D",props)
  const tabItems = [
    {
      id: 1,
      title: "Transactional",
    },
    {
      id: 2,
      title: "Campaign Engagement",
    },
    {
      id: 3,
      title: "Digital Activity",
    },
  ];
  useEffect(() => {
    dispatch(updateDrilldownTabNavigation(0));
  }, []);
  return (
    <Card className={classes.cardItem} elevation={2}>
      <CardContent>
        <DrillDownTabs
          tabItems={tabItems}
          data={data}
          outlierChecked={outlierChecked}
          testControlChecked={testControlChecked}
          Tab1={Transactional}
          campaignId={campaignData}
          Tab2={CampaignEngagement}
          Tab3={DigitalActivity}
        />
      </CardContent>
    </Card>
  );
}
