import React, { Fragment, useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  Chip,
} from "@material-ui/core";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import SendIcon from "@material-ui/icons/Send";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import FacebookIcon from "@material-ui/icons/Facebook";
import NotificationsActiveOutlinedIcon from "@material-ui/icons/NotificationsActiveOutlined";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { useSelector, useDispatch } from "react-redux";
import { format, subDays, addDays } from "date-fns";
import { useHistory, useLocation } from "react-router-dom";
import { APIS, PATHS } from "../../../config";
import { numberWithCommas } from "../../Common/Utils/ClientUtil";
import secureAxios from "../../../config/api-config";
import _ from "lodash";
import {
  clearCampDetails,
  clearCollectionList,
  clearProductGroupList,
} from "../../../redux/campDetail/campaignDetailRedux";
import MessageDialog from "views/Common/MessageDialog/MessageDialog";
import Moment from "moment";
import useStyles from "./style";
const { differenceInDays } = require("date-fns");

const CampaignReview = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const userDetails = localStorage.getItem("userName");
  const [campaign_id] = useState(location.state.campaignId);
  const [totalDuration] = useState(location.state.totalCollectionDuration);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [audFinalData, setAudFinalData] = useState([]);
  const [sameFlightId, setSameFlightId] = useState(true);
  const campData = useSelector((state) => state.campDetail);
  const audData = useSelector((state) => [...state.campDetail.collectionList]);
  const selectPro = useSelector((state) => [
    ...state.campDetail.selectProductList,
  ]);
  const { campDetails, collectionList } = campData;
  const history = useHistory();
  const date1 = new Date(
    Moment(campData?.campDetails?.PREPERIOD[0]).format("MM-DD-YY")
  );
  const date2 = new Date(
    Moment(campData?.campDetails?.PREPERIOD[1]).format("MM-DD-YY")
  );

  const duration = differenceInDays(date2, date1);
  const preDuration = duration + 1;

  //close the popup
  // const handleClose = () => {
  //   setOpen(false);
  // };

  //api call for run dag
  const runDag = async () => {
    secureAxios
      .post("/dag/runDag", {
        campaign_id: campaign_id,
      })
      .then((res) => {
        //api call for dag response data insert
        if (res.status === 200) {
          secureAxios
            .post("/dag/data", {
              campaign_id_key: res.data?.repons?.conf.campaign_id_key,
              dag_run_id: res.data?.repons?.dag_run_id,
              dag_status: res.data?.repons?.state,
            })
            .then((res) => {
              console.log(res);
            });
        }
      });
  };

  //function for create campaign
  const submitData = () => {
    dispatch({
      type: "LOADING",
      payload: true,
    });
    const campaignData = [];
    campaignData.push({
      campaign_desc: campData?.campDetails?.DESCRIPTION,
      campaign_name: campData?.campDetails?.NAME,
      campaign_grp_name: campData?.campDetails?.GROUP,
      type_of_campaign: campData?.campDetails?.CAMPAIGNTYPE,
      blackout_dt:
        campData?.campDetails?.BLACKOUT?.length > 0
          ? campData?.campDetails?.BLACKOUT?.map((x) => format(x, "yyyy-MM-dd"))
          : null,
      campaign_brand: campData?.campDetails?.BRANDS,
      campaign_type: campData?.campDetails?.TYPE,
      primary_kpi: campData?.campDetails?.PRIMARYMETRIC,
      campaign_channel: campData?.campDetails?.CHANNELS.map((x) => x),
      campaign_strt_date: format(campData?.campDetails?.DATE, "yyyy-MM-dd"),
      pre_start_dt: campData?.campDetails?.PREPERIOD[0]
        ? format(campData?.campDetails?.PREPERIOD[0], "yyyy-MM-dd")
        : null,
      created_date: format(campData?.campDetails?.CREATEDON, "yyyy-MM-dd"),
      campaign_duration: campData?.campDetails?.DURATION,
      // pre_duration: campData?.campDetails?.PREDURATION,
      pre_duration: preDuration,
      campaign_owner: userDetails,
      campaign_status: "Scheduled",
      campaign_end_date: format(campData?.campDetails?.ENDDATE, "yyyy-MM-dd"),
      pre_end_dt: campData?.campDetails?.PREPERIOD[0]
        ? format(campData?.campDetails?.PREPERIOD[1], "yyyy-MM-dd")
        : null,
      product_grp_id:
        selectPro.length > 0 ? selectPro?.map((p) => p.product_grp_id) : null,
      campaign_id: campaign_id,
      segment_type: campData?.campDetails?.SEGMENTTYPE,
      campaign_src_type: campData?.campDetails?.DATASOURCE,
      // campaign_strt_date:campData?.campIcon.STARTDATE
    });
    secureAxios
      .post(APIS.DESIGN_CAMPAIGN.LAUNCH_DATA, {
        audList: audFinalData,
        campList: campaignData,

        // productList:selectProduct
      })
      .then((res) => {
        dispatch({
          type: "LOADING",
          payload: false,
        });
        setOpen(true);
        setType("success");
        setMessage("Campaign created successfully");
        runDag();
        setTimeout(() => {
          history.replace(PATHS.DESIGN_CAMPAIGN);
          dispatch(clearCampDetails());
          dispatch(clearCollectionList([]));
          dispatch(clearProductGroupList([]));
        }, 4000);
      })
      .catch((err) => {
        dispatch({
          type: "LOADING",
          payload: false,
        });
        setOpen(true);
        setType("error");
        setMessage("Something went wrong");
        // console.log(err);
      })
      .catch((err) => {
        dispatch({
          type: "LOADING",
          payload: false,
        });
        setOpen(true);
        setType("waring");
        setMessage("server error");
      });
  };

  function calculateDuration(index) {
    const to = index;
    const arr = _.map(collectionList, "collectionDuration").map(Number);
    const array = arr.slice(0, to);
    const sum = array.reduce((accumulator, a) => {
      return accumulator + parseInt(a);
    }, 0);

    return sum;
  }

  function replaceDoubleQuotesWithSingleQuotes(str) {
    return str.replace(/"/g, "'");
  }
  //i 1,2,3
  //0-1 ,0-2
  useEffect(() => {
    function returnStatus() {
      for (let i = 0; i < audData.length; i++) {
        let a = _.map(audData[i].testControlMap, "test_id");
        let b = _.map(audData[0].testControlMap, "test_id");
        if (JSON.stringify(a) !== JSON.stringify(b)) return false;
      }
    }
    let result = returnStatus();
    if (result === false) {
      setSameFlightId(false);
    } else {
      setSameFlightId(true);
    }

    const audList = [];
    if (
      campData?.campDetails?.SEGMENTTYPE !== "Reusable segments" ||
      audData?.length === 1
    ) {
      console.log("c1");
      //not Reusable segments or collection list only one
      audData.map((y) => {
        y.testControlMap.map((x) => {
          audList.push({
            flight_test_id: x?.test_id
              ? replaceDoubleQuotesWithSingleQuotes(x?.test_id)
              : null,
            flight_control_id: x?.control_id
              ? replaceDoubleQuotesWithSingleQuotes(x?.control_id)
              : null,
            flight_test_name: x?.test_name
              ? replaceDoubleQuotesWithSingleQuotes(x?.test_name)
              : null,
            flight_control_name:
              x?.control_name === null || x?.control_name === "Non Linked"
                ? null
                : replaceDoubleQuotesWithSingleQuotes(x?.control_name),
            job_id: x.job_id,
            batch_id: x.batch_id,
            insert_dt: format(new Date(), "yyyy-MM-dd"),
            insert_by: x.insert_by,
            last_modified_dt: format(new Date(), "yyyy-MM-dd"),
            last_modified_by: x.last_modified_by,
            collection_name: y.collectionName,
            campaign_id: campaign_id,
            start_dt: format(campData?.campDetails?.DATE, "yyyy-MM-dd"),
            end_dt: format(campData?.campDetails?.ENDDATE, "yyyy-MM-dd"),
            duration: y.collectionDuration,
          });
        });
      });
    } else if (
      audData?.length > 1 &&
      !sameFlightId &&
      _.map(collectionList, "collectionDuration")
        .map(Number)
        .every((val, i, arr) => val === arr[0])
    ) {
      console.log("c2");
      //Flight id is not same and during is same  collection list more than one
      audData.map((y) => {
        y.testControlMap.map((x) => {
          audList.push({
            flight_test_id: x?.test_id
              ? replaceDoubleQuotesWithSingleQuotes(x?.test_id)
              : null,
            flight_control_id: x?.control_id
              ? replaceDoubleQuotesWithSingleQuotes(x?.control_id)
              : null,
            flight_test_name: x?.test_name
              ? replaceDoubleQuotesWithSingleQuotes(x?.test_name)
              : null,
            flight_control_name:
              x?.control_name === null || x?.control_name === "Non Linked"
                ? null
                : replaceDoubleQuotesWithSingleQuotes(x?.control_name),
            job_id: x.job_id,
            batch_id: x.batch_id,
            insert_dt: format(new Date(), "yyyy-MM-dd"),
            insert_by: x.insert_by,
            last_modified_dt: format(new Date(), "yyyy-MM-dd"),
            last_modified_by: x.last_modified_by,
            collection_name: y.collectionName,
            campaign_id: campaign_id,
            start_dt: format(campData?.campDetails?.DATE, "yyyy-MM-dd"),
            end_dt: format(campData?.campDetails?.ENDDATE, "yyyy-MM-dd"),
            duration: y.collectionDuration,
          });
        });
      });
    } else if (
      audData?.length > 1 &&
      campData?.campDetails?.DURATION > totalDuration &&
      sameFlightId
    ) {
      console.log("c3");
      //Flight id same and camp DURATION > total collection during and  collection list more than one
      audData.map((y, index) => {
        y.testControlMap.map((x) => {
          audList.push({
            flight_test_id: x?.test_id
              ? replaceDoubleQuotesWithSingleQuotes(x?.test_id)
              : null,
            flight_control_id: x?.control_id
              ? replaceDoubleQuotesWithSingleQuotes(x?.control_id)
              : null,
            flight_test_name: x?.test_name
              ? replaceDoubleQuotesWithSingleQuotes(x?.test_name)
              : null,
            flight_control_name:
              x?.control_name === null || x?.control_name === "Non Linked"
                ? null
                : replaceDoubleQuotesWithSingleQuotes(x?.control_name),
            job_id: x.job_id,
            batch_id: x.batch_id,
            insert_dt: format(new Date(), "yyyy-MM-dd"),
            insert_by: x.insert_by,
            last_modified_dt: format(new Date(), "yyyy-MM-dd"),
            last_modified_by: x.last_modified_by,
            collection_name: y.collectionName,
            campaign_id: campaign_id,
            start_dt: format(
              addDays(campData?.campDetails?.DATE, calculateDuration(index)),
              "yyyy-MM-dd"
            ),
            end_dt: format(
              subDays(
                campData?.campDetails?.ENDDATE,
                totalDuration - calculateDuration(index + 1)
              ),
              "yyyy-MM-dd"
            ),
            duration: y.collectionDuration,
          });
        });
      });
    } else if (
      audData?.length > 1 &&
      sameFlightId &&
      campData?.campDetails?.DURATION <= totalDuration
    ) {
      console.log("c4");
      //Flight id same and camp DURATION <= total collection during and  collection list more than one
      audData.map((y, index) => {
        y.testControlMap.map((x) => {
          audList.push({
            flight_test_id: x?.test_id
              ? replaceDoubleQuotesWithSingleQuotes(x?.test_id)
              : null,
            flight_control_id: x?.control_id
              ? replaceDoubleQuotesWithSingleQuotes(x?.control_id)
              : null,
            flight_test_name: x?.test_name
              ? replaceDoubleQuotesWithSingleQuotes(x?.test_name)
              : null,
            flight_control_name:
              x?.control_name === null || x?.control_name === "Non Linked"
                ? null
                : replaceDoubleQuotesWithSingleQuotes(x?.control_name),
            job_id: x.job_id,
            batch_id: x.batch_id,
            insert_dt: format(new Date(), "yyyy-MM-dd"),
            insert_by: x.insert_by,
            last_modified_dt: format(new Date(), "yyyy-MM-dd"),
            last_modified_by: x.last_modified_by,
            collection_name: y.collectionName,
            campaign_id: campaign_id,
            start_dt: format(
              addDays(campData?.campDetails?.DATE, calculateDuration(index)),
              "yyyy-MM-dd"
            ),
            end_dt: format(
              addDays(
                campData?.campDetails?.DATE,
                calculateDuration(index) + parseInt(y.collectionDuration) - 1
              ),
              "yyyy-MM-dd"
            ),
            duration: y.collectionDuration,
          });
        });
      });
    } else if (
      audData?.length > 1 &&
      !sameFlightId &&
      campData?.campDetails?.DURATION <= totalDuration
    ) {
      console.log("c5");
      //Flight id not same and camp DURATION <= total collection during and  collection list more than one
      audData.map((y, index) => {
        y.testControlMap.map((x) => {
          audList.push({
            flight_test_id: x?.test_id
              ? replaceDoubleQuotesWithSingleQuotes(x?.test_id)
              : null,
            flight_control_id: x?.control_id
              ? replaceDoubleQuotesWithSingleQuotes(x?.control_id)
              : null,
            flight_test_name: x?.test_name
              ? replaceDoubleQuotesWithSingleQuotes(x?.test_name)
              : null,
            flight_control_name:
              x?.control_name === null || x?.control_name === "Non Linked"
                ? null
                : replaceDoubleQuotesWithSingleQuotes(x?.control_name),
            job_id: x.job_id,
            batch_id: x.batch_id,
            insert_dt: format(new Date(), "yyyy-MM-dd"),
            insert_by: x.insert_by,
            last_modified_dt: format(new Date(), "yyyy-MM-dd"),
            last_modified_by: x.last_modified_by,
            collection_name: y.collectionName,
            campaign_id: campaign_id,
            start_dt: format(
              addDays(campData?.campDetails?.DATE, calculateDuration(index)),
              "yyyy-MM-dd"
            ),
            end_dt: format(
              addDays(
                campData?.campDetails?.DATE,
                calculateDuration(index) + parseInt(y.collectionDuration) - 1
              ),
              "yyyy-MM-dd"
            ),
            duration: y.collectionDuration,
          });
        });
      });
    } else if (
      audData?.length > 1 &&
      !sameFlightId &&
      campData?.campDetails?.DURATION > totalDuration
    ) {
      console.log("c6");
      //Flight id not same and camp DURATION > total collection during and  collection list more than one
      audData.map((y, index) => {
        y.testControlMap.map((x) => {
          audList.push({
            flight_test_id: x?.test_id
              ? replaceDoubleQuotesWithSingleQuotes(x?.test_id)
              : null,
            flight_control_id: x?.control_id
              ? replaceDoubleQuotesWithSingleQuotes(x?.control_id)
              : null,
            flight_test_name: x?.test_name
              ? replaceDoubleQuotesWithSingleQuotes(x?.test_name)
              : null,
            flight_control_name:
              x?.control_name === null || x?.control_name === "Non Linked"
                ? null
                : replaceDoubleQuotesWithSingleQuotes(x?.control_name),
            job_id: x.job_id,
            batch_id: x.batch_id,
            insert_dt: format(new Date(), "yyyy-MM-dd"),
            insert_by: x.insert_by,
            last_modified_dt: format(new Date(), "yyyy-MM-dd"),
            last_modified_by: x.last_modified_by,
            collection_name: y.collectionName,
            campaign_id: campaign_id,
            start_dt: format(
              addDays(campData?.campDetails?.DATE, calculateDuration(index)),
              "yyyy-MM-dd"
            ),
            end_dt: format(
              addDays(
                campData?.campDetails?.DATE,
                calculateDuration(index) + parseInt(y.collectionDuration) - 1
              ),
              "yyyy-MM-dd"
            ),
            duration: y.collectionDuration,
          });
        });
      });
    }
    console.log(audList);
    setAudFinalData(audList);
  }, [sameFlightId]);

  if (audData.length && selectPro.length && campData.length === 0) {
    return <div>No data found</div>;
  }
  return (
    <div className={classes.root}>
      <MessageDialog
        type={type}
        open={open}
        message={message}
        // close={handleClose}
      />

      <Grid container spacing={3}>
        <Grid item xs={6} className={"pt0 pb0"}>
          <Typography
            component="h4"
            variant="h4"
            className={classes.pageHeading}
          >
            {"Overview"}
          </Typography>
        </Grid>

        <Grid item xs={6} className={"pt0 pb0 textRight"}>
          <Button
            className={classes.cancelBtn}
            type="button"
            variant="contained"
            onClick={() => history.replace(PATHS.CREATE_CAMPAIGN)}
          >
            Edit
          </Button>
          <Button
            className={classes.primaryBtn}
            type="Submit"
            variant="contained"
            color="primary"
            onClick={submitData}
          >
            Create
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.cardItem} elevation={2}>
            <CardContent>
              <Typography className={classes.cardHeading}>
                {/* Campaign Details */}
                {campDetails.NAME}
              </Typography>
              <Box component="div" className={classes.detailBlock}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography className={classes.description}>
                      {campDetails.DESCRIPTION ? campDetails.DESCRIPTION : "NA"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={3}>
                        <Typography className={classes.heading}>
                          {"Campaign Group:"}
                        </Typography>
                        <Typography component="h5" className={classes.value}>
                          {campDetails.GROUP}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={3}>
                        <Typography className={classes.heading}>
                          {"Campaign Type:"}
                        </Typography>
                        <Typography component="h5" className={classes.value}>
                          {campDetails.TYPE}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={3}>
                        <Typography className={classes.heading}>
                          {"Primary Metric:"}
                        </Typography>
                        <Typography component="h5" className={classes.value}>
                          {campDetails.PRIMARYMETRICLABEL}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={3}>
                        <Typography className={classes.heading}>
                          {"Brand:"}
                        </Typography>
                        <Typography component="h5" className={classes.value}>
                          {campDetails.BRANDS}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography className={classes.heading}>
                          {"Vehicle:"}
                        </Typography>

                        {campDetails.CHANNELS === 0 ? (
                          <Typography component="h5" className={classes.value}>
                            {"NA"}
                          </Typography>
                        ) : (
                          <>
                            {campDetails.CHANNELS.includes("DM") && (
                              <Typography
                                className={classes.campIcon}
                                variant="inherit"
                              >
                                <SendIcon fontSize="small" /> DM
                              </Typography>
                            )}
                            {campDetails.CHANNELS.includes("SMS") && (
                              <Typography
                                className={classes.campIcon}
                                variant="inherit"
                              >
                                <ChatBubbleIcon fontSize="small" /> SMS
                              </Typography>
                            )}
                            {campDetails.CHANNELS.includes("Email") && (
                              <Typography
                                className={classes.campIcon}
                                variant="inherit"
                              >
                                <EmailOutlinedIcon fontSize="small" /> Email
                              </Typography>
                            )}
                            {campDetails.CHANNELS.includes("Facebook") && (
                              <Typography
                                className={classes.campIcon}
                                variant="inherit"
                              >
                                <FacebookIcon fontSize="small" /> Facebook
                              </Typography>
                            )}
                            {campDetails?.CHANNELS?.includes(
                              "Push Notification"
                            ) && (
                              <Typography
                                className={classes.campIcon}
                                variant="inherit"
                              >
                                <NotificationsActiveOutlinedIcon fontSize="small" />{" "}
                                Push Notification
                              </Typography>
                            )}
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography className={classes.heading}>
                          {"Segment Type :"}
                        </Typography>
                        <Typography component="h5" className={classes.value}>
                          {campDetails.SEGMENTTYPE}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography className={classes.heading}>
                          {"Data Source :"}
                        </Typography>
                        <Typography component="h5" className={classes.value}>
                          {campDetails.DATASOURCE}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography className={classes.heading}>
                          {"Campaign Type:"}
                        </Typography>
                        <Typography component="h5" className={classes.value}>
                          {campDetails.CAMPAIGNTYPE}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography className={classes.heading}>
                          {"Blackout Date(s):"}
                        </Typography>
                        <Typography className={classes.durationArea}>
                          <DateRangeIcon fontSize="small" />
                          {}
                          {campDetails.BLACKOUT.length
                            ? campDetails.BLACKOUT.map((item, index) => (
                                <Typography component={"span"} key={index}>
                                  {(index ? ", " : "") + format(item, "MMM dd")}
                                </Typography>
                              ))
                            : "NA"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography className={classes.heading}>
                          {"Campaign Duration:"}
                        </Typography>
                        <Typography className={classes.durationArea}>
                          <DateRangeIcon fontSize="small" />
                          {format(campDetails?.DATE, "MMM dd")} -
                          {format(campDetails?.ENDDATE, "MMM dd")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography className={classes.heading}>
                          {"Campaign Pre Period:"}
                        </Typography>
                        <Typography className={classes.durationArea}>
                          <DateRangeIcon fontSize="small" />
                          {}
                          {campDetails?.PREPERIOD?.length
                            ? campDetails?.PREPERIOD?.map((item, index) => (
                                <Typography component={"span"} key={index}>
                                  {(index ? " - " : "") +
                                    format(item, "MMM dd")}
                                </Typography>
                              ))
                            : "NA"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography className={classes.heading}>
                          {"Created on:"}
                        </Typography>
                        <Typography className={classes.durationArea}>
                          <DateRangeIcon fontSize="small" />
                          {format(campDetails.CREATEDON, "MMM dd")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography className={classes.heading}>
                          {"Created by:"}
                        </Typography>
                        <Typography component="h5" className={classes.value}>
                          {campDetails.CREATEDBY}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.cardItem} elevation={2}>
            <CardContent>
              <Typography className={classes.cardHeading}>
                Audience List
              </Typography>
              <Box component="div" className={classes.detailBlock}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6} className="p0">
                    <Typography className={classes.cardSubHeading}>
                      Test
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} className="p0">
                    <Typography className={classes.cardSubHeading}>
                      Control
                    </Typography>
                  </Grid>
                </Grid>
                {collectionList?.map((i, index) => (
                  <Grid container spacing={3} key={index}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography className={classes.cardSubHeading}>
                        {i.collectionName} &nbsp;
                        {campData?.campDetails?.SEGMENTTYPE ===
                          "Reusable segments" && (
                          <>
                            {" "}
                            <Typography
                              component="span"
                              className={classes.duration}
                            >
                              {"Cohort Duration : "}
                              {i.collectionDuration}
                            </Typography>
                            {collectionList?.length > 0 && (
                              <Typography
                                component="span"
                                className={classes.durationArea}
                              >
                                {audData?.length > 1 &&
                                !sameFlightId &&
                                _.map(collectionList, "collectionDuration")
                                  .map(Number)
                                  .every((val, i, arr) => val === arr[0])
                                  ? format(campDetails.DATE, "MMM dd")
                                  : format(
                                      addDays(
                                        campDetails.DATE,
                                        calculateDuration(index)
                                      ),
                                      "MMM dd"
                                    )}
                                {" - "}

                                {audData?.length === 1
                                  ? format(campDetails?.ENDDATE, "MMM dd")
                                  : audData?.length > 1 &&
                                    campData?.campDetails?.DURATION >
                                      totalDuration &&
                                    sameFlightId
                                  ? format(
                                      subDays(
                                        campDetails?.ENDDATE,
                                        totalDuration -
                                          calculateDuration(index + 1)
                                      ),
                                      "MMM dd"
                                    )
                                  : audData?.length > 1 &&
                                    !sameFlightId &&
                                    _.map(collectionList, "collectionDuration")
                                      .map(Number)
                                      .every((val, i, arr) => val === arr[0])
                                  ? format(campDetails?.ENDDATE, "MMM dd")
                                  : format(
                                      addDays(
                                        campDetails.DATE,
                                        calculateDuration(index) +
                                          parseInt(i.collectionDuration) -
                                          1
                                      ),
                                      "MMM dd"
                                    )}
                              </Typography>
                            )}
                          </>
                        )}
                      </Typography>
                    </Grid>
                    {i.testControlMap.map((item, index) => (
                      <Fragment key={index}>
                        <Grid item xs={12} sm={12} md={6} className="p0">
                          <Box className={classes.audListItem}>
                            <Box component={"div"}>
                              <Typography>
                                <Typography component="span">
                                  {item.test_id} &nbsp;
                                </Typography>{" "}
                                {item.test_name}
                              </Typography>

                              <Typography>
                                {" "}
                                <PeopleAltOutlinedIcon fontSize={"small"} />
                                <Typography component="span">
                                  {" "}
                                  {item.test_count
                                    ? numberWithCommas(item.test_count)
                                    : ""}
                                </Typography>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} className="p0">
                          <Box className={classes.audListItem}>
                            <Box component={"div"}>
                              {item.control_id ? (
                                <>
                                  <Typography>
                                    <Typography component="span">
                                      {item.control_id} &nbsp;
                                    </Typography>{" "}
                                    {item.control_name}
                                  </Typography>

                                  <Typography>
                                    {" "}
                                    <PeopleAltOutlinedIcon fontSize={"small"} />
                                    <Typography component="span">
                                      {" "}
                                      {item.cust_cout
                                        ? numberWithCommas(item.cust_cout)
                                        : ""}
                                    </Typography>
                                  </Typography>
                                </>
                              ) : (
                                <Typography
                                  component="p"
                                  style={{ margin: "28px 0" }}
                                >
                                  {"No Linked Control"}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        </Grid>
                      </Fragment>
                    ))}
                  </Grid>
                ))}
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.cardItem} elevation={2}>
            <CardContent>
              <Typography className={classes.cardHeading}>
                Product group name
              </Typography>

              {selectPro.length ? (
                <div style={{ display: "flex" }}>
                  {selectPro?.map((p, index) => {
                    return (
                      <div key={index}>
                        <Chip
                          label={p.product_grp_name}
                          key={index}
                          style={{ margin: "2px 2px", padding: "2px 2px" }}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                "NA"
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default CampaignReview;
