import React, { useEffect, useState } from "react";
import CustomMaterialTable from "../Common/CustomMaterialTable";
import MultiSelectDropdown from "../Common/Filters/MultiSelectDropdown";
import CustomMultiSelectDropdown from "../Common/Filters/CustomMultiSelectDropdown";
import {
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Grid,
  Button,
  Box,
} from "@material-ui/core/";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import ExportCsv from "../Common/Utils/ExportCsv";
import secureAxios from "../../config/api-config";
import MeasureLineTrend from "./MeasureLineTrend";
import _ from "lodash";
import {
  numberWithCommas,
  roundToTwoDecimalPlaces,
} from "../Common/Utils/ClientUtil";
import { APIS } from "../../config";
import useOnline from "views/Common/OffOnline/useOnline";
import palette from "theme/palette";

import ModalLoading from "components/ModalLoading";
import { useParams } from "react-router";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import useStyles from "./style";

export default function CampaignEngagement(props) {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  //props
  const { outlierChecked } = props;
  //redux
  const appReduxState = useSelector((state) => ({
    ...state.drilldownDetail,
  }));
  const { engageFilters } = appReduxState;

  //define state
  const [selectedTblKpi, setSelectedTblKpi] = useState({
    id: "",
    name: "",
    type: "",
    label: "",
  });
  const [kpiDropdown, setKpiDropdown] = useState([]);
  const [touchBaseDropdown, setTouchBaseDropdown] = useState([]);
  const [flightDropDdown, setFlightDropdown] = useState([]);
  const [collectionDropDdown, setCollectionDropdown] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [msg, setMsg] = useState("Loading...");
  const [selectedKPIs, setSelectedKPIs] = useState([]);
  const [selectedTouchBase, setSelectedTouchBase] = useState([]);
  const [selectedFlight, setSelectedFlight] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState([]);
  let successfulApiCalls = 0;
  let apiCallCount = 0;
  //function check api reponse
  const handleApiSuccess = () => {
    successfulApiCalls++;
    // Check if both API calls have been successfully completed
    if (successfulApiCalls === apiCallCount) {
      // Close the loader
      dispatch({
        type: "MODALLOADING",
        payload: false,
      });
    }
  };

  //Table data for analysis peroid
  const columns = [
    {
      field: "KPI Name",
      colspan: 4,
      render: (rowData) => {
        return rowData.kpiLabel;
      },
    },

    {
      field: "COLLECTION",
      colspan: 4,
      render: (rowData) => {
        return rowData.collection_name;
      },
    },

    {
      field: "FLIGHT",
      colspan: 4,
      render: (rowData) => {
        return rowData.flight_id;
      },
    },

    {
      field: "TOUCHBASE",
      colspan: 4,
      render: (rowData) => {
        return rowData.touchBase;
      },
    },
    {
      field: "KPI Value",
      colspan: 4,
      render: (rowData) => {
        return rowData?.kpiValue === null
          ? "NA"
          : numberWithCommas(roundToTwoDecimalPlaces(rowData?.kpiValue));
      },
    },
    {
      field: "BENCHMARK",
      colspan: 4,
      render: (rowData) => {
        return rowData?.benchmark === null
          ? "NA"
          : roundToTwoDecimalPlaces(rowData?.benchmark);
      },
    },
    {
      field: "% Change",
      colspan: 4,
      render: (rowData) => {
        return rowData?.pctChange === null
          ? "NA"
          : roundToTwoDecimalPlaces(rowData?.pctChange);
      },
    },
  ];

  /**   *   * Api call*  */
  const getKpioptions = (val, status) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.KIPS, {
        outlierValue: outlierChecked ? true : false,
        filters: val,
        tabName: "CampaignEngagement",
        campaign_id: id,
      })
      .then((res) => {
        if (res.status === 200) {
          let resData = res.data.responseData;
          setKpiDropdown(resData);
          if (status) {
            setSelectedKPIs(resData);
          } else {
            let newSelected = resData.filter((array) =>
              val.selectedKpi.some((filter) => filter === array.kpiName)
            );
            setSelectedKPIs(newSelected);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally((err) => {
        handleApiSuccess();
      });
  };

  //Get flight data api call

  const getFlightoptions = (val, status) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.FLIGHT_ID, {
        outlierValue: outlierChecked ? true : false,
        filters: val,
        tabName: "CampaignEngagement",
        campaign_id: id,
      })
      .then((res) => {
        if (res.status === 200) {
          let resData = res?.data?.responseData;
          setFlightDropdown(resData);
          if (status) {
            let all = resData.filter((item) => {
              return item === "ALL";
            });
            setSelectedFlight(all);
          } else {
            let newSelected = val?.flight_id?.filter((item) =>
              resData.includes(item)
            );
            setSelectedFlight(newSelected);
          }
        }
      })
      .catch((err) => {})
      .finally((err) => {
        handleApiSuccess();
      });
  };

  //Apicall for get collection data
  const getCollectionoptions = (val, status) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.COLLECTION, {
        outlierValue: outlierChecked ? true : false,
        filters: val,
        tabName: "CampaignEngagement",
        campaign_id: id,
      })
      .then((res) => {
        if (res.status === 200) {
          let resData = res?.data?.responseData;
          setCollectionDropdown(resData);
          if (status) {
            let all = resData.filter((item) => {
              return item === "ALL";
            });
            setSelectedCollection(all);
          } else {
            let newSelected = val?.collection?.filter((item) =>
              resData.includes(item)
            );
            setSelectedCollection(newSelected);
          }
        }
      })
      .catch((err) => {})
      .finally((err) => {
        handleApiSuccess();
      });
  };

  const getTouchBaseoptions = (val, status) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.TOUCH_BASE, {
        outlierValue: outlierChecked ? true : false,
        filters: val,
        tabName: "CampaignEngagement",
        campaign_id: id,
      })
      .then((res) => {
        if (res.status === 200) {
          let resData = res?.data?.responseData;
          setTouchBaseDropdown(resData);
          if (status) {
            setSelectedTouchBase(resData);
          } else {
            let newSelected = val?.touchBase?.filter((item) =>
              resData.includes(item)
            );
            setSelectedTouchBase(newSelected);
          }
        }
      })
      .catch((err) => {})
      .finally((err) => {
        handleApiSuccess();
      });
  };
  const fetchTblData = (viewStatus, val) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.KPI_DATA, {
        outlierValue: outlierChecked ? true : false,
        filters: val,
        campaign_id: id,
        tabName: "CampaignEngagement",
      })
      .then(function(response) {
        if (response.status === 200) {
          if (!response?.data?.responseData?.length) {
            setMsg("No records to display");
          }
          setTblData(response?.data?.responseData);

          
            if (viewStatus === "firstLoad") {
              setSelectedTblKpi({
                ...selectedTblKpi,
                id: response?.data?.responseData[0]["kpiRowId"],
                name: response?.data?.responseData[0]["kpi"],
                type: response?.data?.responseData[0]["kpiType"],
                label: response?.data?.responseData[0]["kpiLabel"],
              });
            }
            fetchGraphData(response?.data?.responseData[0]["kpiRowId"]);
          
        }
      })
      .catch((err) => {})
      .finally((err) => {
        handleApiSuccess();
      });
  };
  const fetchGraphData = (val) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.GRAPH_DATA, {
        campaign_id: id,
        tabName: "CampaignEngagement",
        selectedKpiRowId: val,
        outlierValue: outlierChecked ? true : false,
      })
      .then(function(response) {
        if (response.status === 200) {
          setGraphData(response?.data?.responseData);
        }
      })
      .catch((err) => {})
      .finally((err) => {
        handleApiSuccess();
      });
  };

  useEffect(() => {
    getKpioptions(engageFilters, "firstLoad");
    getFlightoptions(engageFilters, "firstLoad");
    getCollectionoptions(engageFilters, "firstLoad");
    getTouchBaseoptions(engageFilters, "firstLoad");
    fetchTblData("firstLoad", {
      selectedKpi: [],
      brands: [],
      channel: [],
      region: ["ALL"],
      device: [],
      touchBase: [],
      collection: ["ALL"],
      flight_id: ["ALL"],
    });
  }, [outlierChecked]);

  const isOnline = useOnline();
  if (!isOnline) {
    return <h1>Offline</h1>;
  }

  //file download
  const newArr = [];
  tblData.forEach((x, i) => {
    const campaignEngagementData = {
      Sno: `${i + 1}`,
      Metrics: `${x.kpiLabel}`,
      Collection: `${x.collection_name}`,
      Flight: `${x.flight_id}`,
      TouchBase: `${x.touchBase}`,
      "KPI Value": `${x.kpiValue?.toFixed(2)}`,
      Benchmark: `${x.benchmark === null ? "NA" : x.benchmark?.toFixed(2)}`,
      "% Change": `${x.pctChange === null ? "NA" : x.pctChange?.toFixed(2)}`,
    };

    newArr.push(campaignEngagementData);
  });

  return (
    <>
      <ModalLoading />
      <Box component="div" className={classes.filterAreaDropDown}>
        <Formik
          initialValues={{
            KPI: selectedKPIs,
            TOUCHBASE: selectedTouchBase,
            FLIGHT: selectedFlight,
            COLLECTION: selectedCollection,
          }}
          enableReinitialize={true}
          onSubmit={(values) => {
            dispatch({
              type: "MODALLOADING",
              payload: true,
            });
            fetchTblData("firstLoad", {
              selectedKpi: _.map(selectedKPIs, "kpiName"),
              region: ["ALL"],
              touchBase: selectedTouchBase,
              flight_id: selectedFlight,
              collection: selectedCollection,
              brands: [],
              device: [],
            });
          }}
        >
          {({ values, setFieldValue, resetForm }) => (
            <Form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <CustomMultiSelectDropdown
                    width={"w100"}
                    label={"KPIs"}
                    name={"KPI"}
                    value={values.KPI}
                    handleMethod={(value) => {
                      setFieldValue("KPI", value);
                      setSelectedKPIs(value);
                    }}
                    options={kpiDropdown}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                  {/* touchBase */}
                  <MultiSelectDropdown
                    width={"w100"}
                    label={"Touch Base"}
                    name={"TOUCHBASE"}
                    value={values.TOUCHBASE}
                    handleMethod={(value) => {
                      setFieldValue("TOUCHBASE", value);
                      setSelectedTouchBase(value);
                    }}
                    options={touchBaseDropdown}
                  />
                </Grid>

                {/* select dropdown for collection */}

                <Grid item xs={12} sm={6} md={2}>
                  <MultiSelectDropdown
                    width={"w100"}
                    label={"Collection"}
                    name={"COLLECTION"}
                    value={values.COLLECTION}
                    handleMethod={(value) => {
                      setFieldValue("COLLECTION", value);
                      setSelectedCollection(value);
                      getFlightoptions({
                        selectedKpi: _.map(selectedKPIs, "kpiName"),
                        brands: [],
                        channel: [],
                        region: ["ALL"],
                        device: [],
                        collection: value,
                        touchBase: selectedTouchBase,
                        flight_id: selectedFlight,
                      });
                    }}
                    options={collectionDropDdown}
                  />
                </Grid>

                {/* select dropdown for Flight */}

                <Grid item xs={12} sm={6} md={2}>
                  <MultiSelectDropdown
                    width={"w100"}
                    label={"Flight"}
                    name={"FLIGHT"}
                    value={values.FLIGHT}
                    handleMethod={(value) => {
                      setFieldValue("FLIGHT", value);
                      setSelectedFlight(value);
                    }}
                    options={flightDropDdown}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={1}>
                  <Button className={classes.buttonFilter} type="submit">
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={1}>
                  <div className={classes.innerContainer}>
                    <RotateLeftIcon
                      color="primary"
                      className={classes.resetBtn}
                      onClick={() => {
                        dispatch({
                          type: "MODALLOADING",
                          payload: true,
                        });
                        resetForm();
                        getKpioptions(engageFilters, "firstLoad");
                        // getRegionoptions(engageFilters, "firstLoad");
                        getFlightoptions(engageFilters, "firstLoad");
                        getTouchBaseoptions(engageFilters, "firstLoad");
                        getCollectionoptions(engageFilters, "firstLoad");
                        fetchTblData("firstLoad", {
                          selectedKpi: [],
                          brands: [],
                          channel: [],
                          region: ["ALL"],
                          device: [],
                          // touchBase: [],
                          collection: ["ALL"],
                          flight_id: ["ALL"],
                        });
                      }}
                    />
                    <ExportCsv
                      tableData={newArr}
                      fileName={`campaign_engagement_${id}`}
                      style={{
                        verticalAlign: "top",
                        margin: "10px 8px 8px 0",
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
      {tblData?.length ? (
        <>
          <>
            {graphData?.hasOwnProperty("kpiData") ? (
              <MeasureLineTrend
                selectedKPI={selectedTblKpi}
                graphData={graphData}
                view={"CampaignEngagement"}
              ></MeasureLineTrend>
            ) : (
              <div style={{ textAlign: "center", margin: "50px" }}>
                {graphData === "no data" ? "" : "...Loading Data for Graphs"}
              </div>
            )}
          </>
              {/* table */}
          <CustomMaterialTable
            title=""
            columns={columns}
            data={tblData}
            onRowClick={(event, rowData) => {
              dispatch({
                type: "MODALLOADING",
                payload: true,
              });
              setSelectedTblKpi({
                ...selectedTblKpi,
                id: rowData.kpiRowId,
                name: rowData.kpi,
                type: rowData.kpiType,
                label: rowData.kpiLabel,
              });
              fetchGraphData(rowData.kpiRowId);
            }}
            options={{
              sorting: true,
              search: false,
              toolbar: false,
              paging: true,
              pageSize: 5,
              rowStyle: (rowData) => {
                return {
                  color:
                    selectedTblKpi.id === rowData.kpiRowId
                      ? palette.tableColors.bodyText
                      : "inherit",
                  cursor: "pointer",
                  backgroundColor:
                    selectedTblKpi.id === rowData.kpiRowId
                      ? palette.tableColors.selectedBackground
                      : "inherit",
                };
              },
            }}
            components={{
              Container: (props) => (
                <Box component="div" className="tblScroll">
                  {props.children}
                </Box>
              ),
              Header: () => {
                return (
                  <TableHead className={classes.tableRightBorder}>
                    <TableRow>
                      <TableCell rowSpan={2}>KPI Name</TableCell>
                      <TableCell rowSpan={2}>Collection</TableCell>
                      <TableCell rowSpan={2}>Flight</TableCell>
                      <TableCell rowSpan={2}>Touch Base</TableCell>
                      <TableCell rowSpan={2}>KPI Value</TableCell>
                      <TableCell rowSpan={2}>Benchmark</TableCell>
                      <TableCell rowSpan={2}>% Change</TableCell>
                    </TableRow>
                  </TableHead>
                );
              },
            }}
          ></CustomMaterialTable>
        </>
      ) : (
        <Grid item xs={12}>
          <Typography
            component="h3"
            variant="subtitle2"
            className={classes.info}
          >
            {msg}
          </Typography>
        </Grid>
      )}
    </>
  );
}
