import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography } from '@material-ui/core';
import { ErrorMessage } from 'formik';

const useStyles = makeStyles(theme => ({
  autoComplete: {
    margin: theme.spacing(0),
    width: 'auto',
    "& input": {
      padding: "0 !important"
    }
  },
  errorText: {
    color: 'red',
    fontSize: 12
  }
}));

function AutocompleteDropdown(props) {
  const classes = useStyles();
  const { value, label, name, options, handleMethod } = props;
  const [inputValue, setInputValue] = useState('');

  useEffect(() => { }, props);

  return (
    <>
      <Autocomplete
        multiple
        className={classes.autoComplete}
        name={name}
        value={value}
        getOptionLabel={options => (options ? options : options)}
        onChange={(event, newValue) => handleMethod(newValue)}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        id="controllable-states-demo"
        options={options}
        renderInput={params => (
          <TextField {...params} label={label} variant="outlined" />
        )}
      />
      <Typography className={classes.errorText}>
        <ErrorMessage name={name} />
      </Typography>
    </>
  );
}

export default AutocompleteDropdown;
