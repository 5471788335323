import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Box,
  Grid,
  TableHead,
  TableCell,
  TableRow,
  Typography,
 
} from "@material-ui/core";
import CustomMaterialTable from "../Common/CustomMaterialTable";
import DisplayContainer from "../Common/Utils/DisplayContainer";
import {  APIS } from "../../config";
import secureAxios from "../../config/api-config";
import ReactBreadcrumbs from "views/Common/Breadcrumb/Breadcrumb";
import CreateGroupForm from "./createGroupForm";
import { getBrands } from "redux/dashboard/dashBoardRedux";
import { useDispatch } from "react-redux";
import ConfirmDelete from "components/confirm";
import EditGroupForm from "./editGroupForm";
import ViewGroup from "./viewGroup";
import MessageDialog from "views/Common/MessageDialog/MessageDialog";
import useStyles from './style'

const ProductGroup = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [groups, setGroups] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [viewData, setViewData] = useState(null);
  const [groupData, setGroupData] = useState({});
  const [message, setMessage] = useState("");
  const [opens, setOpens] = useState(false);
  const [type, setType] = useState("");
  const [productsInitial, setProductsInitial] = useState({});
  

  useEffect(() => {
    fetchData();
    fetchProducts();
  }, []);

  const fetchProducts = () => {
    dispatch({
      type: "LOADING",
      payload: true,
    });
    secureAxios
      .get(APIS.MASTER.PRODUCTS)
      .then((res) => {
        if (res.status === 200) {
          setProductsInitial(res.data.data);

          dispatch({
            type: "LOADING",
            payload: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "LOADING",
          payload: false,
        });
      });
  };

  const deleteProductGroup = (row) => {
    const index = groups.findIndex(
      (g) => g.product_grp_id === row.product_grp_id
    );
    groups.splice(index, 1);
    setGroups([...groups]);
    secureAxios
      .delete(APIS.MASTER.PRODUCT_GROUPS, { data: { id: row.product_grp_id } })
      .then((res) => {
        if (res.status === 200) {
          // fetchData();
        }
      });
  };

  const editProductGroup = (id) => {
    dispatch({
      type: "LOADING",
      payload: true,
    });
    setEditMode(true);
    setGroupData(null);
    secureAxios
      .get(APIS.MASTER.PRODUCT_GROUPS + `/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setGroupData(res.data);

          dispatch({
            type: "LOADING",
            payload: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "LOADING",
          payload: false,
        });
      });
  };

  const columns = [
    {
      title: "Name",
      field: "product_grp_name",
      render: (rowData) => {
        return rowData.product_grp_name;
      },
      customFilterAndSearch: (term, rowData) =>
        rowData.product_grp_name.toLowerCase().indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Category",
      field: "prd_grp_catg",
      render: (rowData) => (
        <Typography title={rowData.product_cnt}>
          {rowData.prd_grp_catg}
        </Typography>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.prd_grp_catg.toLowerCase().indexOf(term.toLowerCase()) > -1,
    },
    {
      title: "Action",
      field: "Action",
      filtering: false,
      render: (rowData) => (
        <Box component={"div"} className={classes.action_group}>
          <Box
            component={"div"}
            className={classes.action_view}
            onClick={() => setViewData(rowData)}
          >
            View
          </Box>
       
            <Box
              component={"div"}
              className={classes.action_edit}
              onClick={() => editProductGroup(rowData.product_grp_id)}
            >
              Edit
            </Box>
          
          
            <ConfirmDelete
              onDelete={() => {
                deleteProductGroup(rowData);
              }}
            ></ConfirmDelete>
          
        </Box>
      ),
    },
  ];

  const fetchData = () => {
    dispatch({
      type: "LOADING",
      payload: true,
    });
    secureAxios
      .get(APIS.MASTER.PRODUCT_GROUPS)
      .then((res) => {
        if (res.status === 200) {
          setGroups(res.data);
          dispatch({
            type: "LOADING",
            payload: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "LOADING",
          payload: false,
        });
      });
  };
  // clear barnds values
  useLayoutEffect(() => {
    dispatch(getBrands(""));
  }, []);
  //close the popup
  const handleClose = () => {
    setOpens(false);
  };
  const closeViewData = () => {
    setViewData(null);
    // setProductsInitial(null)
    window.location.reload();
  };
  return (
    <>
      <MessageDialog
        type={type}
        open={opens}
        message={message}
        close={handleClose}
      />
      <Box style={{ background: "#F8F8F8", marginTop: "-1%", height: "100%" }}>
        <Box component={"div"} style={{ padding: "1% 0% 1% 2%" }}>
          <ReactBreadcrumbs />
        </Box>
        <DisplayContainer
          // title={"Product Groups"}
          content={
            <Box component="div" container p={1}>
              <Grid container spacing={3}>
                <Grid
                  item
                  md={6}
                >
                  
                    <CustomMaterialTable
                      title=""
                      columns={columns}
                      data={groups}
                      options={{
                        sorting: true,
                        search: false,
                        toolbar: false,
                        paging: true,
                        pageSize: 10,
                        filtering: true,
                        maxBodyHeight: 275,
                        position: "sticky",
                      }}
                      components={{
                        Container: (props) => (
                          <Box component="div" className="tblScroll">
                            {props.children}
                          </Box>
                        ),
                        Header: () => {
                          return (
                            <TableHead className={classes.tableRightBorder}>
                              <TableRow>
                                <TableCell rowSpan={2}>Name</TableCell>

                                <TableCell rowSpan={2}>Category</TableCell>
                                <TableCell rowSpan={2}>Action</TableCell>
                              </TableRow>
                            </TableHead>
                          );
                        },
                      }}
                    ></CustomMaterialTable>
                  
                </Grid>
                <Grid item md={6}>
                  {!(editMode && groupData) &&
                   
                      <DisplayContainer
                        content={
                          <CreateGroupForm
                            onAdd={() => fetchData()}
                            initialProducts={productsInitial}
                          />
                        }
                        containerClass={useStyles.formBox}
                        contentRight={<></>}
                      />
                    }

                  {editMode &&
                    groupData &&
                  
                      <DisplayContainer
                        content={
                          <EditGroupForm
                            onAdd={() => {
                              setOpens(true);
                              setType("success");
                              setMessage("Updated successfully");
                              fetchData();
                            }}
                            isEdit={editMode}
                            data={groupData}
                            initialProducts={productsInitial}
                            cancelEdit={() => {
                              setEditMode(false);
                            }}
                          />
                        }
                        containerClass={useStyles.formBox}
                        contentRight={<></>}
                      />
                    }
                </Grid>
              </Grid>
            </Box>
          }
          contentRight={<></>}
        />
      </Box>
      {viewData &&
       
          <ViewGroup
            data={viewData}
            onClose={closeViewData}
            productsList={productsInitial}
          />
        }
    </>
  );
};

export default ProductGroup;
