import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import secureAxios from "../../../../config/api-config";
import DialogBox from "../../../Common/Modals/Dailog";
import TimelineChart from "../../../Common/Charts/TimelineChart";
import DateRange from "../../../Common/Filters/DateRange";
import {
  fetchDate,
  getBrands,
} from "../../../../redux/dashboard/dashBoardRedux";
import { useSelector, useDispatch } from "react-redux";
import Moment from "moment";
import { Form, Formik } from "formik";
import { APIS } from "../../../../config";
import SingleSelectDropdown from "views/Common/Filters/SingleSelectDropdown";
import _ from "lodash";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { updateDeepdiveTabNavigation } from "redux/measure/measureLandingRedux";
import useStyles from './Style'


export default function ViewTimeLine(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [brands, setBrands] = React.useState(["No brands"]);
  const [selectedBrands] = React.useState("TOT");
  const [dateValues, setDateValues] = React.useState([]);
  const [timelineData, setTimelineData] = React.useState([]);
  const [reset] = React.useState(false);
  const dashBoardData = useSelector((state) => state?.dashBoardDetail);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await secureAxios.get(APIS.DESIGN_CAMPAIGN.TIMELINE).then((res) => {
        if (res.status === 200) {
          setTimelineData(res.data);
        }
      });
      await secureAxios.get(APIS.DESIGN_CAMPAIGN.BRANDS).then((res) => {
        if (res.status === 200) {
          const data = _.filter(res?.data?.responseData, (v) => v !== null);
          setBrands(data);
        }
      });
    };

    fetchData().catch((err) => console.log(err));
  }, [selectedBrands, reset]);

  useEffect(() => {
    setDateValues(dashBoardData?.dateRange);
  }, [dashBoardData]);

  function viewTimeLineHandler() {
    setOpen((prev) => !open);
  }
  useEffect(() => {
    dispatch(updateDeepdiveTabNavigation(0))
  }, [])

  return (
    <Grid content className={classes.timelineChart}>
      <Grid
        item
        md={3}
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <Typography
          align="Right"
          className={classes.viewTimelineText}
          onClick={viewTimeLineHandler}
        >
          Campaign Calendar
        </Typography>
      </Grid>
      <Grid item md={8}>
        <Formik
          initialValues={{
            DATERANGE: [
              Moment(
                dateValues[0]
                  ? dateValues[0].startPeriod
                  : dateValues?.startPeriod
              ).toDate(),
              Moment(
                dateValues[0]
                  ? dateValues[0].endPeriod
                  : dateValues?.endPeriod
              ).toDate(),
            ],
            BRANDS: [],
          }}
          enableReinitialize={true}
          onSubmit={(values) => {
          }}
        >
          {({
            values,

            setFieldValue,
          }) => (
            <Form
              noValidate
              autoComplete="off"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <Box
                component="div"
                style={{
                  width: "200px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <SingleSelectDropdown
                  width={"w100"}
                  value={values.BRANDS}
                  label={"Select Brands"}
                  name={"BRANDS"}
                  handleMethod={(value) => {
                    setFieldValue("BRANDS", value?.target?.value);
                    dispatch(getBrands(value?.target?.value));
                  }}
                  options={brands}
                  noneRequired={false}
                /> */}
              </Box>
              <Box component="div" className={classes.dateRangeArea}>
                <DateRange
                  name={"DATERANGE"}
                  label={
                    <p className={classes.dateRangeLabel}>Select Period​</p>
                  }
                  placeHolder="Select Period​"
                  value={values.DATERANGE}
                  handleMethod={(name, value) => {
                    setFieldValue("DATERANGE", value);
                    setDateValues({
                      startPeriod: value[0],
                      endPeriod: value[1],
                    });
                    dispatch(
                      fetchDate([
                        {
                          startPeriod: Moment(value[0]).format("YYYY-MM-DD"),
                          endPeriod: Moment(value[1]).format("YYYY-MM-DD"),
                        },
                      ])
                    );
                  }}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid item md={1}>
        <DialogBox
          open={open}
          handleClose={viewTimeLineHandler}
          width={340}
          header={"Active Campaign"}
          children={
            timelineData?.length === 0 ? (
              <div className={classes.timelineContainer}>
                <Typography>No active campaign found</Typography>
              </div>
            ) : (
              <TimelineChart data={timelineData ? timelineData : []} />
            )
          }
        ></DialogBox>
        <Box
          variant="container"
          className={classes.Reset}
          onClick={() => {
            window.location.reload();
          }}>
          <RotateLeftIcon fontSize="small" />
        </Box>
      </Grid>
    </Grid>
  );
}
