import React, { useEffect, useState } from 'react';
import { UserAgentApplication } from 'msal';
import { getUserDetails } from './GraphService';
import config from '../azure-configs/azureConfig';
import SignInSide from 'views/Login/Login';

const AuthProvider = props => {
 
  const userAgentApplication = new UserAgentApplication({
    auth: {
      clientId: config.appId,
      redirectUri: config.redirectUri,
      postLogoutRedirectUri: config.postLogoutRedirectUri
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true
    }
  });


  const [loginState, setLoginState] = useState({
    isAuthenticated: false,
    user: {},
    error: null
  });

  const login = async () => {
    try {
      await userAgentApplication.loginRedirect({
        scopes: config.scopes,
        prompt: 'select_account'
      });
      //this will redirect to login page
      //await userAgentApplication.loginRedirect();
      await getUserProfile();
    } catch (err) {
      let error = {};

      if (typeof err === 'string') {
        let errParts = err.split('|');
        error =
          errParts.length > 1
            ? { message: errParts[1], debug: errParts[0] }
            : { message: err };
      } else {
        error = {
          message: err.message,
          debug: JSON.stringify(err)
        };
      }
      setLoginState({
        isAuthenticated: false,
        user: {},
        error: error
      });
    }
  };

  const getUserProfile = async () => {
    try {
      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token

      let accessToken = await userAgentApplication.acquireTokenSilent({
        scopes: config.scopes
      });

      if (accessToken) {
        // Get the user's profile from Graph
        let user = await getUserDetails(accessToken);
        localStorage.setItem("userName", user.displayName);
        localStorage.setItem('accessToken', accessToken.accessToken);
        setLoginState({
          isAuthenticated: true,
          user: {
            displayName: user.displayName,
            email: user.mail || user.userPrincipalName,
            givenName: user.givenName,
            surname: user.surname
          },
          error: null
        });
       
      }
    } catch (err) {
      let error = {};
      if (typeof err === 'string') {
        let errParts = err.split('|');
        error =
          errParts.length > 1
            ? { message: errParts[1], debug: errParts[0] }
            : { message: err };
      } else {
        error = {
          message: err.message,
          debug: JSON.stringify(err)
        };
      }
      setLoginState({
        isAuthenticated: false,
        user: {},
        error: error
      });
    }
  };

  useEffect(() => {
    if (localStorage?.getItem('accessToken')?.length > 0){
      setLoginState({...loginState,isAuthenticated: true})
      return
    }
    let user = userAgentApplication.getAccount();

    if (user) {
      // Enhance user object with data from Graph
      getUserProfile();
    }
    // else {
    //   login();
    // }
  }, []);

  return <div>{loginState.isAuthenticated ? props.children : <SignInSide handleLogin={login} />}</div>;

};

export default AuthProvider;
