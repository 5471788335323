

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Grid } from "@material-ui/core";
import StackedBar from "../Common/Charts/HorizontalStackedBarChart";
import PieChart from "../Common/Charts/PieChart";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import BarGroup from "../Common/Charts/BarGroup";
import DialogBox from "../Common/Modals/Dailog";
import ProgressBar from "../Common/Charts/ProgressBar";
import { toMetricFormat } from "../Common/Utils/ClientUtil";
import retriveMetricFromStr from "../Common/Utils/ClientUtil";

import MeasureStackedChart from "./MeasureStackedChart";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  block: {
    margin: "10px !important",
    width: 340,
    height: 220,
    textAlign: "left",
    boxSizing: "border-box",
    boxShadow: "2.73577px 3.6477px 11.855px rgb(0 0 0 / 8%)",
    borderRadius: 6.38347,
    background: "#ffffff",
    position: "relative",
    padding: 20,
    "& h4": { color: "#333333", marginBottom: 10 },
    "& h5": { color: "#333333", minHeight: 55 },
  },
  value: { color: "#24A148" },
  highlight: { color: "#8E44AD" },
  chartValues: {
    marginTop: 10,
  },
  valBM: { color: "#182C52", marginRight: 30 },
  info: {
    padding: "40px 0 !important",
    textAlign: "center",
    "& h3": {
      lineHeight: 10,
    },
  },
  sideBlock: {
    textAlign: "center",
    "& h4": {
      color: "#333333",
      marginBottom: 10,
      position: "relative",
      textTransform: "capitalize",
      "& svg": {
        position: "absolute",
        right: 0,
        cursor: "pointer",
      },
    },
    "& h5": { color: "#24A148", margin: "15px 0" },
    "& h6": {
      color: "#182C52",
      marginBottom: 50,
    },
  },
  cardBlockSvgRed: {
    "& svg": {
      color: "#ffffff",
      borderRadius: 2,
      padding: 2,
      fontSize: 30,
      background: "#E01F29",
    },
  },
  cardBlockSvgGreen: {
    "& svg": {
      color: "#ffffff",
      borderRadius: 2,
      padding: 2,
      fontSize: 30,
      background: "#24A148",
    },
  },
  cardBlockLiftRed: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    padding: 5,
    background: "#FBE8E9",
    color: "#E01F29",
    justifyContent: "center",
    "& span": {
      color: "#E01F29",
    },
  },
  cardBlockLiftGreen: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    padding: 5,
    background: "#EAF6ED",
    justifyContent: "center",
    color: "#24A148",
    "& span": {
      color: "#24A148",
    },
  },
  cardBlockVal: {
    "& h4": {
      display: "inline-block",
      lineHeight: 1.5,
    },
    "& span": {
      display: "inline-block",
      padding: "0 0 0 10px",
    },
  },
  infoArea: {
    textAlign: "center",
    marginBottom: 25,
    "& svg": {
      color: "#182C52",
      verticalAlign: "sub",
      marginRight: 10,
    },
  },
  testControl: { display: "table", width: "100%" },
  testControlLabel: {
    width: "60px",
    float: "left",
    paddingTop: "29px",
    color: "#000",
    fontWeight: "500",
  },
  testConrolBar: { width: "calc(100% - 60px)", float: "left" },
  hints: {
    position: "absolute",
    top: 7,
    right: 15,
  },
  hint: {
    float: "left",
    width: "auto",
    marginLeft: "15px",
  },
  circle: {
    width: "12px",
    height: "12px",
    borderRadius: "12px",
    float: "left",
    position: "relative",
  },
  circleHalf: {
    width: "6px",
    height: "12px",
    borderTopLeftRadius: "12px",
    borderBottomLeftRadius: "12px",
    float: "left",
    position: "absolute",
    left: 0,
    top: 0,
    background: "#e01f29",
  },
  text: {
    width: "auto",
    float: "left",
    marginLeft: "5px",
    color: "#777",
    lineHeight: "12px",
    fontSize: "11px",
  },
}));

export default function ExpandDialog(props) {
  const classes = useStyles();
  const { data, testControlChecked } = props;
  // console.log(data,"data");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const handleDialogClose = () => {
    setDialogOpen(false);
    setDialogData("");
  };
  const handleDialogOpen = (val) => {
    setDialogOpen(true);
    setDialogData(val);
  };
  var order = [
    "Region",
    "Product Group",
    "Flight Details",
    "Brand",
    "Division",
    "Dept",
    "Fulfillment Channel",
  ];
  let segmentData = [];
  let finalDataset = _.reject(data.val.segmentArr, ["segmentName", "Region"]);
  let sortedData = _.sortBy(finalDataset, function(obj) {
    return _.indexOf(order, obj.segmentName);
  });
  if (data.view === "purchaseBehavior") {
    segmentData = sortedData;
  } else {
    segmentData = data.val.segmentArr;
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={5} style={{ marginTop: 25 }}>
          {data.val.kpiType === "abs" || data.val.kpiType === "int" ? (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                  <Grid item xs={6}>
                    {data.val.lift < 0 ? (
                      <Typography
                        component="span"
                        variant="subtitle2"
                        className={classes.cardBlockLiftRed}
                      >
                        <ArrowDropDownIcon />
                        <Typography component="span" variant="subtitle2">
                          {`${data.val.lift}     %Lift`}
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography
                        component="span"
                        variant="subtitle2"
                        className={classes.cardBlockLiftGreen}
                      >
                        <ArrowDropUpIcon />
                        <Typography component="span" variant="subtitle2">
                          {`${data.val.lift}     %Lift`}
                        </Typography>
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    {data.val.absLift < 0 || data.val.absLift === null ? (
                      <Typography
                        component="span"
                        variant="subtitle2"
                        className={classes.cardBlockLiftRed}
                      >
                        <ArrowDropDownIcon />
                        <Typography component="span" variant="subtitle2">
                          {`${
                            data.val.absLift ? data.val.absLift : "NA"
                          } Abs lift`}
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography
                        component="span"
                        variant="subtitle2"
                        className={classes.cardBlockLiftGreen}
                      >
                        <ArrowDropUpIcon />
                        <Typography component="span" variant="subtitle2">
                          {`${
                            data.val.absLift ? data.val.absLift : "NA"
                          }     Abs lift`}
                        </Typography>
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              
              {
                !testControlChecked && 
                <>
                <Typography
                component="h6"
                variant="subtitle1"
                style={{ marginLeft: "15px" }}
              >
                Test Details
              </Typography>
              <Grid item xs={12}>
                <BarGroup
                  data={[
                    { name: "Pre", data: [data.val.benchmark] },
                    { name: "Post", data: [data.val.testAverage] },
                  ]}
                  xAxis={["Pre", "Post"]}
                  kpiName={data.val.kpiName}
                />
              </Grid>
                </>
              }

             {
              testControlChecked && data.view === "purchaseBehavior" && 
              <>
               <Typography
                component="h6"
                variant="subtitle1"
                style={{ marginTop: "20px", marginLeft: "15px" }}
              >
                Test /Control Post details
              </Typography>
              <Grid item xs={12}>
                <BarGroup
                  data={[
                    { name: "Test Post", data: [data.val.testAverage] },
                    { name: "Control Post", data: [data.val.controlPost] },
                  ]}
                  xAxis={["Test Post", "Control Post"]}
                  kpiName={data.val.kpiName}
                />
              </Grid>
              </>
             }
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  component="h5"
                  variant="subtitle1"
                  style={{
                    color:
                      data.val.benchmark < data.val.testAverage
                        ? "#24A148"
                        : "#E01F29",
                  }}
                >
                  {data.val.comment}
                </Typography>
              </Grid>
              <Typography
                component="div"
                variant="subtitle1"
                style={{ marginLeft: "15px", width: "100%" }}
              >
                Test Details
              </Typography>

              <Box
                component="div"
                className={classes.sideBlock}
                style={{ textAlign: "left", marginLeft: "15px" }}
              >
                <Typography component="h6" variant="subtitle1">
                  {data.view !== "purchaseBehavior" ? "Benchmark : " : "Pre : "}
                  {/* {data.val.benchmark ? data.val.benchmark.toFixed(2) : ""} */}
                  {data.val.benchmark
                    ? toMetricFormat(data.val.benchmark, data.val.kpiName)
                    : ""}
                </Typography>
              </Box>

              <Grid item xs={12}>
                <PieChart
                  height={200}
                  data={
                    data.val.testAverage > data.val.benchmark
                      ? [
                          data.val.benchmark,
                          +(data.val.testAverage - data.val.benchmark)?.toFixed(
                            2
                          ),
                        ]
                      : [
                          data.val.testAverage,
                          +(data.val.benchmark - data.val.testAverage)?.toFixed(
                            2
                          ),
                        ]
                  }
                  // testAvg={data.val.testAverage}
                  testAvg={toMetricFormat(
                    data.val.testAverage,
                    data.val.kpiName
                  )}
                  labels={[
                    data.val.testAverage > data.val.benchmark
                      ? "Benchmark"
                      : "Test Avg",
                    "%Change",
                   
                  ]}
                  colors={
                    data.val.testAverage > data.val.benchmark
                      ? ["#013598", "#24A148", "#EAEAEA"]
                      : ["#E01F29", "#013598", "#EAEAEA"]
                  }
                  sparkline={false}
                />
              </Grid>

             {
              testControlChecked && data.view === "purchaseBehavior" && 
              <Grid item xs={12}>
              <Typography
                component="div"
                variant="subtitle1"
                style={{
                  marginTop: "20px",
                  marginLeft: "15px",
                  width: "100%",
                }}
              >
                Control Details
              </Typography>
              <Box
                component="div"
                className={classes.sideBlock}
                style={{ textAlign: "left", marginLeft: "15px" }}
              >
                <Typography component="h6" variant="subtitle1">
                  {/* Control Pre:{" "}
                  {data.val.controlPre
                    ? toMetricFormat(data.val.controlPost, data.val.kpiName)
                    : ""}{" "} */}
                  Control / Post:{" "}
                  {data.val.controlPost
                    ? toMetricFormat(data.val.controlPost, data.val.kpiName)
                    : ""}
                </Typography>
              </Box>

              <PieChart
                height={200}
                data={
                  data.val.controlPost > data.val.controlPre
                    ? [
                        
                        data.val.controlPost,
                        +(data.val.controlPost - data.val.controlPre)?.toFixed(
                          2
                        ),
                      ]
                    : [
                        
                        data.val.controlPost,
                        +(data.val.controlPre - data.val.controlPost)?.toFixed(
                          2
                        ),
                      ]
                }
                // testAvg={data.val.testAverage}
                testAvg={toMetricFormat(
                  data.val.controlPost,
                  data.val.kpiName
                )}
                labels={["Post", "%Change"]}
                colors={
                  data.val.controlPost > data.val.controlPre
                    ? ["#013598", "#8f2eff", "#24A148", "#EAEAEA"]
                    : ["#013598", "#8f2eff", "#E01F29", "#EAEAEA"]
                }
                sparkline={false}
              />
            </Grid>
             }
            </Grid>
          )}
        </Grid>
        <Grid item xs={7}>
          <Grid container spacing={3}>
            {segmentData ? (
              segmentData?.map((item, index) => (
                <Grid item xs={6} key={index}>
                  <Box
                    component="div"
                    className={classes.block}
                    onClick={(e) => {
                      handleDialogOpen(item);
                    }}
                  >
                   
                    <Typography component="h4" variant="subtitle1">
                      {retriveMetricFromStr(item.segmentName)}
                    </Typography>
                    <Typography component="h5" variant="body2">
                    
                      {item.segmentComment === null
                        ? "NA"
                        : item.segmentComment}
                    
                    </Typography>

                  {testControlChecked && data.view ==="purchaseBehavior" ?
                    <>
                      <div className={classes.testControl}>
                      <div className={classes.testControlLabel}>Test</div>
                      <div className={classes.testConrolBar}>
                        <Typography
                          component="h6"
                          variant="body2"
                          className={classes.chartValues}
                        >
                          
                          <Typography
                            component="span"
                            style={{
                              color:
                                
                                "#24A148",
                              fontSize: "12px",
                            }}
                          >
                           
                            {item.testDuringValue !== null
                              ? toMetricFormat(
                                  item.testDuringValue,
                                  data.val.kpiName
                                )
                              : ""}
                          </Typography>
                        </Typography>
                        <StackedBar
                          data={item}
                          testControlChecked={testControlChecked}
                          viewStatus={data.view}
                          type={data.val.kpiType}
                          colors={
                            
                              ["#013598"]
                          }
                        />
                      </div>
                    </div>
                    </>
                  :
                  
                  <div className={classes.testControl}>
                  <div className={classes.testControlLabel}>Test</div>
                  <div className={classes.testConrolBar}>
                    <Typography
                      component="h6"
                      variant="body2"
                      className={classes.chartValues}
                    >
                      <Typography
                        component="span"
                        className={classes.valBM}
                        style={{ fontSize: "12px" }}
                      >
                        {data.view !== "purchaseBehavior"
                          ? "Benchmark : "
                          : "Pre : "}
                        {toMetricFormat(item.benchmark, data.val.kpiName)}
                        {/* {numberWithCommas(item.benchmark.toFixed(2))} */}
                      </Typography>
                      <Typography
                        component="span"
                        style={{
                          color:
                            item.testDuringValue < item.benchmark
                              ? "#E01F29"
                              : "#24A148",
                          fontSize: "12px",
                        }}
                      >
                       
                        {item.testDuringValue !== null
                          ? toMetricFormat(
                              item.testDuringValue,
                              data.val.kpiName
                            )
                          : ""}
                      </Typography>
                    </Typography>
                    <StackedBar
                      data={item}
                      // testControlChecked={testControlChecked}
                      viewStatus={data.view}
                      type={data.val.kpiType}
                      colors={
                        item.testDuringValue < item.benchmark
                          ? ["#013598", "#E01F29"]
                          : ["#013598", "#24A148"]
                      }
                    />
                  </div>
                </div>
                  }
                  {/* control */}
                    {
                      testControlChecked && data.view === "purchaseBehavior" &&
                      <>
                      <div>
                      <div className={classes.testControlLabel}>Control</div>
                      <div className={classes.testConrolBar}>
                        <Typography
                          component="h6"
                          variant="body2"
                          className={classes.chartValues}
                        >
                         
                          <Typography
                            component="span"
                            style={{
                             color: "#24A148",
                              fontSize: "12px",
                            }}
                          >
                            
                            {item.controlPost !== null
                              ? toMetricFormat(
                                  item.controlPost,
                                  data.val.kpiName
                                )
                              : ""}
                          </Typography>
                        </Typography>
                        <StackedBar
                          control={true}
                          testControlChecked={testControlChecked}
                          data={item}
                          viewStatus={data?.view}
                          type={data?.val?.kpiType}
                          colors={
                            ["#013598"]
                          }
                        />
                      </div>
                    </div>
                      </>
                    } 
                  </Box>
                </Grid>
              ))
            ) : (
              <Typography component="h3" variant="h3" className={classes.info}>
                {"No records to display"}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <DialogBox
        open={dialogOpen}
        handleClose={handleDialogClose}
        width={"medium"}
        header={
          dialogData?.segmentName
            ?`${ retriveMetricFromStr(dialogData?.segmentName)} (${data.val.kpiLabel})`
            : "NA"
        }
        children={
          <>
            {data?.view === "purchaseBehavior" &&
            dialogData.segmentName !== "Flight Details" ? (
              <MeasureStackedChart data={dialogData} />
            ) : (
              <ProgressBar data={dialogData} viewStatus={data.view} />
            )}
          </>
        }
      ></DialogBox>
    </>
  );
}
