import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

const RouteWithLayout = (props) => {
  const { layout: Layout, component: Component, ...rest } = props;

  // if( localStorage?.getItem('accessToken')?.length <0  ){
  //   return <SignInSide />
  // }

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        localStorage.getItem("accessToken")? (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        ) : (
          <Redirect from="/" to="/login" />
        )
      }
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default RouteWithLayout;
