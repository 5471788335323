export const BASE_URL_DEV = "http://localhost:8080";

export const BASE_URL_PROD =
  "";


export const PATHS = {
  LOGIN: "/login",
  AUTH: '/auth',
  MEASURE_CAMPAIGN: "/measure-campaign",
  REVIEW_CAMPAIGN: "/design-campaign/create-campaign/review-campaign",
  CREATE_CAMPAIGN: "/design-campaign/create-campaign",
  DESIGN_CAMPAIGN: "/design-campaign",
  PRODUCT_GROUP: "/design-campaign/product-group",
  PRODUCT_GROUP_CREATE: "/design-campaign/product-group/new",
};

export const APIS = {
  AUTH: {
    LOGIN: "/login",
    AUTH_DETAILS: "/login/getAuthDetails",
    TOKEN: "/login/getAuthToken",
    LOGOUT: "/login/getLogoutDetails"
  },
  DESIGN_CAMPAIGN: {
    DASHBOARD: "designCampaign/dashboard/dashboardDetail",
    DETAILS: "designCampaign/dashboard/getDashTableDetail",
    AUDIENCE_LIST: "campaign/getAudienceList",
    DESIGN_CAMPAIGN_LIST: "campaign/getDesignCampaignList",
    DATE_RANGE: "designCampaign/dashboard/getDashboardDateRange",
    TIMELINE: "designCampaign/dashboard/getDashboardTimeline",
    INSIGHT: "designCampaign/dashboard/getInsightData",
    LAUNCH_DATA: "/campaign/createcampaign",
    BRANDS: "campaign/getDesignBrands",
    SELECT_PRODUCT_GROUP: "campaign/selectProductGroup",
    DELETECAMPAIGN: "campaign/deleteCampaign",
    SEARCH_CAMPLIST: "designCampaign/dashboard/searchCampList",
    PRIMARY_KPI: "designCampaign/dashboard/primaryMetrics",
    UPDATE_DETAILS:"campaign/updateCampaign",
    KPI_INSIGHTS: "designCampaign/dashboard/getkpiInsightData",
  },
  MASTER: {
    BRANDS: "/measureCampaign/getBrands",
    FULLFILLMENT: "/measureCampaign/getFulfillmentChannels",
    TEST_TYPES: "/measureCampaign/getTesttype",
    GROUP: "/measureCampaign/getGroups",
    PRODUCTS: "/master/products",
    PRODUCT_GROUP_SAVE: "/master/productgroup",
    PRODUCT_GROUPS: "/master/productgroup",
  },
  MEASURE_CAMPAIGN: {
    DATE_RANGE: "/measureCampaign/getDateRange",
    CAMPAIGN_LIST: "/measureCampaign/getCampaignData",
    CAMPAIGN_LIST_ID: "/measureCampaign/getCampaignDataByID",
    DRILLDOWN: {
      BRANDS: "/measureCampaign/drilldown/getBrands",
      CHANNELS: "/measureCampaign/drilldown/getChannels",
      REGIONS: "/measureCampaign/drilldown/getRegions",
      DEVICE_TYPES: "/measureCampaign/drilldown/getDeviceTypes",
      KIPS: "/measureCampaign/drilldown/getKpis",
      PRODUCTS: "/measureCampaign/drilldown/getProducts",
      TOUCH_BASE: "/measureCampaign/drilldown/getTouchbase",
      KPI_DATA: "/measureCampaign/drilldown/getCampaignKpiData",
      GRAPH_DATA: "/measureCampaign/drilldown/campaignGraphData",
      SESSION_CHANNEL: "/measureCampaign/drilldown/getSessionChannel",
      WEB_APP: "/measureCampaign/drilldown/getAppWeb",
      KPI_SUMMARY: "/measureCampaign/drilldown/getKpiSummary",
      KPI_SUMMARY_GRAPH_DATA: "/measureCampaign/drilldown/kpiSummaryGraphData",
      FULLFILLMENT_CHANNEL: "/measureCampaign/drilldown/getFulfillmentChannels",
      MERCH_DROPDOWN: "/measureCampaign/drilldown/getMerchDropdown",
      MERCH_SUMMARY: "/measureCampaign/drilldown/getMerchSummary",
      COLLECTION: "measureCampaign/drilldown/getCollection",
      FLIGHT_ID: "measureCampaign/drilldown/getFlight",
      GROUP: "measureCampaign/drilldown/getGroups",
      DIVISION: "measureCampaign/drilldown/getMerchDivisionDropdown",
      DEPARTMENT: "measureCampaign/drilldown/getMerchDepartmentDropdown",
      CLASS: "measureCampaign/drilldown/getMerchClassDropdown",
    },
    DEEPDIVE: {
      PURCHASE_BEHAVIOUR: "/measureCampaign/deepdive/getPurchaseBehavior",
      CAMPAIGN_ENGAGEMENT: "/measureCampaign/deepdive/getCampaignEngagement",
      DIGITAL_ACTIVITY: "/measureCampaign/deepdive/getDigitalActivity",
      CUSTOMER_INSIGHT: "/measureCampaign/deepdive/getCustomerInsights",
    },
  },
};
