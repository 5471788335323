let appUrl = 'http://localhost:3000';
if (process.env.NODE_ENV === 'production') {
  // appUrl = `https://talp-retail-dev-nextjsapp.azurewebsites.net`;
  appUrl = `https://talpa-customer.theatom.ai`;
}

module.exports = {
  appId: '35a0de76-8382-4f2e-a2c3-82a5f3527cb5',
  redirectUri: appUrl,
  postLogoutRedirectUri: appUrl,
  scopes: ['user.read'],
};
