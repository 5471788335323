import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { NavLink, useHistory } from "react-router-dom";
import { PATHS } from "../../../config";
import palette from "../../../theme/palette";

export default function ReactBreadcrumbs() {
  const router = useHistory();
  // console.log(router, "Breadcrumb");
  function generateBreadcrumbs() {
    const asPathNestedRoutes = router.location.pathname
      .split("/")
      .filter((v) => v.length > 0);

    let crumblist = asPathNestedRoutes.map((subpath, idx) => {
      const href = "/" + asPathNestedRoutes.slice(0, idx + 1).join("/");
      const title = subpath;
      return { text: title, href };
    });
    //console.log(crumblist);
    crumblist.shift();

    return [
      { href: PATHS.DESIGN_CAMPAIGN, text: "design-campaign" },
      ...crumblist,
    ];
  }

  const breadcrumbs = generateBreadcrumbs();

  return (
    <Breadcrumbs
      style={{ margin: "0rem", color: palette.primary.main }}
      separator="›"
      aria-label="breadcrumb"
      maxItems={3}>
      {breadcrumbs.map((crumb, idx) => (
        <Crumb
          {...crumb}
          key={idx}
          first={idx === 0}
          style={{ paddingTop: "5%" }}
        />
      ))}
    </Breadcrumbs>
  );
}

function Crumb({ text, href, first = false }) {
  text = text.replace("review-campaign", "Review");
  text = text.replace("create-campaign", "Create campaign");
  text = text.replace("design-campaign", "");
  text = text.replace("product-group", "Product Group");
  text = text.replace("campaignDetails", "campaign Details");
  text = text.replace("new", "New");
  if (first) {
    return (
      <NavLink
        underline="hover"
        style={{ color: "#333333", fontSize: "12px" }}
        to={href}
      >
        Home
      </NavLink>
    );
  }
  return (

    <NavLink
      underline="hover"
      style={{ color: palette.primary.main, fontSize: "12px", fontWeight: 500 }}
      to={href}
    >
      {text}
    </NavLink>
  );
}
