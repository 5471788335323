import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  kpiSumFilters: {
    selectedKpi: [],
    productGrp: [],
    collection: [],
    flight: [],
    // brands: [],
    // channel: [],
    // region: [],
    // fulfillmentChannel: [],
  },
  merchHierarchyFilters: {
    selectedKpi: [],
    brands: [],
    channel: [],
    region: [],
    merchHierarchy: [],
    fulfillmentChannel: [],
    collection: [],
    flight: [],
    productGrp : [],
    division: [],
    department: [],
    class: [],
  },
  engageFilters: {
    selectedKpi: [],
    // brands: [],
    // channel: [],
    // region: [],
    // device: [],
    touchBase: [],
    collection: [],
    flight_id: [],
  },
  digitalFilters: {
    selectedKpi: [],
    // brands: [],
    // channel: [],
    region: [],
    // device: [],
    sessionChannel: [],
    appWeb: [],
    collection: [],
    flight_id: [],
  },
};

export const drilldownDetails = createSlice({
  name: "drilldownDetail",
  initialState,
  reducers: {},
});

export const {} = drilldownDetails.actions;

export default drilldownDetails.reducer;
