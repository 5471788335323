import { makeStyles } from '@material-ui/core'
import palette from 'theme/palette';

export default makeStyles((theme) => ({
 
  root: {
    width: '10%',
  },
  "&$expanded": {
    margin: "auto"
  },
  accordionSummary: {
    flexDirection: 'row-reverse',
    gap: "12px"
  },
  checkbox: {
    color: "pink",
    "&.MuiCheckbox-root": {
      color: palette.primary.main,
    },
    "&.MuiCheckbox-colorSecondary": {
      "&.Mui-checked": {
        color: palette.primary.main,
      },
    },
  },
  createBtn: {
    background: palette.primary.main,
    color: "white",
    marginTop: 8,
    borderRadius: 0,
    textTransform: "capitalize",
  },
  innerContainer: {
    display: "flex",
    alignItems: "Flex",
    color: "#182C52",
    cursor: "pointer",
    padding: "3px 0px",
    gap: "4px"
  },
  
  buttonContainer: {
    display: "flex",
    justifyContent: "end",

  },
  sectionHeading: {
    fontWeight: 400,
    fontSize: 16,
    // letterSpacing: "-0.02em",
    color: "#404041",
  },
  primaryBtn: {
    marginTop: 8,
    borderRadius: 0,
    textTransform: "capitalize",
  },
  
  audSelectBtn: {
    color: "#333333",
    border: "1px solid #333333",
    borderRadius: "6px",
    textTransform: "capitalize",
    padding: "0 10px",
    margin: "9px 0 9px 20px",
  },
  successMsg: {
    color: "#24A148 !important",
    fontWeight: 500,
    display: "inline-block",
  },
  applyBtn: {
    marginTop: 8,
    borderRadius: 0,
    textTransform: "capitalize",
  },

  //audiencelist style
  errorText: {
    color: "#ff0000",
    lineHeight: 2
  },
  audienceListArea: {
    padding: "20px 0 15px",
  },
  audListTest: {
    "& p": {
      margin: 0,
      padding: "15px 10px",
      borderRadius: 4,
      border: "1px solid #B3BAC5",
      "& svg + span": {
        verticalAlign: "top",
        padding: "0 3px",
        color: "#404041",
        fontWeight: 300,
      },
    },
    "& div + div": {
      "& p": {
        border: 0,
        padding: "13px 0",
      },
    },
  },
  audListControl: {
    "& h6": {
      border: 0,
      padding: "13px 0",
      "& svg + span": {
        verticalAlign: "top",
        padding: "0 3px",
        color: "#404041",
        fontWeight: 300,
      },
    },
  },
  
  cancelBtn: {
    "&:hover": {
      backgroundColor: "#e7e7e7",
    },
    margin: "8px 16px 0 0",
    borderRadius: "0",
    textTransform: "capitalize",
  },
  deleteIcon: { margin: "11px 0", cursor: "pointer" },
  noDataMsg: {
    textAlign: "center",
  },
  formControl: {
    width: "100%",
    "& label": {
      zIndex: 0
    }
  },
  collectionIcon: {
    verticalAlign: "middle",
    cursor: "pointer",
    fontSize: 16,
    margin: "0 5px",
    color: "#24A148",
    float: "right",
  },
  collectionArea: {
    background: "#f7f7f7",
    padding: 5,
    marginBottom: 10,
    "& svg + svg": {
      color: palette.semanticBGColors.primaryOrange,
      margin: 0,
    },
  },
  duration: {
    padding: "0 20px",
    color: "#2d2d2d"
  },
  // C:\Users\shikhara-ops\talp-frontend\src\views\DesignCampaign\CampaignDetail\index.js
  pageHeading: {
    fontSize: 22,
    color: "#333333",
    marginBottom: 10,
  },
  detailBlock: {
    width: "95%",
    margin: "0 auto 12px",
  },
  mainroot: {
    padding: "0 16px",
    marginTop: 25,
  },

}));