import { useLocation, useHistory } from "react-router-dom";
import { parse } from 'query-string';
import React, { useEffect,useState } from "react";
import jwtDecode from "jwt-decode";
import { APIS, PATHS } from "../../config";
import secureAxios from "config/api-config";
import FullLoader from "views/Common/FullLoader/FullLoader";
import { makeStyles,Typography,Grid } from "@material-ui/core";


const useContainer = makeStyles(theme => ({
    root: {
      height: '100vh',
    }
}));
const Auth = (props) => {
    const classes = useContainer();
    const { hash } = useLocation();
    const history = useHistory();
    const [error, setError] = useState('');
    
    useEffect(() => {
        let data = parse(hash);
        if(data.id_token){
            let  userData = jwtDecode(data.id_token),
                user_role =userData?.groups &&  [...userData?.groups]?.includes('SSO_TALP_CUSTOMER_ADMIN') ? 'Viewer' : 'Admin';;
                console.log({userData});
                localStorage.setItem("id_token", data.id_token);

            secureAxios.get(APIS.AUTH.TOKEN, {
                params: {
                    username: userData.preferred_username,
                    email:userData.email,
                    fullName:userData.name,
                    user_role: user_role
                }
            }).then(res => {
                if (res.status === 200) {
                    let userToken = res.data.token;
                    if (userToken && res.data.status === "success") {
                    localStorage.setItem("apiToken", JSON.stringify(res.data));
                    history.push(PATHS.DESIGN_CAMPAIGN);
                    }
                }
            })
    }
    else{
        setError('Login failed');
    }

    }, [])

    return (

        error.length > 0 ?

            <Grid

                className={classes.root}

                container

                direction="column"

                justifyContent="center"

                alignItems="center"

            >

                <Typography variant="h5">You do not have access to this Application</Typography>

                <Typography variant="h5">Please contact your administrator</Typography>

        </Grid> : <FullLoader  />

    );
}

export default Auth;