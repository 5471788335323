import React, { useState, useEffect } from "react";
import { Button,  Grid, Typography } from "@material-ui/core";

import { Form, Formik } from "formik";
import FlatDatePicker from "views/Common/Filters/FlatDatePicker";
import * as Yup from "yup";
import SingleSelectDropdown from "views/Common/Filters/SingleSelectDropdown";
import TextFieldElement from "views/Common/Filters/TextFieldElement";
import MultiSelectDropdown from "views/Common/Filters/MultiSelectDropdown";
import AudienceList from "./AudienceList";
import { useSelector, useDispatch } from "react-redux";
import {
  campDescription,
  clearCampDetails,
  clearCollectionList,
  clearProductGroupList,
  clearAudienceList,
  addAudSearchData
} from "../../../redux/campDetail/campaignDetailRedux";
import MultilineTextField from "views/Common/Filters/MultilineTextField";
import DialogBox from "views/Common/Modals/Dailog";
import secureAxios from "../../../config/api-config";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { subDays } from "date-fns";
import { PATHS, APIS } from "../../../config";
import ProductGroup from "./ProductGroup";
import { useLayoutEffect } from "react";
import { getBrands } from "redux/dashboard/dashBoardRedux";
import Moment from "moment";
import RadioGroupElement from "views/Common/Filters/RadioGroupElement";
import MessageDialog from "views/Common/MessageDialog/MessageDialog";
import DateRange from "views/Common/Filters/DateRange";
import useStyles from "./Style";
const { differenceInDays } = require("date-fns");


export default function CreateCampaign(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const audData = useSelector((state) => [...state.campDetail.collectionList]);
  const campDetails = useSelector((state) => state.campDetail.campDetails);
  const audSearchData = useSelector((state) => state.campDetail.audSearchData);
  const grpData = useSelector((state) => [
    ...state.campDetail.selectProductList,
  ]);

  const [searchData, setSearchData] = useState(audSearchData.length ? audSearchData : []);
  const [openList, setOpenList] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [brands, setBrands] = useState([]);
  const [channels] = useState(["Email", "DM", "SMS", "Push Notification"]);
  const [campaignType] = useState(["Adhoc-Fixed", "Simple Multitouch ongoing", "Simple Multitouch one time send", "Complex multitouch ongoing send", "Complex multitouch one time send", "One touch ongoing","Others"]);
  const [, setStartDate] = useState();
  const [campaignGrp, setCampaignGrp] = useState([]);
  const [sdata, setSData] = useState([]);
  const [, setEndDate] = useState();
  const [, setDuration] = useState();
  const [message, setMessage] = useState("");
  const [open2, setOpen2] = useState(false);
  const [type, setType] = useState("");
  const [, setPrePeriod] = useState([]);

  const [campSearchData, setCampSearchData] = useState([]);
  const [primaryMetric, setPrimaryMetric] = useState([]);
  const [segmentType] = useState(["Disposable segments", "Reusable segments"]);
  const [dataSource] = useState(["Source 1", "Source 2"])
  const [campaign_id, setCampaign_id] = useState(0);
  const validSchema = Yup.object().shape({
    NAME: Yup.string().required("Required").matches(/^[a-zA-Z0-9_'|.?,()@ [\]]+$/, "Some special char are not allowed"),
    DESCRIPTION: Yup.string().matches(/^[a-zA-Z0-9_'|.?,()@? [\]]+$/, "Some special char are not allowed"),
    GROUP: Yup.string().required("Required"),
    TYPE: Yup.string().required("Required"),
    BLACKOUT: Yup.array(),
    DATE: Yup.date().typeError("Required"),
    ENDDATE: Yup.date().typeError("Required"),
    PREPERIOD: Yup.array().min(2, "Required"),
    BRANDS: Yup.string().required("Required"),
    CHANNELS: Yup.array().min(1, "Required"),
    CAMPAIGNTYPE: Yup.string().required("Required"),
    PRIMARYMETRIC: Yup.string().required("Required"),
    SEGMENTTYPE: Yup.string().required("Required"),
    DATASOURCE: Yup.string().required("Required"),
  });



  useEffect(() => {
    secureAxios.get(APIS.DESIGN_CAMPAIGN.DESIGN_CAMPAIGN_LIST).then((res) => {
      if (res.status === 200) {
        setCampaignGrp(res.data.responseData);
      }
    });
    secureAxios.get(APIS.DESIGN_CAMPAIGN.BRANDS).then((res) => {
      if (res.status === 200) {
        const data = _.filter(res?.data?.responseData, (v) => v !== null);
        setBrands(data);
      }
    });
  }, []);

  const handleAudModal = (selectPro) => {
    if (open) {
      dispatch(clearAudienceList([]));
    }
    setOpen((prev) => !open);
  };

  const handleGroupList = (e) => {
    setOpenList((prev) => !openList);
  };

  //APi call for select product group

  useEffect(() => {
    dispatch({
      type: "LOADING",
      payload: true,
    });
    let isMounted = true;
    if (isMounted) {
      secureAxios
        .get(APIS.DESIGN_CAMPAIGN.SELECT_PRODUCT_GROUP)
        .then((res) => {
          dispatch({
            type: "LOADING",
            payload: false,
          });
          setSData(res.data);
        })
        .catch((err) => {
          dispatch({
            type: "LOADING",
            payload: false,
          });
          console.log(err, "product group");
        });

      secureAxios
        .get(APIS.DESIGN_CAMPAIGN.SEARCH_CAMPLIST)
        .then((res) => {
          dispatch({
            type: "LOADING",
            payload: false,
          });
          setCampSearchData(res.data.campName);
        })
        .catch((err) => {
          dispatch({
            type: "LOADING",
            payload: false,
          });
          console.log(err, "Search data");
        });

      secureAxios
        .get(APIS.DESIGN_CAMPAIGN.PRIMARY_KPI)
        .then((res) => {
          dispatch({
            type: "LOADING",
            payload: false,
          });
          setPrimaryMetric(res?.data?.kpiList);
        })
        .catch((err) => {
          dispatch({
            type: "LOADING",
            payload: false,
          });
          console.log(err, "KPI data");
        });
    }
    return () => {
      isMounted = false;
    };
  }, []);

  //api call for audience list

  const audList = async (startDate, dataSource) => {
    dispatch({
      type: "LOADING",
      payload: true,
    });
    try {
      const res = await secureAxios.post(APIS.DESIGN_CAMPAIGN.AUDIENCE_LIST, {
        dataSource: dataSource
      });
      if (res?.data?.responseData?.length === 0) {
        await setOpen2(true);
        await setType("waring");
        await setMessage("No data found in given date range");
        await setSearchData([]);
      } else {
        await setSearchData(res.data.responseData);
        dispatch(addAudSearchData(res.data.responseData));
      }
      dispatch({
        type: "LOADING",
        payload: false,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: "LOADING",
        payload: false,
      });
    }
  };

  //clear brands state for design campaign
  useLayoutEffect(() => {
    dispatch(getBrands(""));
  }, []);

  //settig campaign id
  function generateRandomNumber() {
    let digits = "123456789";
    let OTP = "";
    for (let i = 0; i < 12; i++) {
      OTP += digits[Math.floor(Math.random() * 9)];
    }
    setCampaign_id(OTP);
    return OTP;
  }
  useEffect(() => {
    generateRandomNumber();
  }, []);

  //close the popup
  const handleClose = () => {
    setOpen2(false);
  };

  return (
    <>
      {/* messgae for audlist */}
      <MessageDialog
        type={type}
        open={open2}
        message={message}
        close={handleClose}
      />
      <Formik
        initialValues={
          campDetails
            ? campDetails
            : {
              NAME: "",
              GROUP: "",
              TYPE: "",
              DESCRIPTION: "",
              BLACKOUT: [],
              DATE: "",
              ENDDATE: "",
              DURATION: "",
              DURATIONTYPE: "days",
              PREPERIOD: [],
              BRANDS: "",
              CHANNELS: [],
              CAMPAIGNTYPE: "",
              PRIMARYMETRIC: "",
              PRIMARYMETRICLABEL: "",
              CREATEDON: new Date(),
              CREATEDBY: "Admin",
              CAMPAIGN: "",
              UPLOAD: "",
              SEGMENTTYPE: "",
              DATASOURCE: ""
            }
        }
        validationSchema={validSchema}
        // enableReinitialize={true}

        onSubmit={(values) => {
          dispatch(campDescription(values));
          history.push({
            pathname: PATHS.REVIEW_CAMPAIGN,
            state: {
              campaignId: campaign_id,
              totalCollectionDuration: _.sumBy(audData, "collectionDuration"),
            },
          });
        }}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          setFieldValue,
          isValid,
          dirty,
        }) => (
          <Form autoComplete="off" noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className={classes.sectionHeading}>
                  Enter Campaign Details
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextFieldElement
                  name={"NAME"}
                  label="Campaign Name"
                  width={"w100"}
                />
                {campSearchData.includes(values.NAME.toLowerCase()) ? (
                  <Typography style={{ color: "#ff0026", fontsize: 13 }}>
                    {" Campaign Name exists!"}
                  </Typography>
                ) : (
                  ""
                )}
              </Grid>

              <Grid item xs={4}>
                <SingleSelectDropdown
                  width={"w100"}
                  value={values.GROUP}
                  label={"Campaign Group"}
                  name={"GROUP"}
                  handleMethod={(value) => {
                    handleChange(value);
                  }}
                  options={_.uniq(_.map(campaignGrp, "campaign_grp_name"))}
                  noneRequired={false}
                />
              </Grid>
              <Grid item xs={4}>
                <SingleSelectDropdown
                  width={"w100"}
                  value={values.TYPE}
                  name={"TYPE"}
                  handleMethod={(value) => {
                    handleChange(value);
                  }}
                  label={"Journey"}
                  options={
                    values.GROUP !== ""
                      ? _.map(
                        _.filter(
                          campaignGrp,
                          (item) => item.campaign_grp_name === values.GROUP
                        ),
                        "campaign_type"
                      )
                      : ["No Data"]
                  }
                  noneRequired={false}
                />
              </Grid>
              <Grid item xs={12}>
                <MultilineTextField
                  name={"DESCRIPTION"}
                  label="Campaign Description"
                  width={"w100"}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <Divider />
              </Grid> */}
              <Grid item xs={12}>
                <Typography className={classes.sectionHeading}>
                  Select Blackout Date(s)
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FlatDatePicker
                  name={"BLACKOUT"}
                  label={"Select Date(s)"}
                  value={values.BLACKOUT}
                  mode={"multiple"}
                  handleMethod={(name, value) => {
                    setFieldValue("BLACKOUT", value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.sectionHeading}>
                  Enter Campaign Date & Duration
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <FlatDatePicker
                  name={"DATE"}
                  label="Enter Test Start Date"
                  mode="single"
                  value={values.DATE}
                  maxDate={values.ENDDATE}
                  handleMethod={(name, value) => {
                    setFieldValue("DATE", value[0]);
                    setStartDate(value[0]);
                    setFieldValue("PREPERIOD", subDays(value[0], 1));
                    let date1 = new Date(Moment(value[0]).format("MM-DD-YY"));
                    let date2 = new Date(
                      Moment(values.ENDDATE).format("MM-DD-YY")
                    );
                    if (date1 !== "Invalid Date" && date2 !== "Invalid Date") {
                      let duration = differenceInDays(date1, date2);
                      setFieldValue("DURATION", Math.abs(duration) + 1);
                    }
                  }}
                  disable={values.BLACKOUT}
                />
              </Grid>
              <Grid item xs={3}>
                <FlatDatePicker
                  name={"ENDDATE"}
                  label="Enter Test End Date"
                  mode="single"
                  value={values.ENDDATE}
                  minDate={values.DATE}
                  handleMethod={async (name, value) => {
                    setFieldValue("ENDDATE", value[0]);
                    setEndDate(value[0]);
                    let date1 = new Date(Moment(value[0]).format("MM-DD-YY"));
                    let date2 = new Date(
                      Moment(values.DATE).format("MM-DD-YY")
                    );
                    if (date1 !== "Invalid Date" && date2 !== "Invalid Date") {
                      let duration = differenceInDays(date1, date2);
                      setFieldValue("DURATION", Math.abs(duration) + 1);
                      await setDuration(Math.abs(duration) + 1)
                    }
                  }}
                  disable={values.BLACKOUT}
                />
              </Grid>
              <Grid item xs={3}>
                <TextFieldElement
                  name={"DURATION"}
                  label="Duration (days)"
                  width={"w100"}
                  disabled={true}
                />
              </Grid>

              <Grid item xs={3}>

                <Button
                  className={classes.applyBtn}
                  color="primary"
                  type="button"
                  size="small"
                  variant="contained"
                  onClick={(e) => {
                    setPrePeriod([
                      Moment(values.DATE)
                        .subtract(values.DURATION, values.DURATIONTYPE)
                        .toDate(),
                      Moment(values.DATE)
                        .subtract(1, "days")
                        .toDate(),
                    ]);
                    setFieldValue("PREPERIOD", [
                      Moment(values.DATE)
                        .subtract(values.DURATION, values.DURATIONTYPE)
                        .toDate(),
                      Moment(values.DATE)
                        .subtract(1, "days")
                        .toDate(),
                    ]);
                    const preduration = differenceInDays(
                      Moment(values.DATE)
                        .subtract(values.DURATION, values.DURATIONTYPE)
                        .toDate(),
                      Moment(values.DATE)
                        .subtract(1, "days")
                        .toDate()
                    );
                    setFieldValue("PREDURATION", Math.abs(preduration) + 1);
                  }}
                >
                  Apply
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.sectionHeading}>
                  Enter Pre-Period Date
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <DateRange
                  name={"PREPERIOD"}
                  width={"200px"}
                  // label="Enter Pre-Period for measurement​"
                  placeHolder="Choose Period​"
                  value={values.PREPERIOD}
                  maxDate={values.DATE}
                  disable={values.BLACKOUT}
                  handleMethod={(name, value) => {
                    setFieldValue("PREPERIOD", value);
                    let duration = differenceInDays(value[0], value[1]);
                    setFieldValue("PREDURATION", Math.abs(duration) + 1);
                  }}
                />
                {
                  typeof values.PREPERIOD === "object" && Object.values(values.PREPERIOD).length < 2 ?
                    <Typography style={{ color: "#ff0026", fontsize: 13 }}>
                      {"Required"}
                    </Typography> : ""
                }
              </Grid>
              {/* <Grid item xs={12}>
                <Divider />
              </Grid> */}
              <Grid item xs={12}>
                <Typography className={classes.sectionHeading}>
                  Enter Business Filters
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <SingleSelectDropdown
                  width={"w100"}
                  value={values.BRANDS}
                  label={"Select Brands"}
                  name={"BRANDS"}
                  handleMethod={handleChange}
                  options={brands}
                  noneRequired={false}
                />
              </Grid>
              <Grid item xs={3}>
                <MultiSelectDropdown
                  width={"w100"}
                  label={"Vehicle"}
                  name={"CHANNELS"}
                  value={values.CHANNELS}
                  handleMethod={(value) => {
                    setFieldValue("CHANNELS", value);
                  }}
                  options={channels}
                />
              </Grid>
              <Grid item xs={3}>
                <SingleSelectDropdown
                  width={"w100"}
                  value={
                    values.PRIMARYMETRIC && primaryMetric.length
                      ? _.find(primaryMetric, {
                        kpi_name: values.PRIMARYMETRIC,
                      }).kpi_lvl
                      : ""
                  }
                  name={"PRIMARYMETRIC"}
                  handleMethod={(value) => {
                    setFieldValue(
                      "PRIMARYMETRIC",
                      _.find(primaryMetric, { kpi_lvl: value.target.value })
                        .kpi_name
                    );
                    setFieldValue("PRIMARYMETRICLABEL", value.target.value);
                  }}
                  error={errors.PRIMARYMETRIC && touched.PRIMARYMETRIC}
                  label={"Select Primary Metrics"}
                  options={_.map(primaryMetric, "kpi_lvl")}
                  noneRequired={false}
                />
              </Grid>
              <Grid item xs={3}>
                <SingleSelectDropdown
                  width={"w100"}
                  value={values.CAMPAIGNTYPE}
                  label={"Select Type of Campaign"}
                  name={"CAMPAIGNTYPE"}
                  handleMethod={(value) => {
                    setFieldValue("CAMPAIGNTYPE", value.target.value);
                  }}
                  options={campaignType}
                  noneRequired={false}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.sectionHeading}>
                  Select Segment Type
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <RadioGroupElement
                  name={"SEGMENTTYPE"}
                  options={segmentType}
                  defaultvalues={values["SEGMENTTYPE"]}
                  handleMethod={(e) => {
                    dispatch(clearCollectionList([]));
                    setFieldValue("SEGMENTTYPE", e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.sectionHeading}>
                  Select Campaign Origination Source
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <RadioGroupElement
                  name={"DATASOURCE"}
                  options={dataSource}
                  defaultvalues={values["DATASOURCE"]}
                  disabled={values.DATE === ""}
                  handleMethod={(e) => {
                    //aud list api call
                    audList(values.DATE, e.target.value);
                    dispatch(clearCollectionList([]));
                    setFieldValue("DATASOURCE", e.target.value);
                  }}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <Divider />
              </Grid> */}
              <Grid container spacing={3}>
                <Grid item xs={6} className={"textRight mt20"}>
                  <Button
                    variant="outlined"
                    onClick={handleGroupList}
                    className={classes?.audSelectBtn}
                    disabled={sdata?.length === 0}
                  >
                    Select Product Group
                  </Button>

                  {grpData.length ? (
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.successMsg}
                    >
                      &nbsp; {`${grpData.length} Group(s) Selected`}
                    </Typography>
                  ) : (
                    <Typography variant="body2" component="em">
                      &nbsp; {"No Product Group Selected"}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6} className={"mt20"}>
                  <Button
                    variant="outlined"
                    onClick={handleAudModal}
                    className={classes.audSelectBtn}
                    disabled={values.SEGMENTTYPE === "" || values.DATASOURCE === ""}
                  >
                    Select Audience List
                  </Button>

                  {audData.length ? (
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.successMsg}
                    >
                      &nbsp; {`${audData.length} Collection(s) Selected`}
                    </Typography>
                  ) : (
                    <Typography variant="body2" component="em">
                      &nbsp; {"No Collection Selected"}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} className={"textRight"}>
                  <Button
                    className={classes.cancelBtn}
                    type="button"
                    variant="contained"
                    onClick={() => {
                      history.replace(PATHS.DESIGN_CAMPAIGN);
                      dispatch(clearCampDetails());
                      dispatch(clearCollectionList([]));
                      dispatch(clearProductGroupList([]));
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.primaryBtn}
                    type="Submit"
                    variant="contained"
                    color="primary"
                    disabled={
                      (!(dirty && isValid) && !campDetails.NAME) ||
                      audData.length === 0 ||
                      campSearchData.includes(values.NAME.toLowerCase())
                    }
                  >
                    Proceed
                  </Button>
                  <DialogBox
                    open={open}
                    handleClose={handleAudModal}
                    width={1920}
                    header={"Collection(s)"}
                    children={
                      <AudienceList
                        handleChange={handleChange}
                        handleAudModal={handleAudModal}
                        searchData={searchData}
                        segType={values.SEGMENTTYPE}
                      />
                    }
                  ></DialogBox>
                  <DialogBox
                    open={openList}
                    width={"medium"}
                    handleClose={handleGroupList}
                    header={"Select Custom Product Group"}
                    children={
                      <ProductGroup
                        sdata={sdata}
                        handleAudModal={handleGroupList}
                      />
                    }
                  ></DialogBox>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
