import React, { useEffect, useState } from "react";
import CustomMaterialTable from "../Common/CustomMaterialTable";
import MultiSelectDropdown from "../Common/Filters/MultiSelectDropdown";
import CustomMultiSelectDropdown from "../Common/Filters/CustomMultiSelectDropdown";
import {
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Grid,
  Button,
  Box,
} from "@material-ui/core/";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import ExportCsv from "../Common/Utils/ExportCsv";
import secureAxios from "../../config/api-config";
import MeasureLineTrend from "./MeasureLineTrend";
import ModalLoading from "../../components/ModalLoading";
import _ from "lodash";
import { APIS } from "../../config";
import palette from "../../theme/palette";
import { useParams } from "react-router";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import useStyles from "./style";


export default function DigitalActivity(props) {
  const classes = useStyles();
  const { id } = useParams()
  const dispatch = useDispatch();
  const { outlierChecked } = props;

  const appReduxState = useSelector((state) => ({
    ...state.drilldownDetail,
  }));
  const { digitalFilters } = appReduxState;

  //define state
  const [selectedTblKpi, setSelectedTblKpi] = useState({
    id: "",
    name: "",
    type: "",
    label: "",
  });
  const [kpiDropdown, setKpiDropdown] = useState([]);
  const [sessionDropdown, setSessionDropdown] = useState([]);
  const [appWebDropdown, setAppWebDropdown] = useState([]);
  const [flightDropDdown, setFlightDropdown] = useState([]);
  const [collectionDropDdown, setCollectionDropdown] = useState([]);
  const [selectedKPIs, setSelectedKPIs] = useState([]);
  const [selectedSession, setSelectedSession] = useState([]);
  const [selectedAppWeb, setSelectedAppWeb] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [selectedFlight, setSelectedFlight] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState([]);
  const [msg, setMsg] = useState("Loading...");

  let successfulApiCalls = 0;
  let apiCallCount = 0

  //function check api reponse 
  const handleApiSuccess = () => {
    successfulApiCalls++;
    // Check if both API calls have been successfully completed
    if (successfulApiCalls  === apiCallCount) {
      // Close the loader
      dispatch({
        type: "MODALLOADING",
        payload: false,
      });
    }
  };
  //Table data
  const columns = [
    {
      field: "KPI",
      colspan: 4,
      render: (rowData) => { return rowData.kpiLabel },
    },
    {
      field: "COLLECTION",
      colspan: 4,
      render: (rowData) => { return rowData.collection_name }

    },
    {
      field: "FLIGHT",
      colspan: 4,
      render: (rowData) => { return rowData.flight_id }

    },
    {
      field: "SESSION CHANNEL",
      colspan: 4,
      render: (rowData) => { return rowData.sessionChannel }
    },
    {
      field: "APP/WEB",
      colspan: 4,
      render: (rowData) => { return rowData.appWeb }
    },
    {
      field: "Test",
      colspan: 4,
      render: (rowData) => { return rowData?.testValue }

    },
    {
      field: "Control",
      colspan: 4,
      render: (rowData) => {
        return rowData.controlValue
      },
    },
    {
      field: "BENCHMARK",
      colspan: 4,
      render: (rowData) => { return rowData?.benchmark === null ? "NA" : rowData?.benchmark?.toFixed(2) }

    },
    {
      field: "% Change",
      colspan: 4,
      render: (rowData) => { return rowData?.pctChange === null ? "NA" : rowData?.pctChange?.toFixed(2) }

    },
  ];

  const getKpioptions = (val, status) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.KIPS, {
        outlierValue: outlierChecked ? true : false,
        filters: val,
        campaign_id: id,
        tabName: "DigitalActivity",
      })
      .then((res) => {
        if (res.status === 200) {

          let resData = res.data.responseData;
          fetchTblData("firstLoad", {
            selectedKpi: _.map(resData, "kpiName"),
            // brands: [],
            // channel: [],
            region: ["ALL"],
            // device: [],
            sessionChannel: ["ALL"],
            appWeb: ["ALL"],
            collection: ["ALL"],
            flight_id: ["ALL"],
          });
          setKpiDropdown(resData);
          if (status) {
            setSelectedKPIs(resData);
          } else {
            let newSelected = resData.filter((array) =>
              val.selectedKpi.some((filter) => filter === array.kpiName)
            );
            setSelectedKPIs(newSelected);
          }
         
        }
      }).catch((err) => {

      })
      .finally((err) => {
        handleApiSuccess();
      });
  };
  const getSessionoptions = (val, status) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.SESSION_CHANNEL, {
        outlierValue: outlierChecked ? true : false,
        filters: val,
        campaign_id: id,
        tabName: "DigitalActivity",

      })
      .then((res) => {
        if (res.status === 200) {
          let resData = res.data.responseData;
          setSessionDropdown(resData);
          if (status) {
            let all = resData.filter(item => {
              return item === "ALL"
            })
            setSelectedSession(all);
          } else {
            let newSelected = val.sessionChannel.filter((item) =>
              resData.includes(item)
            );
            setSelectedSession(newSelected);
          }

        }
      }).catch((err) => {

      }).finally((err) => {
        handleApiSuccess();
      });
  };
  const getAppWeboptions = (val, status) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.WEB_APP, {
        outlierValue: outlierChecked ? true : false,
        filters: val,
        campaign_id: id,
        tabName: "DigitalActivity",

      })
      .then((res) => {
        if (res.status === 200) {
          let resData = res.data.responseData;
          setAppWebDropdown(resData);
          if (status) {
            let all = resData.filter(item => {
              return item === "ALL"
            })
            setSelectedAppWeb(all);
          } else {
            let newSelected = val.appWeb.filter((item) =>
              resData.includes(item)
            );
            setSelectedAppWeb(newSelected);
          }

        }
      }).catch((err) => {

      }).finally((err) => {
        console.log(err);
        handleApiSuccess();
      });;
  };

  //api call for table
  const fetchTblData = (viewStatus, val) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.KPI_DATA, {
        outlierValue: outlierChecked ? true : false,
        filters: val,
        campaign_id: id,
        tabName: "DigitalActivity",

      })
      .then(function (response) {
        if (response.status === 200) {
          if (!response.data.responseData.length) {
            setMsg("No records to display");
          }
          setTblData(response.data.responseData);
          
          if (response?.data?.responseData?.length > 0) {
            if (viewStatus === "firstLoad") {
              setSelectedTblKpi({
                ...selectedTblKpi,
                id: response.data.responseData[0]["kpiRowId"],
                name: response.data.responseData[0]["kpi"],
                type: response.data.responseData[0]["kpiType"],
                label: response.data.responseData[0]["kpiLabel"],
              });
            }
            fetchGraphData(response.data.responseData[0]["kpiRowId"]);
          }
        }
      }).catch((err) => {

      }).finally((err) => {
        handleApiSuccess();
      });
  };

  //api call for graph
  const fetchGraphData = (val) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.GRAPH_DATA, {
        campaign_id: id,
        tabName: "DigitalActivity",
        selectedKpiRowId: val,
        outlierValue: outlierChecked ? true : false,
      })
      .then(function (response) {
        if (response.status === 200) {
          setGraphData(response.data.responseData);

        }
      }).catch((err) => {

      }).finally((err) => {
        handleApiSuccess();
        
      });;
  };

  //Get flight data api call
  const getFlightoptions = (val, status) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.FLIGHT_ID, {
        outlierValue: outlierChecked ? true : false,
        filters: val,
        tabName: "DigitalActivity",
        campaign_id: id,
      })
      .then((res) => {
        if (res.status === 200) {
          let resData = res?.data?.responseData;
          setFlightDropdown(resData);
          if (status) {
            let all = resData.filter(item => {
              return item === "ALL"
            })
            setSelectedFlight(all);
          } else {
            let newSelected = val?.flight_id?.filter((item) =>
              resData.includes(item)
            );
            setSelectedFlight(newSelected);
          }

        }
      })
      .catch((err) => {

      })
      .finally((err) => {
        handleApiSuccess();
      });
  };

  //Apicall for get collection data
  const getCollectionoptions = (val, status) => {
    apiCallCount++;
    dispatch({
      type: "MODALLOADING",
      payload: true,
    });
    secureAxios
      .post(APIS.MEASURE_CAMPAIGN.DRILLDOWN.COLLECTION, {
        outlierValue: outlierChecked ? true : false,
        filters: val,
        tabName: "DigitalActivity",
        campaign_id: id,
      })
      .then((res) => {
        if (res.status === 200) {
          let resData = res?.data?.responseData;
          setCollectionDropdown(resData);
          if (status) {
            let all = resData.filter(item => {
              return item === "ALL"
            })
            setSelectedCollection(all);
          } else {
            let newSelected = val?.collection?.filter((item) =>
              resData.includes(item)
            );
            setSelectedCollection(newSelected);
          }

        }
      })
      .catch((err) => {

      })
      .finally((err) => {
        handleApiSuccess();
      });
  };

  useEffect(() => {
    getKpioptions(digitalFilters, "firstLoad");
    getSessionoptions(digitalFilters, "firstLoad");
    getAppWeboptions(digitalFilters, "firstLoad");
    getFlightoptions(digitalFilters, "firstLoad");
    getCollectionoptions(digitalFilters, "firstLoad");

  }, [outlierChecked]);

  //file download
  const newArr = []
  tblData.forEach((x, i) => {
    const digitalActivityData = {
      'Sno': `${i + 1}`,
      'Metrics': `${x.kpiLabel}`,
      'Collection Name':`${x.collection_name}`,
      'Flight':`${x.flight_id}`,
      'Session Channel':`${x.sessionChannel}`,
      'AppWeb':`${x.appWeb}`,
      'Test' :`${x.testValue}`,
      'Control' :`${x.controlValue}`,
      'Benchmark' :`${x.benchmark === null ?"NA" :x.benchmark?.toFixed(2)}`,
      '% Change': `${ x.pctChange === null ?"NA" :x.pctChange?.toFixed(2)}`,
    }

    newArr.push(digitalActivityData)
  })

  return (
    <>
      <ModalLoading />
      
      <Box component="div" className={classes.filterAreaDropDown}>
        <Formik
          initialValues={{
            KPI: selectedKPIs,
            region: ["ALL"],
            SESSION: selectedSession,
            APPWEB: selectedAppWeb,
            FLIGHT: selectedFlight,
            COLLECTION: selectedCollection,
          }}
          enableReinitialize={true}
          onSubmit={(values) => {
            dispatch({
              type: "MODALLOADING",
              payload: true,
            });
            fetchTblData("firstLoad", {
              selectedKpi: _.map(selectedKPIs, "kpiName"),
              region: ["ALL"],
              sessionChannel: selectedSession,
              appWeb: selectedAppWeb,
              flight_id: selectedFlight,
              collection: selectedCollection,
            });
          }}
        >
          {({
            values,
            setFieldValue,
            resetForm,
          }) => (
            <Form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={2}>
                  <CustomMultiSelectDropdown
                    width={"w100"}
                    label={"KPIs"}
                    name={"KPI"}
                    value={values.KPI}
                    handleMethod={(value) => {
                      setFieldValue("KPI", value);
                      setSelectedKPIs(value);
                    }}
                    options={kpiDropdown}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                  <MultiSelectDropdown
                    width={"w100"}
                    label={"Session Channel"}
                    name={"SESSION"}
                    value={values.SESSION}
                    handleMethod={(value) => {
                      setFieldValue("SESSION", value);
                      setSelectedSession(value);
                    }}
                    options={sessionDropdown}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <MultiSelectDropdown
                    width={"w100"}
                    label={"App/Web"}
                    name={"APPWEB"}
                    value={values.APPWEB}
                    handleMethod={(value) => {
                      setFieldValue("APPWEB", value);
                      setSelectedAppWeb(value);
                    }}
                    options={appWebDropdown}
                  />
                </Grid>

                {/* select dropdown for collection */}
                <Grid item xs={12} sm={6} md={2}>
                  <MultiSelectDropdown
                    width={"w100"}
                    label={"Collection"}
                    name={"COLLECTION"}
                    value={values.COLLECTION}
                    handleMethod={(value) => {
                      setFieldValue("COLLECTION", value);
                      setSelectedCollection(value);
                      getFlightoptions({
                        selectedKpi: _.map(selectedKPIs, "kpiName"),
                        region: ["ALL"],
                        collection: value,
                        sessionChannel: selectedSession,
                        appWeb: selectedAppWeb,
                        flight_id: selectedFlight,
                      });
                    }}
                    options={collectionDropDdown}
                  />
                </Grid>

                {/* select dropdown for Flight */}
                <Grid item xs={12} sm={6} md={2}>
                  <MultiSelectDropdown
                    width={"w100"}
                    label={"Flight"}
                    name={"FLIGHT"}
                    value={values.FLIGHT}
                    handleMethod={(value) => {
                      setFieldValue("FLIGHT", value);
                      setSelectedFlight(value);
                    }}
                    options={flightDropDdown}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={1}>
                  <Button className={classes.buttonFilter} type="submit">
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={1}>
                  <div className={classes.innerContainer}>
                    <RotateLeftIcon
                      color="primary"
                      onClick={() => {
                        dispatch({
                          type: "MODALLOADING",
                          payload: true,
                        });
                        resetForm();
                        getKpioptions(digitalFilters, "firstLoad");
                        getSessionoptions(digitalFilters, "firstLoad");
                        getAppWeboptions(digitalFilters, "firstLoad");
                        getFlightoptions(digitalFilters, "firstLoad");
                        getCollectionoptions(digitalFilters, "firstLoad")
                      }}
                      className={classes.resetBtn} />
                    <ExportCsv
                     tableData={newArr}
                     fileName={`digital_activity_${id}`}
                      style={{
                        verticalAlign: "top",
                        margin: "10px 8px 8px 0",
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
      {tblData?.length ? (
        <>
          {graphData?.hasOwnProperty("testData") ? (
            <MeasureLineTrend
              selectedKPI={selectedTblKpi}
              graphData={graphData}
              view={"DigitalActivity"}
            ></MeasureLineTrend>
          ) : (
            <div style={{ textAlign: "center", margin: "50px" }}>
              {graphData === "no data" ? "" : "...Loading Data for Graphs"}
            </div>
          )}
          <CustomMaterialTable
            title=""
            columns={columns}
            data={tblData}
            onRowClick={(event, rowData) => {
              setSelectedTblKpi({
                ...selectedTblKpi,
                id: rowData.kpiRowId,
                name: rowData.kpi,
                type: rowData.kpiType,
                label: rowData.kpiLabel,
              });
              fetchGraphData(rowData.kpiRowId);
            }}
            options={{
              sorting: true,
              search: false,
              toolbar: false,
              paging: true,
              pageSize: 5,
              rowStyle: (rowData) => {
                return {
                  color:
                    selectedTblKpi.id === rowData.kpiRowId
                      ? palette.tableColors.bodyText
                      : "inherit",
                  cursor: "pointer",
                  backgroundColor: selectedTblKpi.id === rowData.kpiRowId
                    ? palette.tableColors.selectedBackground : "inherit"
                };
              },
            }}
            components={{
              Container: props => <Box
                component="div" className="tblScroll">{props.children}</Box>,
              Header: () => {
                return (
                  <TableHead
                    className={classes.tableRightBorder}
                  >
                    <TableRow>
                      <TableCell

                        rowSpan={2}
                      >
                        KPI
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                      >
                        Collection
                      </TableCell>
                      <TableCell

                        rowSpan={2}
                      >
                        Flight
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                      >
                        Session Channel
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                      >
                        App/Web
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                      >
                        Test
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                      >
                        Control
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                      >
                        Benchmark
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                      >
                        % Change
                      </TableCell>
                    </TableRow>
                  </TableHead>
                );
              },
            }}
          ></CustomMaterialTable>
        </>
      ) : (
        <Grid item xs={12}>
          <Typography
            component="h3"
            variant="subtitle2"
            className={classes.info}
          >
            {msg}
          </Typography>
        </Grid>
      )}
    </>
  );
}
