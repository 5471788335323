import React, { useEffect, useState } from "react";
import ReactApexCharts from "react-apexcharts";
import palette from "../../../theme/palette";
import { toCurrencyFormat, numberWithCommas } from "../Utils/ClientUtil";

const LineChart = (props) => {
  const [chartInit, setChartInit] = useState();
  let {
    series,
    xAxis,
    startDate,
    showAnnotations,
    chartColors,
    chartType,
    label,
    markers,
    dateDayDuring,
    dateDayPre,
    campStart,
    campPre
  } = props;
  console.log({startDate});
  let annotationsObj = showAnnotations
    ? {
      annotations: {
        xaxis: [
          {
            x:  campStart[0]? campStart[0] :dateDayDuring[0],
            borderColor: palette.text.secondary,
            label: {
              style: {
                color: palette.text.secondary,
              },
              orientation: "horizontal",
              offsetX: 15,
              text: campStart[0]? "Campaign Start" :"Campaign During Period",
            },
          },
          {
            x: campPre[0] ? campPre[0] : dateDayPre[0],
            borderColor: palette.text.secondary,
            label: {
              style: {
                color: palette.text.secondary,
              },
              orientation: "horizontal",
              offsetX: 15,
              text: campStart[0]? "Campaign Pre Period" :"Campaign Pre Period",
            },
          },
        ],
      },
    }
    : {};

  useEffect(() => {
    setChartInit({
      series: series ? series : [],
      options: {
        chart: {
          height: 300,
          type: "area",
          stacked: false,
          toolbar: {
            show: true,
          },
        },
        markers: markers ? markers : {},
        colors: chartColors,
        legend: {
          show: true,
          horizontalAlign: "center",
          showForSingleSeries: true,
          // offsetX: 40,
        },
        title: {
          text: "",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: [1, 1],
        },
        yaxis: [
          {
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
              //color: '#008FFB'
            },

            labels: {
              formatter: function (value) {
                return toCurrencyFormat(value);
              },
            },

            title: {
              text: label,
              style: {
                color: "#333333",
                fontSize: 12,
                textTransform: "capitalize",
                fontFamily: '"Poppins", sans-serif',
                fontWeight: 400,
              },
            },
            tooltip: {
              enabled: false,
            },
          },
        ],
        xaxis: {
          show: true,
          categories: xAxis ? xAxis : [],
          labels: {
            show: true,
            style: {
              fontSize: "10px",
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          floating: false,
          position: "bottom",
        },
        tooltip: {
          fixed: {
            enabled: false,
            position: "topLeft",
            offsetY: 30,
            offsetX: 60,
          },
          y: {
            formatter: function (y) {
              return numberWithCommas(y?.toFixed(2));
            },
          },
        },
        grid: {
          show: true,
          borderColor: "#E6E7E8",
          strokeDashArray: 4,
          position: "back",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          row: {
            colors: undefined,
            opacity: 0.5,
          },
          column: {
            colors: undefined,
            opacity: 0,
          },
        },
        ...annotationsObj,
      },
    });
  }, [series]);

  return (
    <React.Fragment>
      {chartInit && (
        <ReactApexCharts
          options={chartInit.options}
          series={chartInit.series}
          type={chartType ? chartType : "area"}
          height={300}
        />
      )}
    </React.Fragment>
  );
};
export default LineChart;