import React, { useEffect, useState } from "react";
import ReactApexCharts from "react-apexcharts";
import { roundToTwoDecimalPlaces, toMetricFormat } from "../Utils/ClientUtil";
import _ from "lodash";
import palette from "theme/palette";

const BarChartNegativeResponseRate = (props) => {
    const [chartInit, setChartInit] = useState();
    const { xAxisTitle, colors, data, yAxisTitle, kpiName, categories, height, testControlChecked } = props;
    useEffect(() => {
        setChartInit({
            series: [{
                name: xAxisTitle,
                dataSet: data,
                data: testControlChecked && xAxisTitle === "Campaign Purchasers Absolute Lift"
                    ? _.map(_.sortBy(data, (v) => categories.indexOf(v.dataPointName)), "lift")
                    : testControlChecked && xAxisTitle === "Response Rate Absolute Lift"
                        ? _.map(_.sortBy(data, (v) => categories.indexOf(v.dataPointName)), "abs_lift")
                        : !testControlChecked && xAxisTitle === "Campaign Purchasers Absolute Lift"
                            ? _.map(_.sortBy(data, (v) => categories.indexOf(v.dataPointName)), "prelift")
                            : !testControlChecked && xAxisTitle === "Response Rate Absolute Lift"
                                ? _.map(_.sortBy(data, (v) => categories.indexOf(v.dataPointName)), "preAbsolutelift")
                                : []
            }],
            options: {
                chart: {
                    type: 'bar'
                },
                tooltip: {
                    custom: function ({ seriesIndex, dataPointIndex, w }) {
                        const selectedData = w.globals.labels[dataPointIndex]
                        const finalDataSet = data.find(item => item.dataPointName === selectedData)
                        return testControlChecked ? '<ul style="padding: 10px; list-style: none">' +
                            '<li style="font-size: 12px">' + finalDataSet?.dataPointName + '</li>' +
                            '<li style="font-size: 12px">Response Rate Absolute Lift : ' + toMetricFormat(finalDataSet?.abs_lift, kpiName) + '</li>' +
                            '<li style="font-size: 12px">Campaign Purchasers Absolute Lift : ' + finalDataSet?.lift?.toFixed(0) + '</li>' +
                            '<li style="font-size: 12px">Significance p-value : ' + roundToTwoDecimalPlaces (finalDataSet?.stat_sig) + '</li>' +
                            '</ul>'
                            : '<ul style="padding: 10px; list-style: none">' +
                            '<li style="font-size: 12px">' + finalDataSet?.dataPointName + '</li>' +
                            '<li style="font-size: 12px">Response Rate Absolute Lift : ' + toMetricFormat(finalDataSet?.preAbsolutelift, kpiName) + '</li>' +
                            '<li style="font-size: 12px">Campaign Purchasers Absolute Lift : ' + finalDataSet?.prelift?.toFixed(0) + '</li>' +
                            '<li style="font-size: 12px">Significance p-value : ' + roundToTwoDecimalPlaces (finalDataSet?.stat_sig)+ '</li>' +
                            '</ul>';
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        barHeight: '40px',
                        // dataLabels: {
                        //     position: 'top'
                        // }
                    }
                },
                colors: colors ? colors : [function (opt) {
                    const selectedData = opt.w.globals.labels[opt.dataPointIndex]
                    const finalDataSet = data.find(item => item.dataPointName === selectedData)
                    if (finalDataSet?.stat_sig === null) {
                        return palette.semanticBGColors.primaryRed
                    } else if (finalDataSet?.stat_sig < 0.05) {
                        return palette.semanticBGColors.primaryGreen
                    } else if (finalDataSet?.stat_sig > 0.2) {
                        return palette.semanticBGColors.primaryRed
                    } else {
                        return palette.semanticBGColors.primaryYellow
                    }
                }],
                stroke: {
                    width: [0, 4],
                    curve: 'smooth'
                },
                title: {
                    text: xAxisTitle
                },
                grid: {
                    show: false,
                    position: 'back',
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                },
                dataLabels: {
                    enabled: true,
                    // offsetX: 45,
                    formatter: function (val) {
                        if (xAxisTitle === "Campaign Purchasers Absolute Lift") {
                            return val?.toFixed(0)
                        }
                        else {
                            return toMetricFormat(val, kpiName)
                        }
                    },
                    style: {
                        colors: [palette.text.dark]
                    }
                },
                yaxis: {
                    title: {
                        text: yAxisTitle,
                    },
                },
                xaxis: {
                    tickAmount: 4,
                    title: {
                        text: xAxisTitle,
                    },
                    categories: categories ? categories : _.map(data, "dataPointName"),

                }
            },
        });
    }, [categories,colors,data,kpiName,testControlChecked,xAxisTitle,yAxisTitle,height]);

    return (
        <>
            {chartInit && (
                <>
                    <ReactApexCharts
                        options={chartInit?.options}
                        series={chartInit?.series}
                        type="bar"
                        height={data?.length < 5 ? 300 : data?.length * 70}
                    />
                </>
            )}
        </>
    );
};
export default BarChartNegativeResponseRate;