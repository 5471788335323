import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
} from "@material-ui/core";

import secureAxios from "../../../../config/api-config";
import DashboardContent from "./DashboardContent";
import DashboardCount from "./DashboardCount";
import { useSelector } from "react-redux";
import { APIS } from "../../../../config";
import useStyles from './Style'


export default function Dashboard(props) {
  const classes = useStyles();
  const [load, setLoad] = useState(false);
  const [dashboardData, setDashBoardData] = React.useState();
  const dashboardDetail = useSelector((state) => state?.dashBoardDetail);
  

  React.useEffect(() => {
    setLoad(true);
    secureAxios
      .post(APIS.DESIGN_CAMPAIGN.DASHBOARD, {
        DashboardDateRange: dashboardDetail?.dateRange,
        DashboardBrands: dashboardDetail?.brands,
      })
      .then(async (res) => {
        if (res.status === 200) {
          await setDashBoardData(res.data);
          setLoad(false);

        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dashboardDetail]);

  return (
    <Grid container spacing={3}>
      {load ? (
        <div
          style={{
            width: "100vw",
            minHeight: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {" "}
          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            className={classes.dashboardCountOuter}
          >
            <DashboardCount
              countData={dashboardData?.dashBoardCount}
              dashboardDate={props?.dashboardDate}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={10} className={classes.rightContainer}>
            <DashboardContent
              campaignDetail={dashboardData?.dashBoardContent}
              dashboardDate={props?.dashboardDate}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
