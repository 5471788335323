import React from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  CircularProgress,
} from "@material-ui/core";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import SendIcon from "@material-ui/icons/Send";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import FacebookIcon from "@material-ui/icons/Facebook";
import useStyles from "./style";
import NotificationsActiveOutlinedIcon from "@material-ui/icons/NotificationsActiveOutlined";
import CommandCard from "views/Common/CommonCard/CommandCard";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Moment from "moment";
import _ from "lodash";
import { numberWithCommas } from "views/Common/Utils/ClientUtil";

const CampaignViewDetails = (props) => {
  const classes = useStyles();
  const { data, flightData, customersData, load2 } = props;

  return (
    <Box component="div">
      <CommandCard text="Campaign Details">
        {load2 ? (
          <div className={classes.mainContainerTop}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {flightData?.length === 0 ? (
              <Typography className={classes.mainContainerTop}>
                No data Found
              </Typography>
            ) : (
              <Box component="div" className="p20">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography className={classes.heading}>{"Campaign Name:"}</Typography>
                    <Typography
                      component="h5"
                      className={classes.value}
                    >{data?.name}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography className={classes.heading}>{"Campaign Description:"}</Typography>
                    <Typography
                      component="h5"
                      className={classes.value}
                    > {data?.length > 0
                      ? data?.description === "" ||
                        data?.description === null
                        ? "NA"
                        : data.description
                      : "NA"}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={10}>
                    <Grid container spacing={3}>
                      <Grid item xs={6} sm={6} md={3}>
                        <Typography className={classes.heading}>
                          {"Vehicle:"}
                        </Typography>
                        {data?.channel?.length === 0 ||
                          data?.channel === null ||
                          !data ? (
                          <Typography
                            component="h5"
                            className={classes.value}
                          >
                            {"NA"}
                          </Typography>
                        ) : (
                          <>
                            {data?.channel?.includes("DM") && (
                              <Typography
                                className={classes.campIcon}
                                variant="inherit"
                              >
                                <SendIcon fontSize="small" /> DM
                              </Typography>
                            )}
                            {data?.channel?.includes("SMS") && (
                              <Typography
                                className={classes.campIcon}
                                variant="inherit"
                              >
                                <ChatBubbleIcon fontSize="small" /> SMS
                              </Typography>
                            )}
                            {data?.channel?.includes("Email") && (
                              <Typography
                                className={classes.campIcon}
                                variant="inherit"
                              >
                                <EmailOutlinedIcon fontSize="small" /> Email
                              </Typography>
                            )}
                            {data?.channel?.includes("Facebook") && (
                              <Typography
                                className={classes.campIcon}
                                variant="inherit"
                              >
                                <FacebookIcon fontSize="small" /> Facebook
                              </Typography>
                            )}
                            {data?.channel?.includes(
                              "Push Notification"
                            ) && (
                                <Typography
                                  className={classes.campIcon}
                                  variant="inherit"
                                >
                                  <NotificationsActiveOutlinedIcon fontSize="small" />{" "}
                                  Push Notification
                                </Typography>
                              )}
                          </>
                        )}
                      </Grid>
                      <Grid item xs={6} sm={6} md={3}>
                        <Typography className={classes.heading}>
                          {"Journey:"}
                        </Typography>
                        <Typography
                          component="h5"
                          className={classes.value}
                        >
                          {data ? data.testType : "NA"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={3}>
                        <Typography className={classes.heading}>
                          {"Created by:"}
                        </Typography>
                        <Typography
                          component="h5"
                          className={classes.value}
                        >
                          {data?.campaignOwner === null || !data
                            ? "NA"
                            : data?.campaignOwner}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={3}>
                        <Typography className={classes.heading}>
                          {"Status:"}
                        </Typography>
                        {data?.status ? (
                          <Button
                            className="p0"
                            startIcon={
                              data?.status === "COMPLETED" ||
                                data?.status === "Complete" ? (
                                <CheckCircleOutlineIcon
                                  style={{ color: "rgb(36, 161, 72)" }}
                                />
                              ) : (
                                <WatchLaterIcon />
                              )
                            }
                          >
                            {data?.status}
                          </Button>
                        ) : (
                          <Typography
                            component="h5"
                            className={classes.value}
                          >
                            {"NA"}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}>
                        <Typography className={classes.heading}>
                          {"Campaign Duration:"}
                        </Typography>
                        <Typography className={classes.durationArea}>
                          {!data ? (
                            "NA"
                          ) : (
                            <>
                              <DateRangeIcon fontSize="small" />
                              {Moment(data?.startDate?.value).format(
                                "MMM D, YYYY"
                              )}
                              {" - "}{" "}
                              {Moment(data?.endDate?.value).format(
                                "MMM D, YYYY"
                              )}
                            </>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}>
                        <Typography className={classes.heading}>
                          {"Campaign Pre Period:"}
                        </Typography>
                        <Typography className={classes.durationArea}>
                          {data?.preStartDate === null || !data ? (
                            "NA"
                          ) : (
                            <>
                              <DateRangeIcon fontSize="small" />
                              {Moment(data?.preStartDate?.value).format(
                                "MMM D, YYYY"
                              )}
                              {" - "}{" "}
                              {Moment(data?.preEndDate?.value).format(
                                "MMM D, YYYY"
                              )}
                            </>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}>
                        <Typography className={classes.heading}>
                          {"Group:"}
                        </Typography>
                        <Typography
                          component="h5"
                          className={classes.value}
                        >
                          {data?.campaignGroup === null || !data
                            ? "NA"
                            : data?.campaignGroup}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}>
                        <Typography className={classes.heading}>
                          {"Created on:"}
                        </Typography>
                        <Typography className={classes.durationArea}>
                          <DateRangeIcon fontSize="small" />
                          {data?.createDate
                            ? Moment(data?.createDate?.value).format(
                              "MMM D, YYYY"
                            )
                            : "NA"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={2}>
                        <Typography className={classes.heading}>
                          {"Control Customers:"}
                        </Typography>
                        <Typography
                          component="h5"
                          className={classes.value}
                        >
                          {customersData?.CONTROL_CUSTOMERS === null ||
                            !data
                            ? "NA"
                            : numberWithCommas(
                              customersData?.CONTROL_CUSTOMERS
                            )}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={12} md={2}>
                        <Typography className={classes.heading}>
                          {"Targeted Customers:"}
                        </Typography>
                        <Typography
                          component="h5"
                          className={classes.value}
                        >
                          {customersData?.TARGETED_CUSTOMERS === null ||
                            !data
                            ? "NA"
                            : numberWithCommas(
                              customersData?.TARGETED_CUSTOMERS
                            )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={2}>
                        <Typography className={classes.heading}>
                          {"Type Of Campaign:"}
                        </Typography>
                        <Typography
                          component="h5"
                          className={classes.value}
                        >
                          {data?.type_of_campaign === null ||
                            !data
                            ? "NA"
                            :
                            data?.type_of_campaign
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <Box
                      component="div"
                      className={
                        data?.stag_sig === null ? classes.redLiftBox :
                          data?.stag_sig === undefined ? classes.redLiftBox :
                            data?.stag_sig < 0.05 ? classes.greenLiftBox :
                              data?.stag_sig > 0.2 ? classes.redLiftBox :
                                classes.yellowLiftBox
                      }
                    >
                      <Typography className={classes.heading}>
                        {data?.primaryKpi ? data?.primaryKpi : ""}
                      </Typography>
                      {data?.pct_lift === null ? (
                        <Typography>{"NA"}</Typography>
                      ) : (
                        <Typography>
                          {data?.pct_lift < 0 ? (
                            <TrendingDownIcon />
                          ) : (
                            <TrendingUpIcon />
                          )}
                          {data?.pct_lift?.toFixed(2)}%
                        </Typography>
                      )}

                      <Typography>{"LIFT"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography className={classes.heading}>
                      {"Flight Test Id:"}
                    </Typography>
                    <Typography component="h5" className={classes.value}>
                      {flightData?.flight_test_id === null || !data
                        ? "NA"
                        : _.map(flightData, "flight_test_id").join(" , ")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography className={classes.heading}>
                      {"Flight Control Id:"}
                    </Typography>
                    <Typography component="h5" className={classes.value}>
                      {flightData?.flight_control_id === null || !data
                        ? "NA"
                        : _.map(flightData, "flight_control_id").join(
                          " , "
                        )}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
          </>
        )}
      </CommandCard>
    </Box >
  );
};

export default CampaignViewDetails;
