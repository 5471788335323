import React from "react";
import { Grid, Card, CardContent, Box } from "@material-ui/core";
import CreateCampaign from "./CreateCampaign";
import useStyles from './Style'



const CampaignDetails = (props) => {
  const classes = useStyles();
  
 
  return (
    <>
      <div className={classes.mainroot}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
           <Card elevation={2}>
              <CardContent>
                <Box component="div" className={classes.detailBlock}>
                  <CreateCampaign />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default CampaignDetails;
