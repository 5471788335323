export const LOADING = "LOADING";
export const MODALLOADING = "MODALLOADING";
export const DATE = "DATE";
export const ENABLEDEEPDIVE = "ENABLEDEEPDIVE";
export const TABNAVIGATION = "TABNAVIGATION";
export const NESTEDTABNAV = "NESTEDTABNAV";
export const LANDINGFILTERS = "LANDINGFILTERS";
export const STATUS = "STATUS";
export const DATERANGE = "DATERANGE";
export const NAME = "NAME";
export const BRANDS = "BRANDS";
export const FULFILMENT = "FULFILMENT";
export const CAMPCHANNEL = "CAMPCHANNEL";
export const TYPE = "TYPE";
export const SELECTEDDEEPDIVE = "SELECTEDDEEPDIVE";
export const KPISUMFILTERS = "KPISUMFILTERS";
export const KPISUMKPI = "KPISUMKPI";
export const KPISUMBRAND = "KPISUMBRAND";
export const KPISUMCHANNEL = "KPISUMCHANNEL";
export const KPISUMREGION = "KPISUMREGION";
export const MERCHFILTERS = "MERCHFILTERS";
export const MERCHKPI = "MERCHKPI";
export const MERCHBRAND = "MERCHBRAND";
export const MERCHCHANNEL = "MERCHCHANNEL";
export const MERCHREGION = "MERCHREGION";
export const MERCHMERCHHIERARCHY = "MERCHMERCHHIERARCHY";
export const ENGAGEFILTERS = "ENGAGEFILTERS";
export const ENGAGEKPI = "ENGAGEKPI";
export const ENGAGEBRAND = "ENGAGEBRAND";
export const ENGAGECHANNEL = "ENGAGECHANNEL";
export const ENGAGEREGION = "ENGAGEREGION";
export const ENGAGEDEVICE = "ENGAGEDEVICE";
export const ENGAGETOUCHBASE = "ENGAGETOUCHBASE";
export const DIGITALFILTERS = "DIGITALFILTERS";
export const DIGITALKPI = "DIGITALKPI";
export const DIGITALBRAND = "DIGITALBRAND";
export const DIGITALCHANNEL = "DIGITALCHANNEL";
export const DIGITALREGION = "DIGITALREGION";
export const DIGITALDEVICE = "DIGITALDEVICE";
export const DIGITALSESSION = "DIGITALSESSION";
export const DIGITALAPPWEB = "DIGITALAPPWEB";

export default {
  LOADING,
  MODALLOADING,
  DATE,
  ENABLEDEEPDIVE,
  TABNAVIGATION,
  NESTEDTABNAV,
  LANDINGFILTERS,
  STATUS,
  DATERANGE,
  NAME,
  BRANDS,
  FULFILMENT,
  CAMPCHANNEL,
  TYPE,
  SELECTEDDEEPDIVE,
  KPISUMFILTERS,
  KPISUMKPI,
  KPISUMBRAND,
  KPISUMCHANNEL,
  KPISUMREGION,
  MERCHFILTERS,
  MERCHKPI,
  MERCHBRAND,
  MERCHCHANNEL,
  MERCHREGION,
  MERCHMERCHHIERARCHY,
  ENGAGEFILTERS,
  ENGAGEKPI,
  ENGAGEBRAND,
  ENGAGECHANNEL,
  ENGAGEREGION,
  ENGAGEDEVICE,
  ENGAGETOUCHBASE,
  DIGITALFILTERS,
  DIGITALKPI,
  DIGITALBRAND,
  DIGITALCHANNEL,
  DIGITALREGION,
  DIGITALDEVICE,
  DIGITALSESSION,
  DIGITALAPPWEB,
};
