import { makeStyles } from '@material-ui/core'
import palette from 'theme/palette';

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  rootDropdown: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  cardContainer: {
    padding: '0 20px 20px',
  },
  cardArea: {
    padding: "10px",
  },
  kpiText: {
    color: "rgb(70, 74, 83)",
  },
  cardContainerInsights: {
    padding: 10,
  },
  kpiTextHeader: {
    fontWeight: 600,
    color: "rgb(70, 74, 83)",
    marginBottom: 5
  },
  graphTextArea: {
    textAlign: "center",
    paddingTop: 10,
    "& span": {
      color: "rgb(70, 74, 83)",
      fontWeight: 600
    }
  },
  perTextHeading: {
    fontWeight: 600,
    color: "rgb(70, 74, 83)",
    textAlign: "center",
    fontSize: "14px",

  },
  // recentage:{
  //   width:"100%",
  //   height:"25px",
  //   backgroundColor:"#F0BF0F",
  //   padding:"1px 0px",
  //   borderRadius:"4px"
  // },
  tabLevel: {
    textTransform: "capitalize",
    color: "rgb(70, 74, 83)",
  },
  filterContainer: {
    padding: "20px 0 0",
  },
  filterAreaDropDown: {
    margin: "0 0 10px 0",
    "& $input": {
      padding: 10,
    },
    "& $label": {
      transform: "translate(14px, 10px) scale(1)",
    },
    "& $div.MuiSelect-outlined.MuiSelect-outlined": {
      padding: 10,
    },
    "& button": {
      marginTop: 3
    },
    "& $input + $input": {
      height: "37px !important",
    },
  },
  formControl: { width: "100%" },
  tableRightBorder: {
    position: "sticky",
    top: -1,
    border: "2px solid",
    borderColor: palette.tableColors.background,
    backgroundColor: palette.tableColors.background,
    zIndex: 1,
    "& th": {
      border: "1px solid",
      borderColor: palette.tableColors.border,
      fontSize: 14,
      padding: "6px 16px",
      textAlign: "left",
      color: palette.tableColors.text,
      fontWeight: 400
    },
  },
  tableText: {
    color: "rgb(70, 74, 83)",
    fontWeight: 600,
    padding: "0 0 20px 0"
  },
  tableContainer: {
    height: "45vh",
    overflow: "auto",
    "& tbody": {
      "& th": {
        border: "1px solid",
        borderColor: palette.tableColors.border,
        fontSize: 12,
        padding: "6px 16px",
        textAlign: "left",
        fontWeight: 400,
        borderLeft: 0
      },
      "& td": {
        border: "1px solid",
        borderColor: palette.tableColors.border,
        fontSize: 12,
        padding: "6px 16px",
        textAlign: "left",
        fontWeight: 400
      }
    }
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    margin: "0 auto",
    // paddingTop: "65px"
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  listArea: {
    listStyle: "disc",
    marginBottom: 5,
    padding: "0 0 0 18px",
    "& li": {
      display: "list-item",
      color: palette.text.active,
      paddingBottom: 0,
      "& span": {
        fontWeight: 500,

      }
    }
  },
  disableSelect: {
    "& svg": {
      display: "none"
    }
  }
}));
