import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";

import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { AsyncPaginate } from "react-select-async-paginate";
import _ from "lodash";
import { numberWithCommas } from "../../Common/Utils/ClientUtil";
import { useSelector, useDispatch } from "react-redux";
import {
  addAudienceList,
  deleteAudienceList,
  updateAudienceList,
  clearAudienceList,
  addCollectionList,
  deleteCollectionList,
  fillCollectionList,
  updateCollectionList,
} from "../../../redux/campDetail/campaignDetailRedux";
import  useStyles  from './Style'


const AudienceList = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { searchData, handleAudModal, segType } = props;
  // Redux states
  const searchFilterData = useSelector((state) => [
    ...state.campDetail.audienceList,
  ]);
  const audData = useSelector((state) => [...state.campDetail.collectionList]);
  // Local states
  const [controlCustomers, setControlCustomers] = useState([]);
  const [collectionName, setCollectionName] = useState("");
  const [collectionDuration, setCollectionDuration] = useState(null);
  const [, setControlValue] = useState("");
  const [searchSelection, setSearchSelection] = useState([]);

  // Delete test control mapping (not in use)
  const removeTCMapping = (val, data) => {
    let searchData = data.filter(
      (option) => option.value !== val
    )
    setSearchSelection(searchData)
    dispatch(deleteAudienceList(val));
    if (searchFilterData.length === 1) {
      setCollectionName("");
      setCollectionDuration(null)
    }
  };
  // preparing data for test control mapping
  const testControlMapping = (val, newVal) => {
    if (newVal != null) {
      let objIndex = searchFilterData.findIndex(
        (obj) => obj.test_id === val.test_id
      );
      dispatch(updateAudienceList({ objIndex, newVal }));
    }
  };
  // Create Unique ID for collection
  const generateUID = () => {
    let firstPart = (Math.random() * 46656) | 0;
    let secondPart = (Math.random() * 46656) | 0;
    firstPart = ("000" + firstPart.toString(36)).slice(-3);
    secondPart = ("000" + secondPart.toString(36)).slice(-3);
    return firstPart + secondPart;
  }
  // Create collection
  const handleAddCollection = () => {
    let newArr = {
      testControlMap: searchFilterData,
      collectionName: collectionName,
      collectionDuration: collectionDuration,
      uID: generateUID()
    };
    dispatch(addCollectionList(newArr));
    dispatch(clearAudienceList([]));
    setCollectionName("");
    setCollectionDuration(null)
    setSearchSelection([]);
  };
  // Update collection
  const handleUpdateCollection = () => {
    let finalData = audData.map(obj =>
      obj.collectionName === collectionName ? {
        ...obj,
        collectionDuration: collectionDuration,
        testControlMap: searchFilterData
      } : obj
    );
    dispatch(updateCollectionList(finalData))
    dispatch(clearAudienceList([]));
    setCollectionName("");
    setCollectionDuration(null)
    setSearchSelection([]);
  };
  //  Delete collection
  const removeCollection = (val) => {
    dispatch(deleteCollectionList(val));
  };
  // preparing test data for loadTestOptions
  const options = _.chain(searchData)
    .map((i) => ({
      value: i.test_name,
      label: i.test_name,
    }))
    .orderBy(["label"], ["asc"])
    .value();

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(undefined);
      }, ms);
    });
  // Test dropdown data
  const loadTestOptions = async (search, prevOptions) => {
    await sleep(1000);
    let filteredOptions;
    if (!search) {
      filteredOptions = options;
    } else {
      const searchLower = search?.toLowerCase();

      filteredOptions = options?.filter(({ label }) =>
        label?.toLowerCase()?.includes(searchLower)
      );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );

    return {
      options: slicedOptions,
      hasMore,
    };
  };
  // Control dropdown data
  const loadControlOptions = async (search, prevOptions) => {
    setControlValue(searchFilterData.control_name);
    await sleep(1000);
    let filteredOptions;
    if (!search) {
      filteredOptions = controlCustomers;
    } else {
      const searchLower = search?.toLowerCase();
      filteredOptions = controlCustomers?.filter(({ label }) =>
        label?.toLowerCase()?.includes(searchLower)
      );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );

    return {
      options: slicedOptions,
      hasMore,
    };
  };

  useEffect(() => {
    let controlCustomersData = [...controlCustomers];
    controlCustomersData.push({
      value: null,
      label: "Non Linked",
      cout: null,
      id: null
    });
    _.forEach(searchData, function (i) {
      if (i.control_name !== null) {
        controlCustomersData.push({
          value: i.control_name,
          label: i.control_name,
          cout: i.cust_cout,
          id: i.control_id
        });
      }
      controlCustomersData.push({
        value: i.test_name,
        label: i.test_name,
        cout: i.test_count,
        id: i.test_id
      });
      setControlCustomers(controlCustomersData);
    });
  }, []);
  // Test dropdown style
  const testSearchStyle = { multiValueRemove: (base) => ({ ...base, display: 'none' }) }

  //validation for CollectionName
  const validateCollectionName = (val) => {
    const regexCheck = /^[a-zA-Z0-9_'|.?,()@ [\]]+$/
    let error = ""
    if (val?.trim() === '') {
      error = "Required."
    } else if (!regexCheck?.test(val)) {
      error = "Some special characters are not allowed";
    }
    return error;
  };


  return (
    <>
      {searchData && controlCustomers.length ? (
        <>
          <Grid container spacing={3} className={classes.audienceListArea}>
            <Grid item md={2}>
              {audData?.length ? (
                <>
                  {audData?.map((item, index) => (
                    <Box className={classes.collectionArea} key={index}>
                      <Typography component="span">
                        {item.collectionName}
                        {segType === "Reusable segments" &&
                          <Typography component="span" className={classes.duration}>
                            {item.collectionDuration}{"days"}
                          </Typography>}
                        <DeleteOutlineIcon
                          className={classes.collectionIcon}
                          onClick={(e) => {
                            removeCollection(item?.collectionName);
                          }}
                        />
                        <EditOutlinedIcon
                          className={classes.collectionIcon}
                          onClick={(e) => {
                            dispatch(fillCollectionList(item.testControlMap));
                            setCollectionName(item?.collectionName);
                            setCollectionDuration(item?.collectionDuration)
                            let editFinalData = [];
                            _.forEach(item?.testControlMap, function (i) {
                              editFinalData.push({
                                value: i?.test_name,
                                label: i?.test_name,
                              });
                            });
                            setSearchSelection(editFinalData);
                          }}
                        />

                        <Typography>{item?.testControlMap?.test_id}</Typography>
                        {item?.testControlMap?.map((i, index) => (
                          <Typography key={index}>{i.test_id}</Typography>
                        ))}
                      </Typography>
                    </Box>
                  ))}
                </>
              ) : (
                <Typography component="span">
                  {"No collection found"}
                </Typography>
              )}
            </Grid>
            <Grid item md={10} className={"borderLeft"}>
              <Grid container spacing={3}>
                <Grid item md={12}>
                  <AsyncPaginate
                    className="asyncInput"
                    value={searchSelection}
                    loadOptions={loadTestOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    isClearable={false}
                    styles={testSearchStyle}
                    onChange={(e) => {
                      const dataSet = searchData.filter((i) =>
                        e.find(({ value }) => i.test_name === value)
                      );
                      const finalDataset = _(dataSet)
                        .differenceBy(searchFilterData, 'test_name')
                        .value()[0];
                      if (finalDataset !== undefined) {
                        dispatch(addAudienceList(finalDataset));
                        setSearchSelection(e);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={segType === "Reusable segments" ? 4 : 12}>
                  <TextField
                    name={"COLLECTIONNAME"}
                    label="Enter Collection Name"
                    variant="outlined"
                    autoComplete="off"
                    type="text"
                    value={collectionName}
                    className={classes.formControl}
                    onChange={(e) => setCollectionName(e.target.value)}
                  />
                  {validateCollectionName(collectionName) === "" || validateCollectionName(collectionName) === "Required."
                    ? ""
                    : <Typography className={classes.errorText}>{validateCollectionName(collectionName)}</Typography>}
                  {_.some(audData.flat(1), [
                    "collectionName",
                    collectionName,
                  ]) ? <Typography className={classes.errorText}>Collection name already exists.</Typography> : ""}
                </Grid>
                <Grid item xs={4} style={{ display: segType === "Reusable segments" ? "block" : "none" }}>
                  <TextField
                    name={"COLLECTIONDURATION"}
                    label="Enter Duration"
                    variant="outlined"
                    autoComplete="off"
                    type="number"
                    value={parseInt(collectionDuration)}
                    className={classes.formControl}
                    onChange={(e) => setCollectionDuration(parseInt(e.target.value))}
                  />
                </Grid>
                <Grid item md={6}>
                  <Typography>Test Customer Selection(s)</Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography>Control Customer Collection(s)</Typography>
                </Grid>

                {searchFilterData?.length ? (
                  searchFilterData?.map((item, index) => (
                    <React.Fragment key={index}>
                      <Grid item md={6}>
                        <Box className={classes.audListTest}>
                          <Grid container spacing={3}>
                            <Grid item md={10}>
                              <Typography>
                                {item?.test_name}
                              </Typography>
                            </Grid>
                            <Grid item md={2} className="pl0 pr0">
                              <Typography>
                                <PeopleAltOutlinedIcon fontSize={"small"} />
                                <Typography component="span">
                                  {item?.test_count
                                    ? numberWithCommas(item?.test_count)
                                    : ""}
                                </Typography>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item md={6}>
                        <Box className={classes.audListControl}>
                          <Grid container spacing={3}>
                            <Grid item md={9}>
                              <AsyncPaginate
                                className="asyncInput"
                                value={{
                                  value: item?.control_id,
                                  label: item?.control_name === null ? "Non Linked" : item?.control_name,
                                }}
                                loadOptions={loadControlOptions}
                                onChange={(e) => {
                                  setControlValue();
                                  testControlMapping(item, e);
                                }}
                              />
                            </Grid>
                            <Grid item md={2} className="pl0 pr0">
                              <Typography component="h6">
                                <PeopleAltOutlinedIcon fontSize={"small"} />
                                <Typography component="span">
                                  {item?.cust_cout
                                    ? numberWithCommas(item.cust_cout)
                                    : ""}
                                </Typography>
                              </Typography>
                            </Grid>
                            <Grid item md={1} className="pl0 pr0">
                              <DeleteOutlineIcon
                                className={classes.deleteIcon}
                                onClick={(e) => {
                                  removeTCMapping(item?.test_name, searchSelection);
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </React.Fragment>
                  ))
                ) : (
                  <Grid item md={12} className={classes.noDataMsg}>
                    <Typography>No selection found</Typography>
                  </Grid>
                )}

                <Grid item xs={12} className={"textRight"}>
                  <Button
                    style={{
                      display: _.some(audData.flat(1), [
                        "collectionName",
                        collectionName,
                      ])
                        ? "inline-flex"
                        : "none",
                    }}
                    className={classes.cancelBtn}
                    type="button"
                    variant="contained"
                    onClick={handleUpdateCollection}
                    disabled={segType === "Reusable segments" ?
                      !(collectionName.replace(/\s/g, '').length && searchFilterData.length > 0 && collectionDuration)
                      :
                      !(collectionName.replace(/\s/g, '').length && searchFilterData.length > 0)
                    }
                  >
                    Update Collection
                  </Button>
                  <Button
                    style={{
                      display: !_.some(audData.flat(1), [
                        "collectionName",
                        collectionName,
                      ])
                        ? "inline-flex"
                        : "none",
                    }}
                    className={classes.cancelBtn}
                    type="button"
                    variant="contained"
                    onClick={handleAddCollection}
                    disabled={segType === "Reusable segments" ?
                      !(collectionName.replace(/\s/g, '').length && searchFilterData.length > 0 && collectionDuration)
                      :
                      !(collectionName.replace(/\s/g, '').length && searchFilterData.length > 0)
                    }
                  >
                    Add Collection
                  </Button>
                  <Button
                    className={classes.cancelBtn}
                    type="button"
                    variant="contained"
                    onClick={(e) => {
                      dispatch(clearAudienceList([]));
                      handleAudModal();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.primaryBtn}
                    type="button"
                    variant="contained"
                    color="primary"
                    disabled={audData.length === 0}
                    onClick={(e) => {
                      dispatch(clearAudienceList([]));
                      handleAudModal();
                    }}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        "No Data Found"
      )
      }
    </>
  );
};

export default AudienceList;
