import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Card, CardContent } from "@material-ui/core";
import BarChartVerticalNegative from "../Common/Charts/BarChartVerticalNegative";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  accordBlock: {
    background: "#ffffff",
    "& h4": { color: "#333333", marginBottom: 10 },
    "& h5": { color: "#333333" },
  },
  cardItem: {
    background: "#FFFFFF",
    boxShadow: "0px 20px 48px -12px rgba(0, 0, 0, 0.2)",
    borderRadius: 2,
    marginBottom: 25,
    "& h6": {
      color: "#929497",
    },
  },
}));

export default function CustomerInsights(props) {
  const classes = useStyles();
  const { data, testControlChecked } = props;
  const [cltvData, setCltvData] = useState([])
  const [lifecycleData, setLifecycleData] = useState([])

  useEffect(() => {
    setCltvData(data?.finalResult?.cltvResult)
    setLifecycleData(data?.finalResult?.liecycleResult)
  }, [data, testControlChecked]);
  return (
    <>
      {testControlChecked && (
        <Grid container spacing={3} className={classes.accordBlock}>
          <Grid item xs={12} sm={12} md={6}>
            <Card className={classes.cardItem} elevation={1}>
              <CardContent>
                <Typography component="h4" variant="subtitle1">
                  Analysis Period  - CLTV
                </Typography>
                <BarChartVerticalNegative
                  data={cltvData?.cltvPostData?.modifiedDataPostCltv?.filter(item => item?.data !== null)}
                  xAxisTitle={"Buckets"}
                  yAxisTitle={"% Change"}
                  categories={_.keys(_.mapValues(_.groupBy(cltvData?.cltvPostData?.modifiedDataPostCltv, 'name')))}
                  horizontal={false}
                  title={""}
                  tooltipData={cltvData?.cltvPostData?.modifiedDataPostCltv}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Card className={classes.cardItem} elevation={1}>
              <CardContent>
                <Typography component="h4" variant="subtitle1">
                  Analysis Period  - Lifecycle
                </Typography>
                <BarChartVerticalNegative
                  data={lifecycleData?.lifeCyclePostData?.modifiedDataPostLifecycle?.filter(item => item?.data !== null)}
                  xAxisTitle={"Buckets"}
                  yAxisTitle={"% Change"}
                  categories={_.keys(_.mapValues(_.groupBy(lifecycleData?.lifeCyclePostData?.modifiedDataPostLifecycle, 'name')))}
                  horizontal={false}
                  title={""}
                  tooltipData={lifecycleData?.lifeCyclePostData?.modifiedDataPostLifecycle}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      {!testControlChecked && (
        <Grid container spacing={3} className={classes.accordBlock}>
          <Grid item xs={12} sm={12} md={6}>
            <Card className={classes.cardItem} elevation={1}>
              <CardContent>
                <Typography component="h4" variant="subtitle1">
                  Pre-Post Period  - CLTV
                </Typography>
                <BarChartVerticalNegative
                  data={cltvData?.cltvPredata?.modifiedDataPreCltv?.filter(item => item?.data !== null)}
                  xAxisTitle={"Buckets"}
                  yAxisTitle={"% Change"}
                  categories={_.keys(_.mapValues(_.groupBy(cltvData?.cltvPredata?.modifiedDataPreCltv, 'name')))}
                  horizontal={false}
                  title={""}
                  tooltipData={cltvData?.cltvPredata?.modifiedDataPreCltv}
                  preData={true}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Card className={classes.cardItem} elevation={1}>
              <CardContent>
                <Typography component="h4" variant="subtitle1">
                  Pre-Post Period  - Lifecycle
                </Typography>
                <BarChartVerticalNegative
                  data={lifecycleData?.lifeCyclePredata?.modifiedDataPreLifecycle?.filter(item => item?.data !== null)}
                  xAxisTitle={"Buckets"}
                  yAxisTitle={"% Change"}
                  categories={_.keys(_.mapValues(_.groupBy(lifecycleData?.lifeCyclePredata?.modifiedDataPreLifecycle, 'name')))}
                  horizontal={false}
                  title={""}
                  tooltipData={lifecycleData?.lifeCyclePredata?.modifiedDataPreLifecycle}
                  preData={true}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
}
