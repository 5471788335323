import React, { useEffect, useState } from "react";
import ReactApexCharts from "react-apexcharts";


const BasicStackedBar = (props) => {
  const [chartInit, setChartInit] = useState();
  const { data, categories, xAxisLabel, horizontal, total, colors } = props;
  useEffect(() => {
    setChartInit({
      series: data,
      options: {
        chart: {
          type: 'bar',
          toolbar: { show: false },
          stacked: true,
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            return total ? val + " M" : val
          },
        },
        plotOptions: {
          bar: {
            horizontal: horizontal,
            dataLabels: {
              // position: total ? 'top' : 'middle',
              total: {
                enabled: total,
                formatter: function (val) {
                  return total ? val?.toFixed(2) + " M" : val?.toFixed(2)
                },
              },
            },
          },
        },
        colors: colors ? colors : ["#680891", "#8A0BC1", "#BD3EF4", "#CE6EF7", "#DE9FF9"],
        grid: {
          show: false,
          padding: {
            left: 0,
            top: -20,
            bottom: 0,
          },
        },
        xaxis: {
          categories: categories,
          title: {
            text: xAxisLabel ? xAxisLabel : "",
            style: {
              color: undefined,
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-title',
            },
          },
        },
        yaxis: {
          show: false,
          tickAmount: 6,
          tickPlacement: "between",
          labels: {
            show: true,
            maxWidth: 160,
          },
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
        },
        legend: {
          show: true,
          position: 'bottom',
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
              ${w?.config?.xaxis?.categories[dataPointIndex]}
              </div>
              <div class="apexcharts-tooltip-series-group" style="order: 1; display: flex;"><span class="apexcharts-tooltip-marker"
                      style="background-color: ${w?.globals?.colors[seriesIndex]
              };"></span>
                  <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                      <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">${w?.config?.series[seriesIndex]?.name
              }: </span>
                          <span class="apexcharts-tooltip-text-y-value">${series[seriesIndex][dataPointIndex]
              }</span>
                      </div>
                  </div>
              </div>
              <div class="apexcharts-tooltip-series-group" style="order: 2; display: flex;">
      <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
     
      </div>
  </div>
              `;
          },
          //HTML code for total value in tooltip
          //     <div class="apexcharts-tooltip-y-group">
          //     <span class="apexcharts-tooltip-text-y-label">Total: </span>
          //     <span class="apexcharts-tooltip-text-y-value">
          //     ${series.map((s) => s[dataPointIndex]).reduce((a, b) => a + b, 0)}
          //       </span>
          // </div>
        },
      }
    });
  }, [data,categories,colors,horizontal,total,xAxisLabel]);

  return (
    <>
      {chartInit && (
        <>
          <ReactApexCharts
            options={chartInit?.options}
            series={chartInit?.series}
            type="bar"
            height={categories?.length > 10 ? 900 : 250}
          />
        </>
      )}
    </>
  );
};
export default BasicStackedBar;
