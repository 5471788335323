import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { TextField, Typography } from '@material-ui/core';
import { ErrorMessage, Field } from 'formik';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '30%'
  },
  errorText: {
    color: 'red',
    fontSize: 12
  }
}));

const MultilineTextField = props => {
  const classes = useStyles();
  const { name, label, width,inputProps,...rest } = props;
  return (
    <>
      <Field
        as={TextField}
        variant='outlined'
        className={width ? width : classes.formControl}
        name={name}
        multiline
        inputProps={inputProps}
        rows={2}
        label={label}
        rest={rest}
      />
      <Typography className={classes.errorText}>
        <ErrorMessage name={name} />
      </Typography>
    </>
  );
};

export default MultilineTextField;
