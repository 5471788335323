import React from "react";
import BasicStackedBar from "../Common/Charts/BasicStackedBarChart";
import { Typography, Grid } from "@material-ui/core";

const MeasureStackedChart = (props) => {
  const { data } = props;
  const info = {
    padding: "40px 0 !important",
    textAlign: "center",
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={1}></Grid>
      <Grid item xs={10}>
        {data ? (
          <BasicStackedBar
            xAxisLabel={"Lift %"}
            data={data?.graphData?.data}
            categories={data.graphData?.categories}
            label={data?.segmentName}
            horizontal={true}
            total={false}
          />
        ) : (
          <Typography component="h3" variant="h3" style={info}>
            {"No records to display"}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default MeasureStackedChart;
