import { makeStyles } from '@material-ui/core'


export default makeStyles((theme) => ({
    flexGrow: {
        flexGrow: 1,
      },
      grow: {
        flexGrow: 1,
      },
    
    
      navItems: {
        flexGrow: 1,
        display: "flex",
        padding: 0,
        "& li": {
          width: "auto",
          padding: 0,
        },
        "& li:nth-child(2)": {
          paddingLeft: 0,
        },
      },
      item: {
        margin: "0 10px !important",
        textTransform: "none",
        fontSize: 16,
        padding: 0,
        borderBottom: "1px solid transparent",
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      active: {
        borderBottom: "2px solid #182C52",
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightMedium,
        borderRadius: 0,
        "& $icon": {
          color: theme.palette.primary.contrastText,
        },
      },
    
      userIcon: {
        verticalAlign: "bottom",
      },
    
      text: {
        color: theme.palette.text.secondary,
        "& button": {
          verticalAlign: "bottom",
        },
      },
      textData:{
        color: "#182C52",
        fontWeight:"600",
        size:"11px"
      },
      topBar: {
        backgroundColor: theme.palette.background.paper,
        zIndex: 12,
        "& p:first-child": {
          color: "#333333",
          fontSize: 18,
          fontWeight: 500,
          paddingLeft: 20,
        },
      },
}));