import React from "react";
import { makeStyles } from "@material-ui/styles";
import { TextField, Typography } from "@material-ui/core";
import { ErrorMessage, Field } from "formik";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  errorText: {
    color: "red",
    fontSize: 12,
  },
}));

const TextFieldElement = (props) => {
  const classes = useStyles();
  const { name, label, width, disabled } = props;
  return (
    <>
      <Field
        as={TextField}
        className={width ? width : classes.formControl}
        name={name}
        label={label}
        variant="outlined"
        disabled={typeof disabled != 'undefined'?disabled:false}
      />
      <Typography className={classes.errorText}>
        <ErrorMessage name={name} />
      </Typography>
    </>
  );
};

export default TextFieldElement;
