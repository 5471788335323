import React from "react";
import { Box } from "@material-ui/core";
import CampaignDetails from "./CampaignDetail/index";
import CampaignReview from "./CampainOverview/Index";
import DashBoardHome from "./DesignHome/index";
import ReactBreadcrumbs from "views/Common/Breadcrumb/Breadcrumb";
import { PATHS } from "../../config";

const DesignCampaign = (props) => {
  
  const url = props.location.pathname;
  return (
    <>
      <Box style={{ background: "#F8F8F8", marginTop: "-1%", height: "100%" }}>
        <Box component={"div"} style={{ margin: "0% 0% 0% 2%" }}>
          <ReactBreadcrumbs />
        </Box>
        {url === PATHS.REVIEW_CAMPAIGN ? (
          <CampaignReview />
        ) : url === PATHS.CREATE_CAMPAIGN ? (
          <CampaignDetails />
        ) : (
          <DashBoardHome />
        )}
      </Box>
    </>
  );
};

export default DesignCampaign;
