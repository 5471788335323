import React from 'react';
import './Style.js';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import success from '../../../assets/success.svg';
import error from '../../../assets/waring.svg';
import confirm from '../../../assets/confirm.svg';
import useStyles from './Style';

const MessageDialog = ({
  open,
  close,
  message,
  type,
  handleButton,
  buttonType = true
}) => {
  const classes = useStyles();
  if (!open) return null;

  //Please pass confirm ,success or error in type as pros
  //function for imagecheck dynamic
  function imageCheck() {
    switch (type) {
      case 'success':
        return success;
      case 'error':
        return error;
      case 'waring':
        return confirm;
        default:
          return ;
    }
  }

  return (
    <>
      <div>
        <Dialog
          classes={{ root: classes.root, paper: classes.paper }}
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <div style={{ padding: '15px' }}>
            <div className={classes.titleContainer}>
              <img src={imageCheck(type)} width="50" alt="" />
            </div>
            <div className={classes.textMessage}>{message}</div>
            {buttonType ? (
              <div className={classes.buttonConatiner}>
                <Button
                  onClick={close}
                  variant="contained"
                  className={classes.buttonPorperty}>
                  Okay
                </Button>
              </div>
            ) : (
              <div className={classes.buttonInnerConatiner}>
                <Button
                  onClick={handleButton}
                  variant="contained"
                  className={classes.buttonPorperty}>
                  Delete
                </Button>
                <Button
                  onClick={close}
                  variant="outlined"
                  className={classes.buttonPorpertyCancel}>
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default React.memo(MessageDialog);
