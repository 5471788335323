import React from "react";
import NoWrapper from "./Common/NoWrapper";
const Forbidden403 = (props) => {
    return (
        <>
            <NoWrapper
                heading={`Sorry, you don't have access to this page  :(`}
                subHeading={`You don't have access to this page.`}
                showBtn={true}
                btnPath={`/design-campaign`}
                btnText={'Go Home'}
            />
        </>
    );
};

export default Forbidden403;
