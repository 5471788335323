import React, { useState, useEffect } from "react";
import NestedTabSection from "../Common/Utils/NestedTabs";
import KpiSummary from "./KpiSummary";
import MerchHierarchy from "./MerchHierarchy";

export default function Transactional(props) {
  const [tabItems, setTabItems] = useState([]);
  const { data,  outlierChecked,campaignId,testControlChecked } = props;
  // console.log("props value T",props)
  const campTabs = [
    {
      id: 1,
      title: "Metrics",
    },
    {
      id: 2,
      title: "Hierarchy",
    },
  ];
  
  useEffect(() => {
    setTabItems(campTabs);
  }, []);
  return (
    <NestedTabSection
      data={data}
      campaignId={campaignId}
      outlierChecked={outlierChecked} 
      testControlChecked={testControlChecked}
      tabItems={tabItems}
      Tab1={KpiSummary}
      Tab2={MerchHierarchy}
    />
  );
}
