import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

import { format } from "date-fns";



const TimelineChart = (props) => {

  const [chartInit, setChartInit] = useState();
  const { data } = props;
  

  const d = [
    {
      name: "Growth",
      data: [
        {
          x: "A1hhhhhhhhhhhhhhhhhhhhhhhhhh",
          y: [new Date("2020-01").getTime(), new Date("2021-02").getTime()],
          z: 230000,
        },
      ],
    },
    {
      name: "Acquisition",
      data: [
        {
          x: "B1",
          y: [new Date("2020-03").getTime(), new Date("2021-04").getTime()],
          z: 354623,
        },
      ],
    },
    {
      name: "Retention",
      data: [
        {
          x: "C1",
          y: [new Date("2021-05").getTime(), new Date("2021-12").getTime()],
          z: 909023,
        },
      ],
    },
    {
      name: "Awareness",
      data: [
        {
          x: "D1",
          y: [new Date("2021-08").getTime(), new Date("2023-12").getTime()],
          z: 787823,
        },
      ],
    },
    {
      name: "Schedule",
      data: [
        {
          x: "E1",
          y: [new Date("2020-09").getTime(), new Date("2022-01").getTime()],
          z: 898923,
        },
      ],
    },
  ];

  useEffect((props) => {
    // console.log(data['timeline'], 'timeline useeefect');
    setChartInit({
      series: data ? data["timeline"] : d,
      // series: d,
      options: {
        chart: {
          height: 60,
          // width: '100%',
          type: "rangeBar",
          toolbar: {
            show: true,
            // offsetX: -500,
          },
          zoom: {
            enabled: true,
          },
          
        },
        colors: ["#CEA3FF", "#F9D2D4", "#F99FF0", "#9FF9F9", "#F9DA9F"],
        plotOptions: {
          bar: {
            horizontal: true,
            // distributed: true,
            dataLabels: {
              hideOverflowingLabels: false,
            },
            barHeight: "50%",
            rangeBarGroupRows: true,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function(val, obj) {
           
            let campName =
              obj.w.config.series[obj.seriesIndex].data[obj.dataPointIndex]?.x;
            var label = "Total Customer";
            var value =
              obj.w.config.series[obj.seriesIndex].data[obj.dataPointIndex].z;
            let text = label + ":" + value;
            return campName;
          },

          

          style: {
            colors: ["#333333"],
          },
         
        },
       
        xaxis: {
          type: "datetime",
         
          tickAmount: 19,
          
        
          tickPlacement: "on",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },

          labels: {
            formatter: function(value, i) {
              // console.log(value, data["weekStartDay"], "y axis");
              const yDate = format(new Date(value), "MM-dd-yy");
              return yDate;
            },
          },
        },
        yaxis: {
          show: true,

          labels: {
            formatter: function(value) {
              return `${
                value.length > 25
                  ? String(value).substring(0, 30) + "..."
                  : value
              }`;
            },
          },
        },

        grid: {
          show: true,
          borderColor: "#E8E8E8",
          strokeDashArray: 7,
          position: "back",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          row: {
            colors: undefined,
            opacity: 0,
          },
          column: {
            colors: undefined,
            opacity: 0,
          },
        },
        tooltip: {
          custom: function({ seriesName, seriesIndex, dataPointIndex, w }) {
            var data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            const campType = w.globals.initialSeries[seriesIndex].name;
            const campName =
              w.config.series[seriesIndex].data[dataPointIndex].x;
            const count = w.config.series[seriesIndex].data[dataPointIndex].z;
            const dateRange =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex].y;

            return (
              "<div className={timelineTooltip}>" +
              "<ul >" +
              "<li ><b>Campaign Name:</b> " +
              campName +
              // ":   " +
              // format(dateRange[0], "dd.MMM.yyyy") +
              // "-" +
              // format(dateRange[1], "dd.MMM.yyyy") +
              "</li>" +
              "<li style={fontWeight:'bold,color:'green'}><b>Campaign Type:</b> " +
              campType +
              "</li>" +
              "<li><b>Total Customer:</b> " +
              count +
              "</li>" +
              "<li><b> Start Date : </b> " +
              format(dateRange[0], "dd-MMM-yyyy") +
              "</li>" +
              "<li><b>End Date: </b> " +
              format(dateRange[1], "dd-MMM-yyyy") +
              "</li>" +
              "<li><b>Primary Kpi : </b> " +
              data?.primaryKPI +
              "</li>" +
              "</ul>" +
              "</div>"
            );
          },
        },

       
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          showForSingleSeries: true,
          offsetY: 8,
        },
      },
    });
  }, []);

 

  return (
    <React.Fragment>
      {chartInit && (
        <>
          <ReactApexChart
            options={chartInit.options}
            series={chartInit.series}
            type="rangeBar"
            height={400}
          />
        </>
      )}
    </React.Fragment>
  );
};
export default TimelineChart;

