import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Typography,
  ListItemText
} from "@material-ui/core";
import { ErrorMessage } from "formik";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "30%",
  },
  errorText: {
    color: "red",
    fontSize: 12,
  },
}));

function SingleSelectDropdown(props) {
  const classes = useStyles();
  const { value, name, width, handleMethod, options, noneRequired, allRequired } = props;
  return (
    <FormControl
      className={width ? width : classes.formControl}
      variant="outlined"
    >
      <InputLabel id="demo-simple-select-outlined-label">
        {props.label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        name={name}
        value={typeof value === 'string' ? value : value[name]}
        onChange={handleMethod}
        label={props.label}
      >
        {noneRequired && <MenuItem value="">{"None"}</MenuItem>}
        {allRequired && <MenuItem value="All">{"All"}</MenuItem>}
        {options?.length > 0
          ? options?.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))
          : <ListItemText classes={{ primary: classes.selectAllText }}
            primary={"No Data Found"}
          />
        }
      </Select>
      <Typography className={classes.errorText}>
        <ErrorMessage name={name} />
      </Typography>
    </FormControl>
  );
}

export default SingleSelectDropdown;
