import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import BarChartNegativeResponseRate from "views/Common/Charts/BarChartNegativeResponseRate";
import { Form, Formik } from "formik";
import MultiSelectDropdown from "../Common/Filters/MultiSelectDropdown";

const useStyles = makeStyles((theme) => ({
    filterArea: {
        width: "100%",
        float: "left",
        margin: "30px 20px 10px"
    }
}))

const CustomExpandView = (props) => {
    const { dialogData, kpiName, testControlChecked } = props
    const classes = useStyles();
    return (
        <Grid container spacing={3}>
            <Formik
                initialValues={{
                    DIVISION: _.map(dialogData.segmentDatapts, "dataPointName"),
                    DEPARTMENT: _.map(dialogData.segmentDatapts, "dataPointName"),
                }}
                enableReinitialize={true}
                onSubmit={(values) => { }}
            >
                {({ values, setFieldValue }) => (
                    <>
                        <Form noValidate autoComplete="off" className={classes.filterArea}>
                            < Grid
                                container
                                spacing={3}
                            >
                                {dialogData.segmentName === 'Division' && <Grid item md={3}>
                                    <MultiSelectDropdown
                                        width={"w100"}
                                        label={"Division"}
                                        name={"DIVISION"}
                                        value={values.DIVISION}
                                        handleMethod={(value) => {
                                            setFieldValue("DIVISION", value);

                                        }}
                                        options={_.map(dialogData.segmentDatapts, "dataPointName")}
                                    />
                                </Grid>}
                                {dialogData.segmentName === 'Dept' && <Grid item md={3}>
                                    <MultiSelectDropdown
                                        width={"w100"}
                                        label={"Department"}
                                        name={"DEPARTMENT"}
                                        value={values.DEPARTMENT}
                                        handleMethod={(value) => {
                                            setFieldValue("DEPARTMENT", value);
                                        }}
                                        options={_.map(dialogData.segmentDatapts, "dataPointName")}
                                    />
                                </Grid>}
                            </Grid>
                        </Form>
                        {dialogData.segmentName === 'Division' &&
                            <>
                                <Grid item xs={6}>
                                    <BarChartNegativeResponseRate
                                        data={dialogData.segmentDatapts.filter(item => values.DIVISION.includes(item.dataPointName))}
                                        kpiName={kpiName}
                                        xAxisTitle={"Response Rate Absolute Lift"}
                                        yAxisTitle={dialogData.segmentName}
                                        categories={values.DIVISION}
                                        height={600}
                                        testControlChecked={testControlChecked}
                                    />

                                </Grid>

                                <Grid item xs={6}>
                                    <BarChartNegativeResponseRate
                                        data={dialogData.segmentDatapts.filter(item => values.DIVISION.includes(item.dataPointName))}
                                        kpiName={kpiName}
                                        xAxisTitle={"Campaign Purchasers Absolute Lift"}
                                        yAxisTitle={dialogData.segmentName}
                                        categories={values.DIVISION}
                                        height={600}
                                        testControlChecked={testControlChecked}
                                    />
                                </Grid>
                            </>
                        }
                        {dialogData.segmentName === 'Dept' &&
                            <>
                                <Grid item xs={6}>
                                    <BarChartNegativeResponseRate
                                        data={dialogData.segmentDatapts.filter(item => values.DEPARTMENT.includes(item.dataPointName))}
                                        kpiName={kpiName}
                                        xAxisTitle={"Response Rate Absolute Lift"}
                                        yAxisTitle={dialogData.segmentName}
                                        categories={values.DEPARTMENT}
                                        height={1500}
                                        testControlChecked={testControlChecked}
                                    />

                                </Grid>

                                <Grid item xs={6}>
                                    <BarChartNegativeResponseRate
                                        data={dialogData.segmentDatapts.filter(item => values.DEPARTMENT.includes(item.dataPointName))}
                                        kpiName={kpiName}
                                        xAxisTitle={"Campaign Purchasers Absolute Lift"}
                                        yAxisTitle={dialogData.segmentName}
                                        categories={values.DEPARTMENT}
                                        height={1500}
                                        testControlChecked={testControlChecked}
                                    />
                                </Grid>
                            </>
                        }
                    </>
                )}
            </Formik>


        </Grid>
    );
};
export default CustomExpandView;