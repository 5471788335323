import { makeStyles } from '@material-ui/core'
import imageBackground from "../../assets/background.png";
export default makeStyles((theme) => ({
    root: {
      height: "100vh",
    },
    image: {
      backgroundImage: `url(${imageBackground})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& img": {
        height: "100vh",
      },
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    loginPaper: {
      margin: "30px 30px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-evenly",
     
      "& img": {
        width: "60%",
        margin: "10% 0 30%",
      },
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
   submit:{
    backgroundColor:"#182C52",
    color:"#fff",
    "&:hover": {
        backgroundColor: "#182C52",
      },
   }
  }));